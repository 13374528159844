// import logo from "./logo.svg";
import "./App.css";
import Footer from "./components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min";

import PrivateRoute from './route-guard/private-route';
import PublicRoute from './route-guard/public-route';
import UserRoute from './route-guard/user-route';
import PortalAdminRoute from './route-guard/portal-admin-route';
import DTIAdminRoute from './route-guard/dti-admin-route';

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import FormSample from "./pages/FormBuilder"
import FormbuilderView from "./pages/FormBuilderView";
import AdminDashboard from "./pages/AdminDashboard";
import ApplicationEvaluationList from "./pages/admin/ApplicationEvaluationList";
import Registration from "./pages/user/Registration";
import Information from "./pages/user/Information";
import MyAccount from "./pages/user/Account";
import MyCompany from "./pages/user/Company";
import EditAccount from "./pages/user/EditAccount";
import isLoggedIn from "../src/store/isLoggedIn";
import user from "../src/store/user";
import token from "../src/store/token";
import personalInfo from "../src/store/personalInfo";
import lastUrl from "../src/store/lastUrl";
import ScrollToTop from "../src/imports/ScrollToTop";
import ResetPassword from "./pages/user/ResetPassword";
import EditLogin from "./pages/user/EditLogin";
import EditPersonalInfo from "./pages/user/EditPersonalInfo";
import EditStartup from "./pages/registration/EditStartup";
import PrivacyStatement from "./components/PrivacyStatement";
import StartupCompanyRegistration from "./pages/registration/StartupCompanyRegistration";
import PSDPVerification from "./pages/registration/PSDPVerification";
import AspiringIndividual from "./pages/registration/AspiringIndividual";
import StartupEnablerRegistration from "./pages/registration/StartupEnablerResgistration";
import ApplicantStartup from "./pages/admin/ApplicantStartup";
import EnablerVerification from "./pages/registration/EnablerVerification";
import Rectify from "./pages/registration/Rectify";
import IdleTimerContainer from "./components/IdleTimerContainer";
import UserCreate from "./pages/user-management/user/UserCreate";
import UserIndex from "./pages/user-management/user/UserIndex";
import PermissionCreate from "./pages/user-management/permission/PermissionCreate";
import PermissionIndex from "./pages/user-management/permission/PermissionIndex";
import RolesCreate from "./pages/user-management/roles/RolesCreate";
import RolesIndex from "./pages/user-management/roles/RolesIndex";
import SectorCreate from "./pages/libraries/sector/SectorCreate";
import StartupstageCreate from "./pages/libraries/startup-stage/StartupstageCreate";
import BusinessclassCreate from "./pages/libraries/business-class/BusinessclassCreate";
import RequirementsCreate from "./pages/libraries/requirements/RequirementsCreate";
import SocialclassCreate from "./pages/libraries/social-class/SocialclassCreate";
import currentActions from "../src/store/currentActions";
import { useState } from "react";
import OicDesignationCreate from "./pages/libraries/oic-designation/OicDesignationCreate";
import SidebarLibraries from "./components/SidebarLibraries";
import SectorIndex from "./pages/libraries/sector/SectorIndex";
import StartupstageIndex from "./pages/libraries/startup-stage/StartupstageIndex";
import BusinessclassIndex from "./pages/libraries/business-class/BusinessclassIndex";
import RequirementsIndex from "./pages/libraries/requirements/RequirementsIndex";
import SocialclassIndex from "./pages/libraries/social-class/SocialclassIndex";
import OicDesignationIndex from "./pages/libraries/oic-designation/OicDesignationIndex";
import Login from "./pages/Login";

function App() {
	let isAdmin = false;
	let isPortalAdmin = false;
	let isDTIAdmin = false;
	if(currentActions() !== null){
		if(currentActions().indexOf("/api/user/create") !== -1){
			isAdmin = true;
			isPortalAdmin = true;
		}else if(currentActions().indexOf("/api/approval/startup/evaluate") !== -1){
			isAdmin = true;
			isDTIAdmin = true;
		}
	}
	
	const dashboard = () => {
		if(isAdmin){
			return <AdminDashboard isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />
		}else{
			return <Dashboard isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />
		}
	};

	const [activePage, setActivePage] = useState("");
	const PageHandler = (page) =>{
		setActivePage(page);
	}

	return (
		<div className="App">
			<Router>
				<IdleTimerContainer isLoggedIn={isLoggedIn()}></IdleTimerContainer>
				<ScrollToTop />
				{/* <Route path='*' exact={true} component={() => <Redirect to="/home" />} /> */}
				<PublicRoute exact path="/user/signup" component={() => <Registration  PageHandler={PageHandler} activePage={activePage} />} />
				<PublicRoute exact path="/user/formbuilder" component={() => <FormSample  PageHandler={PageHandler} activePage={activePage} />} />
				<PublicRoute exact path="/user/formbuilderview" component={() => <FormbuilderView  PageHandler={PageHandler} activePage={activePage} />} />
				<UserRoute exact path="/user/resetpassword" component={() => (<ResetPassword isLoggedIn={isLoggedIn()} user={user()} token={token} PageHandler={PageHandler} activePage={activePage} />) }/>

				<Route exact path="/home" component={() => (<Home isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} PageHandler={PageHandler} activePage={activePage} />) } />
				<Route exact path="/" component={() => <Redirect to="/home" />} />
				<UserRoute exact path="/user/privacy" component={()=> (<PrivacyStatement isLoggedIn={isLoggedIn()} />)} />
				<PrivateRoute exact path="/dashboard" component={dashboard} />
				
				<UserRoute exact path="/user/signup/details" isAdmin={isAdmin} component={() => (<Information isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} PageHandler={PageHandler} activePage={activePage} />)}/>
				<UserRoute path="/myaccount" isAdmin={isAdmin} component={() => <MyAccount isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />} />
				<UserRoute path="/mycompany" isAdmin={isAdmin} component={() => <MyCompany  isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />} />
				<UserRoute exact path="/user/editaccount" isAdmin={isAdmin} component={() => <EditAccount isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} lastUrl={lastUrl()} />} />
				<UserRoute exact path="/user/editlogin" isAdmin={isAdmin} component={() => <EditLogin isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />} />

				<UserRoute path="/v2/registration/aspiring-individual" isAdmin={isAdmin} component={() => <AspiringIndividual isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />} />
				
				<UserRoute exact path="/v2/registration/startup" isAdmin={isAdmin} component={() => <StartupCompanyRegistration isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />} />
				<UserRoute exact path="/v2/registration/psdpverification" isAdmin={isAdmin} component={() => <PSDPVerification isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />} />
				
				<UserRoute path="/v2/registration/enabler" isAdmin={isAdmin} component={() => <StartupEnablerRegistration isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />} />
				<UserRoute path="/v2/registration/enablerverification" isAdmin={isAdmin} component={() => <EnablerVerification isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />} />

				<UserRoute exact path="/register/personalInfo/update" component={() => <EditPersonalInfo isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />} />
				<UserRoute exact path="/register/startup/update" isAdmin={isAdmin} component={() => (<EditStartup isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} /> )} />
				<UserRoute exact path="/register/rectify" isAdmin={isAdmin} component={() => (<Rectify isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} /> )} />

				{/* DTI ADMIN ROUTE */}
				<DTIAdminRoute exact path="/evaluate/startup" isDTIAdmin={isDTIAdmin} component={() => <ApplicantStartup isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />} />

				<DTIAdminRoute path="/startup/evaluation/index" isDTIAdmin={isDTIAdmin} component={() => <ApplicationEvaluationList isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} />} />
				{/* END OF DTI ADMIN ROUTE */}
				
				{/* PORTAL ADMIN ROUTE */}
				<PortalAdminRoute exact path="/user-management/user/create" isPortalAdmin={isPortalAdmin} component={() => <UserCreate isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/user-management/user/index" isPortalAdmin={isPortalAdmin} component={() => <UserIndex isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/user-management/permission/create" isPortalAdmin={isPortalAdmin} component={() => <PermissionCreate isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />}  />
				<PortalAdminRoute exact path="/user-management/permission/index" isPortalAdmin={isPortalAdmin} component={() => <PermissionIndex isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />}  />
				<PortalAdminRoute exact path="/user-management/roles/create" isPortalAdmin={isPortalAdmin} component={() => <RolesCreate isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/user-management/roles/index" isPortalAdmin={isPortalAdmin} component={() => <RolesIndex isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/settings" isPortalAdmin={isPortalAdmin} component={() => <SidebarLibraries />} />
				<PortalAdminRoute exact path="/libraries/sector/create" isPortalAdmin={isPortalAdmin} component={() => <SectorCreate isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/libraries/sector/index" isPortalAdmin={isPortalAdmin} component={() => <SectorIndex isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/libraries/startup-stage/create" isPortalAdmin={isPortalAdmin} component={() => <StartupstageCreate isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/libraries/startup-stage/index" isPortalAdmin={isPortalAdmin} component={() => <StartupstageIndex isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/libraries/business-classification/create" isPortalAdmin={isPortalAdmin} component={() => <BusinessclassCreate isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/libraries/business-classification/index" isPortalAdmin={isPortalAdmin} component={() => <BusinessclassIndex isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/libraries/requirements/create" isPortalAdmin={isPortalAdmin} component={() => <RequirementsCreate isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/libraries/requirements/index" isPortalAdmin={isPortalAdmin} component={() => <RequirementsIndex isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/libraries/social-classification/create" isPortalAdmin={isPortalAdmin} component={() => <SocialclassCreate isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/libraries/social-classification/index" isPortalAdmin={isPortalAdmin} component={() => <SocialclassIndex isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/libraries/oic-designation/create" isPortalAdmin={isPortalAdmin} component={() => <OicDesignationCreate isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				<PortalAdminRoute exact path="/libraries/oic-designation/index" isPortalAdmin={isPortalAdmin} component={() => <OicDesignationIndex isLoggedIn={isLoggedIn()} PageHandler={PageHandler} activePage={activePage} />} />
				{/* END OF PORTAL ADMIN ROUTEs */}

				{/* login page */}
				<Route exact path="/WxK9pQ" component={() => (<Login isLoggedIn={isLoggedIn()} user={user()} personalInfo={personalInfo()} PageHandler={PageHandler} activePage={activePage} />) } />
			</Router>
			<Footer />
		</div>
	);
}

export default App;
