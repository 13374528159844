import React, { useState, useEffect } from "react";
import "../dist/css/home.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import token from "../store/token";
import xAuthToken from "../store/xAuthToken";
import { Modal } from "react-bootstrap";

const FlaggedItemsComponent = (props) => {
  const history = useHistory();

	const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
        
	};

    const arrayParams = props.items;

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
    }, []);

    const checkApproval = () => {
        let check = false;
        for(let x = 0; x < arrayParams.length; x++){
            if(arrayParams.status.toLowercase() === "flagged"){
                check = true;
                break;
            }
        }
        return check;
    };

    const DisplayFlagged = () => {
        return arrayParams.map((value, index) => {
            let field = value.field;
            let field_status = value.status.toLowerCase();
            let remarks = value.remarks;
            return <div className="form-group" key={`field_${index}`} hidden={field_status === "passed" ? true : false}>
                <label><strong> - {field}</strong></label>
                <div className="clearfix"></div>
                <label>
                    {remarks}
                </label>
            </div>
        });
    };

    const confirmSubmission = () => {
        props.showLoading();
        
        if(checkApproval){
            let paramArray = [];
            for(let x = 0; x < arrayParams.length; x++){
                const status = arrayParams[x].status;
                const params = {
                    "status": status,
                    "_id": arrayParams[x]._id,
                    "remarks": status === "Passed" ? '' : arrayParams[x].remarks
                };
                paramArray.push(params);
            }
            const params = {
                evaluation: paramArray
            };
            const api = host + `/api/approval/startup/evaluate?_id=${props.registrationId}`;
            axios.post(api, params, {headers: headers}).then(res => {
                props.hideLoading();
                props.showModalHandler();
                setShowSuccessModal(!showSuccessModal);
            }, error => {
                props.hideLoading();
                const err = error.response;
                const data = err.data;
                const message = data.message;
                toast.error(message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            });
        }else{
            props.hideLoading();
            props.showModalHandler();
            setShowSuccessModal(!showSuccessModal);
        }
    }

    const submittedFeedback = () => {
        history.push('/startup/evaluation/index');
    }

    return (
        <React.Fragment>
            <Modal backdrop="static" keyboard={false} show={props.showModal} onHide={props.showModalHandler}>
                <Modal.Header>
                    <Modal.Title>There are flagged items in this evaluation.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DisplayFlagged />
                    <div className="py-4 text-center mt-4">
                        <a className="pointer" onClick={(e) => props.showModalHandler()} >Go Back</a>
                        <button type="button" onClick={confirmSubmission} className="startup-btn-main margin-btn">Continue</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal backdrop="static" keyboard={false} show={showSuccessModal}>
                <Modal.Body>
                    <h4 className="text-center">Registration feedback submitted!</h4>
                    <div className="py-4 text-center mt-4">
                        <button type="button" onClick={submittedFeedback} className="startup-btn-main margin-btn">OK</button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};
export default FlaggedItemsComponent;