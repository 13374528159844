import React, { useState } from "react";

import Header from "../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../environment";
import "../dist/css/main.css";

const PrivacyStatement = (props) => {
  document.title = "Privacy Statement";
  let history = useHistory();

  const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
  const user = props.user !== null ? props.user : {};
  const host = url.hostPort();
  const back = () => {
    history.goBack();
  };
  return (
    <React.Fragment>
      <Header isLoggedIn={isLoggedIn} />
      <div className="privacy-statement-container text-left mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h4>To continue with your account creation</h4>
              <p>
                <strong>Startup Innovations Portal - Privacy Policy</strong> <br />
                <br />
                The Startup Innovations Portal of the Department of Information
                and Communications Technology (DICT) firmly commits to protect
                and respect the privacy of personal information of the data
                subjects, in accordance with Republic Act No. 10173, otherwise
                known as the Data Privacy Act of 2012, as well as other relevant
                issuances.
                <br />
                <br />
                Data subjects will be provided with a Personal Information
                Collection Statement in an appropriate format and manner
                whenever personal data and sensitive information will be
                collected. The DICT ensures strict compliance with the standards
                of security and confidentiality with respect to the personal or
                sensitive data provided by the users.
                <br />
                <br />
                Collection and Usage
                <br />
                The Startup Innovations Portal may collect personal information
                to properly facilitate the registration, application, and other
                services relative to the Philippine Startup Development Program
                (PSDP). The necessary data may include:
                <br />
                <br />
                Personal information
                <br />
                User’s Full Name;
                <br />
                Birth Date;
                <br />
                Sex (Assigned at birth);
                <br />
                Preferred Pronoun;
                <br />
                Place of Birth;
                <br />
                Citizenship;
                <br />
                Home Address;
                <br />
                Contact Numbers;
                <br />
                Email Address;
                <br />
                Identification Cards;
                <br />
                Employment information and/or representation;
                <br />
                <br />
                Business Information
                <br />
                Business Name;
                <br />
                Business Classification;
                <br />
                Position/ Role within the Business;
                <br />
                Business Address
                <br />
                Area of Business Activities;
                <br />
                Details of Business Activities and Applications;
                <br />
                Business Contact Numbers;
                <br />
                Email Address; and
                <br />
                Copy of pertinent documents such as permits, licenses, etc.
                <br />
                <br />
                Protection and Disclosure
                <br />
                Startup Innovations Portal Website Registration
                <br />
                Data and information provided by the individual relative to
                their registration to the Startup Innovations Portal will only
                be shared with authorized representatives of government units
                that are mandated to process and monitor such applications.
                While the data and information stored and processed by the
                system are protected by the Department’s data privacy protocols,
                the authorized users and processors delegated by the national
                government agencies and local government units shall be
                responsible in ensuring the privacy and confidentiality of data
                disclosed to them.
                <br />
                <br />
                Philippine Startup Development Program
                <br />
                Only authorized personnel of the DICT, Department of Trade and
                Industry (DTI), and Department of Science and Technology (DOST)
                can access data collected by the Startup Innovations Portal from
                users seeking to register with the PSDP and those applying to
                the different programs, benefits, and incentives under the PSDP.
                The DICT, DTI, and DOST has implemented various organizational,
                physical, and technical measures to safeguard the user’s
                personal data.
                <br />
                <br />
                The private information gathered shall not be disclosed to any
                unauthorized personnel, division, bureau, department, or
                organization, unless otherwise mandated by the law or permitted
                by the data subjects themselves. Consent shall be secured from
                the user whenever their personally identifiable information will
                be used for other purposes not mentioned above.
                <br />
                <br />
                Retention and Disposal
                <br />
                Data gathered shall be retained only for a period necessary to
                complete a specific transaction, or as required under any
                obligation or by any law, rule, or regulation. Thereafter,
                digital data shall be anonymized, and physical copies, if any,
                shall be disposed of by shredding to ensure that data in the
                Startup Innovations Portal cannot be altered or tampered with.
                <br />
                <br />
                Access and Correction
                <br />
                Users have the right to request for their personal information
                in the possession of the Startup Innovations Portal, as well as
                to update the given details should there be any changes or
                corrections.
                <br />
                <br />
                Website Analytics
                <br />
                While the website collects web traffic data to improve the
                features of the website and the user’s experience, the Startup
                Innovations Portal does not use cookies. Data will not be shared
                with any third-parties, and only non-identifiable web data shall
                be analyzed. These include:
                <br />
                The IP address of the user’s device and details of the web
                browser used;
                <br />
                Date, time, and frequency of visit to the website;
                <br />
                Queries searched; and
                <br />
                Email address, and questions and feedback submitted by the user
                in the website.
                <br />
                <br />
                Links to Other Websites
                <br />
                The Startup Innovations Portal may contain links to other
                websites, which are covered by their own terms and conditions.
                Users are advised to take note of these sites’ respective
                privacy policies.
                <br />
                <br />
                Changes and Updates
                <br />
                The DICT reserves the right to amend, modify and/or change this
                Privacy Policy at any time, which shall take effect immediately
                after being posted on this site.
                <br />
                <br />
                Contact Us
                <br />
                For feedback and other concerns on the Startup Innovations
                Portal Privacy Policy, please contact the System Administrator
                via email at helpdesk .
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrivacyStatement;
