import axios from "axios";
import isLoggedIn from "../src/store/isLoggedIn";
import store from 'store';
import { toast } from "react-toastify";

const clearStorage = () => {
    store.remove("zgETDnOXBY");
    store.remove("LjfADyiZFB");
    store.remove("OnvBIFUcVC");
    store.remove("HYLQcSsSWe");
    store.remove("oqzAsiGkQs");
    store.remove("iSWgCQnurz");
    store.remove("MaeIHzsYGC");
    store.remove("startupRegistrationFirst");
    store.remove("startupRegistrationFirstFile");
    store.remove("startupRegistrationSecond");
};

const interceptor = () => {
    try{
        let check = axios.interceptors.response.use(undefined, function axiosRetryInterceptor(error){
            if(error.response.status === 401 && error.response.data.message === 'Token expired'){
                if(isLoggedIn()){
                    let message = "Your token has expired.";
                    toast.error(message, {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: false,
                      pauseOnHover: false,
                      draggable: false,
                      progress: undefined,
                    });
                    // clearStorage();
                    // setTimeout(function(){
                    //     try{
                    //         window.location.href = window.location;
                    //     }catch(ex){
                    //         window.location.reload();
                    //     }
                    // }, 1000);
                }else{
                    let message = error.response.data.message;
                    toast.error(message, {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: false,
                      pauseOnHover: false,
                      draggable: false,
                      progress: undefined,
                    });
                }
            }
            return Promise.reject(error);
        });
        return check;
    }catch(ex){
        console.log("interceptor", ex);
        let message = "Your token has expired.";
        toast.error(message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        clearStorage();
        setTimeout(function(){
            try{
                window.location.href = window.location;
            }catch(ex){
                window.location.reload();
            }
        }, 1000);
    }
};

export default interceptor;