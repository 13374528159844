import React, { Component, createRef, useEffect, useState } from "react";
import Header from "../components/Header";
import $ from "jquery";
import { Link, useHistory,useLocation } from "react-router-dom";

import "../dist/css/home.css";


window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
require("formBuilder/dist/form-render.min.js");


export default function FormSample() {
  const [dataSet, setDataSet] = useState([]);
  const navigate = useHistory();
  const location = useLocation();

  useEffect(() => {
    const fbTemplate = document.getElementById("build-wrap");

    //on save function for the form button save
    var options = {
      onSave: function (evt, formData) {
        toggleEdit(false);
        // $(".render-wrap").formRender({ formData });
        handleSubmit();
        /**route to the page formSample then 
        pass the form data to be able to view the form on other page **/
        navigate.push({pathname: '/user/formbuilderview',  state: { id: 1, data: formData } });
        localStorage.setItem("formdata", formData);
      },
      onClearAll: function(formData) {
        localStorage.clear("formdata");
        window.location.reload(false);
      },
      defaultFields:
      JSON.parse(localStorage.getItem("formdata")),
      persistDefaultFields: true,
      
    };

    function toggleEdit(editing) {
      document.body.classList.toggle("form-rendered", !editing);
    }
    
    //-----------to view form builder form drag and drop inside div tag id------------
      $(fbTemplate).formBuilder(options);
      

     //--------------------get data of form control JS------------------------------
    //   document.getElementById("getJs").addEventListener("click", function () {
    //     setDataSet(form.actions.getData());
    //   })
    // //   document.getElementById('getXML').addEventListener('click', function() {
    // //   setDataSet(form.actions.getData('xml'));
    // // });
    // document.getElementById('getJSON').addEventListener('click', function() {
    //     setDataSet(form.actions.getData('json', true));
    //   //   const formData = dataSet;
    //   //   console.log(formData,"formdata")
    //   //
    // });
    // document.getElementById('getJSON').addEventListener('click', function() {
    //     setDataSet(form.actions.getData('json', true));
    //   //   const formData = dataSet;
    //   //   console.log(formData,"formdata")
    //   //
    // });

    // console.log(dataSet)
  }, []);

  function handleSubmit() {
    console.log("clicked submit button");
  }
  return (
    <div >
      <Header />
      {/* <button id="getJs">Get Js</button>
      <button id="Save">Save</button>
      <button id="getJSON">Get JSON</button>
      <div id="build-wrap" />
      <form id="fb-render" >

      </form> */}
      <h3 style={{padding: "20px", textAlign: "center"}}>Form Builder</h3>
      <div style={{padding: "20px"}} id="build-wrap"></div>
      <form
        onSubmit={handleSubmit}
        class="render-wrap"
        style={{ width: "50%", margin: "auto" }}
      ></form>
    </div>
  );
}
