import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import store from "./store/store";
import axios from "axios";
import isLoggedIn from "../src/store/isLoggedIn";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import interceptor from './interceptor';
import "bootstrap-icons/font/bootstrap-icons.css";

interceptor();

ReactDOM.render(
    <React.StrictMode>
        {/* <Provider store={store}>
            <ToastContainer />
            <App />
        </Provider> */}
        <ToastContainer />
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
