import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../dist/css/editaccount.css";
import * as moment from "moment";
import store from "store";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import LoadingService from "../../imports/LoadingService";

const accountSchema = Yup.object().shape({
    lastName: Yup.string().required("This is Required!"),
    firstName: Yup.string().required("This is Required!"),
    suffix: Yup.string(),
    middleName: Yup.string().required("This is Required!"),
    sex: Yup.boolean(),
    birthDate: Yup.string().required("This is Required!").nullable(),
    placeOfBirth: Yup.string().required("This is Required!"),
    contact_no: Yup.string("This is Required!"),
    citizenship: Yup.string(),
    others: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema;
		}else{
			return schema.required('This is Required!');
		}
	}),
});

const EditPersonalInfo = (props) => {
	document.title = 'Update Personal Information';
    const history = useHistory();
	const formikRef = useRef();

	const [loader, showLoader, hideLoader] = LoadingService();

    const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
        
	};

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const user = props.user && Object.keys(props.user).length !== 0 ? props.user : {};
    const personalInfo = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? props.personalInfo : "";
    const buttonLabel = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? "Update" : "Create";

    const [citizenshipStatus, setStatus] = useState(0);
    const [sexStatus, setSex] = useState(0);

    const [isLoaded, setIsLoaded] = useState(false);

    const [personalValue, setPersonalValue] = useState({
        lastName: personalInfo === "" ? "" : personalInfo.lastname,
        firstName: personalInfo === "" ? "" : personalInfo.firstname,
        suffix: personalInfo === "" ? "" : personalInfo.extname,
        middleName: personalInfo === "" ? "" : personalInfo.middlename,
        birthDate: personalInfo === "" ? "" : personalInfo.birthday,
        placeOfBirth: personalInfo === "" ? "" : personalInfo.birthplace,
        others: personalInfo === "" ? "" : personalInfo.citizenship !== "Filipino" ? personalInfo.citizenship : "",
        sex: personalInfo === "" ? 1 : personalInfo.sex,
        citizenship: personalInfo === ""  ? 0 : personalInfo.citizenship !== "Filipino" ? 1 : 0,
        contact_no: personalInfo === "" ? "" : personalInfo.contact_no === null ? "" : personalInfo.contact_no,
    });

    const [personalApproval, setPersonalApproval] = useState({
        'firstname': {
            'hidden': true,
            'remarks': ''
        },
        'middlename': {
            'hidden': true,
            'remarks': ''
        },
        'lastname': {
            'hidden': true,
            'remarks': ''
        },
        'extname': {
            'hidden': true,
            'remarks': ''
        },
        'birthday': {
            'hidden': true,
            'remarks': ''
        },
        'sex': {
            'hidden': true,
            'remarks': ''
        },
        'birthplace': {
            'hidden': true,
            'remarks': ''
        },
        'citizenship': {
            'hidden': true,
            'remarks': ''
        },
        'contact_no': {
            'hidden': true,
            'remarks': ''
        },
    });

    const citizenshipHandler = (value) => {
        setStatus(value);
    };

    const sexHandler = (value) => {
        setSex(value);
    };

    const getApprovals = async (userId) => {
        showLoader();
        let api = host + `/api/v1/registration/approvals?user_id=${userId}&view=all&group_by=personal_info,personal_info.contact_no`;
        axios.get(api, {headers: headers}).then(res => {
            hideLoader();
            let result = res.data;
            let data = result.items;
            checkApproval(data);
        }, error => {
            hideLoader();
            let errorMessage = "Registration not found.";
            toast.error(errorMessage, {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
            history.push('/dashboard');
        });
    };

    const checkApproval = (fields) => {
        for(let x = 0; x < fields.length; x++){
            if(fields[x].remarks !== null && fields[x].field_status === 2){
                let name = fields[x].field_name;
                const oldList = personalApproval;
                oldList[name] = {...oldList[name], hidden: false, remarks: fields[x].remarks};
                setPersonalApproval(oldList);
            }
        }
        setIsLoaded(true);
    };

    const submitAccount = async (values) => {
        showLoader();
        let params = {
        user_id: user.user_id,
        firstname: values.firstName,
        middlename: values.middleName,
        lastname: values.lastName,
        extname: values.suffix,
        sex: values.sex,
        birthday: moment(values.birthDate).format("YYYY-MM-DD"),
        birthplace: values.placeOfBirth,
        citizenship: values.citizenship === 0 ? "Filipino" : values.others,
        contact_no: values.contact_no,
        };

        let endpoint = `/api/v1/personal-info/edit-info?user_id=${user.user_id}&personal_info_id=${personalInfo.personal_info_id}`;
        const api = host + endpoint;
        axios.put(api, params, {headers: headers,}).then(
        (res) => {
            hideLoader();
            let result = res.data;
            let data = result.data;
            store.set("personalInfo", data);
            toast.info(result.message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            });
            history.push(`/myaccount?_id=${user.user_id}`);
        },
        (error) => {
            hideLoader();
            let errorMessage = "Error!";
            toast.error(errorMessage, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            });
        }
        );
    };

    useEffect(() => {
        let citizenship = personalInfo === "" ? 0 : personalInfo.citizenship !== "Filipino" ? 1 : 0;
        let sex = personalInfo === "" ? 1 : personalInfo.sex;
        setStatus(citizenship);
        setSex(sex);
        getApprovals(user.user_id);
    }, [props]);

    const showOthers = (touched, errors) => {
        return (
        <div className="form-group">
            <Field
            type="text"
            name="others"
            autoComplete="off"
            className={`form-control form-control-sm ${
                touched.others && errors.others ? "is-invalid" : ""
            }`}
            />
            <ErrorMessage
            component="div"
            name="others"
            className="invalid-feedback"
            />
        </div>
        );
    };

  return (
    <React.Fragment>
        {loader}
        <Header isLoggedIn={isLoggedIn} />
        <div className="information-container text-left mt-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                    <h4>Update Personal Information</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                    {isLoaded ? 
                        <Formik
                            innerRef={formikRef}
                            validationSchema={accountSchema}
                            onSubmit={submitAccount}
                            initialValues={personalValue}
                        >
                            {({ handleSubmit, errors, touched, values, setFieldValue }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <div className="form-group" hidden={personalApproval.lastname.hidden}>
                                    <label htmlFor="lastName">Last Name</label>
                                    <Field type="text" name="lastName" autoComplete="off" className={`form-control ${touched.lastName && errors.lastName ? "is-invalid" : ""}`} />
                                    <ErrorMessage component="div" name="lastName" className="invalid-feedback" />
                                </div>
                                <div className="form-group" hidden={personalApproval.lastname.hidden}>
                                    <label>Remarks: <small className="red">{personalApproval.lastname.remarks}</small></label>
                                </div>
        
                                <div className="form-group" hidden={personalApproval.firstname.hidden}>
                                    <label htmlFor="firstName">First Name</label>
                                    <Field type="text" name="firstName" autoComplete="off" className={`form-control ${touched.firstName && errors.firstName ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="firstName" className="invalid-feedback" />
                                </div>
                                <div className="form-group" hidden={personalApproval.firstname.hidden}>
                                    <label>Remarks: <small className="red">{personalApproval.firstname.remarks}</small></label>
                                </div>
                                
                                <div className="form-group" hidden={personalApproval.extname.hidden}>
                                    <label htmlFor="suffix">Suffix</label>
                                    <Field type="text" name="suffix" autoComplete="off" className={`form-control ${touched.suffix && errors.suffix ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="suffix" className="invalid-feedback" />
                                </div>
                                <div className="form-group" hidden={personalApproval.extname.hidden}>
                                    <label>Remarks: <small className="red">{personalApproval.extname.remarks}</small></label>
                                </div>
        
                                <div className="form-group" hidden={personalApproval.middlename.hidden}>
                                    <label htmlFor="suffix">Middle Name</label>
                                    <Field type="text" name="middleName" autoComplete="off" className={`form-control ${touched.middleName && errors.middleName ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="middleName" className="invalid-feedback" />
                                </div>
                                <div className="form-group" hidden={personalApproval.middlename.hidden}>
                                    <label>Remarks: <small className="red">{personalApproval.middlename.remarks}</small></label>
                                </div>
        
                                <label htmlFor="sex" hidden={personalApproval.sex.hidden}>Sex (Assigned at birth)</label>
                                <div className="form-check" hidden={personalApproval.sex.hidden}>
                                    <Field className="form-check-input" id="male" type="radio" name="sex"
                                        value="Male"
                                        onChange={() => setFieldValue("sex", 1)}
                                        checked={sexStatus === 1}
                                        onClick={(e) => sexHandler(1)}
                                    />
                                    <label className="form-check-label" htmlFor="male"> Male{" "} </label>
                                </div>
                                <div className="form-check mb-2" hidden={personalApproval.sex.hidden}>
                                    <Field className="form-check-input" id="female" type="radio" name="sex"
                                        value="Female"
                                        onChange={() => setFieldValue("sex", 0)}
                                        checked={sexStatus === 0}
                                        onClick={(e) => sexHandler(0)}
                                    />
                                    <label className="form-check-label" htmlFor="female"> Female{" "} </label>
                                </div>
                                <div className="form-group" hidden={personalApproval.sex.hidden}>
                                    <label>Remarks: <small className="red">{personalApproval.sex.remarks}</small></label>
                                </div>
        
                                <div className="form-group" hidden={personalApproval.birthday.hidden}>
                                    <label htmlFor="birthDate">Birth Date</label>
                                    <Field type="date" name="birthDate" autoComplete="off" className={`form-control ${touched.birthDate && errors.birthDate ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="birthDate" className="invalid-feedback display-block" />
                                </div>
                                <div className="form-group" hidden={personalApproval.birthday.hidden}>
                                    <label>Remarks: <small className="red">{personalApproval.birthday.remarks}</small></label>
                                </div>
        
                                <div className="form-group" hidden={personalApproval.birthplace.hidden}>
                                    <label htmlFor="placeOfBirth">Place of Birth</label>
                                    <Field
                                        type="text"
                                        name="placeOfBirth"
                                        autoComplete="off"
                                        className={`form-control ${
                                        touched.placeOfBirth && errors.placeOfBirth
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="placeOfBirth"
                                        className="invalid-feedback"
                                    />
                                </div>
                                <div className="form-group" hidden={personalApproval.birthplace.hidden}>
                                    <label>Remarks: <small className="red">{personalApproval.birthplace.remarks}</small></label>
                                </div>
                                
                                <div className="form-group" hidden={personalApproval.citizenship.hidden}>
                                    <label htmlFor="citizenship">Citizenship</label>
                                    <div className="form-check">
                                        <Field
                                            className="form-check-input"
                                            id="filipino"
                                            type="radio"
                                            name="filipino"
                                            value="Filipino"
                                            onChange={() => setFieldValue("citizenship", 0)}
                                            checked={citizenshipStatus === 0}
                                            onClick={(e) => citizenshipHandler(0)}
                                        />
                                        <label className="form-check-label" htmlFor="filipino">
                                            Filipino{" "}
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <Field
                                            className="form-check-input"
                                            id="others"
                                            type="radio"
                                            name="others"
                                            value="Others"
                                            onChange={() => setFieldValue("citizenship", 1)}
                                            checked={citizenshipStatus === 1}
                                            onClick={(e) => citizenshipHandler(1)}
                                        />
                                        <label className="form-check-label" htmlFor="others">Others{" "}</label>
                                        {citizenshipStatus === 1 ? showOthers(touched, errors) : null}
                                    </div>
                                    <div className="form-group">
                                        <label>Remarks: <small className="red">{personalApproval.citizenship.remarks}</small></label>
                                    </div>
                                </div>
                                
                                <div className="form-group" hidden={personalApproval.contact_no.hidden}>
                                    <label htmlFor="contact_no">Contact No.</label>
                                    <Field type="text" name="contact_no" autoComplete="off" className={`form-control ${touched.contact_no && errors.contact_no ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="contact_no" className="invalid-feedback" />
                                </div>
                                <div className="form-group" hidden={personalApproval.contact_no.hidden}>
                                    <label>Remarks: <small className="red">{personalApproval.contact_no.remarks}</small></label>
                                </div>
        
                                <div className="text-center">
                                    <button className="startup-btn mt-4" type="submit">
                                        <b>Update</b>
                                    </button>
                                </div>
                            </Form>
                            )}
                        </Formik>
                    :
                        null
                    }
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  );
};

export default EditPersonalInfo;
