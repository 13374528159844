const URL = {
    hostPort: function() {
      return `${window.location.protocol}//${process.env.REACT_APP_HOST}`;
    },
    registration: function() {
      return process.env.REACT_APP_REGISTRATION ? process.env.REACT_APP_REGISTRATION : true;
    },
    maintenance: function(){
      return process.env.REACT_APP_MAINTENANCE ? process.env.REACT_APP_MAINTENANCE : true;
    }
};

export default URL;
