import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import { toast } from 'react-toastify';
import LoadingService from "../../imports/LoadingService";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import "../../dist/scss/custom.scss"; 
import { Modal } from "react-bootstrap";
import ImageUploaderField from '../../imports/Upload';

const requiredLabel = 'This is Required!';

const oicSchema = Yup.object().shape({
    isOIC: Yup.boolean(),
	designation: Yup.string().required(requiredLabel),
	lastName: Yup.string().required(requiredLabel),
	firstName: Yup.string().required(requiredLabel),
	suffix: Yup.string(),
	middleName: Yup.string().required(requiredLabel),
	citizenship: Yup.string(),
	others: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
    sex: Yup.number(),
    birthDate: Yup.string().required(requiredLabel),
    placeOfBirth: Yup.string().required(requiredLabel),
    contactno: Yup.string().required(requiredLabel),
    email: Yup.string().required(requiredLabel).email('Invalid email format.'),
    oicRequirement: Yup.string().required(requiredLabel),
    oicRequirementFile: Yup.mixed().required(requiredLabel).test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        } 

    }),
    oicPassportIdentifier: Yup.string(),
    oicPassportRequirement: Yup.string(),
    oicPassportRequirementFile: Yup.mixed().test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        } 

    }).when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
});

const StartupEnablerOIC = (props) => {
	const history = useHistory();

	const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const user = props.user && Object.keys(props.user).length !== 0 ? props.user : '';
	const personalInfo = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? props.personalInfo : '';

	const [requirementList, setRequirementList] = useState([]);
	const [transformRequirementList, setTransformList] = useState([]);

    const [disabledEmailField, setDisabledEmailField] = useState(false);

	const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
        
	};

	const designationOptions = ['CEO', 'Founder', 'President', 'Owner'];

    const [oicValue, setOICValue] = useState({
        'designation': '',
        'lastName': '',
        'firstName': '',
        'suffix': '',
        'middleName': '',
        'citizenship': 0,
        'others': '',
        'contactno': '',
        'sex': 1,
        'birthDate': '',
        'placeOfBirth': '',
        'email': '',
        'oicRequirement': '',
        'oicRequirementFile': undefined,
        'oicPassportIdentifier': '',
        'oicPassportRequirement': '',
        'oicPassportRequirementFile': undefined,
	});

    async function getRequirementList() {
		const endpoint = `/api/v1/requirements?requirement_category=STARTUP_INFO_BUSINESS&expand=requirement_type,requirement_category&view=all&sort=requirement_name`;
		const api = host + endpoint;
		let response = await axios.get(api, {headers: headers});
		let result = response.data;
		let data = result.items;
        data = data.filter(function(item){ return item.requirement_name !== 'PhilHealth ID'});
        data = data.filter(function(item){ return item.requirement_name !== 'OFW ID'});
        data = data.filter(function(item){ return item.requirement_name !== 'TIN ID'});
        data = data.filter(function(item){ 
            if(item.requirement_name === "Senior Citizen ID"){
                item.requirement_name = "Senior Citizen's ID";
            }
            return item;
        });
		setRequirementList(data);
		mapRequirementList(data);
	};
    
    const mapRequirementList = (data) => {
		let newList = [];
		if(citizenshipStatus == 0){ //filipino
			newList = data.filter(function(item){ return item.requirement_type === 1 });
		}else{
			let newData = data.filter(function(item){ return item.requirement_type === 2 });
            newData.map(function(item){
                if(item.requirement_name === "Special Investor's Resident Visa"){
                    newList.push(item);
                }else if(item.requirement_name === "Certificate of Authority to Engage in Business in the Philippines"){
                    newList.push(item);
                }else if(item.requirement_name === "Others"){
                    newList.push(item);
                }
            });
            
		}
		setTransformList(newList);
	};

    const [requirementLabel, setRequirmentLabel] = useState('Government-Issued ID (for Filipino OIC)');
	const setRequirmentLabelHandler = (value) => {
		setRequirmentLabel(value);
	};

    const [isOfficerInCharge, setOfficerInCharge] = useState(false);
	const setOfficerInChargeHandler = () => {
		setOfficerInCharge(!isOfficerInCharge);
	};

    const [citizenshipStatus, setStatus] = useState(0);
	const citizenshipHandler = (value) =>{
		setStatus(value);
	};

    const [sexStatus, setSex] = useState(1);
    const sexHandler = (value) => {
        setSex(value);
    };

    const onClickOfficerInCharge = (value, setFieldValue, touched, errors) => {
		if(value){
			setFieldValue('lastName', personalInfo.lastname);
			setFieldValue('firstName', personalInfo.firstname);
			setFieldValue('suffix', personalInfo.extname);
			setFieldValue('middleName', personalInfo.middlename);
			setFieldValue('contactno', personalInfo.contact_no);
			setFieldValue('citizenship', personalInfo.citizenship.toLowerCase() == 'filipino' ? 0 : 1);
			setFieldValue('others', personalInfo.citizenship.toLowerCase() == 'filipino' ? '' : personalInfo.citizenship);
			citizenshipHandler(personalInfo.citizenship.toLowerCase() == 'filipino' ? 0 : 1);
			setRequirmentLabelHandler(personalInfo.citizenship.toLowerCase() == 'filipino' ? 'Government-Issued ID (for Filipino OIC)' : 'Other documents to support eligibity to engage in business in the Philippines');
		
            setFieldValue('contactno', personalInfo.contact_no);
            setFieldValue('email', personalInfo.email_address);
            setFieldValue('sex', personalInfo.sex);
            setFieldValue('birthDate', personalInfo.birthday);
            setFieldValue('placeOfBirth', personalInfo.birthplace);
            sexHandler(personalInfo.sex);

			touched.lastName = false;
			touched.firstName = false;
			touched.suffix = false;
			touched.middleName = false;
            touched.contactno = false;
            touched.email = false;
            touched.birthDate = false;
            touched.placeOfBirth = false;

            setDisabledEmailField(true);
		}else{
			setFieldValue('lastName', '');
			setFieldValue('firstName','');
			setFieldValue('suffix', '');
			setFieldValue('middleName', '');
			setFieldValue('contactno', '');
			setFieldValue('citizenship', 0);
			setFieldValue('others', '');
            setFieldValue('contactno', '');
            setFieldValue('email', '');
            setFieldValue('sex', 1);
            setFieldValue('birthDate', '');
            setFieldValue('placeOfBirth', '');

			citizenshipHandler(0);
            sexHandler(1);

            setDisabledEmailField(false);
		}
		setFieldValue('isOIC', value);
	};

    const showOthers = (touched, errors, values) => {
		return <div className="form-group">
			<Field value={values.others || ''} type="text" name="others" autoComplete="off" className={`form-control form-control-sm ${touched.others && errors.others ? "is-invalid" : ""}`} />
			<ErrorMessage component="div" name="others" className="invalid-feedback" />
		</div>
	};

    const back = (page) => {
        history.push('/dashboard');
    };

    const next = (page) => {
        window.scrollTo(0, 0);
        props.setFormPage(page);
    };
    
    useEffect( () => {
		let newList = [];
		if(citizenshipStatus == 0){ //filipino
			newList = requirementList.filter(function(item){ return item.requirement_type === 1 });
		}else{
			let newData = requirementList.filter(function(item){ return item.requirement_type === 2 });
            newData.map(function(item){
                if(item.requirement_name === "Special Investor's Resident Visa"){
                    newList.push(item);
                }else if(item.requirement_name === "Certificate of Authority to Engage in Business in the Philippines"){
                    newList.push(item);
                }else if(item.requirement_name === "Others"){
                    newList.push(item);
                }
            });
		}
		setTransformList(newList);
	}, [citizenshipStatus]);

    useEffect(() => {
		getRequirementList();
	}, []);
    return (
        <React.Fragment>
            <div className="startup-enabler-container mt-4">
                <div className="container mb-5">
                    <div className="row border bg-white">
                        <div className="col-md-8 col-sm-12 form-align mb-5">
                            <h4 className="mt-5">Officer-in-Charge Information (CEO/Founder/President/Owner)</h4>
                            <hr></hr>
                            <div className="clearfix">&nbsp;</div>
                            <Formik
                                validationSchema={oicSchema}
                                initialValues={Object.assign(oicValue)}
                                onSubmit={next}
                                key="oicSchema"
                            >
                                {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values, field }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <div className="text-left">
                                            <div className="form-group">
                                                <label htmlFor="designation" ><strong>Designation</strong></label>
                                                <select name="designation"
                                                    className={`form-control ${touched.designation && errors.designation ? "is-invalid" : ""}`}
                                                    value={values.designation || ''}
                                                    onChange={val => { 
                                                        const selectedIndex = val.target.options.selectedIndex; 
                                                        let designation = val.target.options[selectedIndex].getAttribute('label');
                                                        setFieldValue('designation', designation);
                                                    }}
                                                >
                                                    <option name="designation_" key="3" value="" label="" />
                                                    {designationOptions.map((value, index) => {
                                                        return <option value={value} name={'designation_' + index} key={'designation_' + value} label={value} />
                                                    })}
                                                </select>
                                                <ErrorMessage component="div" name="designation" className="invalid-feedback" />
                                            </div>
                                            <div className="form-check">
                                                <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="officerInCharge"
                                                onChange={event => {
                                                    setOfficerInChargeHandler();
                                                }}
                                                onClick={event => {
                                                    let checked = event.target.checked;
                                                    onClickOfficerInCharge(checked, setFieldValue, touched, errors);
                                                }}
                                                checked={isOfficerInCharge === true}
                                                />
                                                <label className="form-check-label" htmlFor="officerInCharge">
                                                    I am the Officer-in-Charge. Use my personal information to
                                                    fill out this section.
                                                </label>
                                                <div className="form-group">
                                                    <label htmlFor="lastName"><b>Last Name</b></label>
                                                    <Field type="text" value={values.lastName || ''} name="lastName" autoComplete="off" className={`form-control ${touched.lastName && errors.lastName ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="lastName" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="firstName"><b>First Name</b></label>
                                                    <Field type="text" value={values.firstName || ''} name="firstName" autoComplete="off" className={`form-control ${touched.firstName && errors.firstName ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="firstName" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="suffix"><b>Suffix</b></label>
                                                    <Field type="text" value={values.suffix || ''} name="suffix" autoComplete="off" className={`form-control ${touched.suffix && errors.suffix ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="suffix" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="middleName"><b>Middle Name</b></label>
                                                    <Field type="text" value={values.middleName || ''} name="middleName" autoComplete="off" className={`form-control ${touched.middleName && errors.middleName ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="middleName" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="sex"><b>Sex (Assigned at birth)</b></label>
                                                    <div className="form-check">
                                                        <Field
                                                        className="form-check-input"
                                                        id="male"
                                                        type="radio"
                                                        name="sex"
                                                        value="Male"
                                                        onChange={() => setFieldValue("sex", 1)}
                                                        checked={sexStatus === 1}
                                                        onClick={(e) => sexHandler(1)}
                                                        />
                                                        <label className="form-check-label" htmlFor="male">
                                                        Male{" "}
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <Field
                                                        className="form-check-input"
                                                        id="female"
                                                        type="radio"
                                                        name="sex"
                                                        value="Female"
                                                        onChange={() => setFieldValue("sex", 0)}
                                                        checked={sexStatus === 0}
                                                        onClick={(e) => sexHandler(0)}
                                                        />
                                                        <label className="form-check-label" htmlFor="female">
                                                        Female{" "}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="birthDate"><b>Birth Date</b></label>
                                                    <Field
                                                    type="date"
                                                    name="birthDate"
                                                    autoComplete="off"
                                                    className={`form-control ${
                                                        touched.birthDate && errors.birthDate
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                    />
                                                    <ErrorMessage
                                                    component="div"
                                                    name="birthDate"
                                                    className="invalid-feedback display-block"
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="placeOfBirth"><b>Place of Birth</b></label>
                                                    <Field
                                                    type="text"
                                                    name="placeOfBirth"
                                                    autoComplete="off"
                                                    className={`form-control ${
                                                        touched.placeOfBirth && errors.placeOfBirth
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                    />
                                                    <ErrorMessage
                                                    component="div"
                                                    name="placeOfBirth"
                                                    className="invalid-feedback"
                                                    />
                                                </div>
                                                <label htmlFor="citizenship"><b>Citizenship</b></label>
                                                <div className="form-check">
                                                    <Field id="filipino" className="form-check-input" type="radio" name="filipino" value="0"
                                                        checked={citizenshipStatus === 0} 
                                                        onClick={event => {
                                                            citizenshipHandler(0);
                                                            let value = event.target.value;
                                                            if(value != citizenshipStatus){
                                                                setFieldValue('oicRequirement', '');
                                                                setFieldValue('oicRequirementFile', '');
                                                                setFieldValue('oicRequirementIdentifier', '');
                                                                
                                                                setFieldValue('oicPassportRequirement', '');
                                                                setFieldValue('oicPassportRequirementFile', '');
                                                            }
                                                            setFieldValue("citizenship", 0);
                                                        }}
                                                        onChange={event => {
                                                            setRequirmentLabelHandler('Government-Issued ID (for Filipino OIC)');
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="filipino">Filipino </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <Field id="others" className="form-check-input" type="radio" name="others" value="1" 
                                                        checked={citizenshipStatus === 1}
                                                        onClick={event => {
                                                            citizenshipHandler(1);
                                                            let value = event.target.value;
                                                            if(value != citizenshipStatus){
                                                                setFieldValue('oicRequirement', '');
                                                                setFieldValue('oicRequirementFile', '');
                                                                setFieldValue('oicRequirementIdentifier', '');
                                                                
                                                                setFieldValue('oicPassportRequirement', '');
                                                                setFieldValue('oicPassportRequirementFile', '');
                                                            }
                                                            setFieldValue("citizenship", 1);
                                                        }}
                                                        onChange={event => {
                                                            setRequirmentLabelHandler('Other documents to support eligibity to engage in business in the Philippines');
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="others">Others </label>
                                                    { citizenshipStatus === 1 ? showOthers(touched, errors, values) : null }
                                                </div>
                                                
                                                <div className="form-group">
                                                    <label htmlFor="contactno"><b>Contact Number</b></label>
                                                    <Field type="text" value={values.contactno || ''} name="contactno" autoComplete="off" className={`form-control ${touched.contactno && errors.contactno ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="contactno" className="invalid-feedback" />
                                                </div>
                                                
                                                <div className="form-group">
                                                    <label htmlFor="email"><b>Email Address</b></label>
                                                    <Field disabled={disabledEmailField} type="text" value={values.email || ''} name="email" autoComplete="off" className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="email" className="invalid-feedback" />
                                                </div>
                                                
                                                <div className="form-group" hidden={citizenshipStatus === 0 ? true : false}>
                                                    <label htmlFor="oicPassportRequirementFile"><b>Passport Datasheet (for Non-Filipino OIC)</b></label>
                                                    <p><strong>NOTE:</strong> Maximum upload file size: 12 MB.</p>
                                                    <ImageUploaderField accept="image/*,application/pdf" name="oicPassportRequirementFile" type="file" value={undefined}
                                                        className={`form-control-file ${touched.oicPassportRequirementFile && errors.oicPassportRequirementFile ? "is-invalid" : ""}`}
                                                        onChange={value => {
                                                            setFieldValue("oicPassportRequirementFile", value.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    <ErrorMessage component="div" name="oicPassportRequirementFile" className="invalid-feedback" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="oicRequirement"><b>{requirementLabel}</b></label>
                                                    <select name="oicRequirement"
                                                        className={`form-control ${touched.oicRequirement && errors.oicRequirement ? "is-invalid" : ""}`}
                                                        value={values.oicRequirement || ''}
                                                        onChange={val => { 
                                                            const selectedIndex = val.target.options.selectedIndex; 
                                                            let code = val.target.options[selectedIndex].getAttribute('name');
                                                            code = code.replace("oicRequirement_", "");
                                                            let label = val.target.options[selectedIndex].getAttribute('label');
                                                            setFieldValue('oicRequirement', code);
                                                            setFieldValue('oicRequirementIdentifier', label);
                                                        }}
                                                    >
                                                        <option name="oicRequirement_" key="3" value="" label="" />
                                                        {transformRequirementList.map((value, index) => {
                                                            return <option value={value.requirement_id} name={'oicRequirement_' + value.requirement_id} key={'oicRequirement_' + index} label={value.requirement_name} />
                                                        })}
                                                    </select>
                                                    <ErrorMessage component="div" name="requirement" className="invalid-feedback" />
                                                </div>

                                                <div className="form-group">
                                                    <p><strong>NOTE:</strong> Maximum upload file size: 12 MB.</p>
                                                    <ImageUploaderField accept="image/*,application/pdf" name="oicRequirementFile" type="file" value={undefined}
                                                        className={`form-control-file ${touched.oicRequirementFile && errors.oicRequirementFile ? "is-invalid" : ""}`}
                                                        onChange={value => {
                                                            setFieldValue("oicRequirementFile", value.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    <ErrorMessage component="div" name="oicRequirementFile" className="invalid-feedback" />
                                                </div>
                                                
                                            </div>
                                            <div className="py-4 text-center mt-4">
                                                <button type="button" className="startup-btn-main mx-3" onClick={() => { back(0)}}>Back</button>
                                                <button type="button" className="startup-btn-main" onClick={() => { next(1)}}>Next</button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default StartupEnablerOIC;