import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import store, { set, get } from "store";
import { Modal } from "react-bootstrap";
import LoadingService from "../../imports/LoadingService";

const registrationSchema = Yup.object().shape({
	startupStage: Yup.string().required('This is Required.'),
	classification: Yup.array().min(1, 'This is required.').of(Yup.string().trim().required())
});

const StartupCompanyRegistration = (props) => {
	document.title = 'Startup Company Account Registration';
    const history = useHistory();

	const [loader, showLoader, hideLoader] = LoadingService();

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const user = props.user !== null ? props.user : {};
    const personalInfo = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? props.personalInfo : "";

    const host = url.hostPort();
    const headers = {
        Authorization: `Bearer ${token()}`,
        
    };

    const [sectorList, setSectorList] = useState([]);
    const [startupStageList, setStartupStageList] = useState([]);

	const [formValue, setFormValue] = useState({
		'startupStage': '',
		'classification': [],
	});

	const [classificationList, setClassificationList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [registrationNo, setRegistrationNo] = useState('');
    const [reg_uuid, setRegUUID] = useState('');

    const numberPerColumn = Math.ceil(sectorList.length / 3); 

    const modalHandler = () => {
        setShowModal(!showModal);
    };

    const submitRegistration = (values) => {
        const sectors = values.classification;
        if(sectors.length < 1){
            const errorMessage = "Startup sector/s is required.";
			toast.error(errorMessage, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
			});
        }else{
            showLoader();
            const endpoint = `/api/startup/account/create?_id=${user._id}`;
		    const api = host + endpoint;
            let params = {
                'applicantType': 4,
                'startupStage': values.startupStage,
                'sectors': sectors
            }

            axios.post(api, params, {headers: headers}).then((res) => {
                let result = res.data;
                setRegistrationNo(result.startupId);
                modalHandler();
                hideLoader();
            }, error => {
                toast.error(error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
                hideLoader();
            });
        }
    };

    const redirectBusinessRegistration = () => {
        history.push(`/v2/registration/psdpverification?_id=${user._id}`);
    }

    const redirectDashboard = () => {
        history.push('/dashboard');
    };

    const getSectorList = () => {
        let params = {
            "isActive": true,
            "sort":  {
                "name": 1
            }
        }
		const endpoint = `/api/sector/search`;
		const api = host + endpoint;
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            for(let x = 0; x < data.length; x++){
                data[x].checked = false;
            }
            setSectorList(data);
        }, error => {
        });
    };

    const getStartupStage = () => {
        let params = {
            "isActive": true,
            "sort":  {
                "name": 1
            }
        }
		const endpoint = `/api/startupPhase/search`;
		const api = host + endpoint;
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            setStartupStageList(data);
        }, error => {
        });
    };

    const checkRegistration = () => {
      showLoader();
      let api = host + `/api/startup/registration/check?_id=${user._id}`;
      axios.get(api, {headers: headers}).then((res) => {
        let result = res.data;
        console.log(result);
        if(result.startupType !== null){
            if(result.isVerified){
                history.push('/dashboard');
                let error = 'Registration is already created.';
                toast.error(error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }
            if(result.startupType == 2 || result.startupType == 3){
                history.push('/dashboard');
                let error = 'Registration is already created.';
                toast.error(error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }
        }
        getSectorList();
        getStartupStage();
        hideLoader();
      }, error => {
        hideLoader();
      });
    };

	useEffect(() => {
        checkRegistration();
	}, []);
    
    return (
        <React.Fragment>
            {loader}
            <Header isLoggedIn={isLoggedIn} />
			<div className="startup-business-container mt-4">
				<div className="container mb-5">
					<div className="row bg-white border">
						<div className="col-md-8 col-sm-12 form-align mb-5">
                            <h4 className="mt-5">Startup Business / Entity</h4>
                            <hr></hr>
                            <p>
                                A <b>"Startup Business"</b> is a <b>registered entity</b> in the Philippines,
                                <b> engaged in any activity</b> during the validation and growth phases
                                in <b>startup development</b> (including, but not limited to,
                                development of Minimum Viable Product, market validation,
                                scaling up) and has not exceeded ten (10) years from the date of
                                its business registration or incorporation. Any pre-business
                                registration or pre-incorporation years is not counted against
                                this period.
                            </p>
                            <div className="clearfix">&nbsp;</div>
                            <Formik
                                validationSchema={registrationSchema}
                                initialValues={Object.assign(formValue)}
                                onSubmit={submitRegistration}
                            >
                                {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values, field }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label><strong>Startup Development Phase*</strong></label>
                                            <Field as="radio" name="startupStageField" className={`${touched.startupStage && errors.startupStage ? "is-invalid" : ""}`}>
                                            {startupStageList.map((value, index) => {
                                                return (
                                                <div key={`startupStage_${value._id}`} className="form-check">
                                                <input
                                                    name={`startupStage_${index}`}
                                                    className="form-check-input"
                                                    type="radio"
                                                    id={`startupStage_${index}`}
                                                    value={value._id}
                                                    checked={values.startupStage ? values.startupStage == value._id : false}
                                                    onChange={event => {
                                                    }}
                                                    onClick={(e) => {
                                                        let value = e.target.value;
                                                        setFieldValue("startupStage", value);
                                                    }}
                                                    />
                                                    <label className="form-check-label" htmlFor={`startupStage_${index}`}>{value.name}</label>
                                                </div> 
                                                )
                                            })}
                                            </Field>
                                            <ErrorMessage component="div" name="startupStage" className="invalid-feedback" />
                                        </div>
                                        {/* <div className="form-group">
                                            <label><strong>Startup Development Phase*</strong></label>
                                            {startupStageList.map((value, index) => {
                                                return 	<div className="form-check" key={`stage_${value._id}`}>
                                                    <Field id={`radioButton_${value._id}`} className="form-check-input" type="radio" name={`startupStage_${value.name}`} value={value._id}
                                                        checked={ values.startupStage ? values.startupStage == value._id : false }
                                                        onClick={event => {
                                                            let value = event.target.value;
                                                            setFieldValue("startupStage", value);
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={`radioButton_${value._id}`}>{value.name}</label>
                                                </div>
                                            })}
                                        </div> */}
                                        <div className="form-group flex-wrap">
                                            <label><strong>Startup Sector/s*</strong></label>
                                            <div className="clearfix"></div>
                                            <small><i>(Select at least one sector.)</i></small>
                                            <FieldArray
                                                as="checkbox"
                                                name="classification"
                                                className={`${touched.classification && errors.classification ? "is-invalid" : ""}`}
                                                render={arrayHelpers => (
                                                    <div className="row classrow border py-2">
                                                        <div className="col-md-4 pr-0">
                                                            {sectorList.filter((v, i) => i < numberPerColumn).map((value, index) => {
                                                                return (
                                                                    <div key={`classification_${value._id}`} className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id={`classification_${index}`}
                                                                        value={value._id}
                                                                        checked={value.checked}
                                                                        onChange={event => {
                                                                            
                                                                        }}
                                                                        onClick={event => {
                                                                            let checked = event.target.checked;
                                                                            let find = values.classification.indexOf(value._id);
                                                                            value.checked = checked;
                                                                            if(checked){
                                                                                arrayHelpers.insert('', value._id);
                                                                            }else{
                                                                                arrayHelpers.remove(find);
                                                                            }
                                                                        }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`classification_${index}`}>{value.name}</label>
                                                                </div> 
                                                                )
                                                            })}
                                                        </div>
                                                        <div className="col-md-4 pr-0">
                                                            {sectorList.filter((v, i) => i >= numberPerColumn && i < numberPerColumn*2 ).map((value, index) => {
                                                                return (
                                                                    <div key={`classification_${value._id}`} className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id={`classification_${index + numberPerColumn}`}
                                                                        value={value._id}
                                                                        checked={value.checked}
                                                                        onChange={event => {
                                                                            
                                                                        }}
                                                                        onClick={event => {
                                                                            let checked = event.target.checked;
                                                                            let find = values.classification.indexOf(value._id);
                                                                            value.checked = checked;
                                                                            if(checked){
                                                                                arrayHelpers.insert('', value._id);
                                                                            }else{
                                                                                arrayHelpers.remove(find);
                                                                            }
                                                                        }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`classification_${index + numberPerColumn}`}>{value.name}</label>
                                                                </div> 
                                                                )
                                                            })}
                                                        </div>  
                                                        <div className="col-md-4 pr-0">
                                                            {sectorList.filter((v, i) => i >= numberPerColumn*2 && i < sectorList.length).map((value, index) => {
                                                                return (
                                                                    <div key={`classification_${value._id}`} className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id={`classification_${index + numberPerColumn*2}`}
                                                                        value={value._id}
                                                                        checked={value.checked}
                                                                        onChange={event => {
                                                                            
                                                                        }}
                                                                        onClick={event => {
                                                                            let checked = event.target.checked;
                                                                            let find = values.classification.indexOf(value._id);
                                                                            value.checked = checked;
                                                                            if(checked){
                                                                                arrayHelpers.insert('', value._id);
                                                                            }else{
                                                                                arrayHelpers.remove(find);
                                                                            }
                                                                        }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`classification_${index + numberPerColumn*2}`}>{value.name}</label>
                                                                </div> 
                                                                )
                                                            })}
                                                        </div> 
                                                    </div> 
                                                )}
                                            />
                                            <ErrorMessage component="div" name="classification" className="text-danger small" />
                                        </div>

                                        <div className="py-4 text-center mt-3">
                                            <button className="startup-btn-main mx-2" type="submit">Save</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                backdrop="static"
                keyboard={false}
                show={showModal}
            >
                <Modal.Header>
                <Modal.Title>Congratulations!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-left">
                        <p>
                            You now have a Startup Company Account. Your Startup ID is:
                        </p>
                        <h4 className="text-center">{registrationNo}</h4>
                        <p>
                            You may choose to continue by having your business verified by the PSDP,
                            or you may do this at a later date by going to your Dashboard &gt; My Company.
                        </p>
                    </div>
                    <div className="py-4 text-center mt-3">
                        <button className="startup-btn mx-2" onClick={redirectBusinessRegistration}>Continue to PSDP Verification</button>
                        <div className="text-center mt-4">
                            <a className="pointer" onClick={redirectDashboard}>Continue Later</a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};
export default StartupCompanyRegistration;
