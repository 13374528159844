import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import store from 'store';

const IdleTimerContainer = (props) => {

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const idleTimerRef = useRef(null);
    const [showIdleModal, setShowIdleModal] = useState(false);
    const sessionTimeoutRef = useRef(null);

    const onIdle = () => {
        if (isLoggedIn == true) {
            setShowIdleModal(true);
            sessionTimeoutRef.current = setTimeout(logout, 5000);
        } else {
            setShowIdleModal(false);
        }
        
    }

    const stayActive = () => {
        setShowIdleModal(false);
        clearTimeout(sessionTimeoutRef.current);
    }

    const logout = async (event) => {
       
        store.remove("zgETDnOXBY");
        store.remove("LjfADyiZFB");
        store.remove("OnvBIFUcVC");
        store.remove("HYLQcSsSWe");
        store.remove("oqzAsiGkQs");
        store.remove("iSWgCQnurz");
        store.remove("MaeIHzsYGC");
        store.remove("startupRegistrationFirst");
        store.remove("startupRegistrationFirstFile");
        store.remove("startupRegistrationSecond");
       
        setTimeout(function(){ 
            window.location.href = "/home";
            window.location.reload();
        }, 200);
      };

    return (
        <React.Fragment>
               {/* 1800 * 1000 */}
                <IdleTimer ref={idleTimerRef} timeout={1800 * 1000} onIdle={onIdle}></IdleTimer>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    show={showIdleModal}
                    isLoggedIn={isLoggedIn}
                >
                    <Modal.Header>
                    <Modal.Title>You've been idle for a while!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p>You will be logout soon.</p>
                    

                    <div className="clearfix">&nbsp;</div>

                    <div className="modal-footer text-center">
                        <button type="button" className="startup-btn-main mx-3" onClick={(e) => logout(e)}>Log out</button>
                        <button type="button" className="startup-btn-main" onClick={stayActive}>Keep me signed in</button>
                    </div>
                    </Modal.Body>
                </Modal>
        </React.Fragment>
       
    )
}

export default IdleTimerContainer;