import React, { useEffect, useState, useRef } from "react";
import Header from "../../../components/Header";
import DataTable, { defaultThemes } from 'react-data-table-component';
import url from "../../../environment";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import token from "../../../store/token";
import xAuthToken from "../../../store/xAuthToken";
import axios from "axios";
import * as moment from "moment";
import "../../../dist/scss/custom.scss";
import orderBy from 'lodash/orderBy';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from "react-toastify";
import Sidebar from "../../../components/Sidebar"

const customStyles = {
    width: 'auto',
}

const RolesIndex = (props) => {

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;

    const [checked, setChecked] = useState(false);
    const startupApplicationColumns = [
        {
            name: 'ROLE NAME',
            selector: row => row['name'],
            style: { cursor: 'pointer' },
            sortable: true,
            grow: 3,
        },
        {
            name: 'DESCRIPTION',
            selector: row => row['description'],
            style: { cursor: 'pointer', 'whiteSpace': 'unset' },
            sortable: true,
            grow: 3,
        },
        {
            name: 'STATUS',
            cell: (value) => {
                const activateDeactivateRole = async (id, isActive) => {
                    const status = {
                        id: id,
                        isActive: !isActive
                    }

                    value.isActive = status.isActive;
                    setChecked(!checked)

                    const params = {
                        _id: id,
                        isActive: value.isActive
                    }

                    let api = url.hostPort() + '/api/role/setIsActive';
                    const headers = {
                        'Authorization': `Bearer ${token()}`,
                        
                    };
                    axios.post(api, params, { headers: headers }).then(res => {
                        let result = res.data;
                        toast.info(result.message, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });
                    }, error => {
                        let responseError = error.response.data;
                        let errorMessage = responseError.message;
                        toast.error(errorMessage, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });
                    });
                }
                return <label className="switch"><input type="checkbox" id={`status${value._id}`}
                    checked={value.isActive} onChange={() => activateDeactivateRole(value._id, value.isActive)}
                />
                    <span className="slider round"></span>
                </label>
            },
            style: { cursor: 'pointer' },
            sortable: false,
            grow: 1
        },
        {
            name: 'DATE CREATED',
            selector: row => moment(row['dateCreatedFormatted']).format("MM-DD-YYYY"),
            style: { cursor: 'pointer' },
            sortable: true,
            grow: 3
        },
    ];

    const rowPerPage = [10, 25, 50, 100];
    const { register, handleSubmit, reset, formState } = useForm();
    const [startupRoleList, setStartupRoleList] = useState();
    const [roleList, setRoleList] = useState([]);
    const [startupApplicationListotalRows, setStartupApplicationListTotalRows] = React.useState(0);
    const [startupApplicationListLoading, setStartupApplicationListLoading] = React.useState(true);
    const [role, setRole] = useState(null);

    const [updateRole, setupdateRole] = useState({
        page: 1,
        limit: 10,
        name: "",
        status: "",
        date: ""
    });

    const getStartupRoleList = async () => {
        const host = url.hostPort();
        const headers = {
            'Authorization': `Bearer ${token()}`,
            
        };

        let params = {
            "sort":  {
              "name": 1,
            }
        }

        let api = host + `/api/role/search`;
        axios.post(api, params, { headers: headers }).then((res) => {
            setRoleList(res.data.data);
        },
            (error) => {

                let responseError = error.message;
                toast.error(responseError, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            });
    };

    const getStartupRoleListWithFilter = async () => {
        setStartupApplicationListLoading(true);
        const host = url.hostPort();
        const headers = {
            'Authorization': `Bearer ${token()}`,
            
        };

        let params = {
            page: (role != null ? role.page : 1),
            limit: (role != null ? role.limit : 10),
            name: (role != null ? updateRole.name : ""),
            // isActive: (role == null) ? "" : (updateRole.status === true ? true : false),
            isActive: role == null ? "" : updateRole.status,
            dateCreatedFormatted: updateRole.dateCreated === undefined ? "" : moment(updateRole.dateCreated).format('MM-DD-YYYY')
        };
        if(updateRole.dateCreated === ""){
          delete params.dateCreatedFormatted
        }

        let api = host + `/api/role/search`;
        axios.post(api, params, { headers: headers }).then((res) => {
            setStartupRoleList(res.data.data)
            setStartupApplicationListTotalRows(res.data.total);
            setStartupApplicationListLoading(false);
        },
            (error) => {

                let responseError = error.message;
                setStartupApplicationListLoading(false);
                toast.error(responseError, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            });
    };

    const startupHandlePerRowsChange = async (newPerPage, page) => {
        role.limit = newPerPage;
        role.page = page;

        getStartupRoleListWithFilter();
    };

    const startupHandlePageChange = page => {
        setRole({
            page: page,
            limit: role.limit,
            status: role.status,
        })

        role.page = page;
        getStartupRoleListWithFilter();

    };

    const customSort = (rows, field, direction) => {
        field = field.toString();
        let transformField = field.match(/'([^']+)'/)[1];
        const handleField = row => {
            if (row[transformField]) {
                return row[transformField].toLowerCase();
            }
            return row[transformField];
        };

        return orderBy(rows, handleField, direction);
    };

    const onSubmit = (data) => {
        updateRole.name = data.name;
        updateRole.date = data.date;
        updateRole.status = JSON.parse(data.status);
        updateRole.dateCreated = data.date;
        getStartupRoleListWithFilter();
    }

    useEffect(() => {
        setRole({
            // page: 1,
            limit: 10,
            name: "",
            description: "",
            status: true,
            date: ""
        })
    }, [])

    useEffect(() => {
        reset(role);
    }, [role]);

    useEffect(() => {
        getStartupRoleList();
        getStartupRoleListWithFilter();
    }, []);

    useEffect(() => {
        props.PageHandler('role');
    }, [])

    return (
        <React.Fragment>
            <Header isLoggedIn={isLoggedIn} isAdmin={true} />
            <div className="container py-4">
                <div className="row">
                    <div className="col-3">
                        <Sidebar activePage={props.activePage} />
                    </div>
                    <div className="col-9">
                        <div className="container startup-table-container">
                            <div className="mb-4"><a className="startup-secondary-btn-fill" href="/user-management/roles/create"><i className="bi bi-plus"></i>CREATE ROLE</a></div>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <div className="advance-search-btn w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <span className="text-left">Advanced Search</span>
                                            <span className="accicon float-right rotate-icon"><i className="bi bi-chevron-down"></i></span>
                                        </div>
                                    </div>

                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit(onSubmit)} >
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="name" className="form-label">Name</label>
                                                        <select name="name"
                                                            className="form-control"
                                                            {...register('name')}
                                                        >
                                                            <option name="name" key="0" value="" label="" />
                                                            {roleList.map((value, index) => {
                                                                return <option value={value.name} name="role" key={value._id} label={value.name} />
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="date" className="form-label">Date Created</label>
                                                        <input type="date" className="form-control" name="date" {...register('date')} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="status" className="form-label">Status</label>
                                                        {/* <input type="text" className="form-control" name="status" value={status} onChange={changeHandler}/> */}
                                                        <select className="custom-select"
                                                            name="status"
                                                            {...register('status')}
                                                        >
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>

                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="p-t-25"></div>
                                                <div className="btn-div d-flex justify-content-end">
                                                    <div className="btn-cancel pr-3">
                                                        <button className="startup-btn" type="button" onClick={() => reset()}>
                                                            <b>Clear</b>
                                                        </button>
                                                    </div>
                                                    <div className="btn-submit">
                                                        <button className="startup-btn" >
                                                            <b>Search</b>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-t-25"></div>
                            {/* <hr></hr> */}

                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <DataTable
                                        columns={startupApplicationColumns}
                                        data={startupRoleList}
                                        progressPending={startupApplicationListLoading}
                                        customStyles={customStyles}
                                        highlightOnHover
                                        striped
                                        responsive
                                        persistTableHead
                                        pagination
                                        paginationRowsPerPageOptions={rowPerPage}
                                        paginationServer
                                        paginationTotalRows={startupApplicationListotalRows}
                                        onChangeRowsPerPage={startupHandlePerRowsChange}
                                        onChangePage={startupHandlePageChange}
                                        //   onRowClicked={onRowClick}
                                        sortFunction={customSort}
                                        noDataComponent="There are no records to display."
                                    />
                                    {/* <div className="text-right">
                        <a href="#">
                        <strong>See Full List of Startups Applications</strong>
                        </a>
                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default RolesIndex;