import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import { toast } from 'react-toastify';
import LoadingService from "../../imports/LoadingService";
import * as Yup from "yup";
import "../../dist/scss/custom.scss"; 
import { Modal } from "react-bootstrap";

const StartupEnablerDescription = (props) =>{
	const history = useHistory();

    const back = (page) => {
        window.scrollTo(0, 0);
        props.setFormPage(page);
    };

    const openConsent = () => {
        props.openConsent();
    };
    return(
        <React.Fragment>
            <div className="startup-enabler-container mt-4">
                <div className="container mb-5">
                    <div className="row border bg-white">
                        <div className="col-md-8 col-sm-12 form-align mb-5">
                            <h4 className="mt-5">Description of your Startup Enabler Business</h4>
                            <hr></hr>
                            <div className="clearfix">&nbsp;</div>

                            {/* Technology Incubator Field */}
                            <label><strong>(For Technology Business Incubators)</strong></label>
                            <p>You selected Incubator as your Enabler Type. Please upload the Certificate
                                of Registration of your University and an endorsement letter from DOST for student founders.
                            </p>
                            <div className="px-3 border-left">
                                <div className="form-group">
                                    <label><strong>Certificate of Registration</strong></label>
                                    <input type="file" className="form-control-file"/>
                                </div>
                                <div className="form-group">
                                    <label><strong>Endorsement Letter</strong></label>
                                    <input type="file" className="form-control-file"/>
                                </div>
                            </div>
                            {/* End of Technology Incubator Field */}

                            <div className="form-group mt-2">
                                <label htmlFor="description"><strong>Short description of your business</strong></label>
                                <textarea rows="5" name="description" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="servicesOffered"><strong>Services being offered</strong></label>
                                <textarea rows="5"  name="servicesOffered" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="problemSolved"><strong>Problems being solved </strong>(seperate by comma)</label>
                                <textarea rows="5"  name="problemSolved" className="form-control" />
                            </div>

                            <div className="py-4 text-center mt-4">
                                <button type="button" className="startup-btn-main mx-3" onClick={() => { back(1)}}>Back</button>
                                <button type="button" className="startup-btn-main" onClick={() => { openConsent()}}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default StartupEnablerDescription;