import React, { useEffect, useState, useRef } from "react";
import Header from "../../../components/Header";
import DataTable, { defaultThemes } from 'react-data-table-component';
import { useForm } from "react-hook-form";
import url from "../../../environment";
import { useHistory } from "react-router-dom";
import token from "../../../store/token";
import xAuthToken from "../../../store/xAuthToken";
import axios from "axios";
import * as moment from "moment";
import "../../../dist/scss/custom.scss";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from "react-toastify";
import Sidebar from "../../../components/Sidebar"
import orderBy from 'lodash/orderBy';

const customStyles = {
}

const UserIndex = (props) => {

  const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;

  const headers = {
    'Authorization': `Bearer ${token()}`,
    
  };

  const [checked, setChecked] = useState(false);
  const startupApplicationColumns = [
    {
      name: 'NAME',
      selector: row => row['completeName'],
      style: { cursor: 'pointer' },
      sortable: true,
      grow: 3,
    },
    {
      name: 'EMAIL',
      selector: row => row['email'],
      style: { cursor: 'pointer' },
      sortable: true,
      grow: 3,
    },
    {
      name: 'ROLE',
      selector: row => row['roleName'],
      style: { cursor: 'pointer' },
      sortable: true,
      grow: 3,
    },
    {
      name: 'STATUS',
      // selector: row => row['isActive'],
      cell: (value) => {
        const activateDeactivateUser = (id, isActive) => {
          const status = {
            id: id,
            isActive: !isActive
          }

          value.isActive = status.isActive;
          setChecked(!checked)

          const params = {
            _id: id,
            isActive: value.isActive
          }

          let api = url.hostPort() + '/api/user/setIsActive';
          const headers = {
            'Authorization': `Bearer ${token()}`,
            
          };
          axios.post(api, params, { headers: headers }).then(res => {
            let result = res.data;
            toast.info(result.message, {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
          }, error => {
            let responseError = error.response.data;
            let errorMessage = responseError.message;
            toast.error(errorMessage, {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
          });
        }
        return <label className="switch"><input type="checkbox" id={`status${value._id}`}
          checked={value.isActive} onChange={() => activateDeactivateUser(value._id, value.isActive)}
        />
          <span className="slider round"></span>
        </label>
      },
      style: { cursor: 'pointer' },
      sortable: false,
      grow: 1
    },
    {
      name: 'DATE CREATED',
      selector: row => moment(row['dateCreatedFormatted']).format("MM-DD-YYYY"),
      style: { cursor: 'pointer' },
      sortable: true,
      grow: 3
    },
  ];

  const rowPerPage = [10, 25, 50, 100];
  const [startupUserList, setStartupUserList] = useState();
  const [roleList, setRoleList] = useState([]);
  const [startupApplicationListotalRows, setStartupApplicationListTotalRows] = React.useState(0);
  const [startupApplicationListLoading, setStartupApplicationListLoading] = React.useState(true);
  const { register, handleSubmit, reset, formState } = useForm();
  const [user, setUser] = useState();

  const [updateUser, setUpdateUser] = useState({
    page: 1,
    limit: 10,
    completeName: "",
    roleName: "",
    status: "",
    date: ""
  });

  const onSubmit = (data) => {
    updateUser.completeName = data.completeName;
    updateUser.roleName = data.roleName;
    updateUser.data = data.date;
    updateUser.status = JSON.parse(data.status);
    updateUser.dateCreated = data.date;
    getStartupUserList();
  }

  /* Get User Role */
  const getStartupUserRoleList = async () => {
    const host = url.hostPort();
    const headers = {
      'Authorization': `Bearer ${token()}`,
      
    };

    let params = {
      "sort":  {
        "name": 1 
      }
    }

    let api = host + `/api/role/search`;
    axios.post(api, params , { headers: headers }).then((res) => {
      setRoleList(res.data.data);
    },
      (error) => {

        let responseError = error.message;
        toast.error(responseError, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };

  /* Fetch User using rows per page dropdown */
  const startupHandlePerRowsChange = async (newPerPage, page) => {

    // setUser({ 
    //   page: page,
    //   limit: newPerPage,
    //   status: user.status,
    // })

    user.page = page;
    user.limit = newPerPage;
    getStartupUserList();
  };

  /* Fetch User using page arrow */
  const startupHandlePageChange = page => {

    setUser({
      page: page,
      limit: user.limit,
      status: user.status,
    })

    user.page = page;
    getStartupUserList();
  };

  const customSort = (rows, field, direction) => {

    field = field.toString();

    let transformField = field.match(/'([^']+)'/)[1];

    const handleField = row => {
      if (row[transformField]) {
        if (Array.isArray(row[transformField])) {
          return row[transformField].toString().toLowerCase();
        } else {
          return row[transformField].toLowerCase();
        }
      }
      return row[transformField];
    };

    return orderBy(rows, handleField, direction);
  };

  /* Get User List with Filter */
  const getStartupUserList = async () => {

    setStartupApplicationListLoading(true);
    const host = url.hostPort();
    const headers = {
      'Authorization': `Bearer ${token()}`,
      
    };
    let params = {
      page: (user != null ? user.page : 1),
      limit: (user != null ? user.limit : 10),
      roleName: (user != null ? updateUser.roleName : ""),
      completeName: (user != null ? updateUser.completeName : ""),
      isActive: user == null ? "" : updateUser.status,
      dateCreatedFormatted: updateUser.dateCreated === undefined ? "" : moment(updateUser.dateCreated).format('MM-DD-YYYY')
    };
    if(updateUser.dateCreated === ""){
      delete params.dateCreatedFormatted
    }

    let api = host + `/api/user/search`;
    axios.post(api, params, { headers: headers }).then((res) => {
      setStartupUserList(res.data.data)
      setStartupApplicationListTotalRows(res.data.total);
      setStartupApplicationListLoading(false);
    },
      (error) => {

        let responseError = error.message;
        setStartupApplicationListLoading(false);
        toast.error(responseError, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    setUser({
      limit: 10,
      completeName: "",
      roleName: "",
      status: true,
      date: ""
    })
  }, [])

  useEffect(() => {
    getStartupUserList();
    getStartupUserRoleList();
  }, []);

  useEffect(() => {
    reset(user);
  }, [user]);

  useEffect(() => {
    props.PageHandler('user');
  }, [])

  return (
    <React.Fragment>
      <Header isLoggedIn={isLoggedIn} isAdmin={true} />
      <div className="container py-4">
        <div className="row">
          <div className="col-3">
            <Sidebar activePage={props.activePage} />
          </div>
          <div className="col-9">
            <div className="container startup-table-container">
              <div className="mb-4"><a className="startup-secondary-btn-fill" href="/user-management/user/create"><i className="bi bi-plus"></i>CREATE USER</a></div>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <div className="advance-search-btn w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span className="text-left">Advanced Search</span>
                      <span className="accicon float-right rotate-icon"><i className="bi bi-chevron-down"></i></span>
                    </div>
                  </div>

                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input name="completeName" type="text" {...register('completeName')} className="form-control f" />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="role" className="form-label">Role</label>
                            {/* <input type="text" className="form-control" name="role" value={role} onChange={changeHandler}/> */}
                            <select name="roleName"
                              className="form-control"
                              {...register('roleName')}
                            // onChange={changeHandler}
                            >
                              <option name="roleName" key="0" value="" label="" />
                              {roleList.map((value, index) => {
                                return <option value={value.name} name="roleName" key={value._id} label={value.name} />
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="status" className="form-label">Status</label>
                            {/* <input type="text" className="form-control" name="status" value={status} onChange={changeHandler}/> */}
                            <select className="custom-select"
                              name="status"
                              {...register('status')}
                            // onChange={statusChangeHandler}
                            >
                              {/* <option value="true">Please Select</option> */}
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>

                            </select>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="date" className="form-label">Date Created</label>
                            <input type="date" className="form-control" name="date" {...register('date')} />
                          </div>
                        </div>

                        <div className="p-t-25"></div>

                        <div className="btn-div d-flex justify-content-end">
                          <div className="btn-cancel pr-3">
                            <button className="startup-btn" type="button" onClick={() => reset()}>
                              <b>Clear</b>
                            </button>
                          </div>
                          <div className="btn-submit">
                            <button className="startup-btn" >
                              <b>Search</b>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-t-25"></div>
              {/* <hr></hr> */}

              <div className="row">
                <div className="col-md-12 mb-2">
                  <DataTable
                    columns={startupApplicationColumns}
                    data={startupUserList}
                    progressPending={startupApplicationListLoading}
                    customStyles={customStyles}
                    highlightOnHover
                    striped
                    responsive
                    persistTableHead
                    pagination
                    paginationRowsPerPageOptions={rowPerPage}
                    paginationServer
                    paginationTotalRows={startupApplicationListotalRows}
                    onChangeRowsPerPage={startupHandlePerRowsChange}
                    onChangePage={startupHandlePageChange}
                    //   onRowClicked={onRowClick}
                    sortFunction={customSort}
                    noDataComponent="There are no records to display."
                  />
                  {/* <div className="text-right">
                    <a href="#">
                      <strong>See Full List of Startups Applications</strong>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserIndex;