import React, { useEffect, useState, useRef } from "react";
import Header from "../../../components/Header";
import DataTable, { defaultThemes } from 'react-data-table-component';
import url from "../../../environment";
import { useHistory } from "react-router-dom";
import token from "../../../store/token";
import xAuthToken from "../../../store/xAuthToken";
import axios from "axios";
import * as moment from "moment";
import "../../../dist/scss/custom.scss";
import orderBy from 'lodash/orderBy';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import SidebarLibraries from "../../../components/SidebarLibraries";

const customStyles = {
}

const StartupStageIndex = (props) => {

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;

    document.title = 'Startup Stage List';
    const [startupStage, setStartupStage] = useState();
    const startupApplicationColumns = [
        {
            name: 'STARTUP STAGE NAME',
            selector: row => row['name'],
            style: { cursor: 'pointer' },
            sortable: true,
            grow: 3,
        },
        {
            name: 'DESCRIPTION',
            selector: row => row['description'],
            style: { cursor: 'pointer' },
            sortable: true,
            grow: 3,
        },
        {
            name: 'STATUS',
            cell:(value) => { 
                const activateDeactivateStartupPhase = (id, isActive) => {
                const status = {
                    id: id,
                    isActive: !isActive
                }
    
                value.isActive = status.isActive;
                setChecked(!checked)
    
                const params = {
                    _id: id,
                    isActive: value.isActive
                }
                const headers = {
                    'Authorization': `Bearer ${token()}`,
                    
                };
    
                  let api = url.hostPort() + '/api/startupPhase/setIsActive';
                  axios.post(api, params, {headers: headers}).then(res => {
                      let result = res.data;
                      toast.info(result.message, {
                          position: "bottom-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: false,
                          draggable: false,
                          progress: undefined,
                      });
                  }, error => {
                      let responseError = error.response.data;
                      let errorMessage = responseError.message;
                      toast.error(errorMessage, {
                          position: "bottom-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: false,
                          draggable: false,
                          progress: undefined,
                      });
                  });
                }
                return <label className="switch"><input type="checkbox" id={`status${value._id}`}  
                        checked={value.isActive} onChange={() => activateDeactivateStartupPhase(value._id, value.isActive)}
                        />
                      <span className="slider round"></span>
                      </label> 
            }, 
            style: { cursor: 'pointer' },
            sortable: false,
            grow: 1
        },
        {
            name: 'DATE CREATED',
            selector: row => moment(row['dateCreatedFormatted']).format("MM-DD-YYYY"),
            style: { cursor: 'pointer' },
            sortable: true,
            grow: 3
        },
    ];
    const rowPerPage = [10, 25, 50, 100];
    const [startupStageList, setStartupStageList] = useState();
    const [stageList, setStageList] = useState([]);
    const [startupApplicationListotalRows, setStartupApplicationListTotalRows] = React.useState(0);
    const [startupApplicationListLoading, setStartupApplicationListLoading] = React.useState(true);
    const { register, handleSubmit, reset, formState } = useForm();
    const [checked, setChecked] = useState(false);
    const [updateStartupStage, setUpdateStartupStage] = useState({
        page: 1,
        limit: 10,
        name: "",
        status: "",
        date: ""
    })

    const onSubmit = (data) => {
        updateStartupStage.name = data.name;
        updateStartupStage.date = data.date;
        updateStartupStage.dateCreated = data.date;
        updateStartupStage.status = JSON.parse(data.status);
        getStartupStageListwithFilter();
    }

    /* Get Startup stage list */
    const getStartupUserStageList = async () => {
        const host = url.hostPort();
        const headers = {
		'Authorization': `Bearer ${token()}`,
        
	};

        let params = {
            "sort":  {
              "name": 1,
            }
        }


        let api = host + `/api/startupPhase/search`;
        axios.post(api, params, { headers: headers }).then((res) => {
            setStageList(res.data.data);
        },
            (error) => {

                let responseError = error.message;
                toast.error(responseError, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            });
    };

    /* Get Permission List with Filter */
    const getStartupStageListwithFilter = async () => {
        setStartupApplicationListLoading(true);
        const host = url.hostPort();
        const headers = {
            'Authorization': `Bearer ${token()}`,
            
        };

        let params = {
            page: (startupStage != null ? startupStage.page : 1),
            limit: (startupStage != null ? startupStage.limit : 10),
            name: (startupStage != null ? updateStartupStage.name : ""),
            isActive: startupStage == null ? "" : updateStartupStage.status,
            dateCreatedFormatted: updateStartupStage.dateCreated === undefined ? "" : moment(updateStartupStage.dateCreated).format('MM-DD-YYYY')
        };

        if(updateStartupStage.dateCreated === ""){
            delete params.dateCreatedFormatted
        }
       
        let api = host + `/api/startupPhase/search`;
        axios.post(api, params, { headers: headers }).then((res) => {
            setStartupStageList(res.data.data)
            setStartupApplicationListTotalRows(res.data.total);
            setStartupApplicationListLoading(false);
        },
            (error) => {

                let responseError = error.message;
                setStartupApplicationListLoading(false);
                toast.error(responseError, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            });
    };

    /* Fetch Permission using rows per page dropdown */
    const startupHandlePerRowsChange = async (newPerPage, page) => {
        startupStage.limit = newPerPage;
        startupStage.page = page;
        getStartupStageListwithFilter();
    };

    /* Fetch Permission using page arrow */
    const startupHandlePageChange = page => {
        startupStage.page = page;
        getStartupStageListwithFilter();
    };

    const customSort = (rows, field, direction) => {
        field = field.toString();
        let transformField = field.match(/'([^']+)'/)[1];
        const handleField = row => {
          if (row[transformField]) {
            return row[transformField].toLowerCase();
          }
          return row[transformField];
        };
      
        return orderBy(rows, handleField, direction);
    };

    useEffect( () => {
        setStartupStage({ 
            page: 1,
            limit: 10,
            name: "",
            description: "",
            status: true,
            date: ""
        })
      }, [])
    
    useEffect(() => {
        reset(startupStage);
    }, [startupStage]);
    
    useEffect(() => {
        getStartupUserStageList();
        getStartupStageListwithFilter();
        props.PageHandler('startupStage');
    }, []);


    return (
        <React.Fragment>
            <Header isLoggedIn={isLoggedIn} isAdmin={true} />
            <div className="container py-4">
                <div className="row">
                    <div className="col-3">
                        <SidebarLibraries activePage={props.activePage} />
                    </div>
                    <div className="col-9">
                    <div className="container startup-table-container">
                <div className="mb-4"><a className="startup-secondary-btn-fill" href="/libraries/startup-stage/create"><i className="bi bi-plus"></i>CREATE STARTUP DEVELOPMENT PHASE</a></div>
                <div className="accordion" id="accordionExample">
                    <div className="card">
                        <div className="card-header" id="headingOne">
                            <div className="advance-search-btn w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className="text-left">Advanced Search</span>
                                <span className="accicon float-right rotate-icon"><i className="bi bi-chevron-down"></i></span>
                            </div>
                        </div>

                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <select name="name"
                                                className="form-control"
                                                {...register('name')}
                                            >
                                                <option name="name" key="0" value="" label="" />
                                                {stageList.map((value, index) => {
                                                    return <option value={value.name} name="name" key={value._id} label={value.name} />
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="date" className="form-label">Date Created</label>
                                            <input type="date" className="form-control" name="date" {...register('date')} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="status" className="form-label">Status</label>
                                            {/* <input type="text" className="form-control" name="status" value={status} onChange={changeHandler}/> */}
                                            <select className="custom-select"
                                                name="status"
                                                {...register('status')}
                                            >
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>

                                            </select>
                                        </div>
                                    </div>

                                    <div className="p-t-25"></div>

                                    <div className="btn-div d-flex justify-content-end">
                                        <div className="btn-cancel pr-3">
                                            <button className="startup-btn" type="button" onClick={() => reset()}>
                                            <b>Clear</b>
                                            </button>
                                        </div>
                                        <div className="btn-submit">
                                            <button className="startup-btn" >
                                            <b>Search</b>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-t-25"></div>
                {/* <hr></hr> */}

                <div className="row">
                    <div className="col-md-12 mb-2">
                        <DataTable
                            columns={startupApplicationColumns}
                            data={startupStageList}
                            progressPending={startupApplicationListLoading}
                            customStyles={customStyles}
                            highlightOnHover
                            striped
                            responsive
                            persistTableHead
                            pagination
                            paginationRowsPerPageOptions={rowPerPage}
                            paginationServer
                            paginationTotalRows={startupApplicationListotalRows}
                            onChangeRowsPerPage={startupHandlePerRowsChange}
                            onChangePage={startupHandlePageChange}
                            //   onRowClicked={onRowClick}
                              sortFunction={customSort}
                            noDataComponent="There are no records to display."
                        />
                        {/* <div className="text-right">
                        <a href="#">
                        <strong>See Full List of Startups Applications</strong>
                        </a>
                    </div> */}
                    </div>
                </div>
            </div>
                    </div>
                </div>
            </div>
           
        </React.Fragment>
    );
}

export default StartupStageIndex;