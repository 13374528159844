import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import { toast } from 'react-toastify';
import LoadingService from "../../imports/LoadingService";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from "yup";
import "../../dist/scss/custom.scss"; 
import { Modal } from "react-bootstrap";
import ImageUploaderField from '../../imports/Upload';

const requiredLabel = 'This is Required!';

const businessInfoSchema = Yup.object().shape({
	startupName: Yup.string().required(requiredLabel),
	businessName: Yup.string().required(requiredLabel),
	businessLogo: Yup.mixed(),
	businessLogoFile: Yup.mixed().test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        } 

    }),
	businessClassification: Yup.string(),
	registrationNo: Yup.string().required(requiredLabel),
	proof: Yup.string().required(requiredLabel),
    proofFile: Yup.mixed().required(requiredLabel).test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        } 

    }),
    proofIdentifier: Yup.string(),
	foundingYear: Yup.string(),
    region: Yup.string().required(requiredLabel),
	regionName: Yup.string(),
	province: Yup.string().required(requiredLabel),
	provinceName: Yup.string(),
	city: Yup.string().required(requiredLabel),
	cityName: Yup.string(),
	barangay: Yup.string().required(requiredLabel),
	barangayName: Yup.string(),
	streetNo: Yup.string(),
	lotBlockUnit: Yup.string(),
	zipCode: Yup.string().required(requiredLabel),
    companyEmail: Yup.string(),
    companyContactNumber: Yup.string().required(requiredLabel),
    website: Yup.string(),
    linkedIn: Yup.string(),
    facebook: Yup.string(),
    twitter: Yup.string(),
    instagram: Yup.string(),
    youtube: Yup.string(),
});

const StartupEnablerBusiness = (props) => {
	const history = useHistory();

	const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
        
	};

	const [regionList, setRegionList] = useState([]);
	const [provinceList, setProvinceList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [barangayList, setBarangayList] = useState([]);
    
    const [proofList, setProofList] = useState([]);
	const [requirementList, setRequirementList] = useState([]);
	const [transformRequirementList, setTransformList] = useState([]);

    const [businessClassificationList, setBusinessClassificationList] = useState([]);
    
    const [registrationLabel, setRegistrationLabel] = useState('DTI Certificate of Registration (For Sole Proprietorship)');
	const setRegistrationLabelHandler = (id) => {
		if(id === 1){
			setRegistrationLabel('DTI Certificate of Registration (For Sole Proprietorship)');
		}else if(id === 2 || id === 3 || id === 4 || id === 6){
			setRegistrationLabel('SEC Certificate of Registration (For Partnership, One Person Corporation, Joint Venture, and Association)');
		}else if(id === 5){
			setRegistrationLabel('CDA Certificate of Registration (For Cooperative)');
		}else if(id === 7){
			setRegistrationLabel("Mayor's Permit (For NECs)");
        }
	};

    const [businessInfoValue, setBusinessInfoValue] = useState({
		'startupName': '',
		'businessName': '',
		'businessLogo': '',
        'businessLogoFile': undefined,
		'businessClassification': 6,
		'registrationNo': '',
		'proof': '',
        'proofFile': undefined,
        'proofIdentifier': '',
		'foundingYear': '',
        'region': '',
        'regionName': '',
        'province': '',
        'provinceName': '',
        'city': '',
        'cityName': '',
        'barangay': '',
        'barangayName': '',
        'streetNo': '',
        'lotBlockUnit': '',
        'zipCode': '',
        'companyEmail': '',
        'companyContactNumber': '',
        'website': '',
        'linkedIn': '',
        'facebook': '',
        'twitter': '',
        'instagram': '',
        'youtube': '',
    });

    const back = (page) => {
        window.scrollTo(0, 0);
        props.setFormPage(page);
    };

    const next = (page) => {
        window.scrollTo(0, 0);
        props.setFormPage(page);
    };

    const getRegion = () => {
		const endpoint = `/api/v1/location/regions?sort=region_code&view=all`;
		const api = host + endpoint;
		if(regionList.length == 0){
			axios.get(api, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.items;
                data = data.filter(function(item){ return item.region_psgc !== "180000000"});
				setRegionList(data);
			}, error => {
			});
		}
	};

	const getProvince = (value) =>{
		if(value != ""){
			const endpoint = `/api/v1/location/provinces?sort=province_name&region_psgc=${value}&view=all`;
			const api = host + endpoint;
			axios.get(api, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.items;
				setProvinceList(data);
			}, error => {
			})
		}
	};

	const getCity = (value) => {
		if(value != ""){
			const endpoint = `/api/v1/location/cities-municipalities?sort=city_municipality_name&province_psgc=${value}&view=all`;
			const api = host + endpoint;
			axios.get(api, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.items;
				setCityList(data);
			}, error => {
			})
		}
	};

	const getBarangay = (value) => {
		if(value != ""){
			const endpoint = `/api/v1/location/barangays?sort=barangay_name&view=all&city_municipality_psgc=${value}`;
			const api = host + endpoint;
			axios.get(api, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.items;
				setBarangayList(data);
			}, error => {
			})
		}
	};

    const getBusinessClassification = () => {
        let api = `/api/v1/business-class`;
        api = host + api;
        axios.get(api, { headers: headers }).then(
            (res) => {
                let result = res.data;
                let data = result.items;
                mapBusinessClassification(data);
            },(error) => {
                console.log("error", error);
            }
        )
    };

    const mapBusinessClassification = (data) => {
        for(let x = 0; x < data.length; x++){
            if(data[x].name === "Association"){
                data[x].sortId = 1;
            }else if(data[x].name === "Cooperative"){
                data[x].sortId = 2;
            }else if(data[x].name === "Joint Venture"){
                data[x].sortId = 3;
            }else if(data[x].name === "One Person Corporation"){
                data[x].sortId = 4;
            }else if(data[x].name === "Partnership"){
                data[x].sortId = 5;
            }else if(data[x].name === "Sole Proprietorship"){
                data[x].sortId = 6;
            }else if(data[x].name === "Others (NEC)"){
                data[x].sortId = 7;
            }
        }
        let newData = data.sort((a, b) => parseFloat(a.sortId) - parseFloat(b.sortId));
        setBusinessClassificationList(newData);
    };

    async function getRequirementList() {
		const endpoint = `/api/v1/requirements?requirement_category=STARTUP_INFO_BUSINESS&expand=requirement_type,requirement_category&view=all&sort=requirement_name`;
		const api = host + endpoint;
		let response = await axios.get(api, {headers: headers});
		let result = response.data;
		let data = result.items;
		setRequirementList(data);
        mapRegistrationList(data);
	};

    const mapRegistrationList = (data) => {
		let newList = [];
        let proof = [];
		newList = data.filter(function(item){ return item.requirement_type === 2 });
		newList.map(function(item){
			if(item.requirement_name === "DTI Registration"){
				item.requirement_name = "DTI Certificate of Registration (For Sole Proprietorship)";
                proof.push(item);
			}else if(item.requirement_name === "SEC Certificate of Registration"){
				item.requirement_name = "SEC Certificate of Registration (For Partnership, One Person Corporation, Joint Venture, and Association)";
                proof.push(item);
			}else if(item.requirement_name === "CDA Certificate of Registration"){
				item.requirement_name = "CDA Certificate of Registration (For Cooperative)";
                proof.push(item);
			}else if(item.requirement_name === "Mayor's Permit"){
				item.requirement_name = "Mayor's Permit (For NECs)";
                proof.push(item);
			}
		});
        setProofList(proof);
	};
    
    useEffect(() => {
		getRequirementList();
        getBusinessClassification();
        getRegion();
	}, []);

    return(
        <React.Fragment>
            <div className="startup-enabler-container mt-4">
                <div className="container mb-5">
                    <div className="row border bg-white">
                        <div className="col-md-8 col-sm-12 form-align">
                            <h4 className="p-t-25">Business Information</h4>
                            <hr></hr>
                            <div className="clearfix">&nbsp;</div>
                            <Formik
                                validationSchema={businessInfoSchema}
                                initialValues={Object.assign(businessInfoValue)}
                                onSubmit={next}
                                key="secondForm"
                            >
                                {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values, field }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <div className="text-left">
                                            <div className="form-group">
                                                <label htmlFor="startupName"><strong>Startup Name</strong></label>
                                                <Field type="text" value={values.startupName || ''} name="startupName" autoComplete="off" className={`form-control ${touched.startupName && errors.startupName ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="startupName" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="businessName"><strong>Registered Business Name</strong></label>
                                                <Field type="text" value={values.businessName || ''} name="businessName" autoComplete="off" className={`form-control ${touched.businessName && errors.businessName ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="businessName" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="businessLogoFile"><b>Business Logo (Optional)</b></label>
                                                <ImageUploaderField accept="image/*" name="businessLogoFile" type="file" value={undefined}
                                                    className={`form-control-file ${touched.businessLogoFile && errors.businessLogoFile ? "is-invalid" : ""}`}
                                                    onChange={value => {
                                                        setFieldValue("businessLogoFile", value.currentTarget.files[0]);
                                                    }}
                                                />
                                                <ErrorMessage component="div" name="photo" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group"> 
                                                <label><strong>Business Classification</strong></label>
                                                {businessClassificationList.map((value, index) => {
                                                    return 	<div className="form-check" key={`stage_${value.business_id}`}>
                                                        <Field id={`radioButton_${value.business_id}`} className="form-check-input" type="radio" name={`businessClassification_${value.name}`} value={value.id}
                                                            checked={ values.businessClassification ? values.businessClassification == value.business_id : false }
                                                            onClick={event => {
                                                                setFieldValue("businessClassification", value.business_id);
                                                                setRegistrationLabelHandler(value.business_id);
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor={`radioButton_${value.business_id}`}>{value.name}</label>
                                                        <ErrorMessage component="div" name="businessClassification" className="invalid-feedback" />
                                                    </div>
                                                })}
                                            </div>
                                            <div className="form-group">
                                                <label><strong>Registration/Certificate/Permit No.</strong></label>
                                                <div className="form-group">
                                                    <label htmlFor="registrationNo">{registrationLabel}</label>
                                                    <Field type="text" value={values.registrationNo || ''} name="registrationNo" autoComplete="off" className={`form-control ${touched.registrationNo && errors.registrationNo ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="registrationNo" className="invalid-feedback" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="proof"><b>Proof of registration or government accreditation</b></label>
                                                <select name="proof"
                                                    className={`form-control ${touched.proof && errors.proof ? "is-invalid" : ""}`}
                                                    value={values.proof || ''}
                                                    onChange={val => { 
                                                        const selectedIndex = val.target.options.selectedIndex; 
                                                        let code = val.target.options[selectedIndex].getAttribute('name');
                                                        code = code.replace("proof_", "");
                                                        let label = val.target.options[selectedIndex].getAttribute('label');
                                                        setFieldValue('proof', code);
                                                        setFieldValue('proofIdentifier', label);
                                                    }}
                                                >
                                                    <option name="proof_" key="3" value="" label="" />
                                                    {proofList.map((value, index) => {
                                                        return <option value={value.requirement_id} name={'proof_' + value.requirement_id} key={'proof_' + index} label={value.requirement_name} />
                                                    })}
                                                </select>
                                                <ErrorMessage component="div" name="proof" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <ImageUploaderField accept="image/*,application/pdf" name="proofFile" type="file" value={undefined}
                                                    className={`form-control-file ${touched.proofFile && errors.proofFile ? "is-invalid" : ""}`}
                                                    onChange={value => {
                                                        setFieldValue("proofFile", value.currentTarget.files[0]);
                                                    }}
                                                />
                                                <ErrorMessage component="div" name="proofFile" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="foundingYear"><strong>Year of Founding/Registration (Optional)</strong></label>
                                                <Field type="text" value={values.foundingYear || ''} name="foundingYear" autoComplete="off" className={`form-control w-25 ${touched.foundingYear && errors.foundingYear ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="foundingYear" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group">
                                                <label><strong>Business Address</strong></label>
                                                <div className="border-left pl-3">
                                                    <div className="form-group">
                                                        <label htmlFor="region">Region</label>
                                                        <select name="region"
                                                            value={values.region || ''}
                                                            className={`form-control ${touched.region && errors.region ? "is-invalid" : ""}`}
                                                            onChange={val => { 
                                                                const selectedIndex = val.target.options.selectedIndex;
                                                                let code = val.target.options[selectedIndex].getAttribute('value');
                                                                let region = val.target.options[selectedIndex].getAttribute('label');
                                                                setFieldValue('region', code);
                                                                setFieldValue('regionName', region);

                                                                setProvinceList([]);
                                                                setCityList([]);
                                                                setBarangayList([]);
                                                                getProvince(code);
                                                            }}
                                                        >
                                                            <option name="region_" key="0" value="" label="" />
                                                            {regionList.map((value, index) => {
                                                                return <option value={value.region_psgc} name={'region_' + value.region_psgc} key={'region_' + index} label={value.region_code + ' - ' +value.region_name} />
                                                            })}
                                                        </select>
                                                        <ErrorMessage component="div" name="region" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="province">Province</label>
                                                        <select name="province"
                                                            className={`form-control ${touched.province && errors.province ? "is-invalid" : ""}`}
                                                            value={values.province || ''}
                                                            onChange={val => { 
                                                                const selectedIndex = val.target.options.selectedIndex; 
                                                                let code = val.target.options[selectedIndex].getAttribute('name');
                                                                code = code.replace("province_", "");
                                                                let province = val.target.options[selectedIndex].getAttribute('label');
                                                                setFieldValue('province', code);
                                                                setFieldValue('provinceName', province);

                                                                setCityList([]);
                                                                setBarangayList([]);
                                                                getCity(code);
                                                            }}
                                                        >
                                                            <option name="province_" key="1" value="" label="" />
                                                            {provinceList.map((value, index) => {
                                                                return <option value={value.province_psgc} name={'province_' + value.province_psgc} key={'province_' + value.province_psgc} label={value.province_name} />
                                                            })}
                                                        </select>
                                                        <ErrorMessage component="div" name="province" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="city">City/Municipality</label>
                                                        <select name="city"
                                                            className={`form-control ${touched.city && errors.city ? "is-invalid" : ""}`}
                                                            value={values.city || ''}
                                                            onChange={val => { 
                                                                const selectedIndex = val.target.options.selectedIndex; 
                                                                let code = val.target.options[selectedIndex].getAttribute('name');
                                                                code = code.replace("city_", "");
                                                                let city = val.target.options[selectedIndex].getAttribute('label');
                                                                setFieldValue('city', code);
                                                                setFieldValue('cityName', city);

                                                                setBarangayList([]);
                                                                getBarangay(code);
                                                            }}
                                                        >
                                                            <option name="city_" key="2" value="" label="" />
                                                            {cityList.map((value, index) => {
                                                                return <option value={value.city_municipality_psgc} name={'city_' + value.city_municipality_psgc} key={'city_' + value.city_municipality_psgc} label={value.city_municipality_name} />
                                                            })}
                                                        </select>
                                                        <ErrorMessage component="div" name="city" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="barangay">Barangay</label>
                                                        <select name="barangay"
                                                            className={`form-control ${touched.barangay && errors.barangay ? "is-invalid" : ""}`}
                                                            value={values.barangay || ''}
                                                            onChange={val => { 
                                                                const selectedIndex = val.target.options.selectedIndex; 
                                                                let code = val.target.options[selectedIndex].getAttribute('name');
                                                                code = code.replace("barangay_", "");
                                                                let barangay = val.target.options[selectedIndex].getAttribute('label');
                                                                setFieldValue('barangay', code);
                                                                setFieldValue('barangayName', barangay);
                                                            }}
                                                        >
                                                            <option name="barangay_" key="3" value="" label="" />
                                                            {barangayList.map((value, index) => {
                                                                return <option value={value.barangay_psgc} name={'barangay_' + value.barangay_psgc} key={'barangay_' + value.barangay_psgc} label={value.barangay_name} />
                                                            })}
                                                        </select>
                                                        <ErrorMessage component="div" name="barangay" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="streetNo">Street No., Village, Subdivision etc.</label>
                                                        <Field type="text" name="streetNo" autoComplete="off" className={`form-control ${touched.streetNo && errors.streetNo ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="streetNo" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="lotBlockUnit">Lot, Block, Unit, Floor No., Etc.</label>
                                                        <Field type="text" name="lotBlockUnit" autoComplete="off" className={`form-control ${touched.lotBlockUnit && errors.lotBlockUnit ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="lotBlockUnit" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="zipCode">Zip Code</label>
                                                        <Field type="text" name="zipCode" autoComplete="off" className={`form-control ${touched.zipCode && errors.zipCode ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="zipCode" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label><strong>Contact Details</strong></label>
                                                <div className="border-left pl-3">
                                                    <div className="form-group">
                                                        <label htmlFor="companyContactNumber">Company Contact Number</label>
                                                        <Field type="text" value={values.companyContactNumber || ''} name="companyContactNumber" autoComplete="off" className={`form-control ${touched.companyContactNumber && errors.companyContactNumber ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="companyContactNumber" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="companyEmail">Company Email (Optional)</label>
                                                        <Field type="text" value={values.companyEmail || ''} name="companyEmail" autoComplete="off" className={`form-control ${touched.companyEmail && errors.companyEmail ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="companyEmail" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="website">Website (Optional)</label>
                                                        <Field type="text" value={values.website || ''} name="website" autoComplete="off" className={`form-control ${touched.website && errors.website ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="website" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label><strong>Social Media (Optional)</strong></label>
                                                <div className="border-left pl-3">
                                                    <div className="form-group">
                                                        <label htmlFor="linkedIn">LinkedIn</label>
                                                        <Field type="text" value={values.linkedIn || ''} name="linkedIn" autoComplete="off" className={`form-control ${touched.linkedIn && errors.linkedIn ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="linkedIn" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="facebook">Facebook</label>
                                                        <Field type="text" value={values.facebook || ''} name="facebook" autoComplete="off" className={`form-control ${touched.facebook && errors.facebook ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="facebook" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="twitter">Twitter</label>
                                                        <Field type="text" value={values.twitter || ''} name="twitter" autoComplete="off" className={`form-control ${touched.twitter && errors.twitter ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="twitter" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="instagram">Instagram</label>
                                                        <Field type="text" value={values.instagram || ''} name="instagram" autoComplete="off" className={`form-control ${touched.instagram && errors.instagram ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="instagram" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="youtube">Youtube</label>
                                                        <Field type="text" value={values.youtube || ''} name="youtube" autoComplete="off" className={`form-control ${touched.youtube && errors.youtube ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="youtube" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-4 text-center mt-4">
                                                <button type="button" className="startup-btn-main mx-3" onClick={() => { back(0)}}>Back</button>
                                                <button type="button" className="startup-btn-main" onClick={() => { next(2)}}>Next</button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default StartupEnablerBusiness;