import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../dist/css/editaccount.css";
import * as moment from "moment";
import store from "store";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import LoadingService from "../../imports/LoadingService";
import ls from '../../config/ls';

const accountSchema = Yup.object().shape({
  lastName: Yup.string().required("This is Required!"),
  firstName: Yup.string().required("This is Required!"),
  suffix: Yup.string(),
  middleName: Yup.string(),
  sex: Yup.string().required("This is Required"),
  birthDate: Yup.string().required("This is Required!").nullable(),
  placeOfBirth: Yup.string().required("This is Required!"),
  citizenship: Yup.string(),
  others: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema;
		}else{
			return schema.required('This is Required!');
		}
	}),
  contactNo: Yup.string().required("This is Required!"),
  socialClassification: Yup.array()
});

const Information = (props) => {
	document.title = 'User Details';
  let history = useHistory();

	const [loader, showLoader, hideLoader] = LoadingService();

  const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
  const user = props.user !== null ? props.user : {};
  const personalInfo = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? props.personalInfo : "";

  const [citizenshipStatus, setStatus] = useState(0);
  const [sexStatus, setSex] = useState(null);

  const [classificationList, setClassificationList] = useState([]);
  const [sexList, setSexList] = useState([]);

  const host = url.hostPort();
  const headers = {
		'Authorization': `Bearer ${token()}`,
    
	};

  const getSocialClassification = () => {
    let params = {
      "isActive": true,
      "sort":  {
        "name": -1 
      }
    }
    let endpoint = "/api/socialClassification/search";
    const api = host + endpoint;
    axios.post(api, params, {headers: headers}).then((res) => {
        let data = res.data.data;
        if(data.length !== 0){
          for(let x = 0; x < data.length; x++){
            data[x].checked = false;
          }
        }
        setClassificationList(data);
      }, (error) => {
      }
    )
  };

  const getSex = () => {
    let params = {
      "isActive": true,
      "sort":  {
        "name": -1
      }
    }
    let endpoint = "/api/sex/search";
    const api = host + endpoint;
    axios.post(api, params, {headers: headers}).then((res) => {
      let data = res.data.data;
      setSexList(data);
      },
      (error) => {
      }
    )
  };

  const citizenshipHandler = (value) => {
    setStatus(value);
  };

  const sexHandler = (value) => {
    setSex(value);
  };

  const submitAccount = async (values) => {
    showLoader();
    let params = {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      suffix: values.suffix,
      sex: values.sex,
      birthDate: moment(values.birthDate).format("MM/DD/YYYY"),
      birthPlace: values.placeOfBirth,
      citizenship: values.citizenship === 0 ? "Filipino" : values.others,
      contactNo: values.contactNo,
      socialClassification: values.socialClassification == undefined ? [] : values.socialClassification
    };
    let endpoint = `/api/startup/personal-information/create?_id=${user._id}`;
    const api = host + endpoint;
    axios.post(api, params, {headers: headers}).then(
      (res) => {
        hideLoader();
        let result = res.data;
        let data = result.data;
        ls.set("HYLQcSsSWe", data);
        toast.info(result.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        history.push('/dashboard');
      },
      (error) => {
        hideLoader();
        let errorMessage = "Error!";
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    );
  };

  const showOthers = (touched, errors) => {
    return (
      <div className="form-group">
        <Field
          type="text"
          name="others"
          autoComplete="off"
          className={`form-control form-control-sm ${
            touched.others && errors.others ? "is-invalid" : ""
          }`}
        />
        <ErrorMessage
          component="div"
          name="others"
          className="invalid-feedback"
        />
      </div>
    );
  };

  const privacy = () => {
    const win = window.open("/user/privacy", "_blank");
    win.focus();
  };

  useEffect( () => {
    if(user.hasPersonalInfo){
      history.push('/home');
    };
    getSocialClassification();
    getSex();
  }, []);

  useEffect(() => {
    props.PageHandler('initialLogin');
  }, [])

  return (
    <React.Fragment>
      {loader}
      <Header isLoggedIn={isLoggedIn} activePage = {props.activePage}/>
      <div className="information-container text-left mt-4">
        <div className="container border">
          <div className="row bg-white">
            <div className="col-md-8 col-sm-12 form-align">
              <h4 className="p-t-25">Continue account creation</h4>
              <div className="clearfix">&nbsp;</div>
              <p>
                To continue with your account creation, the portal will be collecting some personal information about you. 
                <a className="pointer" onClick={privacy}> Click here</a> to
                know more about our Privacy Statement. 
              </p>
              <p>
                The creation of a website account should be accomplished by the
                applicant startup or enabler themselves, using their personal
                details.
              </p>
              <hr></hr>
              <div className="clearfix">&nbsp;</div>
              <div className="form-align">
                <Formik
                  validationSchema={accountSchema}
                  onSubmit={submitAccount}
                  initialValues={{
                    lastName: '',
                    firstName: '',
                    suffix: '',
                    middleName: '',
                    birthDate: '',
                    placeOfBirth: '',
                    others: '',
                    sex: "",
                    citizenship: 0,
                    contactNo: '',
                    email: user.email,
                    socialClassification: ''
                  }}
                >
                  {({ handleSubmit, errors, touched, values, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="lastName">Last Name *</label>
                        <Field
                          type="text"
                          name="lastName"
                          autoComplete="off"
                          className={`form-control ${
                            touched.lastName && errors.lastName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="lastName"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="firstName">First Name *</label>
                        <Field
                          type="text"
                          name="firstName"
                          autoComplete="off"
                          className={`form-control ${
                            touched.firstName && errors.firstName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="firstName"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="suffix">Suffix</label>
                        <Field
                          type="text"
                          name="suffix"
                          autoComplete="off"
                          className={`form-control ${
                            touched.suffix && errors.suffix ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="suffix"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="suffix">Middle Name</label>
                        <Field
                          type="text"
                          name="middleName"
                          autoComplete="off"
                          className={`form-control ${
                            touched.middleName && errors.middleName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="middleName"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="sex">Sex (Assigned at birth) *</label>
                        <Field as="radio" name="sex" className={`${touched.sex && errors.sex ? "is-invalid" : ""}`}>
                          {sexList.map((value, index) => {
                            return (
                              <div key={`sex_${value._id}`} className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                id={`sex_${index}`}
                                value={value._id}
                                checked={sexStatus === value._id}
                                onChange={event => {
                                }}
                                onClick={(e) => {
                                  sexHandler(value._id);
                                  setFieldValue("sex", value._id);
                                }}
                                />
                                <label className="form-check-label" htmlFor={`sex_${index}`}>{value.name}</label>
                            </div> 
                            )
                          })}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="sex"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="birthDate">Birth Date *</label>
                        <Field
                          type="date"
                          name="birthDate"
                          autoComplete="off"
                          className={`form-control ${
                            touched.birthDate && errors.birthDate
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="birthDate"
                          className="invalid-feedback display-block"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="placeOfBirth">Place of Birth *</label>
                        <Field
                          type="text"
                          name="placeOfBirth"
                          autoComplete="off"
                          className={`form-control ${
                            touched.placeOfBirth && errors.placeOfBirth
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="placeOfBirth"
                          className="invalid-feedback"
                        />
                      </div>

                      <label htmlFor="citizenship">Citizenship *</label>
                      <div className="form-check">
                        <Field
                          className="form-check-input"
                          id="filipino"
                          type="radio"
                          name="filipino"
                          value="Filipino"
                          onChange={() => setFieldValue("citizenship", 0)}
                          checked={citizenshipStatus === 0}
                          onClick={(e) => citizenshipHandler(0)}
                        />
                        <label className="form-check-label" htmlFor="filipino">
                          Filipino{" "}
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <Field
                          className="form-check-input"
                          id="others"
                          type="radio"
                          name="others"
                          value="Others"
                          autoComplete="off"
                          onChange={() => setFieldValue("citizenship", 1)}
                          checked={citizenshipStatus === 1}
                          onClick={(e) => citizenshipHandler(1)}
                        />
                        <label className="form-check-label" htmlFor="others">
                          Others{" "}
                        </label>
                        {citizenshipStatus === 1
                          ? showOthers(touched, errors)
                          : null}
                      </div>
                      {/* <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <Field
                          type="text"
                          name="email"
                          className={`form-control ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div> */}
                      <div className="form-group">
                        <label htmlFor="contactNo">Contact Number *</label>
                        <Field
                          type="text"
                          name="contactNo"
                          autoComplete="off"
                          className={`form-control ${
                            touched.contactNo && errors.contactNo ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="contactNo"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                          <label>Social Classification</label>
                          <FieldArray
                            name="socialClassification"
                            className={`form-control ${touched.socialClassification && errors.socialClassification ? "is-invalid" : ""}`}
                            render={arrayHelpers => (
                              <div>
                                {classificationList.map((value, index) => {
                                  return (
                                    <div key={`socialClassification_${value._id}`} className="form-check">
                                      <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`socialClassification_${index}`}
                                      value={value._id}
                                      checked={value.checked}
                                      onChange={event => {
                                      }}
                                      onClick={event => {
                                        let checked = event.target.checked;
                                        let find = values.socialClassification.indexOf(value._id);
                                        value.checked = checked;
                                        if(checked){
                                          arrayHelpers.insert('', value._id);
                                        }else{
                                          arrayHelpers.remove(find);
                                        }
                                      }}
                                      />
                                      <label className="form-check-label" htmlFor={`socialClassification_${index}`}>{value.name}</label>
                                    </div> 
                                  )
                                })}
                              </div>
                            )}
                          />
                      </div>
                      <div className="text-center">
                        <button className="startup-btn mt-4" type="submit">
                          <b>Save</b>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <p className="mt-4">
                  You may later edit your account by going to your profile in your
                  dashboard page.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Information;
