import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "../../dist/css/account.css";
import { useHistory } from "react-router-dom";
import store from "store";
import * as moment from "moment";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import LoadingService from "../../imports/LoadingService";

const accountSchema = Yup.object().shape({
  password: Yup.string().required("This is Required!").matches(
    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{8,})/,
    "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character."
  ),
  confirmPassword: Yup.string().required("This is Required!").oneOf([Yup.ref('password'), null], 'New Password and Confirm New Password does not match.'),
});

const ResetPassword = (props) => {
	document.title = 'Reset Password';
	const history = useHistory();

	const [loader, showLoader, hideLoader] = LoadingService();

  const user = props.user !== null ? props.user : {};
  const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
	const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
    
	};

  const [showModal, setShowModal] = useState(false);
  const showModalHandler = () => {
    setShowModal(!showModal);
  };

  const submitAccount = async (values) => {
    showLoader();
		if(values.password !== values.confirmPassword){
			let error = "New Password and Confirm New Password does not match.";
			toast.error(error, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
			});
		}else{
      let _id = props.user._id;
			let params = {
				password: values.password,
				confirmPassword: values.confirmPassword,
			};
			let endpoint = `/api/change-password?_id=${_id}`;
			let api = host + endpoint;
    	axios.post(api, params, { headers: headers }).then((res) => {
        console.log({Response: res})
        hideLoader();
				let result = res.data;
				let data = result.data;
				showModalHandler();
			},
			(error) => {
        hideLoader();
				let responseError = error.response.data;
				let errorMessage = responseError.message;
				let message = errorMessage[Object.keys(errorMessage)[0]];
				let firstError = message[0];
				toast.error(firstError, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
			});
		}
	};

  const relogin = async () => {
		showModalHandler();
    store.remove("zgETDnOXBY");
    store.remove("LjfADyiZFB");
    store.remove("OnvBIFUcVC");
    store.remove("HYLQcSsSWe");
    store.remove("oqzAsiGkQs");
    store.remove("MaeIHzsYGC");
    store.remove("startupRegistrationFirst");
    store.remove("startupRegistrationFirstFile");
    store.remove("startupRegistrationSecond");
    history.push("/home");

    let message = "Logout Successful.";
    toast.info(message, {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    setTimeout(function(){ 
      window.location.href = window.location.href;
    }, 1000);
	};

  useEffect(() => {
    props.PageHandler('initialLogin');
  }, [])


  return (
    <React.Fragment>
      {loader}
      <Header isLoggedIn={isLoggedIn} user={user} activePage = {props.activePage}/>
      {/* <Header /> */}
      <Modal
        id="submittedModal"
        backdrop="static"
        keyboard={false}
        show={showModal}
        onHide={showModalHandler}
      >
        <Modal.Header>
          <Modal.Title>Successfully updated.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your password has been updated. Please relogin.</p>
        </Modal.Body>
        <Modal.Footer className="modal-footer text-right">
          <button type="button" className="btn btn-secondary"onClick={relogin}>
            OK
          </button>
        </Modal.Footer>
      </Modal>
      <div className="reset-password-container mt-4">
        <div className="container  mb-5">
          <div className="row bg-white border">
            <div className="col-md-8 col-sm-12 form-align mb-5">
              <h4 className="mt-5">Change Password</h4>
              <hr></hr>
              <Formik
                validationSchema={accountSchema}
                onSubmit={submitAccount}
                initialValues={{
									email: user.email_address,
									username: user.username,
									password: '',
									confirmPassword: ''
								}}
              >
                {({ handleSubmit, errors, touched }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="password">New Password</label>
                      <Field type="password" name="password" autoComplete="off" className={`form-control ${touched.password && errors.password? "is-invalid": ""}`}></Field>
                      <ErrorMessage component="div" name="password" className="invalid-feedback"/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="confirmPassword">Confirm New Password</label>
                      <Field type="password" name="confirmPassword" autoComplete="off" className={`form-control ${touched.confirmPassword && errors.confirmPassword? "is-invalid": ""}`}/>
                      <ErrorMessage component="div" name="confirmPassword" className="invalid-feedback"/>
                    </div>

                    <div className="py-4 text-center mt-3">
                      <button className="startup-btn" type="submit">
                        <b>Update</b>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ResetPassword;
