import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import Header from "../../../components/Header";
import axios from "axios";
import url from "../../../environment";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import LoadingService from "../../../imports/LoadingService";
import { useHistory } from "react-router-dom";
import SidebarLibraries from "../../../components/SidebarLibraries";
import token from "../../../store/token";
import xAuthToken from "../../../store/xAuthToken";

const sectorCreateSchema = Yup.object().shape({
    name: Yup.string().required("This is Required!"),
    description: Yup.string().required("This is Required!"),
});

const SectorCreate = (props) => {

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;

    document.title = 'Sector Create';

    const headers = {
        'Authorization': `Bearer ${token()}`,
        
    };

    const [loader, showLoader, hideLoader] = LoadingService();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const formikRef = useRef();
    const [formValue, setFormValue] = useState({
        name: '',
        description: '',
    });
    const history = useHistory();

    const cancelBack = () => {
        history.push('/libraries/sector/index');
    };

    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const createSector = async (values) => {
        setShowConfirmationModal(true);
        setFormValue({
            name: values.name,
            description: values.description,
        })
    };

    const confirmSubmit = async () => {
        let api = url.hostPort() + '/api/sector/create';
        axios.post(api, formValue, {headers: headers}).then(res => {
            let result = res.data;
            toast.info(result.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            setShowConfirmationModal(false);
            history.push('/libraries/sector/index');
        }, error => {
            let responseError = error.response.data;
            let errorMessage = responseError.message;
            toast.error(errorMessage, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        });
    };
    
    useEffect(() => {
        props.PageHandler('sector');
    }, [])

    return (
        <React.Fragment>
            {loader}
            <Modal
                backdrop="static"
                keyboard="false"
                show={showConfirmationModal}
                onHide={closeConfirmationModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Confirm Sector {formValue.name}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Create {formValue.name}?</p>
                    <div className="btn-div d-flex justify-content-end">
                        <div>
                            <button
                                className="startup-btn cancel mr-2"
                                onClick={closeConfirmationModal}
                            >
                                <b>Cancel</b>
                            </button>
                        </div>
                        <div>
                            <button
                                className="startup-btn confirm"
                                type="button"
                                onClick={confirmSubmit}
                            >
                                <b>Confirm</b>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Header isLoggedIn={isLoggedIn} isAdmin={true} />
            <div className="container py-4">
                <div className="row">
                    <div className="col-3">
                        <SidebarLibraries activePage={props.activePage} />
                    </div>
                    <div className="col-9">
                        <div className="container">
                            <div className="row justify-content-md-center">
                                <div className="col-lg-8">
                                    <div className="title-div">
                                        <h4>Create Sector</h4>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <Formik
                                                validationSchema={sectorCreateSchema}
                                                onSubmit={createSector}
                                                innerRef={formikRef}
                                                initialValues={Object.assign(formValue)}
                                            >
                                                {({ handleSubmit, errors, touched, values }) => (
                                                    <Form noValidate onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <label htmlFor="name">Sector Name*</label>
                                                            <Field
                                                                type="text"
                                                                name="name"
                                                                autoComplete="off"
                                                                className={`form-control ${touched.name && errors.name
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="name"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="description">Description*</label>
                                                            <Field
                                                                type="text"
                                                                as="textarea"
                                                                rows="5"
                                                                name="description"
                                                                autoComplete="off"
                                                                className={`form-control ${touched.description && errors.description
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="description"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="btn-div d-flex justify-content-end">
                                                            <div className="btn-cancel pr-3">
                                                                <button className="startup-btn-flat" onClick={cancelBack}>
                                                                    <b>Cancel</b>
                                                                </button>
                                                            </div>
                                                            <div className="btn-submit">
                                                                <button className="startup-secondary-btn-fill" type="submit">
                                                                    <b>Submit</b>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SectorCreate;