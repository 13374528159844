import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import IdleTimerContainer from "../components/IdleTimerContainer";
import "../dist/css/dashboard.css";
import * as moment from "moment";
import "moment-timezone";
import axios from "axios";
import url from "../environment";
import token from "../store/token";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import currentActions from "../store/currentActions";
import "../dist/css/admindashboard.css";
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Modal } from "react-bootstrap";
import { saveAs } from 'file-saver';
import csv from 'csv-parser';
import * as Papa from 'papaparse';
import LoadingService from "../imports/LoadingService";


const generateSchema = Yup.object().shape({
    type: Yup.string().required("This is Required!"),
});

const AdminDashboard = (props) => {
	document.title = 'Dashboard';
    const history = useHistory();
  
	const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const user = props.user !== null ? props.user : {};

    const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
	};

    const [generateList, setGenerateList] = useState([
        {
            "_id": "",
            "name": ""
        },
        {
            "_id": "default",
            "name": "Default fields"
        },
        {
            "_id": "all",
            "name": "All fields"
        }
    ]);
    const [showGenerateModal, setShowGenerateModal] = useState(false);
    const [formValue, setFormValue] = useState({
        type: '',
    });

    const [totalRegistration, setTotalRegistration] = useState('');
    const [totalAspiring, setTotalAspiring] = useState('');
    const [totalStartup, setTotalStartup] = useState('');
    const [totalNew, setTotalNew] = useState('');
    const [totalInProgress, setTotalInProgress] = useState('');
    const [totalApproved, setTotalApproved] = useState('');

    const [hidePSDPList, setHidePSDPList] = useState(true);

    const [loader, showLoader, hideLoader] = LoadingService();

    const verifyRegistrations = () => {
        window.location.href = '/startup/evaluation/index';
    };

    const checkAdminType = () => {
        if(currentActions().indexOf('/api/approval/startup/evaluate') !== -1){
            setHidePSDPList(false);
        }
    };

    const getDashboardData = () => {
        const api = host + '/api/startup/statistic';
        axios.get(api, {headers: headers}).then(res => {
            const result = res.data;
            const data = result.data;
            setTotalRegistration(data.totalRegistration);
            setTotalAspiring(data.totalAspiring);
            setTotalStartup(data.totalStartupAccount);
            setTotalNew(data.totalNewRegistration);
            setTotalInProgress(data.totalInProgressRegistration);
            setTotalApproved(data.totalVerifiedRegistration);
        }, error => {
            setTotalRegistration(0);
            setTotalAspiring(0);
            setTotalStartup(0);
            setTotalNew(0);
            setTotalInProgress(0);
            setTotalApproved(0);
        })
    };

    const closeGenerateModal = () => {
        setShowGenerateModal(false);
    };

    const openGenerateModal = () => {
        setShowGenerateModal(true);
    };

    const generate = (values) => {
        showLoader();
        if (values.type === "defaut") {
          setFormValue({
            type: "",
          });
        } else {
          setFormValue({
            type: values.type,
          });
        }
      
        const api = host + '/api/startup/generate/csv';
        axios.post(api, formValue, { headers: headers }).then((res) => {
            const data = res.data;
            // Convert the response data to CSV format
            return convertToCsv(data)
        }).then((csvData) => {
            hideLoader();
            // Create a new Blob object with the CSV data
            const csv = Papa.unparse(csvData);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
                        
            // Save the Blob as a file using FileSaver.js
            const currentDate = new Date();
            const options = { timeZone: 'Asia/Manila', year: 'numeric', month: '2-digit', day: '2-digit' };
            const formattedDate = currentDate.toLocaleDateString('en-US', options).replace(/\//g, '-');

            const fileName = values.type === 'all'
            ? `startup-registration-${formattedDate}-all-fields.csv`
            : `startup-registration-${formattedDate}.csv`;

            saveAs(blob, fileName);

            toast.info('Successfully generated csv.', {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            closeGenerateModal();
        }).catch((error) => {
            hideLoader();
            toast.error(error, {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        });
      };
      

    const convertToCsv = (data) => {
        const dataArray = [];
  
        return new Promise((resolve, reject) => {
            const stream = csv({
                headers: false,
                skipComments: true,
                skipEmptyLines: true
            })
            .on('data', (row) => {
                dataArray.push(row);
            })
            .on('end', () => {
                resolve(dataArray);
            })
            .on('error', (error) => {
                reject(error);
            });

            stream.write(data);
            stream.end();
        });
    }
    
    const checkKeyDown = (e) => {
        if (e.code === 'Enter') e.preventDefault();
    };

    useEffect( () => {
        checkAdminType();
        getDashboardData();
    }, []);

    return (
        <React.Fragment>
            {loader}
            <IdleTimerContainer isLoggedIn={isLoggedIn} isAdmin={true} />
            <Header isLoggedIn={isLoggedIn} isAdmin={true} />
            <Modal
                backdrop="static"
                keyboard="false"
                show={showGenerateModal}
                onHide={closeGenerateModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Generate report</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Formik
                            validationSchema={generateSchema}
                            onSubmit={generate}
                            initialValues={Object.assign(formValue)}
                        >
                            {({ handleSubmit, setFieldValue, errors, touched, values }) => (
                                <Form noValidate onSubmit={handleSubmit} onKeyDown={checkKeyDown}>
                                    <div className="form-group">
                                        <label htmlFor="generate">Type*</label>
                                        <select
                                            name="generate"
                                            className={`form-control ${touched.type && errors.type
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            value={values.type || ''}
                                            onChange={(e) => setFieldValue("type", e.target.value)}
                                        >
                                            {generateList.map((value, index) => {
                                                return <option value={value._id} name={value.name} key={'type_' + index} label={value.name} />
                                            })}
                                        </select>
                                        <ErrorMessage
                                            component="div"
                                            name="type"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="btn-div d-flex justify-content-end">
                                        <div className="btn-cancel pr-3">
                                            <button className="startup-btn-flat" onClick={closeGenerateModal}>
                                                <b>Cancel</b>
                                            </button>
                                        </div>
                                        <div className="btn-submit">
                                            <button className="startup-secondary-btn-fill" type="submit">
                                                <b>Submit</b>
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="dashboard-container mt-4 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col">
                                            <div className="score-card total-reg my-2">   
                                                <h5>Total Registrations</h5>
                                                <h4>{totalRegistration}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 pr-2">
                                            <div className="score-card startup-reg my-2">   
                                                <h5>Startup Registrations</h5>
                                                <h4>{totalStartup}</h4>
                                            </div>
                                        </div>
                                        <div className="col-6 pl-2">
                                            <div className="score-card aspiring-indv my-2">   
                                                <h5>Aspiring Individuals</h5>
                                                <h4>{totalAspiring}</h4>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                                <div className="col-md-5">
                                    <div className="d-flex flex-column h-100 text-center">
                                        <div className="score-card score-new d-flex flex-column justify-content-center p-3 h-100 my-2">   
                                            <h5>New</h5>
                                            <h4>{totalNew}</h4>
                                        </div>
                                        <div className="score-card score-progress d-flex flex-column justify-content-center p-3 h-100 my-2">   
                                            <h5>In Progress</h5>
                                            <h4>{totalInProgress}</h4>
                                        </div>
                                        <div className="score-card score-verified d-flex flex-column justify-content-center p-3 h-100 my-2">   
                                            <h5>Verified</h5>
                                            <h4>{totalApproved}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row form-align mt-3" hidden={hidePSDPList}>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3 text-center px-0">
                            <button type="button" className="startup-btn-main btn-block" onClick={() => verifyRegistrations()}>
                                Verify Startup Registrations
                            </button>
                        </div>
                    </div>
                    <div className="row form-align mt-1">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3 text-center px-0">
                            <button type="button" className="startup-btn-main btn-block" onClick={() => openGenerateModal()}>
                                Generate Startup Registration
                            </button>
                        </div>
                    </div>
                    <div className="row form-align" hidden={true}>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3 text-center px-0">
                            <button type="button" className="startup-btn-main btn-block">
                                Verify Startup Enabler Registrations
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AdminDashboard;