import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import store from "store";
import * as moment from "moment";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import xAuthToken  from "../../store/xAuthToken";
import { toast } from 'react-toastify';
import LoadingService from '../../imports/LoadingService';
import "../../dist/css/company.css";

//Company Update
const MyCompany = (props) => {
    document.title = 'My Company Details';
    const history = useHistory();

    const [loader, showLoading, hideLoading] = LoadingService();

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const user = props.user !== null ? props.user : {};
    const personalInfo = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? props.personalInfo : "";

    const host = url.hostPort();
    const headers = {
        'Authorization': `Bearer ${token()}`,
        
    };

	const [isLoaded, setIsLoaded] = useState(false);

	const [startupType, setStartupType] = useState(1);
	const [startupId, setStartupId] = useState("");
	const [targetSectors, setSectors] = useState([]);
	const [applicantTypeLabel, setApplicantTypeLabel] = useState("");
	const [applicantType, setApplicantType] = useState(1);
	const [address, setAddress] = useState("");
	const [schoolName, setSchoolName] = useState("");
	const [oicDetails, setOicDetails] = useState("");
	const [designation, setDesignation] = useState("");
	const [sex, setSex] = useState("");
	const [supportDocument, setSupportDocument] = useState("");
	const [supportDocumentRequirement, setSupportDocumentRequirement] = useState("");
	const [passportDatasheet, setPassportDatasheet] = useState("");
	const [passportDatasheetRequirement, setPassportDatasheetRequirement] = useState("");
	const [hidePSDPVerify, setHidePSDPVerify] = useState(true);

	const [isVerified, setIsVerified] = useState(false); //has psdp
	const [startupStage, setStartupStage] = useState("");
	const [startupName, setStartupName] = useState("");
	const [businessName, setBusinessName] = useState("");
	const [registrationStatus, setRegistrationStatus] = useState("");
	const [registrationId, setRegistrationId] = useState("");
	const [businessLogo , setBusinessLogo] = useState(null);
	const [businessLogoImg, setBusinessLogoImg] = useState(null);
	const [yearFounding, setYearFounding] = useState("");
	const [businessClass, setBusinessClass] = useState("");
	const [registrationNumber, setRegistrationNumber] = useState("");
	const [proof, setProof] = useState("");
	const [proofRequirement, setProofRequirement] = useState("");
	const [companyDetails, setCompanyDetails] = useState("");

	const getRegistration = () => {
		showLoading();
		const api = host + `/api/startup/registration/view?_id=${user._id}`;
		axios.get(api, {headers: headers}).then((res) => {
			hideLoading();
			const result = res.data;
			const data = result.data;
			setRegistrationId(data._id);
			setStartupType(data.startupType);
			setStartupId(data.startupId);
			setSectors(data.sectors);
			setApplicantType(data.applicantType);
			setApplicantTypeLabel(transformApplicantType(data.applicantType, data.startupType));
			setAddress(data.address);
			setSchoolName(data.schoolName);
			setOicDetails(data.oic);
			const oic = data.oic;
			setDesignation(oic.designation);
			setSex(oic.sex);
			if(data.fromAspiring){
				if(data.isVerified){
					const oic = data.oic;
					const passportDatasheet = oic.passportDatasheet;
					const passportRequirement = passportDatasheet.requirement;
					setPassportDatasheet(passportDatasheet);
					setPassportDatasheetRequirement(passportRequirement);

					const supportDocument = oic.supportDocument;
					const supportRequirement = supportDocument.requirement;
					setSupportDocument(supportDocument);
					setSupportDocumentRequirement(supportRequirement);
				}else{
					if(data.applicantType === 3){
						let passportDatasheet = data.oic.passportDatasheet;
						let requirement = passportDatasheet.requirement;
						setPassportDatasheet(passportDatasheet);
						setPassportDatasheetRequirement(requirement);
					}else{
						let supportDocument = data.oic.supportDocument;
						let requirement = supportDocument.requirement;
						setSupportDocument(supportDocument);
						setSupportDocumentRequirement(requirement);
					}
				}
			}else{
				if(data.isVerified){
					const oic = data.oic;
					const passportDatasheet = oic.passportDatasheet;
					const passportRequirement = passportDatasheet.requirement;
					setPassportDatasheet(passportDatasheet);
					setPassportDatasheetRequirement(passportRequirement);

					const supportDocument = oic.supportDocument;
					const supportRequirement = supportDocument.requirement;
					setSupportDocument(supportDocument);
					setSupportDocumentRequirement(supportRequirement);
				}
			}
			if(data.startupType == 2 && !data.isVerified){
				setHidePSDPVerify(false);
			}
			setIsVerified(data.isVerified);
			setStartupStage(data.startupStage);
			setStartupName(data.startupName);
			setBusinessName(data.businessName);
			setRegistrationStatus(data.registrationStatus);
			if(data.hasOwnProperty('businessLogo')){
				setBusinessLogo(data.businessLogo);
				if(data.businessLogo !== null){
					getBusinessLogo(data.businessLogo._id, data.businessLogo.name);
				}
			}
			setYearFounding(data.yearFounding);
			setBusinessClass(data.businessClassification);
			setRegistrationNumber(data.registrationNumber);
			setProof(data.proofOfRegistration);
			setProofRequirement(data.proofOfRegistrationRequirement);

			const company = {
				"companyEmail": data.companyEmail,
				"companyFacebook": data.companyFacebook,
				"companyInstagram": data.companyInstagram,
				"companyLinkedIn": data.companyLinkedIn,
				"companyTwitter": data.companyTwitter,
				"companyWebsite": data.companyWebsite,
				"companyYoutube": data.companyYoutube,
				"companyNo": data.companyNo,
				"businessDescription": data.businessDescription,
				"problemSolved": data.problemSolved,
				"idea": data.idea,
				"usp": data.usp
			};
			setCompanyDetails(company);

			setIsLoaded(true);
		}, error => {
			hideLoading();
			toast.error(error, {
			position: "bottom-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: false,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			});
		});
	};

	const transformApplicantType = (applicantType, startupType) => {
		if(startupType == 1){ //aspiring
			if(applicantType === 1){//aspiring individual
				return "Aspiring Individual";
			}else if(applicantType === 2){//aspiring student
				return "Aspiring Student";
			}else if(applicantType === 3){//aspiring foreign
				return "Aspiring Foreigner";
			}else if(applicantType === 4){//startup entity
				return "Registered Entity";
			}else if(applicantType === 5){//startup enabler
				return "Registered Entity";
			}else{
				return "";
			}
		}else if(startupType == 2){ //registered entity, startup account
			return "Registered Entity";
		}else if(startupType == 3){ // registered entity, enabler
			return "Registered Entity";
		}
	};

	const RedirectCBP = () => {
		const website = "business.gov.ph";
		return <a href={`//${website}`} target="_blank" rel="noopener noreferrer">Register your Startup using the Central Business Portal</a>;
	};

	const Redirect = (value) => {
		if(value === "" || value === null){
			return <label className="mb-0">N/A</label>
		}else{
			return <a href={`//${value}`} target="_blank" rel="noopener noreferrer">{value}</a>;
		}
	};

	const viewFile = (_id, name) => {
		showLoading();
        let endpoint = `/api/file/download?_id=${_id}`;
        let api = host + endpoint;
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = name;
        axios.get(api, {responseType: "blob", headers: headers}).then((res) => {
			hideLoading();
            let responseHeaders = res.headers;
            let content = Object.values(responseHeaders);
            content = content[0];
            
            //new tab
            try{
                let fileURL = window.URL.createObjectURL(res.data);
                let tab = window.open();
                tab.location.href = fileURL;
            }catch(ex){
                let message = "Please allow pop-up window to view file.";
				toast.error(message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
            }
        }, error => {
			hideLoading();
            console.log("error", error);
        });
    };

	const getBusinessLogo = (_id, name) => {
        let endpoint = `/api/file/download?_id=${_id}`;
        let api = host + endpoint;
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = name;
        axios.get(api, {responseType: "blob", headers: headers}).then((res) => {
            let fileURL = window.URL.createObjectURL(res.data);
            setBusinessLogoImg(fileURL);
        }, error => {
            console.log("error", error);
        });
    };

	const isNotNull = (value) => {
        if(typeof value !== "undefined" && value !== null){
            return true;
        }
        return false;
    };

    useEffect( () => {
		getRegistration();
    }, [props]);

    return (
        <React.Fragment>
			{loader}
			<Header isLoggedIn={isLoggedIn} />
			<div className="company-container mt-4">
				<div className="container text-left">
					{startupType === 1 ?
						<div className="row">
							<div className="col-md-6 text-left">
								<div className="m-b-10 bg-white p-3 rounded-sm">
									<h4>My Company</h4>
									<hr/>
									<div className="startup-id">
										<h6 className="mt-3 mb-0"><strong>Startup ID:</strong></h6>
										<h5>{startupId}</h5>
									</div>
									<h6 className="mb-0 mt-3"><strong>Industry Sector/s of Interest</strong></h6>
									{
										targetSectors.map((value, index) => {
											return <li key={`li_${index}`}>{value}</li>
										})
									}
									<h6 className="mb-0 mt-3"><strong>User Type</strong></h6>
									<label className="mb-0">{applicantTypeLabel}</label>
									<h6 className="mb-0 mt-3"><strong>Address</strong></h6>
									<label className="mb-0">{address}</label>
									<div className="clearfix">&nbsp;</div>
									{
										applicantType === 2 ?
											<div>
												<h6 className="mb-0 mt-3"><strong>School</strong></h6>
												<label className="mb-0">{schoolName}</label>
											</div>
										:
											null
									}
									<div>
										<h6 hidden={applicantType === 3 ? true : false}><strong>Government-Issued ID</strong></h6>
										<h6 className="mb-0">
											{isLoaded ? (applicantType === 3 ? passportDatasheetRequirement.name : supportDocumentRequirement.name) : ""}
										</h6>
										<small className="file"
											name={isLoaded ? (applicantType === 3 ? passportDatasheet.name : supportDocument.name) : ""}
											value={isLoaded ? (applicantType === 3 ? passportDatasheet._id : supportDocument._id) : ""}
											onClick={event => {
											let _id = event.target.getAttribute('value');
											let name = event.target.getAttribute('name');
											viewFile(_id, name);
											}
										}>{isLoaded ? (applicantType === 3 ? passportDatasheet.name : supportDocument.name) : ""}</small>
									</div>
								</div>
								<div className="m-t-10 m-b-15 bg-white p-3 rounded-sm">
									<div className="startup-id">
										<h6 className="mb-0">Business not yet registered?*</h6>
										<RedirectCBP />
										<p>
											*The Central Business Portal is not yet integrated with the Startup Innovations Portal.
											You will be asked to sign-up and fill-out new forms on the site.
										</p>
									</div>
								</div>
							</div>
						</div>
					:
						<div className="row">
							<div className="col-md-12 text-left" hidden={isVerified}>
								<div className="m-b-15 bg-white p-3 rounded-sm">
									<h4>My Company</h4>
									<hr></hr>
									<div className="startup-id ">
										<h6>Startup IDs:</h6>
										<h5>{startupId}</h5>
									</div>
									<h6 className="mb-0 mt-3">Startup Development Stage</h6>
									<label className="mb-0">{startupStage.name}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0 mt-3">Startup Sector/s</h6>
									{
										targetSectors.map((value, index) => {
											return <li key={`li_${index}`}>{value}</li>
										})
									}
									<h6 className="mb-0 mt-3">Startup Accreditation Type</h6>
									<label className="mb-0">{applicantTypeLabel}</label>
								</div>
							</div>
							<div className="col-md-6 text-left" hidden={!isVerified}>
								<div className="m-b-15 bg-white p-3 rounded-sm">
									<h4>My Company</h4>
									<hr></hr>
									<p hidden={registrationStatus === "For Updating" ? false : true} className="mt-3">
										There are flagged items in your registration
										<br />
										<a href={`/register/rectify?reg_id=${registrationId}`}><strong>Rectify flagged items</strong></a>
									</p>
									<div className="startup-id">
										<h6>Startup IDs:</h6>
										<h5>{startupId}</h5>
									</div>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Startup Development Stage</h6>
									<label className="mb-0">{startupStage.name}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Startup Sector/s</h6>
									{
										targetSectors.map((value, index) => {
											return <li key={`li_${index}`}>{value}</li>
										})
									}
									<h6 className="mb-0 mt-3">Startup Accreditation Type</h6>
									<label className="mb-0">{applicantTypeLabel}</label>
								</div>
								<div className="clearfix">&nbsp;</div>
								<div className="m-b-15 bg-white p-3 rounded-sm">
									<h4>Business Information</h4>
									<hr></hr>
									<h6 className="mb-0">Startup Name</h6>
									<label>{startupName}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Registered Business / SEC / CDA Name</h6>
									<label>{businessName}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-7">Registration Status: 
										<span 
										className={`badge 
										${registrationStatus === "New" ? 'bg-info' : 
										registrationStatus === "In Progress" ? 'bg-warning' : 
										registrationStatus === "Verified" ? 'bg-success' : 
										registrationStatus === "For Updating" ? 'bg-warning' : 
										registrationStatus === "Updated" ? 'bg-success' :
										registrationStatus === "Declined" ? 'bg-warning' : 'bg-info'}  `}
										> 
											{registrationStatus} 
										</span>
									</h6>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Business Logo</h6>
									<div hidden={businessLogo !== null ? false : true}>
										<img src={businessLogoImg} alt="Business Logo" width="70" height="70" />
										<div className="mb-7"></div>
									</div>
									<div hidden={businessLogo !== null ? true : false}>
										<label>N/A</label>
									</div>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Business Classification</h6>
									<label>{businessClass.name}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Registration/Certificate/Permit No.</h6>
									<label>{registrationNumber}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Proof of registration or government accreditation</h6>
									<h6 className="mb-0">{proofRequirement.name}</h6>
									<small className="file"
										name={proof.name}
										value={proof._id}
										onClick={event => {
										let _id = event.target.getAttribute('value');
										let name = event.target.getAttribute('name');
										viewFile(_id, name);
										}
									}>{proof.name}</small>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Year of founding</h6>
									<label>{yearFounding !== "" ? yearFounding : "N/A"}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Address</h6>
									<label>{address}</label>
									<div className="clearfix">&nbsp;</div>
								</div>
								<div className="clearfix">&nbsp;</div>
								<div className="m-b-15 bg-white p-3 rounded-sm">
									<h4>Company Contact Details</h4>
									<hr></hr>
									<h6 className="mb-0">Company Email</h6>
									<label >{companyDetails.companyEmail !== "" ? companyDetails.companyEmail : "N/A"}</label>
									
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Company Contact No.</h6>
									<label >{companyDetails.companyNo !== "" ? companyDetails.companyNo : "N/A"}</label>
								
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Company Website</h6>
									<label >{companyDetails.companyWebsite !== "" ? Redirect(companyDetails.companyWebsite): "N/A"}</label>
								</div>
							</div>
							<div className="col-md-6 text-left pr-3" hidden={!isVerified}>
								<div className="m-b-15 bg-white p-3 rounded-sm">
									<h4>Officer-in-Charge Information ({designation.name})</h4>
									<hr></hr>
									<h6 className="mb-0">Last Name</h6>
									<label>{oicDetails.lastName}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">First Name</h6>
									<label>{oicDetails.firstName}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Suffix</h6>
									<label>{oicDetails.suffix == "" ? "N/A" : oicDetails.suffix}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Middle Name</h6>
									<label>{oicDetails.middleName == "" ? "N/A" : oicDetails.middleName}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Date of Birth</h6>
									<label>
										{moment(oicDetails.birthDate).format("MM/DD/YYYY")}
									</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Sex (Assigned at birth)</h6>
									<label>{sex.name}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Place of Birth</h6>
									<label>{oicDetails.birthPlace}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Citizenship</h6>
									<label>{oicDetails.citizenship}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Email Address</h6>
									<label>{oicDetails.email}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Contact No.</h6>
									<label>{oicDetails.contactNo}</label>
									<div className="clearfix">&nbsp;</div>
									<div>
										<h6 className="mb-0">
											{oicDetails.citizenship === "Filipino" ? "Government-Issued ID" : "Other documents to support eligibity to engage in business in the Philippines"}
										</h6>
										<h6 className="mb-0">
											{supportDocumentRequirement.name}
										</h6>
										<small className="file"
											name={supportDocument.name}
											value={supportDocument._id}
											onClick={event => {
											let _id = event.target.getAttribute('value');
											let name = event.target.getAttribute('name');
											viewFile(_id, name);
											}
										}>{supportDocument.name}</small>
									</div>
									<div className="clearfix" hidden={oicDetails.citizenship === "Filipino" ? true : false}>&nbsp;</div>
									<div hidden={oicDetails.citizenship === "Filipino" ? true : false}>
										<h6 className="mb-0">
											{passportDatasheetRequirement.name}
										</h6>
										<small className="file"
											name={passportDatasheet.name}
											value={passportDatasheet._id}
											onClick={event => {
											let _id = event.target.getAttribute('value');
											let name = event.target.getAttribute('name');
											viewFile(_id, name);
											}
										}>{passportDatasheet.name}</small>
									</div>
								</div> 
								<div className="clearfix" hidden={!isVerified}>&nbsp;</div>
								<div className="m-b-15 bg-white p-3 rounded-sm" hidden={!isVerified}>
									<h4>Business Description</h4>
									<hr></hr>
									<div id="busDesc">
										<h6 className="mb-0">Brief description of your Startup Business</h6>
										{ isNotNull(companyDetails.businessDescription) ? companyDetails.businessDescription.length > 100 ? 
											<div className="collapse" id="collapseBusDesc" aria-expanded="false">
												<label >{companyDetails.businessDescription}</label>
											</div> : 
											<label >{companyDetails.businessDescription}</label> :  
											null
										}
									</div>
									{isNotNull(companyDetails.businessDescription) ?
									companyDetails.businessDescription.length > 100 ? 
									<div className="text-left">
										<a role="button" className="collapsed more-btn" data-toggle="collapse" href="#collapseBusDesc" aria-expanded="false" aria-controls="collapseExample">
										</a>
										<div className="mb-7"></div>
									</div>
									: null 
									: null}
									
									<div className="clearfix">&nbsp;</div>
									<div id="probDesc">
										<h6 className="mb-0">Problems being solved</h6>
										{ isNotNull(companyDetails.problemSolved) ? companyDetails.problemSolved.length > 100 ? 
											<div className="collapse" id="collapseProbDesc" aria-expanded="false">
												<label >{companyDetails.problemSolved}</label>
											</div> 
											: <label >{companyDetails.problemSolved}</label>
											: null
										}
									</div>
									{isNotNull(companyDetails.problemSolved) ?
									companyDetails.problemSolved.length > 100 ? 
									<div className="text-left">
										<a role="button" className="collapsed more-btn" data-toggle="collapse" href="#collapseProbDesc" aria-expanded="false" aria-controls="collapseExample">
										</a>
										<div className="mb-7"></div>
									</div>
									: null 
									: null}
									
									<div className="clearfix">&nbsp;</div>
									<div id="ideaDesc">
										<h6 className="mb-0">Idea or Product being offered</h6>
										{ isNotNull(companyDetails.idea) ? companyDetails.idea.length > 100 ? 
											<div className="collapse" id="collapseIdeaDesc" aria-expanded="false">
												<label >{companyDetails.idea}</label>
											</div> : 
											<label >{companyDetails.idea}</label> :  
										null
										}
										
									</div>
									{isNotNull(companyDetails.idea) ?
									companyDetails.idea.length > 100 ? 
									<div className="text-left">
										<a role="button" className="collapsed more-btn" data-toggle="collapse" href="#collapseIdeaDesc" aria-expanded="false" aria-controls="collapseExample">
										</a>
										<div className="mb-7"></div>
									</div>
									
									: null
									: null} 
									
									<div className="clearfix">&nbsp;</div>
									<div id="uspDesc">
										<h6 className="mb-0">Unique Selling Proposition (USP) of your solution</h6>
										{ isNotNull(companyDetails.usp) ? companyDetails.usp.length > 100 ? 
											<div className="collapse" id="collapseUspDesc" aria-expanded="false">
												<label >{companyDetails.usp}</label>
											</div> : 
											<label >{companyDetails.usp}</label> :  
										null
										}
									</div>
									
									{isNotNull(companyDetails.usp) ?
									companyDetails.usp.length > 100 ?
									<div className="text-left">
										<a role="button" className="collapsed more-btn" data-toggle="collapse" href="#collapseUspDesc" aria-expanded="false" aria-controls="collapseExample">
										</a>
									</div>
									: null
									: null}
								</div>
								<div className="clearfix" hidden={!isVerified}>&nbsp;</div>
								<div className="m-b-15 bg-white p-3 rounded-sm" hidden={!isVerified}>
									<h4>Company Social Media Details</h4>
									<hr></hr>
									<h6 className="mb-0">LinkedIn</h6>
									<label >{Redirect(companyDetails.companyLinkedIn)}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Facebook</h6>
									<label>{Redirect(companyDetails.companyFacebook)}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Twitter</h6>
									<label >{Redirect(companyDetails.companyTwitter)}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Instagram</h6>
									<label >{Redirect(companyDetails.companyInstagram)}</label>
									<div className="clearfix">&nbsp;</div>
									<h6 className="mb-0">Youtube</h6>
									<label>{Redirect(companyDetails.companyYoutube)}</label>
								</div>
							</div>
						</div>
					}
					<div className="row" hidden={hidePSDPVerify}>
						<div className="col-md-6 text-left">
							<div className="p-3">
								Your business is not yet verified.
								<br />
								<a href={`/v2/registration/psdpverification?reg_id=${user._id}`}><strong>Continue to PSDP verification</strong></a>
							</div>
						</div>
					</div>
				</div>
			</div>
        </React.Fragment>
    );
};
export default MyCompany;