import React, { useState, useEffect } from "react";
import "../dist/css/home.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import token from "../store/token";
import xAuthToken from "../store/xAuthToken";
import { Modal } from "react-bootstrap";

const RedirectModal = (props) => {
    const history = useHistory();

	const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
        
	};

    const [modalLabel, setModalLabel] = useState("");
    const [hasRegistration, setHasRegistration] = useState(false);

    const redirectClick = () => {
        let isLoggedIn = props.isLoggedIn;
        let personalInfo = props.personalInfo;
        if(isLoggedIn){
            if(!personalInfo){
                history.push('/user/signup/details');
            }else{
                if(!hasRegistration){
                    history.push('/dashboard');
                }else{
                    history.push('/home');
                }
            }
        }else{
            history.push(`/user/signup`);
        }
    };

    const checkRegistration = () => {
        let user = props.user;
        if(user){
            const api = host + `/api/startup/registration/check?_id=${user._id}`;
            axios.get(api, { headers: headers }).then((res) => {
              let result = res.data;
              setHasRegistration(result.exist);
            }, error => {
            });
        }
    };

    useEffect(() => {
        checkRegistration();
        return;
    }, []);

    useEffect(() => {
		let isLoggedIn = props.isLoggedIn;
        let personalInfo = props.personalInfo;
        if(isLoggedIn){
            if(!personalInfo){
                setModalLabel("u r loggedin but no personal info. redirect to create personal info?");
            }else{
                if(!hasRegistration){
                    setModalLabel("u r loggedin and has personal info but no startup account. redirect to create startup account?");
                }else{
                    setModalLabel("has registration hehe");
                }
            }
        }else{
            setHasRegistration(false);
            setModalLabel("Not logged in(signup or login) u want to signup?");
        }
	}, []);

    return (
        <React.Fragment>
            <Modal backdrop="static" keyboard={false} show={props.showRedirect}>
                <Modal.Header>
                    <Modal.Title>Redirect Sample</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{modalLabel}</p>
                    <div className="py-4 text-center mt-4">
                        <a className="pointer" onClick={(e) => props.showHandler()} >Cancel</a>
                        <button type="button" onClick={(e) => redirectClick()} className="startup-btn-main margin-btn">Redirect</button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};
export default RedirectModal;