import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { ReactDOM } from "react-dom";
import Header from "../components/Header";
import axios from "axios";

//
import $ from "jquery";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
require("formBuilder/dist/form-render.min.js");

const header = {
  Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MjhkZDc3OGM0ZjE3ODMwM2MyNWU5MWEiLCJpYXQiOjE2NjEyMjI1NTd9.I6rLVsQrnhHRXT5r7guc8G-iOmAJ_8VAQGdvVD8uUJg`,
};

function FormSampleView() {
  const location = useLocation();
  const [dataSet, setDataSet] = useState([]);
  const navigate = useHistory();

  useEffect(() => {
    readData();
  }, []);

  function testing(event) {
    const formData = new FormData(event.currentTarget);
    event.preventDefault();

    // console.log(formData.get('Gender'))
    // console.log(formData.get('LName'))
    let newObj = {
      form: [],
    };
    for (let [key, value] of formData.entries()) {
      console.log({ locanto: value.name });
      let keyValue = {
        key: "",
        value: "",
        file: ""
      };
      keyValue.value = formData.get(`${key}`);
      keyValue.key = key;
      keyValue.file = value.name
      newObj.form.push(keyValue);
    }
    console.log(newObj);
    axios
      .post("http://13.213.129.242:3009/api/form/create", newObj, {
        headers: header,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function readData() {
    const fbRender = document.getElementById("render-wrap");
    console.log("formbuilder saved");
    // toggleEdit(false);

    var formRenderOptions = {
      formData: location.state.data,
    };

    $(fbRender).formRender(formRenderOptions);
    console.log(formRenderOptions);
  }

  function editForm() {
    var defaultField = {
      defaultFields: [
        {
          className: 'form-control',
          label: 'Default Field',
          placeholder: 'Enter your default field value',
          name: 'default-field-1',
          type: 'text',
        },
      ],
      persistDefaultFields: true,
    }
    navigate.push({ pathname: '/user/formbuilder', state: { id: 1, data: location.state.data } })
  }

  // function toggleEdit(editing) {
  //   document.body.classList.toggle("form-rendered", !editing);
  // }

  return (
    <div >
      <Header />
      <div style={{ width: "80%", marginTop: "20px" }}>
        <button style={{ marginRight: "0", marginLeft: 'auto', display: "block", marginBottom: "20px",
      backgroundColor: "orange",color: "white", border: "none", padding: "5px", borderRadius: "5px"}} onClick={editForm}>Edit Form</button>
      </div>
      <form
        onSubmit={testing}
        id="render-wrap"
        style={{ width: "50%", margin: "auto" }}
      ></form>
      <div>
        {/* <h1>Data display here</h1>
        {dataSet.map(a => (<div key={a.id}>
          {a.value}
        </div>))} */}
      </div>
    </div>
  );
}

export default FormSampleView;
