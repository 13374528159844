import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import store from 'store';
import LoadingService from "../../imports/LoadingService";

const accountSchema = Yup.object().shape({
  email: Yup.string(),
  username: Yup.string(),
  password: Yup.string().required("This is Required!").matches(
    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{8,})/,
    "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character."
  ),
  confirmPassword: Yup.string().required("This is Required!").oneOf([Yup.ref('password'), null], 'Password and Confirm Password does not match.'),
});

const EditLogin = (props) => {
	const history = useHistory();

	const [loader, showLoader, hideLoader] = LoadingService();

	const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
  const user = props.user && Object.keys(props.user).length !== 0 ? props.user : {};
  const personalInfo =
    props.personalInfo && Object.keys(props.personalInfo).length !== 0
      ? props.personalInfo
      : "";

	const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
    
	};

  const [userDetails, setUserDetails] = useState({
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  
  const [userInfo, setUserInfo] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const showModalHandler = () => {
    setShowModal(!showModal);
  };

	const submitAccount = async (values) => {
    showLoader();
		if(values.password !== values.confirmPassword){
			let error = "New Password and Confirm New Password does not match.";
			toast.error(error, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
			});
		}else{
			let params = {
				password: values.password,
				confirmPassword: values.confirmPassword,
			};
			let endpoint = `/api/startup/edit-login?_id=${userInfo._id}`;
			let api = host + endpoint;
    	axios.put(api, params, { headers: headers }).then((res) => {
        hideLoader();
				let result = res.data;
				let data = result.data;
				showModalHandler();
			},
			(error) => {
        hideLoader();
				let responseError = error.response.data;
				let errorMessage = responseError.message;
				let message = errorMessage[Object.keys(errorMessage)[0]];
				let firstError = message[0];
				toast.error(firstError, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
			});
		}
	};

	const relogin = async () => {
		showModalHandler();
    store.remove("zgETDnOXBY");
    store.remove("LjfADyiZFB");
    store.remove("OnvBIFUcVC");
    store.remove("HYLQcSsSWe");
    store.remove("oqzAsiGkQs");
    store.remove("iSWgCQnurz");
    store.remove("MaeIHzsYGC");
    history.push("/home");

    let message = "Logout Successful.";
    toast.info(message, {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    setTimeout(function(){ 
      window.location.href = window.location.href;
    }, 1000);
	};

  const getRegistrationDetails = () => {
    showLoader();
    let api = host + `/api/startup/personal-information/view?_id=${personalInfo._id}`;
    axios.get(api, {headers: headers}).then(res => {
      hideLoader();
      let data = res.data.data;
      setUserInfo(data);
      setInitialValue(data);
    });
	};

  const setInitialValue = (data) => {
    let value = {
      email: data.email,
      username: data.username,
      password: "",
      confirmPassword: "",
    };
    setUserDetails(value);
    setIsLoaded(true);
  };

	useEffect( () => {
		getRegistrationDetails();
	}, [props]);

	return (
		<React.Fragment>
      {loader}
			<Header isLoggedIn={isLoggedIn} />
      <Modal
        id="submittedModal"
        backdrop="static"
        keyboard={false}
        show={showModal}
        onHide={showModalHandler}
      >
        <Modal.Header>
          <Modal.Title>Successfully updated.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your account has been updated. Please relogin.</p>
        </Modal.Body>
        <Modal.Footer className="modal-footer text-right">
          <button type="button" className="btn btn-secondary" onClick={relogin}>
            OK
          </button>
        </Modal.Footer>
      </Modal>
			<div className="information-container text-left mt-4">
        <div className="container mt-4">
          <div className="row justify-content-md-center border bg-white">
            <div className="col-md-8 mb-5">
              <h4 className="mt-5">Edit Login Information</h4>
              <hr></hr>
              {isLoaded ? 
                <Formik
                  validationSchema={accountSchema}
                  onSubmit={submitAccount}
                  initialValues={Object.assign(userDetails)}
                >
                  {({ handleSubmit, errors, touched }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="email"><strong>Email Address</strong></label>
                        <Field type="text" name="email" autoComplete="off" className={`form-control ${touched.email && errors.email? "is-invalid": ""}`} disabled={true}/>
                        <ErrorMessage component="div" name="email" className="invalid-feedback"/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="username"><strong>Username</strong></label>
                        <Field type="text" name="username" autoComplete="off" className={`form-control ${touched.username && errors.username? "is-invalid": ""}`} disabled={true}/>
                        <ErrorMessage component="div" name="username" className="invalid-feedback"/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="password"><strong>New Password</strong></label>
                        <Field type="password" name="password" autoComplete="off" className={`form-control ${touched.password && errors.password? "is-invalid": ""}`}></Field>
                        <ErrorMessage component="div" name="password" className="invalid-feedback"/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="confirmPassword"><strong>Confirm New Password</strong></label>
                        <Field type="password" name="confirmPassword" autoComplete="off" className={`form-control ${touched.confirmPassword && errors.confirmPassword? "is-invalid": ""}`}/>
                        <ErrorMessage component="div" name="confirmPassword" className="invalid-feedback"/>
                      </div>

                      <div className="text-center mt-4 mb-5">
                        <button className="startup-btn" type="submit">
                          <b>Update</b>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              :
                null
              }
            </div>
          </div>
        </div>
      </div>
			
		</React.Fragment>
	);
};

export default EditLogin;