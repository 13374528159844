import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "../../dist/css/account.css";
import { useHistory } from "react-router-dom";
import store from "store";
import * as moment from "moment";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import { toast } from "react-toastify";
import ls from '../../config/ls';
import LoadingService from "../../imports/LoadingService";

const Account = (props) => {
	document.title = 'My Account Details';
  const history = useHistory();

	const [loader, showLoader, hideLoader] = LoadingService();

  const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
  const user = props.user !== null ? props.user : {};
  const personalInfo = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? props.personalInfo : "";

  const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
    
	};

  const [userInfo, setUserInfo] = useState("");
  const [personalInformation, setPersonalInformation] = useState("");
  const [social, setSocial] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  const getRegistrationDetails = () => {
    showLoader();
    let api = host + `/api/startup/personal-information/view?_id=${personalInfo._id}`;
    axios.get(api, {headers: headers}).then(res => {
      hideLoader();
      let data = res.data.data;
      setUserInfo(data);
      setPersonalInformation(data.personalInformation);
      setSocial(data.personalInformation.socialClassification);
      setIsLoaded(true);
    }, error => {
			let msg = "Unable to retrieve account details.";
			toast.error(msg, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
			});
      history.push('/dashboard');
    });
	};

  const redirect = () => {
    ls.set("oqzAsiGkQs", "/myaccount");
    history.push(`/user/editaccount?_id=${personalInfo._id}`);
  };

  const editLogin = () => {
    history.push(`/user/editlogin?_id=${user._id}`);
  };
  
  useEffect( () => {
		getRegistrationDetails();
  }, [props]);

  return (
    <React.Fragment>
      {loader}
      <Header isLoggedIn={isLoggedIn} />
      <div className="account-container mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12 text-left">
              <div className="col-md-12 bg-white p-3 rounded-sm">
                <div className="py-2">
                  <h4>My Account</h4>
                  <hr></hr>
                  <h6 className="mb-0">Last Name</h6>
                  <label className="mb-2">
                    {personalInformation === "" ? "N/A" : personalInformation.lastName}
                  </label>
                  <h6 className="mb-0">First Name</h6>
                  <label className="mb-2">
                    {personalInformation === "" ? "N/A" : personalInformation.firstName}
                  </label>
                  <h6 className="mb-0">Suffix</h6>
                  <label className="mb-2">
                    {personalInformation === "" ? "N/A" : (personalInformation.suffix === "" ? "N/A" : personalInformation.suffix)}
                  </label>
                  <h6 className="mb-0">Middle Name</h6>
                  <label className="mb-2">
                    {personalInformation === "" ? "N/A" : personalInformation.middleName}
                  </label>
                  <h6 className="mb-0">Date of Birth</h6>
                  <label className="mb-2">
                  {personalInformation === "" ? "N/A" : personalInformation.birthDate}
                  </label>
                  <h6 className="mb-0">Sex (Assigned at birth)</h6>
                  <label className="mb-2">
                    {personalInfo === "" ? 
                      "N/A"
                    : 
                      isLoaded ?
                        personalInformation.sex.name
                      :
                        "N/A"
                    }
                  </label>
                  <h6 className="mb-0">Place of Birth</h6>
                  <label className="mb-2">
                    {personalInformation === "" ? "N/A" : personalInformation.birthPlace}
                  </label>
                  <h6 className="mb-0">Citizenship</h6>
                  <label className="mb-2">
                    {personalInformation === "" ? "N/A" : personalInformation.citizenship}
                  </label>
                  <h6 className="mb-0">Email Address</h6>
                  <label className="mb-2">
                    {userInfo === "" ? "N/A" : userInfo.email === null || userInfo.email === "" ? "N/A" : userInfo.email}
                  </label>
                  <h6 className="mb-0">Contact No.</h6>
                  <label className="mb-2">
                    {personalInformation === "" ? "N/A" : personalInformation.contactNo === null || personalInformation.contactNo === "" ? "N/A" : personalInformation.contactNo}
                  </label>
                  <h6 className="mb-0">Social Classification</h6>
                  <div className="mb-2">
                    {isLoaded ?
                      social.length !== 0 ?
                        social.map((value,index) => {
                          return <div key={index}><label className="mb-0">{value.name}</label></div>
                        })
                      :
                        <label className="mb-0">N/A</label>
                    :
                      <label className="mb-0">N/A</label>
                    }
                  </div>
                </div>
              
                <button className="startup-btn d-inline-block" onClick={redirect}>
                  Edit Personal Information
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 text-left">
              <div className="col-md-12 bg-white p-3 rounded-sm">
                <div className="py-2">
                  <h4>Login Information</h4>
                  <hr></hr>
                  <h6 className="mb-0">Email</h6>
                  <label className="mb-2">{userInfo.email}</label>
                  <h6 className="mb-0">Username</h6>
                  <label className="mb-2">{userInfo.username}</label>
                  <h6 className="mb-0">Password</h6>
                  <label className="mb-2">*********</label>
                </div>
                <button className="startup-btn d-inline-block" onClick={editLogin}>
                  Edit Login Information
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Account;
