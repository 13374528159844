import React, { useState, useEffect } from "react";
import "../dist/css/home.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import token from "../store/token";
import { Modal } from "react-bootstrap";
import xAuthToken from "../store/xAuthToken";

const ConsentFormComponent = (props) => {
  const history = useHistory();

	const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
    
	};
  const [declareTruth, setDeclareTruth] = useState(false);

  const setDeclareTruthHandler = () => {
    setDeclareTruth(!declareTruth);
  };
  const [agreeTruth, setAgreeTruth] = useState(false);

  const setAgreeTruthHandler = () => {
    setAgreeTruth(!agreeTruth);
  };
  const [disableSubmitApplication, setDisableSubmitApplication] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (declareTruth && agreeTruth) {
      setDisableSubmitApplication(false);
    } else {
      setDisableSubmitApplication(true);
    }
  }, [declareTruth, agreeTruth]);

  const submitRegistration = () => {
    let pageModule = props.pageModule;
    if(pageModule === "startupAccount"){
      submitStartupVerification();
    }else{
      submitEnablerVerification();
    }
  };

  const submitStartupVerification = () => {
    props.showLoading();

    const oic = props.oic;
    const businessDetails = props.businessDetails;
    const businessDescription = props.businessDescription;
    const passportDatasheet = props.passportDatasheet;
    console.log(oic, businessDetails, businessDescription, passportDatasheet);
    
    let formData = new FormData();
    //oic
    formData.append('designation', oic.designation);
    formData.append('lastName', oic.lastName);
    formData.append('firstName', oic.firstName);
    formData.append('suffix', oic.suffix);
    formData.append('middleName', oic.middleName);
    formData.append('sex', oic.sex);
    formData.append('birthDate', oic.birthDate);
    formData.append('birthPlace', oic.placeOfBirth);
    formData.append('citizenship', oic.citizenship === 0 ? "Filipino" : oic.others);
    formData.append('contactNo', oic.contactno);
    formData.append('email', oic.email);
    
    if(oic.citizenship === 1){
      if(oic.oicPassportRequirementFile !== undefined){
        if(!oic.oicPassportRequirementFile.hasOwnProperty('requirement')){
          formData.append('passportDatasheet', oic.oicPassportRequirementFile[0]);
          formData.append('passportDatasheetRequirement', passportDatasheet._id);
        }else{
          formData.append('passportDatasheet', oic.oicPassportRequirementFile._id);
          formData.append('passportDatasheetRequirement', passportDatasheet._id);
        }
      }
    }
    if(!oic.oicRequirementFile.hasOwnProperty('requirement')){
      formData.append('supportDocument', oic.oicRequirementFile[0]);
      formData.append('supportDocumentRequirement', oic.oicRequirement);
    }else{
      formData.append('supportDocument', oic.oicRequirementFile._id);
      formData.append('supportDocumentRequirement', oic.oicRequirement);
    }
    //end oic

    //business
    let comma = businessDetails.lotBlockUnit !== "" || businessDetails.streetNo !== "" ? "," : "";
    let address = `${businessDetails.lotBlockUnit} ${businessDetails.streetNo}${comma} ${businessDetails.barangayName}, ${businessDetails.cityName}, ${businessDetails.provinceName}, ${businessDetails.regionName} ${businessDetails.zipCode}`
    address = address.trim();
    formData.append('startupName', businessDetails.startupName);
    formData.append('businessName', businessDetails.businessName);
    if(businessDetails.businessLogoFile !== undefined){
      formData.append('businessLogo',  businessDetails.businessLogoFile[0]);
    }
    formData.append('businessClassification', businessDetails.businessClassification);
    formData.append('registrationNumber', businessDetails.registrationNo);
    formData.append('proofOfRegistrationRequirement', businessDetails.proof);
    formData.append('proofOfRegistration', businessDetails.proofFile[0]);
    formData.append('yearFounding', businessDetails.foundingYear);
    formData.append('address', address);
    formData.append('companyNo', businessDetails.companyContactNumber);
    formData.append('companyEmail', businessDetails.companyEmail);
    formData.append('companyWebsite', businessDetails.website);
    formData.append('companyLinkedIn', businessDetails.linkedIn);
    formData.append('companyFacebook', businessDetails.facebook);
    formData.append('companyTwitter', businessDetails.twitter);
    formData.append('companyInstagram', businessDetails.instagram);
    formData.append('companyYoutube', businessDetails.youtube);
    //end business

    //description
    formData.append('businessDescription', businessDescription.description);
    formData.append('problemSolved', businessDescription.problemSolved);
    formData.append('idea', businessDescription.idea);
    formData.append('usp', businessDescription.usp);
    //end description

    let api = host + `/api/startup/psdp/verification?_id=${props._id}`;
    axios.post(api, formData, { headers: headers }).then((res) => {
      let result = res.data;
      props.hideLoading();
      props.showModalHandler();
      setShowSuccessModal(!showSuccessModal);
    },
    (error) => {
        try{
          props.hideLoading();
          const response = error.response;
          const data = response.data;
          let message = "";
          if(data.message == "Entry matches an existing record!"){
            let sysmessage = data.sysmessage.error;
            let keyValue = sysmessage.keyValue;
            let property = Object.getOwnPropertyNames(sysmessage.keyPattern);
            message = `${keyValue[property]} is already taken.`
          }else{
            message = data.message;
          }
          toast.error(message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          });
        }catch(ex){
          toast.error(ex, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          });
        }
    });
  };

  const submitEnablerVerification = () => {
    props.showLoading();
    setTimeout(function(){ 
      props.hideLoading();
      props.showModalHandler();
      setShowSuccessModal(!showSuccessModal);
    }, 2000);
  };

  const redirectDashboad = () =>{
    history.push('/dashboard');
  };

  return (
    <React.Fragment>
      <Modal
        id="consentModal"
        keyboard={false}
        show={props.showModal}
        onHide={props.showModalHandler}
        backdrop="static"
        dialogClassName="consent-modal"
      >
        <Modal.Header>
          <Modal.Title>Undertaking and Consent Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>PLEASE READ THE UNDERTAKING AND THE CONSENT CAREFULLY BEFORE CHECKING THE BOX.</p>
            <p><strong>Oath of Undertaking</strong></p>
            <p>I hereby declare that:</p>
            <p>
              1. I am an authorized representative of <b>{props.businessDetails.businessName}</b> and all
              information supplied in this application is true and correct to the best of my
              belief and knowledge;
            </p>
            <p>
              2. I undertake to keep my account active and ensure that all information are
              updated and that account inactivity for one (1) year may result in deactivation;
            </p>
            <p>
              3. Any false or misleading information supplied, or production of a false or
              misleading document to support this application shall be a ground for the
              automatic denial of this application, automatic cancellation/revocation of the
              verification, and/or filing of appropriate criminal, civil, and/or administrative
              action against me;
            </p>
            <p>
              4. I understand that a post-evaluation may be conducted after the verification
              process and understand that any negative findings may be ground for the
              cancellation of my startup’s verification upon failure to comply with the post-
              evaluation recommendations, without prejudice to the filing of criminal, civil,
              and/or administrative action, as applicable;
            </p>
            <p>
              5. I understand and undertake to comply with the provisions of Act No. 11337
              otherwise known as the Innovative Startup Law, as amended, and its
              implementing rules and regulations and other related laws and rules;
            </p>
            <p>
              6. I understand and consent to the disclosure to the public of the information
              appearing on the startup database in accordance with the procedure set forth
              under the applicable rules and regulations of the Innovative Startup Law and
              other existing rules and regulations on disclosure of information;
            </p>
            <p>
              7. I fully understand and hereby agree without any reservation that my failure to
              comply with or observe any of the foregoing undertakings or any pertinent rules
              and regulations shall be sufficient ground for the denial of my application or
              deactivation of my account.
            </p>
            <div className="clearfix">&nbsp;</div>
            <p><strong>Consent Form</strong></p>
            <p>
              By registering, I hereby agree and consent to the processing of my personal
              information for the purpose of registration. I understand that my personal information
              will be shared with other government agencies; the use of which shall be governed by
              the Data Privacy Act of 2012 and Act No. 3883. I promise to notify the DTI, DOST, or
              DICT should there be any amendment to my personal information.
            </p>
          <div className="clearfix">&nbsp;</div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck2"
              onChange={setAgreeTruthHandler}
              checked={agreeTruth}
            />
            <label className="form-check-label" htmlFor="defaultCheck2">
              I have read and agree to this consent form.
            </label>
            
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck3"
              onChange={setDeclareTruthHandler}
              checked={declareTruth}
            />
            <label className="form-check-label" htmlFor="defaultCheck3">
              I declare the truthfulness of the information I have provided
              for this application.
            </label>
          </div>
        </Modal.Body>
        <div className="modal-footer text-right">
          <button type="button" className="startup-btn" onClick={props.showModalHandler}>Close</button>
          <button
            type="button"
            className="startup-btn mx-2"
            disabled={disableSubmitApplication}
            onClick={submitRegistration}
            htmlFor="submittedModal"
          >
            Submit Application
          </button>
        </div>
      </Modal>
      <Modal
        id="successModal"
        keyboard={false}
        show={showSuccessModal}
        backdrop="static"
      >
        <Modal.Header>
        <Modal.Title>Business Information Submitted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-left">
            <p>
              Thank you for verifying your account to the PSDP. The DTI will assess your application.
            </p>
            <p>
              You may see the status of your registration by going to your Dashboard &gt; My Company.
            </p>
          </div>
          <div className="py-4 text-center mt-3">
            <button className="startup-btn mx-2" onClick={redirectDashboad}>Okay</button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default ConsentFormComponent;