import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import Header from "../../../components/Header";
import axios from "axios";
import url from "../../../environment";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import LoadingService from "../../../imports/LoadingService";
import { useHistory } from "react-router-dom";
import Sidebar from "../../../components/Sidebar"
// import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import "../../../dist/scss/custom.scss";
import token from "../../../store/token";
import xAuthToken from "../../../store/xAuthToken";

const roleCreateSchema = Yup.object().shape({
    name: Yup.string().required("This is Required!"),
    description: Yup.string().required("This is Required!"),
    // actions: Yup.string().required("This is Required!"),
    actions: Yup.array()
    .min(1, 'This is Required!')
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
});

const RolesCreate = (props) => {

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const host = url.hostPort();
    const headers = {
        'Authorization': `Bearer ${token()}`,
        
    };

    document.title = 'Role Create';
    const [loader, showLoader, hideLoader] = LoadingService();
    const [roleName, setRoleName] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const formikRef = useRef();
    const [permissionList, setPermissionList] = useState([]);
    const [permissionIdList, setPermissionIdList] = useState([]);
    const [actionNewObj, setActionNewObj] = useState({
        actions: []
    })
    // const [selectedPermission, setSelectedPermission] = useState([]);
    // const [input, setInput] = useState('')
    const [formValue, setFormValue] = useState({
        name: '',
        description: '',
        actions: []
    });
    const history = useHistory();

    const cancelBack = () => {
        history.push('/user-management/roles/index');
    };

    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const getPermission = () => {

        let params = {
            isActive: true
        };

        const endpoint = `/api/action/search`;
        const api = url.hostPort() + endpoint;
        const headers = {
            'Authorization': `Bearer ${token()}`,
            
        };
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            let options = data.map(function (permission) {
                return { value: permission._id, label: permission.name };
            })
            setPermissionList(options);
        }, error => {
        });

    };

    const createRole = async (values) => {
        const actionObj = values.actions;
        let newActionArr = [];
        Object.keys(actionObj).map(function(key, index) {
            newActionArr.push(actionObj[key].value)
        });
        
        setShowConfirmationModal(true);
        setFormValue({
            name: values.name,
            description: values.description,
            actions: newActionArr,
        })
    };

    const confirmSubmit = async () => {
        let api = url.hostPort() + '/api/role/create';
        const headers = {
            'Authorization': `Bearer ${token()}`,
            
        };
        axios.post(api, formValue, { headers: headers }).then(res => {
            let result = res.data;
            toast.info(result.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            setShowConfirmationModal(false);
            history.push('/user-management/roles/index');
        }, error => {
            let responseError = error.response.data;
            let errorMessage = responseError.message;
            toast.error(errorMessage, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        });
    }

    // const onSelect = (selectedList, selectedItem) => {
    //     permissionIdList.push(selectedItem._id)
    //     console.log(permissionIdList)
    // }

    // const onRemove = (selectedList, removedItem) => {
    //     permissionIdList.splice(removedItem._id)
    //     console.log(permissionIdList)
    // }

    const checkKeyDown = (e) => {
        if (e.code === 'Enter') e.preventDefault();
    };
    
    const handleChange = value => {
        console.log(value, "value")
    };
    
    useEffect(() => {
        getPermission();
    }, []);

    useEffect(() => {
        props.PageHandler('role');
    }, []);

    return (
        <React.Fragment>
            {loader}
            <Modal
                backdrop="static"
                keyboard="false"
                show={showConfirmationModal}
                onHide={closeConfirmationModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Confirm Role {formValue.name}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Create {formValue.name}?</p>
                    <div className="btn-div d-flex justify-content-end">
                        <div>
                            <button
                                className="startup-btn cancel mr-2"
                                onClick={closeConfirmationModal}
                            >
                                <b>Cancel</b>
                            </button>
                        </div>
                        <div>
                            <button
                                className="startup-btn confirm"
                                type="button"
                                onClick={confirmSubmit}
                            >
                                <b>Confirm</b>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Header isLoggedIn={isLoggedIn} isAdmin={true}/>
            <div className="container py-4">
                <div className="row">
                    <div className="col-3">
                        <Sidebar activePage={props.activePage} />
                    </div>
                    <div className="col-9">
                        <div className="container">
                            <div className="row justify-content-md-center">
                                <div className="col-lg-8">
                                    <div className="title-div">
                                        <h4>Create Role</h4>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <Formik
                                                validationSchema={roleCreateSchema}
                                                onSubmit={createRole}
                                                innerRef={formikRef}
                                                initialValues={Object.assign(formValue)}
                                            >
                                                {({ handleSubmit, setFieldValue, errors, touched, values }) => (
                                                    <Form noValidate onSubmit={handleSubmit} onKeyDown={checkKeyDown}>
                                                        <div className="form-group">
                                                            <label htmlFor="rolename">Role Name*</label>
                                                            <Field
                                                                type="text"
                                                                name="name"
                                                                autoComplete="off"
                                                                className={`form-control ${touched.name && errors.name
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="name"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="description">Description*</label>
                                                            <Field
                                                                type="text"
                                                                as="textarea"
                                                                rows="5"
                                                                name="description"
                                                                autoComplete="off"
                                                                className={`form-control ${touched.description && errors.description
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="description"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <small>Try to include the permissions you are going to give this role in the description for better documentation.</small>

                                                        <div className="p-t-25"></div>
                                                        <div className="form-group">
                                                            <label htmlFor="action">Permissions*</label>
                                                            {/* <select
                                                                name="actions"
                                                                className={`form-control ${touched.actions && errors.actions
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                                value={values.actions || ''}
                                                                onChange={val => {
                                                                    const selectedIndex = val.target.options.selectedIndex;
                                                                    let code = val.target.options[selectedIndex].getAttribute('value');
                                                                    let actions = val.target.options[selectedIndex].getAttribute('label');
                                                                    setFieldValue('actions', code);
                                                                    setSelectedPermission(actions);
                                                                }}
                                                            // onChange={changeHandler}
                                                            >
                                                                <option name="permission_" key="0" value="" label="" />
                                                                {permissionList.map((value, index) => {
                                                                    return <option value={value._id} name={'permission_' + value.name} key={'permission_' + index} label={value.name} />
                                                                })}
                                                            </select> */}
                                                            <Select
                                                                isMulti
                                                                name="actions"
                                                                options={permissionList}
                                                                className={`form-control custom-form ${touched.actions && errors.actions
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                                error={errors.actions}
                                                                touched={touched.actions}
                                                                    value={values.actions}
                                                                    onChange={val => {
                                                                        // let code = val.target.options[selectedIndex].getAttribute('value');
                                                                        // let actions = val.target.options[selectedIndex].getAttribute('label');
                                                                        // console.log(actions, "actions", code, "code")
                                                                        setFieldValue('actions', val);
                                                                        // setSelectedPermission(actions);
                                                                    }}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="actions"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>

                                                        <div className="btn-div d-flex justify-content-end">
                                                            <div className="btn-cancel pr-3">
                                                                <button className="startup-btn" onClick={cancelBack}>
                                                                    <b>Cancel</b>
                                                                </button>
                                                            </div>
                                                            <div className="btn-submit">
                                                                <button className="startup-btn" type="submit" >
                                                                    <b>Submit</b>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default RolesCreate;