import React from "react";
import { useField, useFormikContext, Field } from "formik";

export const ImageUploaderField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext()
    const [field] = useField(props)
    return (
        <Field
            {...field}
            {...props}
            onChange={(event) => {
                if(event.currentTarget.files.length === 0){
                    setFieldValue(field.name, '');
                }else{
                    setFieldValue(field.name, event.currentTarget.files);
                }
            }}
        />
    )
}

export default ImageUploaderField;