import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import IdleTimerContainer from "../components/IdleTimerContainer";
import "../dist/css/dashboard.css";
import axios from "axios";
import url from "../environment";
import { useHistory } from "react-router-dom";
import token from "../store/token";
import xAuthToken from "../store/xAuthToken";
import { Modal } from "react-bootstrap";
import '../dist/scss/custom.scss';
import LoadingService from "../imports/LoadingService";
import Rectify from "../pages/registration/Rectify";

const Dashboard = (props) => {
  document.title = 'Dashboard';

  const history = useHistory();

  const [loader, showLoader, hideLoader] = LoadingService();

  const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
  const user = props.user !== null ? props.user : {};
  const personalInfo = props.personalInfo !== null ? props.personalInfo : {};

  const [registrationId, setRegistrationId] = useState('');

  const [hasFlagged, setHasFlagged] = useState([]);
  const [isFlagged, setIsFlagged] = useState(false);

  const [showFlaggedModal, setShowFlaggedModal] = useState(false);
  const [rectifyMessage, setRectifyMessage] = useState(false);
  const [flaggedStatus, setFlaggedStatus] = useState(0);
  const [hideAspiringIndividual, sethideAspiringIndividual] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const [showDashboardLabel, setShowDashboardLabel] = useState(false);


  const [exist, setExist] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [startupType, setStartupType] = useState(null);
  const [applicantType, setApplicantType] = useState(null);
  const [registrationStatus, setRegistrationStatus] = useState();
  const host = url.hostPort();
  const headers = {
    Authorization: `Bearer ${token()}`,
    
  };

  const myCompanyClick = () => {
    history.push(`/mycompany?_id=${user._id}`);
  };

  const myAccountClick = () => {
    history.push(`/myaccount?_id=${personalInfo._id}`);
  }

  const aspiringIndividual = () => {
    history.push('/v2/registration/aspiring-individual');
  }

  const startupAccountRegistration = () => {
    history.push('/v2/registration/startup');
  };

  const startUpEnabler = () => {
    history.push(`/v2/registration/enabler`);
  };

  const closeFlaggedModal = () => {
    setShowFlaggedModal(!showFlaggedModal);
  };

  const rectifyLater = () => {
    setShowFlaggedModal(!showFlaggedModal);
  };

  const rectify = () => {
    history.push(`/register/rectify?_id=${registrationId}`);
  };

  const transformFieldName = (field) => {
    if (field === "registrationNumber") {
      return "Registration/Certificate/Permit No.";
    } else if (field === "businessName") {
      return "Registered Business / SEC / CDA Name";
    } else if (field === "proofOfRegistration") {
      return "Proof of registration or government accreditation";
    }
  }

  const getFlaggedItems = () => {
    const api = host + `/api/startup/registration/flagged-items?_id=${user._id}`;
    axios.get(api, { headers: headers }).then((res) => {
      const result = res.data;
      const items = result.flaggedItems;
      const businessNameIndex = items.findIndex(item => { return item.fieldName === "businessName" });
      const index = items.findIndex(item => { return item.fieldName === "proofOfRegistration" });
      items.push(items.splice(index, 1)[0]);
      items.sort((x, y) => { return x.fieldName === "businessName" ? -1 : y.fieldName === "businessName" ? 1 : 0; });
      setHasFlagged(items);
      setIsFlagged(result.hasFlagged);
      setRegistrationId(result.registrationId);
      closeFlaggedModal();
    }, error => {

    });
  };

  const checkRegistration = () => {
    showLoader();
    const api = host + `/api/startup/registration/check?_id=${user._id}`;
    axios.get(api, { headers: headers }).then((res) => {
      hideLoader();
      let result = res.data;
      
      setExist(result.exist);
      console.log(result, "hotdog")
      setIsVerified(result.isVerified);
      setStartupType(result.startupType);
      setApplicantType(result.applicantType);
      setRegistrationStatus(result.registrationStatus);
      if (result.isVerified && result.registrationStatus === "For Updating") {
        getFlaggedItems();
      }
    }, error => {
      hideLoader();
    });
  };

  useEffect(() => {
    checkRegistration();
  }, []);

  return (
    <React.Fragment>
      {loader}
      <Header isLoggedIn={isLoggedIn} />
      <IdleTimerContainer isLoggedIn={isLoggedIn} />
      <div className="dashboard-container">
        <div className="container mb-4">
          <div className="dashboard-summary">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h6 className="text-center" hidden={exist === true}>
                <strong>Thank you for creating your personal account.</strong>
                <br />
                You may view and edit your information in the <button className="startup-btn-flat" onClick={myAccountClick}>My Account</button>page.
              </h6>
             
              <h6 className="text-center" hidden={isVerified || startupType === 1 || startupType === null}>
                <strong>Thank you for creating your Startup Company Account.</strong>
                <p>Most of the Programs and Services for Startup Companies are for duly registered entities.</p>
                <p>Please consider having your company verified by the DTI.</p>
              </h6>

              <h6 className="text-center" hidden={exist === true && isVerified === false || isFlagged || registrationStatus == "Updated" ||  registrationStatus == "Verified" || registrationStatus == ""}>
                <strong>Thank you for submitting your Startup Company for verification. The DTI will assess your application</strong>
                <br />
                You may see the status of your registration by going to your Dashboard &gt; <button className="startup-btn-flat" onClick={myCompanyClick}>My Company</button>.
              </h6>

              <h6 className="text-center" hidden={!isFlagged}>
                <p>There are flagged items in your registration.</p>
                {hasFlagged.filter(item => item.status === "Flagged").map((flaggedItems, index) => (
                  <div key={`flagged_modal_${index}`}>
                    <p className="transformText"> <strong>-  {transformFieldName(flaggedItems.fieldName)}</strong></p>
                    <div className="clearfix"></div>
                    <p>Reason: <label className="transformText">{flaggedItems.remarks}</label></p>
                  </div>
                ))}
                <br/>
                <p>You may rectify your registration by going to your Startup Company profile in the My Company page.</p>
              </h6>
              <div className="text-center" hidden={registrationStatus != "Updated"}> 
                <h4>Thank you for rectifying your account.</h4>
                <div className="clearfix">&nbsp;</div>
                <p>The DTI shall assess your application.</p>
                <p>Thank you.</p>

                <div className="clearfix">&nbsp;</div>
                <p>You may see the status of your registration by going to your Dashboard &gt;<button type="button" onClick={myCompanyClick} className="btn btn-link link-my-company">My Company.</button></p>
              </div>

              <div className="text-center" hidden={registrationStatus != "Verified"}>
                <strong>Congratulations!</strong>
                <br />
                <p>Your Startup Company has been verified by the DTI.</p>
              </div>

              <div className="text-center" hidden={startupType != 1}> 
                <h4>Thank you for creating your portal company account.</h4>
                <div className="clearfix">&nbsp;</div>
                <p>Most of the Programs and Services in the portal are for duly registered entities.</p>

                <div className="clearfix">&nbsp;</div>
                <p>However, please feel free to look for programs designed for students or aspiring startups and startup enablers. </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row form-align">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 text-center">
              <button type="button" className="startup-btn-main btn-block" onClick={myAccountClick}>
                My Account
              </button>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 text-center">
              <div className="text-left">
                <button type="button" disabled={!exist} onClick={myCompanyClick} className="startup-btn-main btn-company btn-block">
                  My Company
                </button>
                <small className="f-s" hidden={exist}>
                  You don’t have a Startup or an Enabler Company Account yet.
                </small>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 text-center" hidden={flaggedStatus === 4 ? false : true}>
              <div className="text-left">
                <label>
                  There are flagged items in your registration
                  <br />
                  <a href={`/register/rectify?reg_id=${registrationId}`}><strong>Rectify flagged items</strong></a>
                </label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 text-center">
              <div className="text-left">
                <label hidden={isVerified || startupType === 1 || startupType === null}>
                  Your business is not yet verified.
                  <br />
                  <a href={`/v2/registration/psdpverification?_id=${user._id}`}><strong>Continue to PSDP verification</strong></a>
                </label>
                <button hidden={startupType === 2 || startupType === 3} type="button" className="startup-btn-main btn-block" onClick={startupAccountRegistration}>
                  Create Startup Company Account
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 text-center" hidden={exist}>
              <div className="text-left">
                <button type="button" className="startup-btn-main btn-block" onClick={aspiringIndividual}>
                  Aspiring Individual
                </button>
                <small className="f-s"> This option is open to students, aspiring Startups, or aspiring Startup Enablers.</small>
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-6 col-sm-12 mb-3 text-center" hidden={exist && startupType == 2}> */}
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 text-center" hidden={true}>
              <button className="startup-btn-main btn-block" onClick={startUpEnabler}>
                Create Startup Enabler Company Account
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        backdrop="static"
        keyboard={false}
        show={showFlaggedModal}
        onHide={closeFlaggedModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>There are flagged items in your registration.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {hasFlagged.filter(item => item.status === "Flagged").map((flaggedItems, index) => (
            <div key={`flagged_modal_${index}`}>
              <p className="transformText"> <strong>-  {transformFieldName(flaggedItems.fieldName)}</strong></p>
              <div className="clearfix"></div>
              <p>Reason: <label className="transformText">{flaggedItems.remarks}</label></p>
            </div>
          ))}
        </Modal.Body>
        <div className="modal-footer text-center">
          <button type="button" className="startup-btn btn-block" onClick={() => rectify()}>Rectify</button>
          <button type="button" className="startup-btn btn-block" onClick={rectifyLater} >Rectify Later</button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Dashboard;
