import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import LoadingService from "../../imports/LoadingService";
import * as Yup from "yup";
import "../../dist/scss/custom.scss"; 
import { Modal } from "react-bootstrap";

const registrationSchema = Yup.object().shape({
	startupStage: Yup.string().required('This is Required!'),
	classification: Yup.array().min(1, 'This is Required!'),
});

const StartupEnablerRegistration = (props) => {
	document.title = 'Create Enabler';
	const history = useHistory();
    const [loader, showLoader, hideLoader] = LoadingService();
    const formikRef = useRef();

	const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const user = props.user && Object.keys(props.user).length !== 0 ? props.user : '';

    const [registrationId, setRegistrationId] = useState('');
    const [selectAll, setSelectAll] = useState(false);

	const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
        
	};

    const [classificationList, setClassificationList] = useState([]);

    const startupDevelopmentOptions = [
		{
			"name": "Formation",
			"id": 1
		},
		{
			"name": "Validation",
			"id": 2
		},
		{
			"name": "Growth",
			"id": 3
		},
	];

    const startupTypeOfEnablerOptions = [
		{
			"name": "Academe",
			"id": 1
		},
		{
			"name": "Accelerator",
			"id": 2
		},
		{
			"name": "Angel Investor",
			"id": 3
		},
        {
			"name": "Co-working Space",
			"id": 4
		},
        {
			"name": "Corporate",
			"id": 5
		},
        {
			"name": "Event Organizer",
			"id": 6
		},
        {
			"name": "Mentor",
			"id": 7
		},
        {
			"name": "Venture Capitalist",
			"id": 7
		},
        {
			"name": "Incubator",
			"id": 8
		},
	];

    const [formValue, setFormValue] = useState({
		'startupStage': 1,
		'classification': '',
	});

    const getClassification = () => {
        showLoader();
		const endpoint = `/api/v1/industry-classifications?view=all&sort=name`;
		const api = host + endpoint;
        axios.get(api, {headers: headers}).then(res => {
            hideLoader();
            const result = res.data;
            const items = result.items; 
            let transform = items.map(value => {
            	value.checked = false;
            	return value;
            });
            setClassificationList(transform);
        }, error =>{
            hideLoader();
        });
	};

    const [showCreatedModal, setShowCreatedModal] = useState(false);
    const [reg_uuid, setRegUUID] = useState('');
    
    const redirectBusinessRegistration = () => {
        history.push(`/v2/registration/enablerverification?reg_uuid=${reg_uuid}`);
    }

    const redirectDashboard = () => {
        history.push('/dashboard');
    }

    const submitRegistration = (values) => {
       setShowCreatedModal(true);
    };

    const selectAllHandler = () => {
        setSelectAll(!selectAll);
    }

    useEffect(() => {
        getClassification();
	}, [props]);

	return (
		<React.Fragment>
			<Header isLoggedIn={isLoggedIn} />
			<div className="mainRegistration-container mt-4">
				<div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 form-align">
                        <div className="col-md-12 bg-white p-3 border">
                            <div className="row bg-white">
                                <div className="col-md-8 col-sm-12 mx-auto">
                                    <h4 className="p-t-25">Startup Enabler</h4>
                                    <hr></hr>
                                    <p>A<strong> person </strong> or a<strong> registered entity</strong> in the Philippines providing goods, services, or capital identified 
                                    to be <strong>critical in supporting the operation and growth of startups</strong> by the DTI in consultation with DOST, DICT and pertinent government and non-government organizations (NGOs).</p>
                                    <div className="clearfix">&nbsp;</div> 

                                    <Formik
                                        validationSchema={registrationSchema}
                                        innerRef={formikRef}
                                        initialValues={Object.assign(formValue)}
                                        onSubmit={submitRegistration}
                                    >
                                        {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values, field }) => (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <label><strong>What type of Enabler are you?</strong></label>
                                                <div className="mb-negative"></div>
                                                <small><i>(Select all that applies)</i></small>
                                                {startupTypeOfEnablerOptions.filter((v, i) => i < 17).map((value, index) => {
                                                    return (
                                                        <div key={`enabler_${value.id}_${index}`} className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`enabler_${index}`}
                                                            value={value.id}
                                                            checked={value.checked}
                                                            onChange={event => {
                                                                
                                                            }}
                                                            onClick={event => {
                                                                let checked = event.target.checked;
                                                                let find = values.classification.indexOf(value.id);
                                                                value.checked = checked;
                                                                // if(checked){
                                                                //     arrayHelpers.insert('', value.id);
                                                                // }else{
                                                                //     arrayHelpers.remove(find);
                                                                // }
                                                            }}
                                                            />
                                                            <label className="form-check-label" htmlFor={`enabler_${index}`}>{value.name}</label>
                                                           
                                                    </div> 
                                                    )
                                                })}
                                                 <label>(Note: Technology Business Incubators (TBIs) will be asked to provide the Certificate of Registration of the University and 
                                                        an endorsement letter from DOST for student founders for PSDP verification)
                                                </label>
                                                <div className="clearfix">&nbsp;</div>
                                                <div className="form-group">
                                                    <label><strong>Target Startup Development Phase/s</strong></label>
                                                    {startupDevelopmentOptions.map((value, index) => {
                                                        return <div key={`stage_${value.id}_${index}`} className="form-check">
                                                            <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`stage_${index}`}
                                                            value={value.id}
                                                            checked={value.checked}
                                                            onChange={event => {
                                                                
                                                            }}
                                                            onClick={event => {
                                                                // if(checked){
                                                                //     arrayHelpers.insert('', value.id);
                                                                // }else{
                                                                //     arrayHelpers.remove(find);
                                                                // }
                                                            }}
                                                            />
                                                            <label className="form-check-label" htmlFor={`stage_${index}`}>{value.name}</label>
                                                        </div> 
                                                    })}
                                                </div>
                                                <div className="d-flex">
                                                    <label><strong>Target Sector/s</strong></label>
                                                    <div className="form-check ml-auto">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" onChange={selectAllHandler}/>
                                                        <label className="form-check-label" for="exampleCheck1">Select All</label>
                                                    </div>
                                                </div>
                                                <FieldArray
                                                    name="classification"
                                                    className={`form-control ${touched.classification && errors.classification ? "is-invalid" : ""}`}
                                                    render={arrayHelpers => (
                                                        <div className="row classrow border py-2">
                                                            <div className="col-md-4 pr-0">
                                                                {classificationList.filter((v, i) => i < 17).map((value, index) => {
                                                                    return (
                                                                        <div key={`classification_${value.id}`} className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id={`classification_${index}`}
                                                                            value={value.id}
                                                                            checked={ selectAll ? true : value.checked}
                                                                            onChange={event => {
                                                                                
                                                                            }}
                                                                            onClick={event => {
                                                                                let checked = event.target.checked;
                                                                                let find = values.classification.indexOf(value.id);
                                                                                value.checked = checked;
                                                                                if(checked){
                                                                                    arrayHelpers.insert('', value.id);
                                                                                }else{
                                                                                    arrayHelpers.remove(find);
                                                                                }
                                                                            }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`classification_${index}`}>{value.name}</label>
                                                                    </div> 
                                                                    )
                                                                })}
                                                            </div>
                                                            <div className="col-md-4 pr-0">
                                                                {classificationList.filter((v, i) => i >= 17 && i < 34 ).map((value, index) => {
                                                                    return (
                                                                        <div key={`classification_${value.id}`} className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id={`classification_${index+17}`}
                                                                            value={value.id}
                                                                            checked={selectAll ? true :value.checked}
                                                                            onChange={event => {
                                                                                
                                                                            }}
                                                                            onClick={event => {
                                                                                let checked = event.target.checked;
                                                                                let find = values.classification.indexOf(value.id);
                                                                                value.checked = checked;
                                                                                if(checked){
                                                                                    arrayHelpers.insert('', value.id);
                                                                                }else{
                                                                                    arrayHelpers.remove(find);
                                                                                }
                                                                            }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`classification_${index+17}`}>{value.name}</label>
                                                                    </div> 
                                                                    )
                                                                })}
                                                            </div>  
                                                            <div className="col-md-4 pr-0">
                                                                {classificationList.filter((v, i) => i >= 34).map((value, index) => {
                                                                    return (
                                                                        <div key={`classification_${value.id}`} className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id={`classification_${index+34}`}
                                                                            value={value.id}
                                                                            checked={selectAll ? true :value.checked}
                                                                            onChange={event => {
                                                                                
                                                                            }}
                                                                            onClick={event => {
                                                                                let checked = event.target.checked;
                                                                                let find = values.classification.indexOf(value.id);
                                                                                value.checked = checked;
                                                                                if(checked){
                                                                                    arrayHelpers.insert('', value.id);
                                                                                }else{
                                                                                    arrayHelpers.remove(find);
                                                                                }
                                                                            }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`classification_${index+34}`}>{value.name}</label>
                                                                    </div> 
                                                                    )
                                                                })}
                                                            </div> 
                                                        </div>   
                                                    )}
                                                />
                                                <div className="py-4 text-center mt-3">
                                                    <button type="submit" className="startup-btn-main">Save</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                
                </div>
				</div>
			</div>
            <Modal backdrop="static" show={showCreatedModal} keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Congratulations!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You now have a Startup Enabler Company Account.</p>
                    <p> Your Startup ID is:</p>
                    <div className="text-center">
                        <h4><strong>21-SE000001-0001</strong> </h4>
                    </div>
                    <div className="clearfix">&nbsp;</div>
                    <p>You may choose to continue by having your business verified by the PSDP, or you may do this at a later date by going
                       to your Dashboard &gt; My Company
                    </p>
                   
                    <div className="py-4 text-center mt-3">
                        <button className="startup-btn mx-2" onClick={redirectBusinessRegistration}>Continue to PSDP Verification</button>
                        <div className="text-center mt-4">
                            <a className="pointer" onClick={redirectDashboard}>Continue Later</a>
                        </div>
                    </div>         
                </Modal.Body>
               
            </Modal>
		</React.Fragment>
	);
};
export default StartupEnablerRegistration;
