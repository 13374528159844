import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import store from "store";
import * as moment from "moment";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import { toast } from 'react-toastify';
import LoadingService from '../../imports/LoadingService';
import "../../dist/css/company.css";
import { Modal } from "react-bootstrap";
import FlaggedItemsComponent from "../../components/FlaggedItemsComponent";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';

const requiredLabel = 'This is Required!';

const evaluationSchema = Yup.object().shape({
    businessName: Yup.string().required(requiredLabel),
    registrationNo: Yup.string().required(requiredLabel),
    proof: Yup.string().required(requiredLabel),
    businessNameComment: Yup.string().when('businessName',
        (businessName, schema) => {
            if (businessName == "Passed") {
                return schema;
            } else if (businessName == "Flagged") {
                return schema.required(requiredLabel);
            }
        }),
    registrationNoComment: Yup.string().when('registrationNo',
        (registrationNo, schema) => {
            if (registrationNo == "Passed") {
                return schema;
            } else if (registrationNo == "Flagged") {
                return schema.required(requiredLabel);
            }
        }),
    proofComment: Yup.string().when('proof',
        (proof, schema) => {
            if (proof == "Passed") {
                return schema;
            } else if (proof == "Flagged") {
                return schema.required(requiredLabel);
            }
        }),
    businessNameFieldId: Yup.string(),
    registrationNoFieldId: Yup.string(),
    proofFieldId: Yup.string(),
});

//Company Update
const ApplicantStartup = (props) => {
    document.title = 'My Company Details';
    const history = useHistory();

    const formikRef = useRef();

    const [loader, showLoading, hideLoading] = LoadingService();

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const user = props.user !== null ? props.user : {};
    const personalInfo = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? props.personalInfo : "";
    const host = url.hostPort();
    const headers = {
        'Authorization': `Bearer ${token()}`,
        
    };

    const [registrationDetails, setRegistrationDetails] = useState({});
    const [startupDetails, setStartupDetails] = useState({});
    const [companyName, setCompanyName] = useState("");
    const [startupName, setStartupName] = useState("");
    const [registrationProgress, setRegistrationProgress] = useState("");
    const [accreditationType, setAccreditationType] = useState("");

    const [myCompanyDetails, setMyCompanyDetails] = useState("");
    const [officerInCharge, setOfficerInCharge] = useState("");
    const [officeAddress, setOfficeAddress] = useState("");
    const [accreditation, setAccreditation] = useState([]);
    const [officerRequirement, setOfficerRequirement] = useState([]);
    const [hasBusinessLogo, setHasBusinessLogo] = useState(false);
    const [businessClass, setBusinessClass] = useState({ name: '' });
    const [businessAddress, setBusinessAddress] = useState({});
    const [hasProof, setHasProof] = useState(false);
    const [proof, setProof] = useState([]);

    const [classificationList, setClassificationList] = useState([]);

    const [personalInfoAddress, setPersonalInfoAddress] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [individualRequirement, setIndividualRequirement] = useState("");

    const [hasApproval, setHasApproval] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isVerified, setIsVerified] = useState(true);

    const [businessClassificationList, setBusinessClassificationList] = useState([]);

    const [itemUserId, setItemUserId] = useState('');
    const [itemUserUUID, setItemUserUUID] = useState('');
    const [registrationUUID, setRegistrationUUID] = useState('');
    const [propsArrayParams, setArrayParams] = useState([]);

    const [showApprovedModal, setShowApprovedModal] = useState(false);

    const [approvals, setApprovals] = useState([]);

    const [evaluationValue, setEvaluationValue] = useState({
        'businessName': '',
        'registrationNo': '',
        'proof': '',
        'businessNameComment': '',
        'registrationNoComment': '',
        'proofComment': '',
        'businessNameFieldId': '',
        'registrationNoFieldId': '',
        'proofFieldId': ''
    });

    const mapApproval = (list) => {
        for (let x = 0; x < list.length; x++) {
            if (list[x].fieldName === "businessName") {
                formikRef.current.setFieldValue('businessNameFieldId', list[x]._id);
                formikRef.current.setFieldValue('businessName', list[x].status);
                formikRef.current.setFieldValue('businessNameComment', list[x].remarks === null ? "" : list[x].remarks);
                setBusinessNameEval(list[x].status);
            } else if (list[x].fieldName === "registrationNumber") {
                formikRef.current.setFieldValue('registrationNoFieldId', list[x]._id);
                formikRef.current.setFieldValue('registrationNo', list[x].status);
                formikRef.current.setFieldValue('registrationNoComment', list[x].remarks === null ? "" : list[x].remarks);
                setRegistrationNoEval(list[x].status);
            } else if (list[x].fieldName === "proofOfRegistration") {
                formikRef.current.setFieldValue('proofFieldId', list[x]._id);
                formikRef.current.setFieldValue('proof', list[x].status);
                formikRef.current.setFieldValue('proofComment', list[x].remarks === null ? "" : list[x].remarks);
                setProofEval(list[x].status);
            }
        };
    };

    const getBusinessLogo = (_id, name) => {
        let endpoint = `/api/file/download?_id=${_id}`;
        let api = host + endpoint;
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = name;
        axios.get(api, { responseType: "blob", headers: headers }).then((res) => {
            hideLoading();
            let fileURL = window.URL.createObjectURL(res.data);
            setBusinessLogoImg(fileURL);
        }, error => {
            hideLoading();
            console.log("error", error);
        });
    };

    const viewFile = (_id, name) => {
        showLoading();
        let endpoint = `/api/file/download?_id=${_id}`;
        let api = host + endpoint;
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = name;
        axios.get(api, { responseType: "blob", headers: headers }).then((res) => {
            hideLoading();
            let responseHeaders = res.headers;
            let content = Object.values(responseHeaders);
            content = content[0];

            //new tab
            try {
                let fileURL = window.URL.createObjectURL(res.data);
                let tab = window.open();
                tab.location.href = fileURL;
            } catch (ex) {
                let message = "Please allow pop-up window to view file.";
                toast.error(message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }
        }, error => {
            hideLoading();
            console.log("error", error);
        });
    };

    const Redirect = (value) => {
        if (value === "" || value === null) {
            return <label className="mb-0">N/A</label>
        } else {
            return <a href={`//${value}`} target="_blank" rel="noopener noreferrer">{value}</a>;
        }
    };

    const submitApplicantStartup = () => {
        setShowModal(!showModal);
    }

    const [showModal, setShowModal] = useState(false);
    const showModalHandler = () => {
        setShowModal(!showModal);
    };

    const [textAreaCount, ChangeTextAreaCount] = React.useState(0);
    const [textAreaCountRegistration, ChangeTextAreaCountRegistration] = React.useState(0);
    const [textAreaValue, setTextAreaValue] = useState('');
    const [textRegistrationAreaValue, setRegistrationTextAreaValue] = useState('');
    const [hasTextAreaValue, setHasTextAreaValue] = useState(false);
    const [hasRegistrationTextAreaValue, setHasRegistrationTextAreaValue] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [showCompanyContractShowMore, setCompanyContractShowMore] = useState(false);

    const recalculate = (e) => {
        setTextAreaValue(e.target.value);
        ChangeTextAreaCount(e.target.value.length);
    };

    const enterTextArea = () => {
        setHasTextAreaValue(true);
    }

    const enterRegistrationTextArea = () => {
        setHasRegistrationTextAreaValue(true);
    }

    const editTextArea = () => {
        setHasTextAreaValue(false);
    }

    const editRegistrationTextArea = () => {
        setHasRegistrationTextAreaValue(false);
    }

    const recalculateRegistration = (e) => {
        setRegistrationTextAreaValue(e.target.value)
        ChangeTextAreaCountRegistration(e.target.value.length);
    };

    const back = () => {
        history.push('/startup/evaluation/index');
    };

    const approve = () => {
        showLoading();
        let paramArray = [];
        for (let x = 0; x < propsArrayParams.length; x++) {
            const status = propsArrayParams[x].status;
            const params = {
                "status": status,
                "_id": propsArrayParams[x]._id,
                "remarks": status === "Passed" ? '' : propsArrayParams[x].remarks
            };
            paramArray.push(params);
        }
        const params = {
            evaluation: paramArray
        };
        const api = host + `/api/approval/startup/evaluate?_id=${registrationId}`;
        axios.post(api, params, { headers: headers }).then(res => {
            hideLoading();
            showModalHandler();
            let message = 'Success! You have approved this registration.';
            toast.info(message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            history.push('/startup/evaluation/index');
        }, error => {
            hideLoading();
            const err = error.response;
            const data = err.data;
            const message = data.message;
            toast.error(message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        });
    };

    const submitEvaluation = (values) => {
        if (checkAllApproval(values)) {
            let arrayParams = transformParams(values);
            setArrayParams(arrayParams);
            if (checkAllApproved(values)) {
                setShowApprovedModal(!showApprovedModal);
            } else {
                setShowModal(!showModal);
            }
        } else {
            let error = "Please evaluate all fields.";
            toast.error(error, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        }
    };


    const transformParams = (values) => {
        let arrayParams = [];
        let assessed = user.user_id;
        const businessNameParams = {
            "_id": values.businessNameFieldId,
            "status": values.businessName,
            "field": "Registered Business / SEC / CDA Name",
            "remarks": values.businessName.toLowerCase() === "passed" ? '' : values.businessNameComment
        };
        const registrationNoParams = {
            "_id": values.registrationNoFieldId,
            "status": values.registrationNo,
            "field": "Registration / Certificate / Permit No.",
            "remarks": values.registrationNo.toLowerCase() === "passed" ? '' : values.registrationNoComment
        };
        const proofParams = {
            "_id": values.proofFieldId,
            "status": values.proof,
            "field": "Proof of registration or government accreditation",
            "remarks": values.proof.toLowerCase() === "passed" ? '' : values.proofComment
        };
        arrayParams.push(businessNameParams);
        arrayParams.push(registrationNoParams);
        arrayParams.push(proofParams);
        return arrayParams;
    };

    const checkAllApproval = (values) => {
        if (values.businessName === "" || values.registrationNo === "" || values.proof === "") {
            return false;
        } else {
            return true;
        }
    };

    const checkAllApproved = (values) => {
        if (values.businessName.toLowerCase() === "passed" && values.registrationNo.toLowerCase() === "passed" && values.proof.toLowerCase() === "passed") {
            return true;
        } else {
            return false;
        }
    }

    //"" - new // Passed - passed // Flagged - flagged
    const [businessNameEvaluation, setBusinessNameEval] = useState("");
    const [registrationNoEvaluation, setRegistrationNoEval] = useState("");
    const [proofEvaluation, setProofEval] = useState("");

    const [registration, setRegistration] = useState("");
    const [registrationId, setRegistrationId] = useState("");
    const [approval, setApproval] = useState("");

    const [startupStage, setStartupStage] = useState("");
    const [sectors, setSectors] = useState([]);
    const [oic, setOIC] = useState("");
    const [designation, setDesignation] = useState("");
    const [supportDocument, setSupportDocument] = useState("");
    const [supportDocumentRequirement, setSupportDocumentRequirement] = useState("");
    const [passportDatasheet, setPassportDatasheet] = useState("");
    const [passportDatasheetRequirement, setPassportDatasheetRequirement] = useState("");
    const [registrationStatus, setRegistrationStatus] = useState("");
    const [doneApproval, setDoneApproval] = useState("");
    const [proofOfRegistration, setProofOfRegistration] = useState("");
    const [proofRequirement, setProofRequirement] = useState("");
    const [businessClassification, setBusinessClassification] = useState("");

    const [businessLogo, setBusinessLogo] = useState(null);
    const [businessLogoImg, setBusinessLogoImg] = useState(null);

    const [expandContactInfo, setExpandContactInfo] = useState(false);
    const [expandOIC, setExpandOIC] = useState(false);

    const [lockEvaluation, setLockEvaluation] = useState(false);

    const getRegistration = (_id) => {
        showLoading();
        const api = host + `/api/approval/startup/view?_id=${_id}`;
        axios.get(api, { headers: headers }).then(res => {
            const result = res.data;
            const data = result.data;
            const registration = data.details;
            const registrationId = registration._id;
            const approval = data.approval;
            const startupType = registration.startupType;
            const isVerified = registration.isVerified;
            const oic = registration.oic;
            const assessedBy = registration.assessedById;
            registration.assessedBy = registration.assessedBy === "" ? null : registration.assessedBy;
            if (startupType === 1 || !isVerified) {
                const error = "Registration is not for approval."
                toast.error(error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
                history.push('/startup/evaluation/index');
            } else {
                setRegistration(registration);
                setRegistrationId(registrationId);
                setApproval(approval);
                setStartupStage(registration.startupStage);
                setSectors(registration.sectors);
                setOIC(registration.oic);
                setDesignation(oic.designation);
                setRegistrationStatus(registration.registrationStatus);
                setProofOfRegistration(registration.proofOfRegistration);
                setProofRequirement(registration.proofOfRegistrationRequirement);
                setBusinessClassification(registration.businessClassification);

                const passportDatasheet = oic.passportDatasheet;
                const passportRequirement = passportDatasheet.requirement;
                setPassportDatasheet(passportDatasheet);
                setPassportDatasheetRequirement(passportRequirement);

                const supportDocument = oic.supportDocument;
                const supportRequirement = supportDocument.requirement;
                setSupportDocument(supportDocument);
                setSupportDocumentRequirement(supportRequirement);

                if (registration.registrationStatus === "Verified" || registration.registrationStatus === "Declined") {
                    setDoneApproval(true);
                }

                if (registration.assessedBy !== null) {
                    if (user._id !== assessedBy) {
                        setLockEvaluation(true);
                    } else {
                        if (registration.registrationStatus === "For Updating") {
                            setLockEvaluation(true);
                        }
                    }
                }

                mapApproval(approval);

                if (registration.hasOwnProperty('businessLogo')) {
                    setBusinessLogo(registration.businessLogo);
                    if (registration.businessLogo !== null) {
                        getBusinessLogo(registration.businessLogo._id, registration.businessLogo.name);
                    }
                    hideLoading();
                } else {
                    hideLoading();
                }
            }
        }, error => {
            hideLoading();
            toast.error(error, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            history.push('/startup/evaluation/index');
        });
    };

    const registrationStatusLabel = (status) => {
        if (status === "New") {
            return <span className="text-info">{status}</span>
        } else if (status === "Updated") {// updated
            return <span className="text-warning">{status}</span>
        } else if (status === "Verified") {
            return <span className="text-success">{status}</span>
        } else if (status === "For Updating") {//for updating
            return <span className="text-warning">{status}</span>
        } else if (status === "Declined") {
            return <span className="text-warning">{status}</span>
        }
    };

    const expandContactInfoHandler = () => {
        setExpandContactInfo(!expandContactInfo);
    };

    const expandOICHandler = () => {
        setExpandOIC(!expandOIC);
    };

    const isNotNull = (value) => {
        if (typeof value !== "undefined" && value !== null) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let _id = params.get('_id');
        getRegistration(_id);
    }, [props]);

    const showLoadingFn = () => {
        showLoading();
    };

    const hideLoadingFn = () => {
        hideLoading();
    };

    return (
        <React.Fragment>
            {loader}
            <Header isLoggedIn={isLoggedIn} isAdmin={true} />
            <FlaggedItemsComponent
                showModal={showModal}
                showModalHandler={showModalHandler}
                items={propsArrayParams}
                registrationId={registrationId}
                loader={loader}
                showLoading={showLoadingFn}
                hideLoading={hideLoadingFn}
            />
            <div className="company-container mt-4">
                <Formik
                    validationSchema={evaluationSchema}
                    innerRef={formikRef}
                    initialValues={Object.assign(evaluationValue)}
                    onSubmit={submitEvaluation}
                >
                    {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values, field }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className="container text-left">
                                <div className="row">
                                    <div className="col-md-6 text-left m-t-10">
                                        <div className="text-left">
                                            <h4>{registration.startupName}</h4>
                                            <h6 className="mb-0"><strong>{startupStage.name}</strong></h6>
                                            <label>Startup Sector/s: {sectors.join(', ')}</label>
                                            <h6>{registrationStatusLabel(registration.registrationStatus)}</h6>
                                            <label>Evaluator: {(registration.assessedBy !== null) ? registration.assessedBy : "N/A"}</label>
                                        </div>
                                        <div className="row pr-5 mb-3">
                                            <div className="col-8">
                                                <div className="p-3 bg-white rounded">
                                                    <h5><strong>Startup ID:</strong></h5>
                                                    <h5>{registration.startupId}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div hidden={doneApproval} className="evaluation-div">
                                            {/* proof of registration */}
                                            <div className="clearfix"></div>
                                            <h6 className="mb-0">Proof of registration or government accreditation: </h6>
                                            <label className="mb-2">{proofRequirement.name}</label><br />
                                            <small className="file bg-white py-1 px-2 rounded"
                                                name={proofOfRegistration.name}
                                                value={proofOfRegistration._id}
                                                onClick={event => {
                                                    let _id = event.target.getAttribute('value');
                                                    let name = event.target.getAttribute('name');
                                                    viewFile(_id, name);
                                                }
                                                }>{proofOfRegistration.name}</small>
                                            <div className="form-group mt-2">
                                                <div className="form-check form-check-inline">
                                                    <Field
                                                        className={`${touched.proof && errors.proof ? "is-invalid" : ""} form-check-input`}
                                                        id="proof_1"
                                                        type="radio"
                                                        name="proof"
                                                        value="Flagged"
                                                        onChange={() => setFieldValue("proof", "Flagged")}
                                                        checked={proofEvaluation === "Flagged"}
                                                        onClick={(e) => setProofEval("Flagged")}
                                                        disabled={lockEvaluation}
                                                    />
                                                    <label className="form-check-label" htmlFor="proof_1">
                                                        Flagged
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline mb-2">
                                                    <Field
                                                        className={`${touched.proof && errors.proof ? "is-invalid" : ""} form-check-input`}
                                                        id="proof_2"
                                                        type="radio"
                                                        name="proof"
                                                        value="Passed"
                                                        onChange={() => setFieldValue("proof", "Passed")}
                                                        checked={proofEvaluation === "Passed"}
                                                        onClick={(e) => setProofEval("Passed")}
                                                        disabled={lockEvaluation}
                                                    />
                                                    <label className="form-check-label" htmlFor="proof_2">
                                                        Passed
                                                    </label>
                                                </div>
                                                <ErrorMessage component="div" name="proof" className="invalid-feedback" />
                                                <div className="form-group" hidden={proofEvaluation === "Passed" ? true : proofEvaluation === "Flagged" ? false : proofEvaluation === "" ? true : false}>
                                                    <Field
                                                        type="text"
                                                        component="textarea"
                                                        rows="5"
                                                        name="proofComment"
                                                        autoComplete="off"
                                                        placeholder="Write reason here"
                                                        className={`form-control ${touched.proofComment && errors.proofComment
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                        disabled={lockEvaluation}
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="proofComment"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            {/* business name */}
                                            <h6 className="mb-0">Registered Business / SEC / CDA Name: </h6>
                                            <label className="mb-2">{registration.businessName}</label>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <Field
                                                        className={`${touched.businessName && errors.businessName ? "is-invalid" : ""} form-check-input`}
                                                        id="businessName_1"
                                                        type="radio"
                                                        name="businessName"
                                                        value="Flagged"
                                                        onChange={() => setFieldValue("businessName", "Flagged")}
                                                        checked={businessNameEvaluation === "Flagged"}
                                                        onClick={(e) => setBusinessNameEval("Flagged")}
                                                        disabled={lockEvaluation}
                                                    />
                                                    <label className="form-check-label" htmlFor="businessName_1">
                                                        Flagged
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline mb-2">
                                                    <Field
                                                        className={`${touched.businessName && errors.businessName ? "is-invalid" : ""} form-check-input`}
                                                        id="businessName_2"
                                                        type="radio"
                                                        name="businessName"
                                                        value="Passed"
                                                        onChange={() => setFieldValue("businessName", "Passed")}
                                                        checked={businessNameEvaluation === "Passed"}
                                                        onClick={(e) => setBusinessNameEval("Passed")}
                                                        disabled={lockEvaluation}
                                                    />
                                                    <label className="form-check-label" htmlFor="businessName_2">
                                                        Passed
                                                    </label>
                                                </div>
                                                <div className="form-group" hidden={businessNameEvaluation === "Passed" ? true : businessNameEvaluation === "Flagged" ? false : businessNameEvaluation === "" ? true : false}>
                                                    <Field
                                                        type="text"
                                                        component="textarea"
                                                        rows="5"
                                                        name="businessNameComment"
                                                        autoComplete="off"
                                                        placeholder="Write reason here"
                                                        className={`form-control ${touched.businessNameComment && errors.businessNameComment
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                        disabled={lockEvaluation}
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="businessNameComment"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            {/* business registration no */}
                                            <h6 className="mb-0">Registration / Certificate / Permit No.: </h6>
                                            <label className="mb-2">{registration.registrationNumber}</label>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <Field
                                                        className={`${touched.registrationNo && errors.registrationNo ? "is-invalid" : ""} form-check-input`}
                                                        id="registrationNo_1"
                                                        type="radio"
                                                        name="registrationNo"
                                                        value="Flagged"
                                                        onChange={() => setFieldValue("registrationNo", "Flagged")}
                                                        checked={registrationNoEvaluation === "Flagged"}
                                                        onClick={(e) => setRegistrationNoEval("Flagged")}
                                                        disabled={lockEvaluation}
                                                    />
                                                    <label className="form-check-label" htmlFor="registrationNo_1">
                                                        Flagged
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline mb-2">
                                                    <Field
                                                        className={`${touched.registrationNo && errors.registrationNo ? "is-invalid" : ""} form-check-input`}
                                                        id="registrationNo_2"
                                                        type="radio"
                                                        name="registrationNo"
                                                        value="Passed"
                                                        onChange={() => setFieldValue("registrationNo", "Passed")}
                                                        checked={registrationNoEvaluation === "Passed"}
                                                        onClick={(e) => setRegistrationNoEval("Passed")}
                                                        disabled={lockEvaluation}
                                                    />
                                                    <label className="form-check-label" htmlFor="registrationNo_2">
                                                        Passed
                                                    </label>
                                                </div>
                                                <ErrorMessage component="div" name="registrationNo" className="invalid-feedback" />
                                                <div className="form-group" hidden={registrationNoEvaluation === "Passed" ? true : registrationNoEvaluation === "Flagged" ? false : registrationNoEvaluation === "" ? true : false}>
                                                    <Field
                                                        type="text"
                                                        component="textarea"
                                                        rows="5"
                                                        name="registrationNoComment"
                                                        autoComplete="off"
                                                        placeholder="Write reason here"
                                                        className={`form-control ${touched.registrationNoComment && errors.registrationNoComment
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                        disabled={lockEvaluation}
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="registrationNoComment"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div hidden={!doneApproval}>
                                            <h6 className="mb-0">Registered Business / SEC / CDA Name: </h6>
                                            <label className="mb-2">{registration.businessName}</label>
                                            <h6 className="mb-0">Registration / Certificate / Permit No.: </h6>
                                            <label className="mb-2">{registration.registrationNumber}</label>
                                            <h6 className="mb-0">Proof of registration or government accreditation: </h6>
                                            <label className="mb-2">{proofRequirement.name}</label>
                                            <small className="file"
                                                name={proofOfRegistration.name}
                                                value={proofOfRegistration._id}
                                                onClick={event => {
                                                    let _id = event.target.getAttribute('value');
                                                    let name = event.target.getAttribute('name');
                                                    viewFile(_id, name);
                                                }
                                                }>{proofOfRegistration.name}</small>
                                            <div className="clearfix">&nbsp;</div>
                                        </div>
                                        <h6 className="mb-2">Business Logo </h6>
                                        <div hidden={businessLogo !== null ? false : true}>
                                            <img src={businessLogoImg} alt="Business Logo" width="70" height="70" />
                                            <div className="mb-7"></div>
                                        </div>
                                        <div hidden={businessLogo !== null ? true : false}>
                                            <label>N/A</label>
                                        </div>
                                        <div className="clearfix">&nbsp;</div>
                                        <h6 className="mb-0">Business Classification</h6>
                                        <label>{businessClassification.name}</label>
                                        <div className="clearfix">&nbsp;</div>
                                        <h6 className="mb-0">Year of Founding </h6>
                                        <label>{registration.yearFounding !== "" ? registration.yearFounding : "N/A"}</label>
                                        <div className="clearfix">&nbsp;</div>
                                        <h6 className="mb-0">Company Address</h6>
                                        <label>{registration.address}</label>
                                        <div className="clearfix">&nbsp;</div>
                                    </div>
                                    <div className="col-md-6 text-left m-t-10">
                                        <div className="bg-white p-3 rounded">
                                            <h5><strong>Company Contact Details</strong></h5>
                                            <hr></hr>
                                            <div hidden={expandContactInfo}>
                                                <h6 className="mb-0">Company Email</h6>
                                                <label className={registration.companyEmail !== "" ? "link-label" : ""}>{registration.companyEmail !== "" ? registration.companyEmail : "N/A"}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Company Contact No.</h6>
                                                <label>{registration.companyNo !== "" ? registration.companyNo : "N/A"}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <small className="link-label pointer" onClick={expandContactInfoHandler}><u>More info</u></small>
                                            </div>
                                            <div hidden={!expandContactInfo}>
                                                <h6 className="mb-0">Company Email</h6>
                                                <label className={registration.companyEmail !== "" ? "link-label" : ""}>{registration.companyEmail !== "" ? registration.companyEmail : "N/A"}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Company Contact No.</h6>
                                                <label>{registration.companyNo !== "" ? registration.companyNo : "N/A"}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Company Website</h6>
                                                <label>{registration.companyWebsite !== "" ? registration.companyWebsite : "N/A"}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">LinkedIn</h6>
                                                <label>{registration.companyLinkedIn !== "" ? registration.companyLinkedIn : "N/A"}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Facebook</h6>
                                                <label>{registration.companyFacebook !== "" ? registration.companyFacebook : "N/A"}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Twitter</h6>
                                                <label>{registration.companyTwitter !== "" ? registration.companyTwitter : "N/A"}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Instagram</h6>
                                                <label>{registration.companyInstagram !== "" ? registration.companyInstagram : "N/A"}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Youtube</h6>
                                                <label>{registration.companyYoutube !== "" ? registration.companyYoutube : "N/A"}</label>
                                                <div className="clearfix">&nbsp;</div>

                                                <small className="link-label pointer" onClick={expandContactInfoHandler}><u>Less info</u></small>
                                            </div>
                                        </div>
                                        <div className="clearfix">&nbsp;</div>

                                        <div className="p-3 bg-white rounded">
                                            <h5><strong>Officer-in-Charge Information ({designation.name})</strong></h5>
                                            <hr></hr>
                                            <div hidden={expandOIC}>
                                                <h6 className="mb-0">Name</h6>
                                                <label>{oic.firstName} {oic.middleName} {oic.lastName} {oic.suffix}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {oic.citizenship === "Filipino" ? "Government-Issued ID" : "Other documents to support eligibity to engage in business in the Philippines"}
                                                    </h6>
                                                    <h6 className="mb-0">
                                                        {supportDocumentRequirement.name}
                                                    </h6>
                                                    <small className="file"
                                                        name={supportDocument.name}
                                                        value={supportDocument._id}
                                                        onClick={event => {
                                                            let _id = event.target.getAttribute('value');
                                                            let name = event.target.getAttribute('name');
                                                            viewFile(_id, name);
                                                        }
                                                        }>{supportDocument.name}</small>
                                                </div>
                                                <div className="clearfix" hidden={oic.citizenship === "Filipino" ? true : false}>&nbsp;</div>
                                                <div hidden={oic.citizenship === "Filipino" ? true : false}>
                                                    <h6 className="mb-0">
                                                        {passportDatasheetRequirement.name}
                                                    </h6>
                                                    <small className="file"
                                                        name={passportDatasheet.name}
                                                        value={passportDatasheet._id}
                                                        onClick={event => {
                                                            let _id = event.target.getAttribute('value');
                                                            let name = event.target.getAttribute('name');
                                                            viewFile(_id, name);
                                                        }
                                                        }>{passportDatasheet.name}</small>
                                                </div>
                                                <small className="link-label pointer" onClick={expandOICHandler}><u>More info</u></small>
                                            </div>
                                            <div hidden={!expandOIC}>
                                                <h6 className="mb-0">Name</h6>
                                                <label>{oic.firstName} {oic.middleName} {oic.lastName} {oic.suffix}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Citizenship</h6>
                                                <label>{oic.citizenship}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Date of birth</h6>
                                                <label>{moment(oic.birthDate).format("MM/DD/YYYY")}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Place of birth</h6>
                                                <label>{oic.birthPlace}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Contact No.</h6>
                                                <label>{oic.contactNo}</label>
                                                <div className="clearfix">&nbsp;</div>
                                                <h6 className="mb-0">Email Address</h6>
                                                <label>{oic.email}</label>
                                                <div className="clearfix">&nbsp;</div><div>
                                                    <h6 className="mb-0">
                                                        {oic.citizenship === "Filipino" ? "Government-Issued ID" : "Other documents to support eligibity to engage in business in the Philippines"}
                                                    </h6>
                                                    <h6 className="mb-0">
                                                        {supportDocumentRequirement.name}
                                                    </h6>
                                                    <small className="file"
                                                        name={supportDocument.name}
                                                        value={supportDocument._id}
                                                        onClick={event => {
                                                            let _id = event.target.getAttribute('value');
                                                            let name = event.target.getAttribute('name');
                                                            viewFile(_id, name);
                                                        }
                                                        }>{supportDocument.name}</small>
                                                </div>
                                                <div className="clearfix" hidden={oic.citizenship === "Filipino" ? true : false}>&nbsp;</div>
                                                <div hidden={oic.citizenship === "Filipino" ? true : false}>
                                                    <h6 className="mb-0">
                                                        {passportDatasheetRequirement.name}
                                                    </h6>
                                                    <small className="file"
                                                        name={passportDatasheet.name}
                                                        value={passportDatasheet._id}
                                                        onClick={event => {
                                                            let _id = event.target.getAttribute('value');
                                                            let name = event.target.getAttribute('name');
                                                            viewFile(_id, name);
                                                        }
                                                        }>{passportDatasheet.name}</small>
                                                </div>
                                                <small className="link-label pointer" onClick={expandOICHandler}><u>Less info</u></small>
                                            </div>
                                        </div>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className="p-3 bg-white rounded">
                                            <h4>Business Description</h4>
                                            <hr></hr>
                                            <div id="busDesc">
                                                <h6 className="mb-0">Brief description of your Startup Business</h6>
                                                {isNotNull(registration.businessDescription) ? registration.businessDescription.length > 100 ?
                                                    <div className="collapse" id="collapseBusDesc" aria-expanded="false">
                                                        <label >{registration.businessDescription}</label>
                                                    </div> :
                                                    <label >{registration.businessDescription}</label> :
                                                    null
                                                }
                                            </div>
                                            {isNotNull(registration.businessDescription) ?
                                                registration.businessDescription.length > 100 ?
                                                    <div className="text-left">
                                                        <a role="button" className="collapsed more-btn" data-toggle="collapse" href="#collapseBusDesc" aria-expanded="false">
                                                        </a>
                                                        <div className="mb-7"></div>
                                                    </div>
                                                    : null
                                                : null}
                                            <div className="clearfix">&nbsp;</div>
                                            <div id="probDesc">
                                                <h6 className="mb-0">Problems being solved</h6>
                                                {isNotNull(registration.problemSolved) ? registration.problemSolved.length > 100 ?
                                                    <div className="collapse" id="collapseProbDesc" aria-expanded="false">
                                                        <label >{registration.problemSolved}</label>
                                                    </div>
                                                    : <label >{registration.problemSolved}</label>
                                                    : null
                                                }
                                            </div>
                                            {isNotNull(registration.problemSolved) ?
                                                registration.problemSolved.length > 100 ?
                                                    <div className="text-left">
                                                        <a role="button" className="collapsed more-btn" data-toggle="collapse" href="#collapseProbDesc" aria-expanded="false">
                                                        </a>
                                                        <div className="mb-7"></div>
                                                    </div>
                                                    : null
                                                : null}
                                            <div className="clearfix">&nbsp;</div>
                                            <div id="ideaDesc">
                                                <h6 className="mb-0">Idea or Product being offered</h6>
                                                {isNotNull(registration.idea) ? registration.idea.length > 100 ?
                                                    <div className="collapse" id="collapseIdeaDesc" aria-expanded="false">
                                                        <label >{registration.idea}</label>
                                                    </div> :
                                                    <label >{registration.idea}</label> :
                                                    null
                                                }
                                            </div>
                                            {isNotNull(registration.idea) ?
                                                registration.idea.length > 100 ?
                                                    <div className="text-left">
                                                        <a role="button" className="collapsed more-btn" data-toggle="collapse" href="#collapseIdeaDesc" aria-expanded="false">
                                                        </a>
                                                        <div className="mb-7"></div>
                                                    </div>
                                                    : null
                                                : null}
                                            <div className="clearfix">&nbsp;</div>
                                            <div id="uspDesc">
                                                <h6 className="mb-0">Unique Selling Proposition (USP) of your solution</h6>
                                                {isNotNull(registration.usp) ? registration.usp.length > 100 ?
                                                    <div className="collapse" id="collapseUspDesc" aria-expanded="false">
                                                        <label >{registration.usp}</label>
                                                    </div> :
                                                    <label >{registration.usp}</label> :
                                                    null
                                                }
                                            </div>
                                            {isNotNull(registration.usp) ?
                                                registration.usp.length > 100 ?
                                                    <div className="text-left">
                                                        <a role="button" className="collapsed more-btn" data-toggle="collapse" href="#collapseUspDesc" aria-expanded="false">
                                                        </a>
                                                    </div>
                                                    : null
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="py-4 text-center mt-4">
                                    <button type="button" className="startup-btn-main mx-3" onClick={back}>Back</button>
                                    <button type="submit" className="startup-btn-main" hidden={doneApproval || lockEvaluation}>Submit</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <Modal
                backdrop="static"
                keyboard={false}
                show={showApprovedModal}
            >
                <Modal.Header>
                    <Modal.Title>Confirmation Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Warning: You are about to approve this registration. Ensure that all fields and files have been thoroughly checked.</p>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <div className="text-right">
                        <button type="button" className="startup-btn-main mx-3" onClick={(e) => setShowApprovedModal(!showApprovedModal)}>Cancel</button>
                        <button type="button" className="startup-btn-main" onClick={approve}>Proceed</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default ApplicantStartup;