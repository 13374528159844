import React from "react";
import "../dist/css/sidebar.css";
import Header from "../components/Header";
import UserCreate from "../pages/user-management/user/UserCreate";
import UserIndex from "../pages/user-management/user/UserIndex";
import PermissionIndex from "../pages/user-management/permission/PermissionIndex";
import RolesIndex from "../pages/user-management/roles/RolesIndex";

const Sidebar = (props) => {
    return (
        <React.Fragment>
            <div className="container">
                <h4 className="mb-3">User Management</h4>
                <div>
                    <a className={props.activePage === 'user' ? "sidenav-link active" : "sidenav-link"} href="/user-management/user/index">
                        Users
                    </a>
                    <a className={props.activePage === 'role' ? "sidenav-link active" : "sidenav-link"} href="/user-management/roles/index">
                        Roles
                    </a>
                    <a className={props.activePage === 'permission' ? "sidenav-link active" : "sidenav-link"} href="/user-management/permission/index">
                        Permissions
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Sidebar;