import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import { toast } from 'react-toastify';
import LoadingService from "../../imports/LoadingService";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import "../../dist/scss/custom.scss"; 
import { Modal } from "react-bootstrap";
import StartupEnablerOIC from "./StartupEnablerOIC";
import StartupEnablerBusiness from "./StartupEnablerBusiness";
import StartupEnablerDescription from "./StartupEnablerDescription";
import ConsentFormComponent from "../../components/ConsentFormComponent";

const requiredLabel = 'This is Required!';

const oicSchema = Yup.object().shape({
    isOIC: Yup.boolean(),
	designation: Yup.string().required(requiredLabel),
	lastName: Yup.string().required(requiredLabel),
	firstName: Yup.string().required(requiredLabel),
	suffix: Yup.string(),
	middleName: Yup.string().required(requiredLabel),
	citizenship: Yup.string(),
	others: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
    sex: Yup.number(),
    birthDate: Yup.string().required(requiredLabel),
    placeOfBirth: Yup.string().required(requiredLabel),
    contactno: Yup.string().required(requiredLabel),
    email: Yup.string().required(requiredLabel).email('Invalid email format.'),
    oicRequirement: Yup.string().required(requiredLabel),
    oicRequirementFile: Yup.mixed().required(requiredLabel).test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        } 

    }),
    oicPassportIdentifier: Yup.string(),
    oicPassportRequirement: Yup.string(),
    oicPassportRequirementFile: Yup.mixed().test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        } 

    }).when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
});

const businessInfoSchema = Yup.object().shape({
	startupName: Yup.string().required(requiredLabel),
	businessName: Yup.string().required(requiredLabel),
	businessLogo: Yup.mixed(),
	businessLogoFile: Yup.mixed().test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        } 

    }),
	businessClassification: Yup.string(),
	registrationNo: Yup.string().required(requiredLabel),
	proof: Yup.string().required(requiredLabel),
    proofFile: Yup.mixed().required(requiredLabel).test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        } 

    }),
    proofIdentifier: Yup.string(),
	foundingYear: Yup.string(),
    region: Yup.string().required(requiredLabel),
	regionName: Yup.string(),
	province: Yup.string().required(requiredLabel),
	provinceName: Yup.string(),
	city: Yup.string().required(requiredLabel),
	cityName: Yup.string(),
	barangay: Yup.string().required(requiredLabel),
	barangayName: Yup.string(),
	streetNo: Yup.string(),
	lotBlockUnit: Yup.string(),
	zipCode: Yup.string().required(requiredLabel),
    companyEmail: Yup.string(),
    companyContactNumber: Yup.string().required(requiredLabel),
    website: Yup.string(),
    linkedIn: Yup.string(),
    facebook: Yup.string(),
    twitter: Yup.string(),
    instagram: Yup.string(),
    youtube: Yup.string(),
});


// pages 0 - StartupEnablerOIC, 1 - StartupEnablerBusiness, 2 - StartupEnablerDescription
const EnablerVerification = (props) => {
	document.title = 'PSDP Verification';
    const pageModule = "startupEnabler";
	const history = useHistory();

	const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const user = props.user && Object.keys(props.user).length !== 0 ? props.user : '';
	const personalInfo = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? props.personalInfo : '';

	const [loader, showLoading, hideLoading] = LoadingService();

    const [formPage, setFormPage] = useState(0);

    const setFormPageFn = (page) => {
        setFormPage(page);
    };
    
    const [showModal, setShowModal] = useState(false);
    const showModalHandler = () => {
        setShowModal(!showModal);
    };

    const showLoadingFn = () => {
        showLoading();
    };

    const hideLoadingFn = () => {
        hideLoading();
    };

    return (
        <React.Fragment>
            {loader}
			<Header isLoggedIn={isLoggedIn} />
            <ConsentFormComponent 
                showModal={showModal} 
                showModalHandler={showModalHandler}
                loader={loader} 
                showLoading={showLoadingFn} 
                hideLoading={hideLoadingFn}
                pageModule={pageModule}
            />
            {
                formPage === 0 ?
                    <StartupEnablerOIC setFormPage={setFormPageFn} personalInfo={personalInfo} />
                :
                    (
                        formPage === 1 ?
                            <StartupEnablerBusiness setFormPage={setFormPageFn} personalInfo={personalInfo} />
                        :
                            <StartupEnablerDescription setFormPage={setFormPageFn} personalInfo={personalInfo} openConsent={showModalHandler} />
                    )
            }
        </React.Fragment>
    )
}
export default EnablerVerification;