import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../dist/css/home.css";
import Header from "../components/Header";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import url from "../environment";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingService from "../imports/LoadingService";
import ls from '../config/ls';
import ParticleBackground from "../ParticleBackground";
import RedirectModal from "../components/RedirectModal";

const loginSchema = Yup.object().shape({
	username: Yup.string().required('This is Required!'),
	password: Yup.string().required('This is Required!'),
});
const forgotPasswordSchema = Yup.object().shape({
	username: Yup.string().required('This is Required!'),
	email: Yup.string().required('This is Required!'),
});

const Home = (props) => {
	document.title = 'Startup Portal Home';
	const history = useHistory();
	const location = useLocation()

	const [loader, showLoader, hideLoader] = LoadingService();
	const [show, setShow] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
	const host = url.hostPort();

	const [queryParams, setQueryParams] = useState([]);

	const [showRedirect, setShowRedirect] = useState(false);

	const [isMaintenance, setMaintenance] = useState(false);

	const loginSubmit = async (values) => {
		showLoader();
		let params = {
			'username': values.username,
			'password': values.password,
		}
		const api = host + "/api/login";
		axios.post(api, params).then(res => {
			let result = res.data.data;
			let currentActions = result.defaultActions;
			let hasPersonalInfo = result.hasPersonalInfo;
			ls.set('zgETDnOXBY', true); 
			ls.set('LjfADyiZFB', result.token);
			ls.set('MaeIHzsYGC', result.xAuthToken);
			ls.set('OnvBIFUcVC', result);
			ls.set('iSWgCQnurz', currentActions);
			if(result.resetPassword){
				history.push('/user/resetpassword');
			}else{
				if(hasPersonalInfo){
					ls.set('HYLQcSsSWe', result.PersonalInformation);
					window.scrollTo(0, 0);
					window.location.href = '/dashboard';
					hideLoader();
				}else{
					ls.set('HYLQcSsSWe', '');
					history.push('/user/signup/details');
					hideLoader();
				}
			}
		}, error => {
			if (error.response.data.message == "Error! Account has been deactivated!") {
				let errorMessage = "Error! Account has been deactivated!";
				setShow(true);
			} else {
				let errorMessage = "Incorrect username or password.";
				toast.error(errorMessage, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
			}
			hideLoader();
			
		});
	};
	
	const forgotPassword = (values) =>{
		showLoader();
		let params = {
			'username': values.username,
			'email': values.email,
		}
		const endpoint = `/api/forgot-password`;
		const api = host + endpoint;
		axios.post(api, params).then(res => {
			hideLoader();
			let result = res.data;
			let data = result.data;
			forgotPasswordModalHandler();
			let resetMessage = "Your password has been reset. Please check your email inbox or spam.";
			toast.success(resetMessage, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
			});	
		}, error => {
			hideLoader();
			let responseError = error.response.data;
			let message = responseError.message;
			toast.error(message, {
			position: "bottom-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: false,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			});
		})
	};
	
	const forgotPasswordModalHandler = () => {
		setShowForgotPasswordModal(!showForgotPasswordModal);
		// if(showForgotPasswordModal){
		// 	history.replace({
		// 		search: queryParams.toString(),
		// 	})
		// }
	}

	const Redirect = () => {
		let value = 'startup.gov.ph';
		return <a href={`//${value}`} target="_blank" rel="noopener noreferrer">startup.gov.ph</a>;
	};

	const cancel = () => {
		setShow(!true);
	};

	const redirectHandler = () => {
		setShowRedirect(!showRedirect);
	}

	useEffect( () => {
		setIsLoggedIn(props.isLoggedIn);
    }, [props]);

	useEffect(() => {
		props.PageHandler('notLoggedIn');

		const query = new URLSearchParams(location.search);
		setQueryParams(query);
		if(query.get('_services') !== null){
			if(query.get('_services') == '123456'){
				redirectHandler();
			}
		}

		const maintenance = url.maintenance();
		if (maintenance) {
			setMaintenance(true);
		} else {
			setMaintenance(false);
		}
	}, []);

	return (
		<React.Fragment>
			{ isMaintenance ?
				<div className="homepage-container">
					{loader}
					<Header isLoggedIn={isLoggedIn} isAdmin={true}  activePage = {props.activePage} />
					<div className="welcome">
						<div className="container">
							<div className="row">
								<div className="">
									<div className="welcome-container">
										<h2 className="title mb-4">Startup Philippines Website MAINTENANCE NOTICE</h2>
										<p>
											We are currently undergoing maintenance to make your browsing experience even better and
											keep things top-notch.
										</p>
										<p>
											During this maintenance window, access to certain areas of the website will be temporarily
											unavailable. <br /> If you have an existing account, you may experience difficulty accessing your
											account during this period.
										</p>
										<p>
											We apologize for any inconvenience this may cause.
										</p>
										<p>Stay connected and follow our FB Pages:</p>
										<ul>
											<li><a href="https://www.facebook.com/DICTgovph">DICT - Department of Information and Communications Technology</a></li>
											<li><a href="https://www.facebook.com/DOSTph">DOST - Department of Science and Technology</a></li>
											<li><a href="https://www.facebook.com/DTI.Philippines">DTI - Department of Trade and Industry, Philippines</a></li>
										</ul>
										<p>
											Thank you for your continued support, and we look forward to serving you better with an
											enhanced website.	
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			:
				<React.Fragment>
					<div className="homepage-container">
						{loader}
						
						<Header isLoggedIn={isLoggedIn} isAdmin={true}  activePage = {props.activePage} />
						{/* <CarouselComponent items={CarouselItems} /> */}
						
						{/** Welcome */}
						<div className="welcome">
							<div className="container">
								<div className="row">
									<div className={!isLoggedIn ? "col-md-6" : "col-md-12"}>
									<div className="welcome-container">
										<h2 className="title">Welcome</h2>
										<p>
											The Startup Innovations Portal is the central platform for all
											things related to the Philippine startup ecosystem.
										</p>
										<p>
											For full access to all the features, please consider making an
											account on the Portal.
										</p>
										<p>
											For more information, go to <Redirect />
										</p>
									</div>
									</div>
		
									{!isLoggedIn ? (
										<div className="login-container col-md-6">
											<h2 className="title">Log in</h2>
											<div className="login-form rounded">
												<Formik
													validationSchema={loginSchema}
													onSubmit={loginSubmit}
													initialValues={{
														'username': '',
														'password': '',
													}}
												>
											{({ handleSubmit, errors, touched }) => (
												<Form noValidate onSubmit={handleSubmit}>
													<div className="form-group">
														<label htmlFor="username">Username</label>
														<Field type="text" name="username" autoComplete="off" className={`form-control ${touched.username && errors.username ? "is-invalid" : ""}`} />
														<ErrorMessage component="div" name="username" className="invalid-feedback" />
													</div>
		
													<div className="form-group">
														<label htmlFor="password">Password</label>
														<Field type="password" name="password" autoComplete="off" className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`} />
														<ErrorMessage component="div" name="password" className="invalid-feedback" />
													</div>
													<button type="submit" className="btn btn-primary login-button">Log in</button>
												</Form>
											)}
								
												</Formik>
												<div className="clearfix"></div>
												<div className="login-links pointer">
													<span>Forgot Password? </span>
													<a className="pointer" onClick={forgotPasswordModalHandler}>Click here</a>
												</div>
												<Modal
													backdrop="static"
													keyboard={false}
													show={showForgotPasswordModal}
												>
													<Modal.Header>
														<Modal.Title>Forgot Password?</Modal.Title>
													</Modal.Header>
													<Modal.Body>
														<Formik
															validationSchema={forgotPasswordSchema}
															onSubmit={forgotPassword}
															initialValues={{
																'username': '',
																'email': '',
															}}
														>
															{({ handleSubmit, errors, touched }) => (
																<Form noValidate onSubmit={handleSubmit}>
																	<div className="form-group">
																		<label htmlFor="email">Email:</label>
																		<Field type="email" name="email" autoComplete="off" className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`} />
																		<ErrorMessage component="div" name="email" className="invalid-feedback" />
																	</div>
																	<div className="form-group mb-4">
																		<label htmlFor="username">Username:</label>
																		<Field type="text" name="username" autoComplete="off" className={`form-control ${touched.username && errors.username ? "is-invalid" : ""}`} />
																		<ErrorMessage component="div" name="username" className="invalid-feedback" />
																	</div>
																	<div className="modal-footer text-right">
																		<button type="submit" className="startup-btn-main">Send Reset Link</button>
																		<button type="button" className="startup-btn" onClick={forgotPasswordModalHandler}>Cancel</button>
																	</div>
																</Form>
															)}
														</Formik>
													</Modal.Body>
												</Modal>
												<div className="login-links">
													<span>No account yet? </span>
													<a href="/user/signup">Sign up here</a>
												</div>
											</div>
										</div>
									): null}
								</div>
							</div>
						</div>
		
						{/* Events and Announcements */}
						{/* <div className="events-and-announcements">
							<div className="container">
								<div className="row">
									<div className="col-md-6">
									<div className="events-container">
										<h2 className="title">Upcoming Events</h2>
										<EventsComponent items={Events} />
										<a className="startup-btn" href="/events">
										View All Events
										</a>
									</div>
									</div>
									<div className="col-md-6">
									<div className="announcements-container">
										<h2 className="title">Announcements</h2>
										<AnnouncementsComponent items={Announcements} />
									</div>
									</div>
								</div>
							</div>
						</div> */}
		
						{/** Latest News */}
						{/* <div className="latest-news-container">
							<div className="container">
								<h1 className="title">Latest News</h1>
							</div>
							<div className="latest-news-main-container">
								<div className="container">
									<LatestNewsComponent items={LatestNews} />
								</div>
							</div>
						</div> */}
					</div>
					<Modal show={show}>
						<Modal.Header>
							<Modal.Title>Account Deactivated</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>Sorry, your account seems to have been deactivated. Please contact helpdesk@startup.gov.ph for assistance.</p>
						</Modal.Body>
						{/* <Modal.Footer> */}
						<div className="modal-footer text-right">
						<button type="button" className="startup-btn" onClick={cancel}>Close</button>
						</div>
						{/* </Modal.Footer> */}
					</Modal>
					<RedirectModal showRedirect={showRedirect} user={props.user} personalInfo={props.personalInfo} showHandler={redirectHandler} isLoggedIn={props.isLoggedIn} />
				</React.Fragment>
			}
		<ParticleBackground />
		</React.Fragment>
	);
};

export default Home;
