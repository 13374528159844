import React, { useEffect, useState } from "react";
// import "../../dist/css/PSDPRegistration.css";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import token from '../../store/token';
import xAuthToken from '../../store/xAuthToken';
import store, { set, get } from "store";
import { Modal } from "react-bootstrap";
import ImageUploaderField from '../../imports/Upload';
import LoadingService from '../../imports/LoadingService';
import ConsentFormComponent from "../../components/ConsentFormComponent";
import '../../dist/scss/custom.scss';
import * as moment from "moment";

const hostPort = url.hostPort();
const auth = {
    'Authorization': `Bearer ${token()}`,
    
};

async function validateStartupName(value) {
    try{
        let params = {
            "type": "create",
            "field": "startupName",
            "value": value,
            "_id": ""
        };
        let error;
        let api = `/api/startup/field/check`;
        api = hostPort + api;
        const response = await axios.post(
            api, params, {headers: auth},
        );
        const result = response.data;
        if(result.exist){
            error = `${value} is already taken.`;
            return error;
        }else{
            return;
        }
    }catch(ex){
        console.log("validateStaratupName", ex);
        return;
    }
};

async function validateBusinessName(value) {
    try{
        let params = {
            "type": "create",
            "field": "businessName",
            "value": value,
            "_id": ""
        };
        let error;
        let api = `/api/startup/field/check`;
        api = hostPort + api;
        const response = await axios.post(
            api, params, {headers: auth},
        );
        const result = response.data;
        if(result.exist){
            error = `${value} is already taken.`;
            return error;
        }else{
            return;
        }
    }catch(ex){
        console.log("validateBusinessName", ex);
        return;
    }
};

async function validateRegistrationNo(value) {
    try{
        let params = {
            "type": "create",
            "field": "registrationNumber",
            "value": value,
            "_id": ""
        };
        let error;
        let api = `/api/startup/field/check`;
        api = hostPort + api;
        const response = await axios.post(
            api, params, {headers: auth},
        );
        const result = response.data;
        if(result.exist){
            error = `${value} is already taken.`;
            return error;
        }else{
            return;
        }
    }catch(ex){
        console.log("validateRegistrationNo", ex);
        return;
    }
};

const requiredLabel = 'This is required.';

const firstPageRegistrationSchema = Yup.object().shape({
    isOIC: Yup.boolean(),
    isTransition: Yup.boolean(),
	designation: Yup.string().required(requiredLabel),
	lastName: Yup.string().required(requiredLabel),
	firstName: Yup.string().required(requiredLabel),
	suffix: Yup.string(),
	middleName: Yup.string(),
	citizenship: Yup.string(),
	others: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
    sex: Yup.string().required(requiredLabel),
    birthDate: Yup.string().required(requiredLabel),
    placeOfBirth: Yup.string().required(requiredLabel),
    contactno: Yup.string().required(requiredLabel),
    email: Yup.string().required(requiredLabel).email('Invalid email format.'),
    oicRequirement: Yup.string().required(requiredLabel),
    oicRequirementFile: Yup.mixed().test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12600000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12600000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        } 

    }).when('isOIC', {
        is: true,
        then: Yup.mixed().when('isTransition', {
            is: true,
            then: Yup.mixed().when('citizenship', (citizenship, schema) => {
                if(citizenship == 0){
                    return schema;
                }else{
                    return schema.required(requiredLabel);
                }
            }),
            otherwise: Yup.mixed().required(requiredLabel)
        }),
        otherwise: Yup.mixed().required(requiredLabel)
    }),
    oicPassportIdentifier: Yup.string(),
    oicPassportRequirement: Yup.string(),
    oicPassportRequirementFile: Yup.mixed().test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12600000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12600000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
        } 

    }).when('isTransition', {
        is: true,
        then: Yup.mixed(),
        otherwise: Yup.mixed().when('citizenship', (citizenship, schema) => {
            if(citizenship == 0){
                return schema;
            }else{
                return schema.required(requiredLabel)
            }
        })
    }),
});

const secondPageRegistrationSchema = Yup.object().shape({
	startupName: Yup.string().required(requiredLabel),
	businessName: Yup.string().required(requiredLabel),
	businessLogo: Yup.mixed(),
	businessLogoFile: Yup.mixed().test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true);
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12600000 ? false : true : true);
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12600000 ? false : true : true);
            }
        }
        catch(e) {
            return(value === undefined ? true : true);
        }
    }),
	businessClassification: Yup.string().required(requiredLabel),
	registrationNo: Yup.string().required(requiredLabel),
	proof: Yup.string().required(requiredLabel),
    proofFile: Yup.mixed().required(requiredLabel).test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true);
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12600000 ? false : true : true);
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12600000 ? false : true : true);
            }
        }
        catch(e) {
            return(value === undefined ? true : true);
        }
    }),
    proofIdentifier: Yup.string(),
	foundingYear: Yup.string(),
    region: Yup.string().required(requiredLabel),
	regionName: Yup.string(),
	province: Yup.string().required(requiredLabel),
	provinceName: Yup.string(),
	city: Yup.string().required(requiredLabel),
	cityName: Yup.string(),
	barangay: Yup.string().required(requiredLabel),
	barangayName: Yup.string(),
	streetNo: Yup.string(),
	lotBlockUnit: Yup.string(),
	zipCode: Yup.string().required(requiredLabel),
    companyEmail: Yup.string(),
    companyContactNumber: Yup.string().required(requiredLabel),
    website: Yup.string(),
    linkedIn: Yup.string(),
    facebook: Yup.string(),
    twitter: Yup.string(),
    instagram: Yup.string(),
    youtube: Yup.string(),
});

const thirdPageRegistrationSchema = Yup.object().shape({
	description: Yup.string().required(requiredLabel),
	problemSolved: Yup.string().required(requiredLabel),
	idea: Yup.string().required(requiredLabel),
	usp: Yup.string().required(requiredLabel),
});

const PSDPVerification = (props) => {
	document.title = 'PSDP Verification';
    const pageModule = "startupAccount";
	const history = useHistory();

	const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const user = props.user && Object.keys(props.user).length !== 0 ? props.user : '';
	const personalInfo = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? props.personalInfo : '';

	const [loader, showLoading, hideLoading] = LoadingService();

    const [passportIdentifier, setPassportIdentifier] = useState('');
    const [businessLogoIdentifier, setBusinessLogoIdentifier] = useState('');

	const [regionList, setRegionList] = useState([]);
	const [provinceList, setProvinceList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [barangayList, setBarangayList] = useState([]);

    const [disabledEmailField, setDisabledEmailField] = useState(false);

    const [formPage, setFormPage] = useState(0);

	const host = url.hostPort();
    const headers = {
        'Authorization': `Bearer ${token()}`,
        
    };

    const [designationOptions, setDesignation] = useState([]);
    const [sexList, setSexList] = useState([]);
	const [requirementList, setRequirementList] = useState([]);
	const [transformedRequirementList, setTransformedRequirementList] = useState([]);
	const [businessClassificationList, setBusinessClass] = useState([]);
	const [proofList, setProofList] = useState([]);

    const [passportDatasheet, setPassport] = useState({});
    
    const [oicDetails, setOIC] = useState({});

    const [firstPageValue, setFirstPageValue] = useState({
        'isTransition': false,
        'designation': '',
        'lastName': '',
        'firstName': '',
        'suffix': '',
        'middleName': '',
        'citizenship': 0,
        'others': '',
        'contactno': '',
        'sex': '',
        'birthDate': '',
        'placeOfBirth': '',
        'email': '',
        'oicRequirement': '',
        'oicRequirementFile': undefined,
        'oicPassportIdentifier': '',
        'oicPassportRequirement': '',
        'oicPassportRequirementFile': undefined,
	});

    const [secondPageValue, setSecondPageValue] = useState({
		'startupName': '',
		'businessName': '',
		'businessLogo': '',
        'businessLogoFile': undefined,
		'businessClassification': '',
		'registrationNo': '',
		'proof': '',
        'proofFile': undefined,
        'proofIdentifier': '',
		'foundingYear': '',
        'region': '',
        'regionName': '',
        'province': '',
        'provinceName': '',
        'city': '',
        'cityName': '',
        'barangay': '',
        'barangayName': '',
        'streetNo': '',
        'lotBlockUnit': '',
        'zipCode': '',
        'companyEmail': '',
        'companyContactNumber': '',
        'website': '',
        'linkedIn': '',
        'facebook': '',
        'twitter': '',
        'instagram': '',
        'youtube': '',
    });

    const [thirdPageValue, setThirdPageValue] = useState({
        'description': '',
        'problemSolved': '',
        'idea': '',
        'usp': ''
    });

    const getRegion = () => {
        const params = {
            sort: {
                region_code: 1
            }
        }
		const endpoint = `/api/region/search`;
		const api = host + endpoint;
		if(regionList.length == 0){
			axios.post(api, params, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.data;
                data = data.filter(function(item){ return item.region_psgc !== "180000000"});
				setRegionList(data);
			}, error => {
			});
		}
	};

    const getProvince = (value) =>{
        const params = {
            region_psgc: value,
            sort: {
                province_name: 1
            }
        }
		if(value != ""){
			const endpoint = `/api/province/search`;
			const api = host + endpoint;
			axios.post(api, params, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.data;
				setProvinceList(data);
			}, error => {
			})
		}
	};

    const getCity = (value) => {
        const params = {
            province_psgc: value,
            sort: {
                city_municipality_name: 1
            }
        }
		if(value != ""){
			const endpoint = `/api/city-municipality/search`;
			const api = host + endpoint;
			axios.post(api, params, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.data;
				setCityList(data);
			}, error => {
			})
		}
	};

    const getBarangay = (value) => {
        const params = {
            city_municipality_psgc: value,
            sort: {
                barangay_name: 1
            }
        }
		if(value != ""){
			const endpoint = `/api/barangay/search`;
			const api = host + endpoint;
			axios.post(api, params, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.data;
				setBarangayList(data);
			}, error => {
			})
		}
	};

    const [requirementLabel, setRequirmentLabel] = useState('Government-Issued ID (for Filipino OIC)');
	const setRequirmentLabelHandler = (value) => {
		setRequirmentLabel(value);
	};

    const [isOfficerInCharge, setOfficerInCharge] = useState(false);
	const setOfficerInChargeHandler = () => {
		setOfficerInCharge(!isOfficerInCharge);
	};

    const [citizenshipStatus, setStatus] = useState(0);
	const citizenshipHandler = (value) =>{
		setStatus(value);
        if (value == 0) {
            setRequirmentLabel('Government-Issued ID (for Filipino OIC)');
        }
	};

    const [sexStatus, setSex] = useState("");
    const sexHandler = (value) => {
        console.log("asdadasda", value);
        setSex(value);
    };

    const [isOICClicked, setIsOICClicked] = useState(false);
    const [isTransition, setTransition] = useState(false);

    const onClickOfficerInCharge = (value, setFieldValue, touched, errors) => {
      
        let citizenship = personalInfo.citizenship.toLowerCase();
       
		if(value){
			setFieldValue('lastName', personalInfo.lastName);
			setFieldValue('firstName', personalInfo.firstName);
			setFieldValue('suffix', personalInfo.suffix);
			setFieldValue('middleName', personalInfo.middleName);
			setFieldValue('contactno', personalInfo.contactNo);
			setFieldValue('citizenship', citizenship == 'filipino' ? 0 : 1);
			setFieldValue('others', citizenship == 'filipino' ? '' : personalInfo.citizenship);
			citizenshipHandler(citizenship == 'filipino' ? 0 : 1);
			setRequirmentLabelHandler(citizenship == 'filipino' ? 'Government-Issued ID (for Filipino OIC)' : 'Other documents to support eligibity to engage in business in the Philippines');
		
            setFieldValue('email', user.email);
            setFieldValue('sex', (typeof personalInfo.sex === "string" ? personalInfo.sex : personalInfo.sex._id));
            setFieldValue('birthDate', moment(personalInfo.birthDate).format("YYYY-MM-DD"));
            setFieldValue('placeOfBirth', personalInfo.birthPlace);
            sexHandler((typeof personalInfo.sex === "string" ? personalInfo.sex : personalInfo.sex._id));

			touched.lastName = false;
			touched.firstName = false;
			touched.suffix = false;
			touched.middleName = false;
            touched.contactno = false;
            touched.email = false;
            touched.birthDate = false;
            touched.placeOfBirth = false;
            setDisabledEmailField(true);
            if(citizenship === 'filipino'){
                setIsOICClicked(true);
                setFieldValue('citizenship', 0);
                if(oicDetails.supportDocument.hasOwnProperty('_id')){
                    let supportDocument = oicDetails.supportDocument;
                    let requirement = supportDocument.requirement;
                    if(requirement.name !== "School ID"){
                        setFieldValue('isTransition', true);
                        setFieldValue('oicRequirement', requirement._id);
                        setFieldValue('oicRequirementFile', supportDocument);
                        setTransition(true);
                    }else{
                        setFieldValue('isTransition', false);
                    }
                }else{
                    setFieldValue('isTransition', false);
                }
            }else{
                setFieldValue('citizenship', 1);
                if(oicDetails.passportDatasheet.hasOwnProperty('_id')){
                    let passportDatasheet = oicDetails.passportDatasheet;
                    setFieldValue('isTransition', true);
                    setFieldValue('oicPassportRequirementFile', passportDatasheet);
                    setTransition(true);
                }else{
                    setFieldValue('isTransition', false);
                }
            }
		}else{
			setFieldValue('lastName', '');
			setFieldValue('firstName','');
			setFieldValue('suffix', '');
			setFieldValue('middleName', '');
			setFieldValue('contactno', '');
			setFieldValue('citizenship', 0);
			setFieldValue('others', '');
            setFieldValue('contactno', '');
            setFieldValue('email', '');
            setFieldValue('sex', "");
            setFieldValue('birthDate', '');
            setFieldValue('placeOfBirth', '');

			citizenshipHandler(0);
            sexHandler("");
            if(citizenship === 0){
                setFieldValue('oicRequirement', '');
                setFieldValue('oicRequirementIdentifier', '');
            }
            setFieldValue('isTranstion', false);
            setIsOICClicked(false);
		}
		setFieldValue('isOIC', value);
	};

    const showOthers = (touched, errors, values) => {
		return <div className="form-group">
			<Field disabled={isOfficerInCharge} value={values.others || ''} type="text" name="others" className={`form-control form-control-sm ${touched.others && errors.others ? "is-invalid" : ""}`} />
			<ErrorMessage component="div" name="others" className="invalid-feedback" />
		</div>
	};

    const firstSubmit = (values) => {
		window.scrollTo(0, 0);
        setFirstPageValue(values);
        setFormPage(1);
    };

    const secondSubmit = (values) => {
		window.scrollTo(0, 0);
        setSecondPageValue(values);
        setFormPage(2);
    }

    const openConsent = (values) => {
        setThirdPageValue(values);
        setShowModal(!showModal);
    };

    const back = (page) => {
        if(page === 0){
            history.push('/dashboard');
        }else if(page === 1){
            firstPageValue.oicRequirementFile = undefined;
            firstPageValue.oicPassportRequirement = undefined;
            setFormPage(0);
        }else{
            secondPageValue.businessLogoFile = undefined;
            secondPageValue.proofFile = undefined;
            setFormPage(1);
        }
    };

    const [registrationLabel, setRegistrationLabel] = useState("");
    const setRegistrationLabelHandler = (name) => {
        setRegistrationLabel(name);
        if(name.includes('Sole')){
            setRegistrationLabel('DTI Certificate of Registration (For Sole Proprietorship)');
        }else if(name.includes('Partnership') || name.includes('Corporation') || name.includes('Venture') || name.includes('Association')){
            setRegistrationLabel('SEC Certificate of Registration (For Partnership, One Person Corporation, Joint Venture, and Association)');
        }else if(name.includes('Cooperative')){
            setRegistrationLabel('CDA Certificate of Registration (For Cooperative)');
        }else if(name.includes('Other')){
            setRegistrationLabel("Mayor's Permit (For NECs)");
        }
	};

    const checkRegistration = () => {
		const endpoint = `/api/startup/registration/check?_id=${user._id}`;
		const api = host + endpoint;
        axios.get(api, {headers: headers}).then(res => {
            let result = res.data;
            if(result.isVerified !== null){
                if(result.isVerified){
                    const errorMessage = "PSDP verification is already created.";
                    toast.error(errorMessage, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });
                    history.push('/dashboard');
                }else{
                    if(result.startupType === 2){
                        getOIC(result.exist);
                    }else{
                        const errorMessage = "PSDP verification is for startup account only.";
                        toast.error(errorMessage, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });
                        history.push('/dashboard');
                    }
                }
            }else{
                const errorMessage = "Create Startup account first.";
                toast.error(errorMessage, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
                // history.push('/dashboard');
            }
        }, error => {
        });
    }

    const getOIC = (exist) => {
		const endpoint = `/api/startup/oic/view?_id=${user._id}`;
		const api = host + endpoint;
        axios.get(api, {headers: headers}).then(res => {
            const result = res.data;
            const data = result.data;
            if(exist){
                setOIC(data);
            }else{
                setOIC(null);
            }
        });
    };

    const getDesignation = () => {
        const params = {
            "isActive": true,
            sort: {
                name: 1
            }
        }
		const endpoint = `/api/designation/search`;
		const api = host + endpoint;
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            setDesignation(data);
        }, error => {
        });
    };

    const getSex = () => {
        const params = {
            "isActive": true,
            sort: {
                name: -1
            }
        }
		const endpoint = `/api/sex/search`;
		const api = host + endpoint;
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            setSexList(data);
        }, error => {
        });
    };

    const getRequirements = () => {
        const params = {
            "isActive": true,
            sort: {
                name: 1
            }
        }
		const endpoint = `/api/requirement/search`;
		const api = host + endpoint;
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            let filter = data.filter(item => {return item.name !== "School ID"});
            let passport = data.filter(item => { return item.name == "Passport Datasheet"});
            filter = filter.filter(item => {return item.name !== "Passport Datasheet"});
            let defaultList = filter.filter(item => {return item.type === 1});
            setPassport(passport[0]);
            setTransformedRequirementList(defaultList);
            setRequirementList(filter);
        }, error => {
        });
    };

    const getBusinessClass = () => {
        const params = {
            "isActive": true,
            sort: {
                name: 1
            }
        }
		const endpoint = `/api/businessClassification/search`;
		const api = host + endpoint;
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            data.push(data.splice(4, 1)[0]);
            setBusinessClass(data);
        }, error => {
        });
    };

    const getBusinessReg = () => {
        const params = {
            "isActive": true,
            sort: {
                name: 1
            }
        }
		const endpoint = `/api/businessRegistration/search`;
		const api = host + endpoint;
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            setProofList(data);
        }, error => {
        });
    };
    
	useEffect( () => {
		if(citizenshipStatus == 0){ //filipino
            let data = requirementList.filter(item => { return item.type == 1});
            setTransformedRequirementList(data);
		}else{
            let data = requirementList.filter(item => { return item.type == 2});
            data.push(data.splice(1, 1)[0]);
            setTransformedRequirementList(data);
		}
	}, [citizenshipStatus]);

    useEffect(() => {
        getRegion();
        getBusinessReg();
        getBusinessClass();
        getRequirements();
        getSex();
        getDesignation();
        checkRegistration();
	}, []);
    
    const [showModal, setShowModal] = useState(false);
    const showModalHandler = () => {
        setShowModal(!showModal);
    };

    const showLoadingFn = () => {
        showLoading();
    };

    const hideLoadingFn = () => {
        hideLoading();
    };

	return (
		<React.Fragment>
            {loader}
			<Header isLoggedIn={isLoggedIn} />
            <ConsentFormComponent 
                showModal={showModal} 
                showModalHandler={showModalHandler}
                oic={firstPageValue}
                businessDetails={secondPageValue}
                businessDescription={thirdPageValue}
                passportDatasheet={passportDatasheet}
                _id={user._id}
                loader={loader} 
                showLoading={showLoadingFn} 
                hideLoading={hideLoadingFn}
                pageModule={pageModule}
            />
			<div className="mainRegistration-container mt-4">
				<div className="container">
                    {formPage === 0 ?
                        <div className="row border bg-white">
                            <div className="col-md-8 col-sm-12 form-align mb-5">
                                <h4 className="p-t-25">Officer-in-Charge Information (CEO/Founder/President/Owner)</h4>
                                <hr></hr>
                                <div className="clearfix">&nbsp;</div>
                                <Formik
                                    validationSchema={firstPageRegistrationSchema}
                                    initialValues={Object.assign(firstPageValue)}
                                    onSubmit={firstSubmit}
                                    key="firstForm"
                                >
                                    {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values, field }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <div className="text-left">
                                                <div className="form-group">
                                                    <label htmlFor="designation" ><strong>Designation*</strong></label>
                                                    <select name="designation"
                                                        className={`form-control ${touched.designation && errors.designation ? "is-invalid" : ""}`}
                                                        value={values.designation || ''}
                                                        onChange={val => { 
                                                            const selectedIndex = val.target.options.selectedIndex; 
                                                            let designationId = val.target.options[selectedIndex].getAttribute('value');
                                                            setFieldValue('designation', designationId);
                                                        }}
                                                    >
                                                        <option name="designation_" key="3" value="" label="" />
                                                        {designationOptions.map((value, index) => {
                                                            return <option value={value._id} name={'designation_' + index} key={'designation_' + value._id} label={value.name} />
                                                        })}
                                                    </select>
                                                    <ErrorMessage component="div" name="designation" className="invalid-feedback" />
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="officerInCharge"
                                                    onChange={event => {
                                                        setOfficerInChargeHandler();
                                                    }}
                                                    onClick={event => {
                                                        let checked = event.target.checked;
                                                        onClickOfficerInCharge(checked, setFieldValue, touched, errors);
                                                    }}
                                                    checked={isOfficerInCharge === true}
                                                    />
                                                    <label className="form-check-label" htmlFor="officerInCharge">
                                                        I am the Officer-in-Charge. Use my personal information to
                                                        fill out this section.
                                                    </label>
                                                    <div className="form-group">
                                                        <label htmlFor="lastName"><b>Last Name*</b></label>
                                                        <Field disabled={isOfficerInCharge} type="text" value={values.lastName || ''} name="lastName" autoComplete="off" className={`form-control ${touched.lastName && errors.lastName ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="lastName" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="firstName"><b>First Name*</b></label>
                                                        <Field disabled={isOfficerInCharge} type="text" value={values.firstName || ''} name="firstName" autoComplete="off" className={`form-control ${touched.firstName && errors.firstName ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="firstName" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="suffix"><b>Suffix</b></label>
                                                        <Field disabled={isOfficerInCharge} type="text" value={values.suffix || ''} name="suffix" autoComplete="off" className={`form-control ${touched.suffix && errors.suffix ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="suffix" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="middleName"><b>Middle Name</b></label>
                                                        <Field disabled={isOfficerInCharge} type="text" value={values.middleName || ''} name="middleName" autoComplete="off" className={`form-control ${touched.middleName && errors.middleName ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="middleName" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="sex"><b>Sex (Assigned at birth)*</b></label>
                                                        <Field as="radio" name="sex" className={`${touched.sex && errors.sex ? "is-invalid" : ""}`}>
                                                        {sexList.map((item, index) => {
                                                            return (
                                                            <div key={`sex_${item._id}`} className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                disabled={isOfficerInCharge}
                                                                type="radio"
                                                                id={`sex_${index}`}
                                                                value={item._id}
                                                                checked={sexStatus === item._id}
                                                                onChange={event => {
                                                                }}
                                                                onClick={(e) => {
                                                                    sexHandler(item._id);
                                                                    setFieldValue("sex", item._id);
                                                                }}
                                                                />
                                                                <label className="form-check-label" htmlFor={`sex_${index}`}>{item.name}</label>
                                                            </div> 
                                                            )
                                                        })}
                                                        </Field>
                                                        <ErrorMessage component="div" name="sex" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="birthDate"><b>Birth Date*</b></label>
                                                        <Field
                                                        disabled={isOfficerInCharge}
                                                        type="date"
                                                        name="birthDate"
                                                        autoComplete="off"
                                                        className={`form-control ${
                                                            touched.birthDate && errors.birthDate
                                                            ? "is-invalid"
                                                            : ""
                                                        }`}
                                                        />
                                                        <ErrorMessage
                                                        component="div"
                                                        name="birthDate"
                                                        className="invalid-feedback display-block"
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="placeOfBirth"><b>Place of Birth*</b></label>
                                                        <Field
                                                        disabled={isOfficerInCharge}
                                                        type="text"
                                                        name="placeOfBirth"
                                                        autoComplete="off"
                                                        className={`form-control ${
                                                            touched.placeOfBirth && errors.placeOfBirth
                                                            ? "is-invalid"
                                                            : ""
                                                        }`}
                                                        />
                                                        <ErrorMessage
                                                        component="div"
                                                        name="placeOfBirth"
                                                        className="invalid-feedback"
                                                        />
                                                    </div>
                                                    <label htmlFor="citizenship"><b>Citizenship*</b></label>
                                                    <div className="form-check">
                                                        <Field disabled={isOfficerInCharge} id="filipino" className="form-check-input" type="radio" name="filipino" value="0"
                                                            checked={citizenshipStatus === 0} 
                                                            onClick={event => {
                                                                citizenshipHandler(0);
                                                                let value = event.target.value;
                                                                if(value != citizenshipStatus){
                                                                    setFieldValue('oicRequirement', '');
                                                                    setFieldValue('oicRequirementFile', '');
                                                                    setFieldValue('oicRequirementIdentifier', '');
                                                                    
                                                                    setFieldValue('oicPassportRequirement', '');
                                                                    setFieldValue('oicPassportRequirementFile', '');
                                                                }
                                                                setFieldValue("citizenship", 0);
                                                            }}
                                                            onChange={event => {
                                                                setRequirmentLabelHandler('Government-Issued ID (for Filipino OIC)');
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="filipino">Filipino </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <Field disabled={isOfficerInCharge} id="others" className="form-check-input" type="radio" name="others" value="1" 
                                                            checked={citizenshipStatus === 1}
                                                            onClick={event => {
                                                                citizenshipHandler(1);
                                                                let value = event.target.value;
                                                                if(value != citizenshipStatus){
                                                                    setFieldValue('oicRequirement', '');
                                                                    setFieldValue('oicRequirementFile', '');
                                                                    setFieldValue('oicRequirementIdentifier', '');
                                                                    
                                                                    setFieldValue('oicPassportRequirement', '');
                                                                    setFieldValue('oicPassportRequirementFile', '');
                                                                }
                                                                setFieldValue("citizenship", 1);
                                                            }}
                                                            onChange={event => {
                                                                setRequirmentLabelHandler('Other documents to support eligibity to engage in business in the Philippines');
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="others">Others </label>
                                                        { citizenshipStatus === 1 ? showOthers(touched, errors, values) : null }
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <label htmlFor="contactno"><b>Contact Number*</b></label>
                                                        <Field disabled={isOfficerInCharge} type="text" value={values.contactno || ''} name="contactno" autoComplete="off" className={`form-control ${touched.contactno && errors.contactno ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="contactno" className="invalid-feedback" />
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <label htmlFor="email"><b>Email Address*</b></label>
                                                        <Field disabled={disabledEmailField && isOfficerInCharge} type="text" value={values.email || ''} name="email" autoComplete="off" className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="email" className="invalid-feedback" />
                                                    </div>
                                                    
                                                    <div className="form-group" hidden={citizenshipStatus === 0 ? true : false}>
                                                        <label htmlFor="oicPassportRequirementFile"><b>Passport Datasheet (for Non-Filipino OIC)*</b></label>
                                                        <div className="m-t-5"></div>
                                                        <label hidden={!isTransition}>Current File: <span className="badge bg-info">{isTransition ? oicDetails.passportDatasheet.name : "N/A"}</span></label>  
                                                        <p><strong>NOTE:</strong> Please upload a scanned copy of your <strong>Passport Datasheet</strong>. Maximum upload file size: 12 MB.</p>
                                                        <ImageUploaderField accept="image/*,application/pdf" name="oicPassportRequirementFile" type="file" value={undefined}
                                                            className={`form-control-file ${touched.oicPassportRequirementFile && errors.oicPassportRequirementFile ? "is-invalid" : ""}`}
                                                            onChange={value => {
                                                                setFieldValue("oicPassportRequirementFile", value.currentTarget.files[0]);
                                                            }}
                                                        />
                                                        <ErrorMessage component="div" name="oicPassportRequirementFile" className="invalid-feedback" />
                                                    </div>
                                                    
                                                    <div className="form-group" >
                                                        <label htmlFor="oicRequirement"><b>{requirementLabel}*</b></label>
                                                        <select name="oicRequirement"
                                                            className={`form-control ${touched.oicRequirement && errors.oicRequirement ? "is-invalid" : ""}`}
                                                            value={values.oicRequirement || ''}
                                                            onChange={val => { 
                                                                const selectedIndex = val.target.options.selectedIndex; 
                                                                let code = val.target.options[selectedIndex].getAttribute('name');
                                                                code = code.replace("oicRequirement_", "");
                                                                let label = val.target.options[selectedIndex].getAttribute('label');
                                                                setFieldValue('oicRequirement', code);
                                                                setFieldValue('oicRequirementIdentifier', label);
                                                                if(code !== ""){
                                                                    touched.oicRequirement = false;
                                                                }else{
                                                                    touched.oicRequirement = true;
                                                                }
                                                            }}
                                                        >
                                                            <option name="oicRequirement_" key="3" value="" />
                                                            {transformedRequirementList.map((value, index) => {
                                                                return <option value={value._id} name={'oicRequirement_' + value._id} key={'oicRequirement_' + index} label={value.name} />
                                                            })}
                                                        </select>
                                                        <ErrorMessage component="div" name="oicRequirement" className="invalid-feedback" />
                                                    </div>
                                                    
                                                    <label hidden={!isOfficerInCharge && (isOICClicked && citizenshipStatus === 1 ? false : true) || citizenshipStatus == 1}>Current File: <span className="badge bg-info">{oicDetails.supportDocument !== undefined ? oicDetails.supportDocument.name :  "N/A"}</span></label>  

                                                    <div className="form-group" hidden={citizenshipStatus === 0 ? true : false}>
                                                        <p><strong>NOTE:</strong> Please upload a scanned copy of your <strong>selected document</strong>. Maximum upload file size: 12 MB.</p>
                                                        <ImageUploaderField accept="image/*,application/pdf" name="oicRequirementFile" type="file" value={undefined}
                                                            className={`form-control-file ${touched.oicRequirementFile && errors.oicRequirementFile ? "is-invalid" : ""}`}
                                                            onChange={value => {
                                                                setFieldValue("oicRequirementFile", value.currentTarget.files[0]);
                                                            }}
                                                            
                                                        />
                                                        <ErrorMessage component="div" name="oicRequirementFile" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={citizenshipStatus === 1}>
                                                        <p><strong>NOTE:</strong> Please upload a scanned copy of your <strong>selected government-issued ID</strong>. Maximum upload file size: 12 MB.</p>
                                                        <ImageUploaderField accept="image/*,application/pdf" name="oicRequirementFile" type="file" value={undefined}
                                                            className={`form-control-file ${touched.oicRequirementFile && errors.oicRequirementFile ? "is-invalid" : ""}`}
                                                            onChange={value => {
                                                                setFieldValue("oicRequirementFile", value.currentTarget.files[0]);
                                                            }}
                                                            
                                                        />
                                                        <ErrorMessage component="div" name="oicRequirementFile" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="py-4 text-center mt-4">
                                                    <button type="button" className="startup-btn-main mx-3" onClick={() => { back(0)}}>Back</button>
                                                    <button type="submit" className="startup-btn-main">Next</button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    :
                    formPage === 1 ?
                        <div className="row border bg-white">
                            <div className="col-md-8 col-sm-12 form-align">
                                <h4 className="p-t-25">Business Information</h4>
                                <hr></hr>
                                <div className="clearfix">&nbsp;</div>
                                <Formik
                                    validationSchema={secondPageRegistrationSchema}
                                    initialValues={Object.assign(secondPageValue)}
                                    onSubmit={secondSubmit}
                                    validateOnBlur={true}
                                    validateOnChange={false}
                                    key="secondForm"
                                >
                                    {({ handleSubmit, handleBlur, handleChange, setFieldValue, validateField, validateOnBlur, errors, touched, values, field }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <div className="text-left">
                                                <div className="form-group">
                                                    <label htmlFor="startupName"><strong>Startup Name*</strong></label>
                                                    <Field type="text" validate={validateStartupName} value={values.startupName || ''} name="startupName" autoComplete="off" className={`form-control ${touched.startupName && errors.startupName ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="startupName" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="businessName"><strong>Registered Business / SEC / CDA Name*</strong></label>
                                                    <Field type="text" validate={validateBusinessName} value={values.businessName || ''} name="businessName" autoComplete="off" className={`form-control ${touched.businessName && errors.businessName ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="businessName" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="businessLogoFile"><b>Business Logo (Optional)</b></label>
                                                    <p><strong>NOTE:</strong> Please upload a scanned copy of your <strong>business logo</strong>. Maximum upload file size: 12 MB.</p>
                                                    <ImageUploaderField accept="image/*" name="businessLogoFile" type="file" value={undefined}
                                                        className={`form-control-file ${touched.businessLogoFile && errors.businessLogoFile ? "is-invalid" : ""}`}
                                                        onChange={value => {
                                                            setFieldValue("businessLogoFile", value.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    <ErrorMessage component="div" name="businessLogoFile" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group"> 
                                                    <label><strong>Business Classification*</strong></label>
                                                    <Field as="radio" name="businessClassification" className={`${touched.businessClassification && errors.businessClassification ? "is-invalid" : ""}`}>
                                                        {businessClassificationList.map((value, index) => {
                                                            return (
                                                                <div key={`businessClassification_${value._id}`} className="form-check">
                                                                    <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id={`radioButton_${value._id}`}
                                                                    value={value._id}
                                                                    checked={ values.businessClassification ? values.businessClassification == value._id : false }
                                                                    onChange={event => {
                                                                    }}
                                                                    onClick={(e) => {
                                                                        setFieldValue("businessClassification", value._id);
                                                                        setRegistrationLabelHandler(value.name);
                                                                    }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`radioButton_${value._id}`}>{value.name}</label>
                                                                </div>
                                                            )
                                                        })}
                                                    </Field>
                                                    <ErrorMessage component="div" name="businessClassification" className="invalid-feedback" />
                                                    {/* {businessClassificationList.map((value, index) => {
                                                        return 	<div className="form-check" key={`stage_${value._id}`}>
                                                            <Field id={`radioButton_${value._id}`} className="form-check-input" type="radio" name={`businessClassification_${value.name}`} value={value.id}
                                                                checked={ values.businessClassification ? values.businessClassification == value._id : false }
                                                                onClick={event => {
                                                                    setFieldValue("businessClassification", value._id);
                                                                    setRegistrationLabelHandler(value.name);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor={`radioButton_${value._id}`}>{value.name}</label>
                                                            <ErrorMessage component="div" name="businessClassification" className="invalid-feedback" />
                                                        </div>
                                                    })} */}
                                                </div>
                                                <div className="form-group">
                                                    <label><strong>Registration/Certificate/Permit No.*</strong></label>
                                                    <div className="form-group">
                                                        <label htmlFor="registrationNo">{registrationLabel}</label>
                                                        <Field type="text" validate={validateRegistrationNo} value={values.registrationNo || ''} name="registrationNo" autoComplete="off" className={`form-control ${touched.registrationNo && errors.registrationNo ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="registrationNo" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="proof"><b>Proof of registration or government accreditation*</b></label>
                                                    <select name="proof"
                                                        className={`form-control ${touched.proof && errors.proof ? "is-invalid" : ""}`}
                                                        value={values.proof || ''}
                                                        onChange={val => { 
                                                            const selectedIndex = val.target.options.selectedIndex; 
                                                            let code = val.target.options[selectedIndex].getAttribute('name');
                                                            code = code.replace("proof_", "");
                                                            let label = val.target.options[selectedIndex].getAttribute('label');
                                                            setFieldValue('proof', code);
                                                            setFieldValue('proofIdentifier', label);
                                                        }}
                                                    >
                                                        <option name="proof_" key="3" value="" label="" />
                                                        {proofList.map((value, index) => {
                                                            return <option value={value._id} name={'proof_' + value._id} key={'proof_' + index} label={value.name} />
                                                        })}
                                                    </select>
                                                    <ErrorMessage component="div" name="proof" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <p><strong>NOTE:</strong> Please upload a scanned copy of your <strong>selected proof of registration or government accreditation</strong>. Maximum upload file size: 12 MB</p>
                                                    <ImageUploaderField accept="image/*,application/pdf" name="proofFile" type="file" value={undefined}
                                                        className={`form-control-file ${touched.proofFile && errors.proofFile ? "is-invalid" : ""}`}
                                                        onChange={value => {
                                                            setFieldValue("proofFile", value.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    <ErrorMessage component="div" name="proofFile" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="foundingYear"><strong>Year of Founding/Registration (Optional)</strong></label>
                                                    <Field type="text" value={values.foundingYear || ''} name="foundingYear" autoComplete="off" className={`form-control w-25 ${touched.foundingYear && errors.foundingYear ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="foundingYear" className="invalid-feedback" />
                                                </div>

                                                <div className="form-group">
                                                    <label><strong>Business Address</strong></label>
                                                    <div className="border-left pl-3">
                                                        <div className="form-group">
                                                            <label htmlFor="region">Region*</label>
                                                            <select name="region"
                                                                value={values.region || ''}
                                                                className={`form-control ${touched.region && errors.region ? "is-invalid" : ""}`}
                                                                onChange={val => { 
                                                                    const selectedIndex = val.target.options.selectedIndex;
                                                                    let code = val.target.options[selectedIndex].getAttribute('value');
                                                                    let region = val.target.options[selectedIndex].getAttribute('label');
                                                                    setFieldValue('region', code);
                                                                    setFieldValue('regionName', region);

                                                                    setProvinceList([]);
                                                                    setCityList([]);
                                                                    setBarangayList([]);
                                                                    getProvince(code);
                                                                }}
                                                            >
                                                                <option name="region_" key="0" value="" label="" />
                                                                {regionList.map((value, index) => {
                                                                    return <option value={value.region_psgc} name={'region_' + value.region_psgc} key={'region_' + index} label={value.region_code + ' - ' +value.region_name} />
                                                                })}
                                                            </select>
                                                            <ErrorMessage component="div" name="region" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="province">Province*</label>
                                                            <select name="province"
                                                                className={`form-control ${touched.province && errors.province ? "is-invalid" : ""}`}
                                                                value={values.province || ''}
                                                                onChange={val => { 
                                                                    const selectedIndex = val.target.options.selectedIndex; 
                                                                    let code = val.target.options[selectedIndex].getAttribute('name');
                                                                    code = code.replace("province_", "");
                                                                    let province = val.target.options[selectedIndex].getAttribute('label');
                                                                    setFieldValue('province', code);
                                                                    setFieldValue('provinceName', province);

                                                                    setCityList([]);
                                                                    setBarangayList([]);
                                                                    getCity(code);
                                                                }}
                                                            >
                                                                <option name="province_" key="1" value="" label="" />
                                                                {provinceList.map((value, index) => {
                                                                    return <option value={value.province_psgc} name={'province_' + value.province_psgc} key={'province_' + value.province_psgc} label={value.province_name} />
                                                                })}
                                                            </select>
                                                            <ErrorMessage component="div" name="province" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="city">City/Municipality*</label>
                                                            <select name="city"
                                                                className={`form-control ${touched.city && errors.city ? "is-invalid" : ""}`}
                                                                value={values.city || ''}
                                                                onChange={val => { 
                                                                    const selectedIndex = val.target.options.selectedIndex; 
                                                                    let code = val.target.options[selectedIndex].getAttribute('name');
                                                                    code = code.replace("city_", "");
                                                                    let city = val.target.options[selectedIndex].getAttribute('label');
                                                                    setFieldValue('city', code);
                                                                    setFieldValue('cityName', city);

                                                                    setBarangayList([]);
                                                                    getBarangay(code);
                                                                }}
                                                            >
                                                                <option name="city_" key="2" value="" label="" />
                                                                {cityList.map((value, index) => {
                                                                    return <option value={value.city_municipality_psgc} name={'city_' + value.city_municipality_psgc} key={'city_' + value.city_municipality_psgc} label={value.city_municipality_name} />
                                                                })}
                                                            </select>
                                                            <ErrorMessage component="div" name="city" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="barangay">Barangay*</label>
                                                            <select name="barangay"
                                                                className={`form-control ${touched.barangay && errors.barangay ? "is-invalid" : ""}`}
                                                                value={values.barangay || ''}
                                                                onChange={val => { 
                                                                    const selectedIndex = val.target.options.selectedIndex; 
                                                                    let code = val.target.options[selectedIndex].getAttribute('name');
                                                                    code = code.replace("barangay_", "");
                                                                    let barangay = val.target.options[selectedIndex].getAttribute('label');
                                                                    setFieldValue('barangay', code);
                                                                    setFieldValue('barangayName', barangay);
                                                                }}
                                                            >
                                                                <option name="barangay_" key="3" value="" label="" />
                                                                {barangayList.map((value, index) => {
                                                                    return <option value={value.barangay_psgc} name={'barangay_' + value.barangay_psgc} key={'barangay_' + value.barangay_psgc} label={value.barangay_name} />
                                                                })}
                                                            </select>
                                                            <ErrorMessage component="div" name="barangay" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="streetNo">Street No., Village, Subdivision etc.</label>
                                                            <Field type="text" name="streetNo" autoComplete="off" className={`form-control ${touched.streetNo && errors.streetNo ? "is-invalid" : ""}`} />
                                                            <ErrorMessage component="div" name="streetNo" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="lotBlockUnit">Lot, Block, Unit, Floor No., Etc.</label>
                                                            <Field type="text" name="lotBlockUnit" autoComplete="off" className={`form-control ${touched.lotBlockUnit && errors.lotBlockUnit ? "is-invalid" : ""}`} />
                                                            <ErrorMessage component="div" name="lotBlockUnit" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="zipCode">Zip Code*</label>
                                                            <Field type="text" name="zipCode" autoComplete="off" className={`form-control ${touched.zipCode && errors.zipCode ? "is-invalid" : ""}`} />
                                                            <ErrorMessage component="div" name="zipCode" className="invalid-feedback" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label><strong>Contact Details</strong></label>
                                                    <div className="border-left pl-3">
                                                        <div className="form-group">
                                                            <label htmlFor="companyContactNumber">Company Contact Number*</label>
                                                            <Field type="text" value={values.companyContactNumber || ''} name="companyContactNumber" autoComplete="off" className={`form-control ${touched.companyContactNumber && errors.companyContactNumber ? "is-invalid" : ""}`} />
                                                            <ErrorMessage component="div" name="companyContactNumber" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="companyEmail">Company Email (Optional)</label>
                                                            <Field type="text" value={values.companyEmail || ''} name="companyEmail" autoComplete="off" className={`form-control ${touched.companyEmail && errors.companyEmail ? "is-invalid" : ""}`} />
                                                            <ErrorMessage component="div" name="companyEmail" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="website">Website (Optional)</label>
                                                            <Field type="text" value={values.website || ''} name="website" autoComplete="off" className={`form-control ${touched.website && errors.website ? "is-invalid" : ""}`} />
                                                            <ErrorMessage component="div" name="website" className="invalid-feedback" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label><strong>Social Media (Optional)</strong></label>
                                                    <div className="border-left pl-3">
                                                        <div className="form-group">
                                                            <label htmlFor="linkedIn">LinkedIn</label>
                                                            <Field type="text" value={values.linkedIn || ''} name="linkedIn" autoComplete="off" className={`form-control ${touched.linkedIn && errors.linkedIn ? "is-invalid" : ""}`} />
                                                            <ErrorMessage component="div" name="linkedIn" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="facebook">Facebook</label>
                                                            <Field type="text" value={values.facebook || ''} name="facebook" autoComplete="off" className={`form-control ${touched.facebook && errors.facebook ? "is-invalid" : ""}`} />
                                                            <ErrorMessage component="div" name="facebook" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="twitter">Twitter</label>
                                                            <Field type="text" value={values.twitter || ''} name="twitter" autoComplete="off" className={`form-control ${touched.twitter && errors.twitter ? "is-invalid" : ""}`} />
                                                            <ErrorMessage component="div" name="twitter" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="instagram">Instagram</label>
                                                            <Field type="text" value={values.instagram || ''} name="instagram" autoComplete="off" className={`form-control ${touched.instagram && errors.instagram ? "is-invalid" : ""}`} />
                                                            <ErrorMessage component="div" name="instagram" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="youtube">Youtube</label>
                                                            <Field type="text" value={values.youtube || ''} name="youtube" autoComplete="off" className={`form-control ${touched.youtube && errors.youtube ? "is-invalid" : ""}`} />
                                                            <ErrorMessage component="div" name="youtube" className="invalid-feedback" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-4 text-center mt-4">
                                                    <button type="button" className="startup-btn-main mx-3" onClick={() => { back(1)}}>Back</button>
                                                    <button type="submit" className="startup-btn-main">Next</button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    :
                        <div className="row border bg-white">
                            <div className="col-md-8 col-sm-12 form-align">
                                <div className="text-left">
                                    <h4 className="p-t-25">Description of your Startup Business</h4>
                                    <hr></hr>
                                    <div className="clearfix">&nbsp;</div>
                                    <Formik
                                    validationSchema={thirdPageRegistrationSchema}
                                    initialValues={Object.assign(thirdPageValue)}
                                    onSubmit={openConsent}
                                    key="thirdForm"
                                    >
                                        {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values, field }) => (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor="description"><strong>Brief description of your Startup Business*</strong></label>
                                                    <Field type="text" as="textarea" rows="5" value={values.description || ''} name="description" autoComplete="off" className={`form-control ${touched.description && errors.description ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="description" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="problemSolved"><strong>Problems being solved* </strong>(separate by comma)</label>
                                                    <Field type="text" rows="5" value={values.problemSolved || ''} name="problemSolved" autoComplete="off" className={`form-control ${touched.problemSolved && errors.problemSolved ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="problemSolved" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="idea"><strong>Idea or Product being offered*</strong></label>
                                                    <Field type="text" as="textarea" rows="5" value={values.idea || ''} name="idea" autoComplete="off" className={`form-control ${touched.idea && errors.idea ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="idea" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="usp"><strong>Unique Selling Proposition (USP) of your solution*</strong></label>
                                                    <Field type="text" as="textarea" rows="5" value={values.usp || ''} name="usp" autoComplete="off" className={`form-control ${touched.usp && errors.usp ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="usp" className="invalid-feedback" />
                                                </div>
                                                
                                                <div className="py-4 text-center mt-4">
                                                    <button type="button" className="startup-btn-main mx-3" onClick={() => back(2)}>Back</button>
                                                    <button type="submit" className="startup-btn-main">Next</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    }
				</div>
			</div>
		</React.Fragment>
	);
};
export default PSDPVerification;
