import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "../../dist/css/dashboard.css";
import * as moment from "moment";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import { useHistory } from "react-router-dom";
import DataTable, { defaultThemes } from 'react-data-table-component';
import orderBy from 'lodash/orderBy';
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";

import PageItem from 'react-bootstrap/PageItem'
const customStyles = {
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: defaultThemes.default.divider.default,
      backgroundColor: '#e9ecef'
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};

const customSort = (rows, field, direction) => {
  field = field.toString();
  let transformField = field.match(/'([^']+)'/)[1];
  const handleField = row => {
    if (row[transformField]) {
      return row[transformField].toLowerCase();
    }
    return row[transformField];
  };

  return orderBy(rows, handleField, direction);
};

const ApplicationEvaluationList = (props) => {
  document.title = 'Evaluation List';
  const history = useHistory();

  const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
  const user = props.user !== null ? props.user : {};

  const host = url.hostPort();
  const headers = {
    Authorization: `Bearer ${token()}`,
    
  };

  const rowPerPage = [10, 25, 50, 100];
  const [startupApplicationList, setStartupApplicationList] = useState([]);
  const [startupApplicationListLoading, setStartupApplicationListLoading] = React.useState(true);
  const [startupApplicationListotalRows, setStartupApplicationListTotalRows] = React.useState(0);
  const [startupApplicationGridViewListotalRows, setStartupApplicationGridViewListotalRows] = React.useState(0);
  const [startupApplicationLisPerPage, setStartupApplicationListPerPage] = useState(10);
  const [startupApplicationLisPerPageGrid, setStartupApplicationLisPerPageGrid] = useState(10);
  const [gridActivePageNumber, setGridActivePageNumber] = useState(1);
  const [gridVisibility, setGridVisibility] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [gridCurrentPage, setGridCurrentPage] = useState(1);
  const [gridCurrentMaxPage, setGridCurrentMaxPage] = useState(startupApplicationGridViewListotalRows);

  const onRowClick = async (item) => {
    try {
      const _id = item._id;
      history.push(`/evaluate/startup?_id=${_id}`);
      // let reg_uuid = item.uuid;
      // let user_id = item.user_id;
      // let personal_uuid = item.user.uuid;
      // let registrationId = item.registration_id;
      // let applicantType = item.applicant_type;
      // let startupType;
      // try {
      //   startupType = item.startupInfo[0].startup_type;
      // } catch (ex) {
      //   startupType = '';
      // }
      // let status = item.registration_status;
      // if(applicantType === 4){
      //   let businessName = item.startupInfo[0].business_name;
      //   if(businessName !== null){
      //     history.push(`/evaluate/startup?uuid=${user_id}`);
      //   }else{
      //     let errorMessage = "No PSDP verification application yet.";
      //     toast.error(errorMessage, {
      //       position: "bottom-right",
      //       autoClose: 3000,
      //       hideProgressBar: false,
      //       closeOnClick: false,
      //       pauseOnHover: false,
      //       draggable: false,
      //       progress: undefined,
      //     });
      //   }
      // }else{
      //   history.push(`/evaluate/individual?uuid=${user_id}`);
      // }
    } catch (ex) {
      console.log("exception", ex);
      let errorMessage = "Startup application registration not found.";
      toast.error(errorMessage, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  };

  const startupApplicationColumns = [
    {
      name: 'STARTUP ID',
      selector: row => row['startupId'],
      style: { cursor: 'pointer' },
      sortable: true,
      grow: 3,
    },
    {
      name: 'REGISTERED BUSINESS / SEC / CDA NAME',
      selector: row => row['businessName'],
      style: { cursor: 'pointer' },
      sortable: true,
      grow: 3,
    },
    {
      name: 'NAME',
      selector: row => row['oic'],
      style: { cursor: 'pointer' },
      sortable: true,
      grow: 2,
    },
    {
      name: 'BUSINESS CLASSIFICATION',
      selector: row => row['businessClassification'].name,
      style: { cursor: 'pointer' },
      sortable: true,
      grow: 3
    },
    {
      name: 'DATE OF REGISTRATION',
      selector: row => moment(row['dateCreatedFormatted']).format("MM/DD/YYYY") ,
      style: { cursor: 'pointer' },
      sortable: true,
      grow: 3
    },
    {
      name: 'STATUS',
      selector: row => row['registrationStatus'],
      style: { cursor: 'pointer' },
      sortable: true,
      grow: 1
    },
    {
      name: 'EVALUATOR',
      selector: (row) => { return row['assessedBy'] !== null ? row['assessedBy'] : "N/A"},
      style: { cursor: 'pointer' },
      sortable: true,
      grow: 2
    }
  ];

  const getStartupRegistrationList = async (newPerPage, page) => {
    setStartupApplicationListLoading(true);
    let params = {
      page: page,
      limit: newPerPage,
      sort: {
        dateCreated: -1
      }
    }
    let endpoint = '/api/approval/startup/search';
    let api = host + endpoint;
    const response = await axios.post(api, params, {headers: headers});

    let result = response.data;
    let data = result.data;
    let total = result.total;
    setGridData(data);
    setStartupApplicationListTotalRows(total);
    setStartupApplicationListPerPage(newPerPage);
    setStartupApplicationListLoading(false);
    setStartupApplicationList(data);
  };

  const getStartupRegistrationListGrid = async (startupApplicationLisPerPageGrid, page) => {
    let params = {
      page: page,
      limit: startupApplicationLisPerPageGrid,
      sort: {
        dateCreated: -1
      }
    }
    let endpoint = '/api/approval/startup/search';
    let api = host + endpoint;
    const response = await axios.post(api, params, {headers: headers});
    let result = response.data;
    let data = result.data;
    let total = result.total;

    setGridData(data)
    setStartupApplicationGridViewListotalRows(total);
    setStartupApplicationLisPerPageGrid(startupApplicationLisPerPageGrid);
    setGridActivePageNumber(page);
  };

  const startupHandlePageChange = page => {
    getStartupRegistrationList(startupApplicationLisPerPage, page);
    
  };

  const startupHandlePerRowsChange = async (newPerPage, page) => {
    getStartupRegistrationList(newPerPage, page)
  };

  const startupHandleGridPerRowsChange = (e) => {
    let perPage = parseInt(e.target.value);
    getStartupRegistrationListGrid(perPage, 1)
  };

  const handlePageChange = (e, perpage) => {
    setGridActivePageNumber(e);
    getStartupRegistrationListGrid(perpage, e)
  };

  const callRegistrationGridView = () => {
    setGridVisibility(true);
    getStartupRegistrationListGrid(startupApplicationLisPerPageGrid, 1);
  };

  const gridStatus = (status) => {
    if(status === "New"){
      return <span className="text-info">{status}</span>
    }else if(status === "Updated"){// updated
      return <span className="text-warning">{status}</span>
    }else if(status === "Verified"){
      return <span className="text-success">{status}</span>
    }else if(status === "For Updating"){//for updating
      return <span className="text-warning">{status}</span>
    }else if(status === "Declined"){
      return <span className="text-warning">{status}</span>
    }
  };

  const paginationTextHandler =  () => {
      
      let currentPage = (gridActivePageNumber-1)*startupApplicationLisPerPageGrid+1;
      let currentMaxPage = startupApplicationLisPerPageGrid*gridActivePageNumber;
      if(currentMaxPage >= startupApplicationGridViewListotalRows){
        currentMaxPage = startupApplicationGridViewListotalRows;
      }
      
      let paginationText = `${currentPage}-${currentMaxPage} of ${startupApplicationGridViewListotalRows}`;
      return paginationText;
      
  };

  useEffect(() => {
    getStartupRegistrationList(startupApplicationLisPerPage, 1);
  }, []);

  return (
    <React.Fragment>
      <Header isLoggedIn={isLoggedIn} isAdmin={true} />
      <div className="dashboard-container pt-5 pb-4">
        <div className="container list-container">
          <div className="clearfix mb-2">
            <h5 className="text-left float-left">Startup Registrations</h5>
            <div className="float-right view-buttons">
              <button onClick={() =>setGridVisibility(false)}>
                <i className="bi bi-list-ul"></i>
                <span className={!gridVisibility ? "btn-active-view" : ""}>List View</span>
                </button>
              <button onClick={callRegistrationGridView}>
                <i className="bi bi-grid-3x3-gap"></i>
                <span className={gridVisibility ? "btn-active-view" : ""}>Grid View</span>
                </button>
            </div>
          </div>
          <div className="container startup-table-container" hidden={gridVisibility}>
            <div className="row">
              <div className="col-md-12 mb-2 px-0">
                <DataTable
                  columns={startupApplicationColumns}
                  data={startupApplicationList}
                  progressPending={startupApplicationListLoading}
                  customStyles={customStyles}
                  highlightOnHover
                  striped
                  responsive
                  persistTableHead
                  pagination
                  paginationRowsPerPageOptions={rowPerPage}
                  paginationServer
                  paginationTotalRows={startupApplicationListotalRows}
                  onChangeRowsPerPage={startupHandlePerRowsChange}
                  onChangePage={startupHandlePageChange}
                  onRowClicked={onRowClick}
                  sortFunction={customSort}
                  noDataComponent="There are no records to display."
                />
                {/* <div className="text-right">
                  <a href="#">
                    <strong>See Full List of Startups Applications</strong>
                  </a>
                </div> */}
              </div>
            </div>
          </div>

          {/* Grid view list */}
          <div className="grid-view-list" hidden={!gridVisibility}>
            {/* Filter & Search */}
            <div className="row justify-content-between grid-filter-search" hidden={true}>
              <div className="col-lg-4 col-md-6 py-1">
                <select className="custom-select">
                  <option defaultValue>Filter: All</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div className="col-lg-4 col-md-6 py-1">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                  <div className="input-group-append">
                    <button className="btn startup-btn" type="button">Search</button>
                  </div>
                </div>
              </div>
            </div>
            {/* End of filter & Search */}

            <div className="container">
              <div className="row">
                {gridData.map((value, index) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 p-1 pointer" onClick={(e) => onRowClick(value)} key={`${index}_1`}>
                  {
                    <div className="p-3 border bg-white pointer grid-hover h-100" key={`${index}_2`}>
                      <label className="pointer">{value.startupId}</label>
                      <h5>{value.businessName}</h5>
                      <label className="pointer">{value.oic}</label>
                      <div className="clearfix"></div>
                      <label className="pointer">{value['businessClassification'].name}</label>
                      <div className="clearfix"></div>
                      <h6>{moment(value.dateCreatedFormatted).format("MM/DD/YYYY")}</h6>
                      <div className="clearfix"></div>
                      <label className="pointer">{gridStatus(value.registrationStatus)}</label>
                      <div className="clearfix"></div>
                      <h6>{value.assessedBy !== null ? value.assessedBy : 'N/A'}</h6>
                    </div>    
                  }
                </div>
                 ))}
              </div>

              <hr />
              <div className="gridview-footer d-flex justify-content-between show-entry">
                <div className="d-inline-flex">
                  <label>Show</label>
                  <select className="custom-select custom-select-sm mx-1"  onChange={e => startupHandleGridPerRowsChange(e)} >
                    <option value="10" defaultValue>10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <label>Entries</label>
                </div>
                <div className="pagination-container d-flex">
                    <span className="mr-3">{paginationTextHandler()}</span>
                  <Pagination
                      activePage={gridActivePageNumber}
                      itemsCountPerPage={startupApplicationLisPerPageGrid}
                      totalItemsCount={startupApplicationGridViewListotalRows}
                      pageRangeDisplayed={5}
                      onChange={e => handlePageChange(e ,startupApplicationLisPerPageGrid)} 
                      itemClass="page-item pagination-sm"
                      linkClass="page-link"
                    />
                     
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ApplicationEvaluationList;