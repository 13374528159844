import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import store, { set, get } from "store";
import { Modal } from "react-bootstrap";
import LoadingService from "../../imports/LoadingService";
import ImageUploaderField from '../../imports/RectifyUpload';
import user from '../../store/user';

const userDetails = user() !== null ? user() : {};

const hostPort = url.hostPort();
const auth = {
    'Authorization': `Bearer ${token()}`,
    
};

async function validateBusinessName(value) {
    try{
        let params = {
            "type": "update",
            "field": "businessName",
            "value": value,
            "_id": userDetails._id
        };
        let error;
        let api = `/api/startup/field/check`;
        api = hostPort + api;
        const response = await axios.post(
            api, params, {headers: auth},
        );
        const result = response.data;
        if(result.exist){
            error = `${value} is already taken.`;
            return error;
        }else{
            return;
        }
    }catch(ex){
        return ex;
    }
};

async function validateRegistrationNo(value) {
    try{
        let params = {
            "type": "update",
            "field": "registrationNumber",
            "value": value,
            "_id": userDetails._id
        };
        let error;
        let api = `/api/startup/field/check`;
        api = hostPort + api;
        const response = await axios.post(
            api, params, {headers: auth},
        );
        const result = response.data;
        if(result.exist){
            error = `${value} is already taken.`;
            return error;
        }else{
            return;
        }
    }catch(ex){
        return ex;
    }
};

const registrationSchema = Yup.object().shape({
	businessName:  Yup.string(),
	registrationNumber:  Yup.string(),
	file_name: Yup.string(),
    proofRequirement: Yup.string(),
    proof: Yup.mixed().test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        } 

    })
});

const Rectify = (props) => {    
    document.title = 'Rectify';
    const history = useHistory();
    const formikRef = useRef();
	const host = hostPort;
	const headers = auth;

	const [loader, showLoader, hideLoader] = LoadingService();

    const user = props.user !== null ? props.user : {};
    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;

    const [flaggedItems, setFlaggedItems] = useState([]);
    const [registration, setRegistration] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [submitValue, setSubmitValue] = useState("");

    const [showConfirmationModal, setShowConfirmation] = useState(false);
    const [showSuccessRectifyModal, setShowSuccessRectifyModal] = useState(false);

    const [proofList, setProofList] = useState([]);

    const [rectifyValue, setRectifyValue] = useState({
		'businessName': '',
		'registrationNumber': '',
        'proofRequirement': '',
		'file_name': '',
        'proof': ''
    });

    const [formValue, setFormValue] = useState({
		'businessName': "",
		'registrationNumber': "",
        'proofRequirement': "",
		'file_name': "",
        'proof': ""
	});

    const closeConfirmationModal = () => {
        setShowConfirmation(!showConfirmationModal);
    }
    
    const checkApproval = () => {
        showLoader();
        try{
            let api = host + `/api/v1/registration/approvals?user_id=${user.user_id}&view=all&field_status=2`;
            axios.get(api, { headers: headers }).then(
                (res) => {
                    let result = res.data;
                    let data = result.items;
                    getRegistration(data);
                },(error) => {
                    let responseError = error.response.data;
                    let errorMessage = responseError.message;
                    let message = errorMessage[Object.keys(errorMessage)[0]];
                    let firstError = message[0];
                    toast.error(firstError, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    });
                }
            )
        }catch(ex){
            history.push('/dashboard');
            toast.error("Error on retrieving flagged items.", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            });
        }
    };

    const getRegistration = (flagged) => {
        let api = host + `/api/v1/registrations?user_id=${user.uuid}&expand=requirements.requirement.requirement_category,startupInfo`;
        axios.get(api, {headers: headers}).then(res => {
            hideLoader();
            let result = res.data;
            let data = result.items;
            // console.log(data[0].startupInfo[0].business_name, "data")
            setRegistration(data[0]);
            setRequirements(data[0].requirements);
            setFlaggedItems(flagged);
            for(let x = 0; x < flagged.length; x++){
                let name = flagged[x].field_name;
                let value = flagged[x].field_value;
                formikRef.current.setFieldValue(name, value);
                const oldList = formValue;
                oldList[name] = value;
                setFormValue(oldList);
            }
		}, error => {
		});
    };

    const viewFile = (_id, name) => {
		showLoader();
        let endpoint = `/api/file/download?_id=${_id}`;
        let api = host + endpoint;
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = name;
        axios.get(api, {responseType: "blob", headers: headers}).then((res) => {
            hideLoader();
            let responseHeaders = res.headers;
            let content = Object.values(responseHeaders);
            content = content[0];
            
            //new tab
            try{
                let fileURL = window.URL.createObjectURL(res.data);
                let tab = window.open();
                tab.location.href = fileURL;
            }catch(ex){
                let message = "Please allow pop-up window to view file.";
				toast.error(message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
            }
        }, error => {
            hideLoader();
            console.log("error", error);
        });
    };

    const back = () => {
        history.goBack();
    };

    const submit = (values) => {
        // for(let key in values){
        //     if(key !== "proofRequirement"){
        //         const rectify = values;
        //         rectify[key] = flaggedItems.filter(item => { return item.fieldName === key})[0];
        //         rectify[key] = {...rectify[key], rectify_value: key === 'file_name' ? values.proof : values[key]};
        //         setRectifyValue(rectify);
        //     }else{

        //     }
        // }
        let params = {};
        for(let key in values){
            if(key === "businessName"){
                const find = flaggedItems.find(item => { return item.fieldName === "businessName"});
                if(find !== undefined){
                    params.businessName = values.businessName;
                }
            }
            if(key === "registrationNumber"){
                const find = flaggedItems.find(item => {return item.fieldName === "registrationNumber"});
                if(find !== undefined){
                    params.registrationNumber = values.registrationNumber;
                }
            }
            if(key === "proof"){
                const find = flaggedItems.find(item => { return item.fieldName === "proofOfRegistration"});
                if(find !== undefined){
                    params.proofOfRegistration = values.proof;
                }
            }
        }
        setSubmitValue(values);
        setRectifyValue(params);
        closeConfirmationModal(!showConfirmationModal);
    };

    const submitRectify = async () => {
        showLoader();
        let formData = new FormData();
        const api = host + `/api/startup/registration/rectify?_id=${user._id}`;
        const find = flaggedItems.find(item => {return item.fieldName === "proofOfRegistration"});
        if(find !== undefined){
            formData.append('proofRequirement', submitValue.proofRequirement);
        }
        let flaggedParams = [];
        for(let x = 0; x < flaggedItems.length; x++){
            let item = {};
            if(flaggedItems[x].fieldName === "proofOfRegistration"){
                item = {
                    "_id": flaggedItems[x]._id,
                    "fieldName": flaggedItems[x].fieldName,
                }
                formData.append('proofOfRegistration', submitValue.proof);
            }else{
                let value = "";
                if(flaggedItems[x].fieldName === "businessName"){
                    value = submitValue.businessName;
                }else if(flaggedItems[x].fieldName === "registrationNumber"){
                    value = submitValue.registrationNumber;
                }
                item = {
                    "_id": flaggedItems[x]._id,
                    "fieldName": flaggedItems[x].fieldName,
                    "fieldValue": value
                }
            }
            flaggedParams.push(item);
        }
        const stringParams = JSON.stringify(flaggedParams);
        formData.append('rectify', stringParams);
        axios.post(api, formData, {headers: headers}).then(res => {
            hideLoader();
            closeConfirmationModal(!showConfirmationModal);
            setShowSuccessRectifyModal(true);
        }, error => {
            hideLoader();
            const err = error.response;
            const data = err.data;
            const message = data.message;
            toast.error(message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        })
	};

    const confirmationSubmit = () => {
        submitRectify();
    }

    const myCompanyClick = () => {
        history.push(`/mycompany?_id=${registration.uuid}`);
    };

    const handleCloseAccountCreated = () => {
        setShowSuccessRectifyModal(false);
        history.push('/dashboard');
    };

    const getFlaggedItems = () => {
        showLoader();
        const api = host + `/api/startup/registration/flagged-items?_id=${user._id}`;
        axios.get(api, {headers: headers}).then((res) => {
            hideLoader();
            const result = res.data;
            const items = result.flaggedItems;
            const businessNameIndex = items.findIndex(item => { return item.fieldName === "businessName" });
            const index = items.findIndex(item => { return item.fieldName === "proofOfRegistration" });
            items.push(items.splice(index, 1)[0]);
            items.sort((x,y)=>{ return x.fieldName === "businessName" ? -1 : y.fieldName === "businessName" ? 1 : 0; });
            setFlaggedItems(items);
            

            for(let x = 0; x < items.length; x++){
                let name = items[x].fieldName;
                let value = items[x].fieldValue;
                formikRef.current.setFieldValue(name, value);
                const oldList = formValue;
                if(name === "proofOfRegistration"){
                    let proofOfRequirement = value.requirement;
                    formikRef.current.setFieldValue("proofRequirement", proofOfRequirement._id);
                    formikRef.current.setFieldValue("proof", value);
                    formikRef.current.setFieldValue("file_name", value.name);
                    oldList['proofRequirement'] = proofOfRequirement._id;
                    oldList['proof'] = value;
                    oldList['file_name'] = value.name;
                    getBusinessRegistration();
                }else{
                    oldList[name] = value;
                }
                setFormValue(oldList);
            }
        }, error => {
            hideLoader();
        });
    };

    const getBusinessRegistration = () => {
        const params = {
            sort: {
                name: 1
            }
        }
		const endpoint = `/api/businessRegistration/search`;
		const api = host + endpoint;
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            setProofList(data);
        }, error => {
        });
    };

    const transformFieldName = (field) => {
        if(field === "registrationNumber"){
          return "Registration/Certificate/Permit No.";
        }else if(field === "businessName"){
          return "Registered Business / SEC / CDA Name";
        }else if(field === "proofOfRegistration" ){
          return "Proof of registration or government accreditation";
        }
    }

    useEffect(() => {
        getFlaggedItems();
    }, [props]);

    return (
        <React.Fragment>
            {loader}
            <Header isLoggedIn={isLoggedIn} />
            <div className="mainRegistration-container mt-4">
                <div className="container">
                    <div className="row border bg-white p-3">
                        <div className="col-md-8 col-sm-12 form-align">
                            <Formik
                                validationSchema={registrationSchema}
                                innerRef={formikRef}
                                validateOnBlur={true}
                                validateOnChange={false}
                                initialValues={Object.assign(formValue)}
                                validate={formValue => {
                                
                                    const errors = {};
                                    
                                    // if (formValue.businessName == '') {
                                    //     errors.businessName = 'This is Required!';
                                    // }

                                    // if (formValue.registrationNumber == '') {
                                    //     errors.registrationNumber = 'This is Required!';
                                    // }

                                    // if (formValue.file_name != undefined && (formValue.proof == undefined || formValue.proof == '')) {
                                    //     errors.proof = 'This is Required!';
                                    // }

                                    flaggedItems.map(function(flaggedItem, index) {
                                        if(flaggedItem.fieldName === "businessName"){
                                            if(flaggedItem.fieldValue.trim().toLowerCase() === formValue.businessName.trim().toLowerCase()){
                                                errors.businessName = "No changes found.";
                                            }
                                            if(formValue.businessName === ""){
                                                errors.businessName = 'This is Required.';
                                            }
                                        }
                                        if(flaggedItem.fieldName === "registrationNumber"){
                                            if(flaggedItem.fieldValue.trim().toLowerCase() === formValue.registrationNumber.trim().toLowerCase()){
                                                errors.registrationNumber = "No changes found.";
                                            }
                                            if(formValue.businessName === ""){
                                                errors.registrationNumber = 'This is Required.';
                                            }
                                        }
                                        if(flaggedItem.fieldName === "proofOfRegistration"){
                                            const fieldValue = flaggedItem.fieldValue;
                                            const proofValue = formValue.proof;
                                            const requirement = formValue.proofRequirement;
                                            if(requirement === ""){
                                                errors.proofRequirement = "This is required.";
                                            }
                                            try{
                                                if(fieldValue.name.toLowerCase() === proofValue.name.toLowerCase()){
                                                    errors.proof = "No changes found.";
                                                }
                                            }catch(ex){
                                                errors.proof ='This is required.';
                                            }
                                        }
                                        // if (formValue.businessName === undefined && formValue.registrationNumber !== undefined) {
                                        //     // if business regulatory no is rectified
                                        //     if (flaggedItem.fieldValue.toLowerCase() === formValue.registrationNumber.trim().toLowerCase()) {
                                        //         errors.registrationNumber = 'No changes found!';
                                        //     } else if (formValue.proof != undefined || formValue.proof != null ) {
                                        //         if ( formValue.proof[0].name === flaggedItem.fieldValue ) {
                                        //             errors.proof = 'No changes found!';
                                        //         }
                                        //     }
                                            
                                        // } else if (formValue.registrationNumber === undefined && formValue.businessName !== undefined) {
                                        //     // if business name and filename are rectified
                                        //     if (flaggedItem.field_value.toLowerCase() === formValue.businessName.trim().toLowerCase()) {
                                        //         errors.businessName = 'No changes found!';
                                        //     }else if (formValue.proof != undefined || formValue.proof != null ) {
                                        //         if ( formValue.proof[0].name === flaggedItem.fieldValue ) {
                                        //             errors.proof = 'No changes found!';
                                        //         }
                                        //     }
                                        // } else if (formValue.registrationNumber === undefined && formValue.businessName === undefined) { 
                                        //     // if only the filename is rectified
                                        //     if (formValue.proof != undefined || formValue.proof != null ) {
                                        //         if ( formValue.proof[0].name === flaggedItem.fieldValue ) {
                                        //             errors.proof = 'No changes found!';
                                        //         }
                                        //     }
                                           
                                        // } else {
                                        //     // all items are rectified
                                        //     if (flaggedItem.fieldValue.toLowerCase() === formValue.registrationNumber.trim().toLowerCase()) {
                                        //         errors.registrationNumber = 'No changes found!';
                                        //     } else if (flaggedItem.fieldValue.toLowerCase() === formValue.businessName.trim().toLowerCase()) {
                                        //         errors.businessName = 'No changes found!';
                                        //     } else 
                                        //     if (formValue.proof != undefined || formValue.proof != null ) {
                                        //         if ( formValue.proof[0].name === flaggedItem.fieldValue ) {
                                        //             errors.proof = 'No changes found!';
                                        //         }
                                        //     }
                                        // }
                                    })
                                    
                                    return errors;
                                  }}
                                onSubmit={submit}
                            >
                                {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values, field }) => (

                                    <Form noValidate onSubmit={handleSubmit}>
                                        <h2 htmlFor="flaggedItems"><strong>Flagged Items</strong></h2>
                                        {
                                            flaggedItems.map((value, index) => {
                                                if(value.fieldName !== "proofOfRegistration"){
                                                    return <div className="form-group" key={`field_2_${index}`}>
                                                        <label>{transformFieldName(value.fieldName)}</label>
                                                        <Field type="text" value={values[value.fieldName]} name={value.fieldName} autoComplete="off" className={`form-control ${touched[value.fieldName] && errors[value.fieldName] ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name={value.fieldName} className="invalid-feedback" />
                                                        <label>Reason: <small className="red">{value.remarks}</small></label>
                                                    </div>
                                                }else{
                                                    const fieldValue = value.fieldValue;
                                                    return <div key={`field_3_${index}`}>
                                                        <div className="form-group">
                                                            <label htmlFor="proofRequirement">Proof of registration or government accreditation</label>
                                                            <select name="proofRequirement"
                                                                className={`form-control ${touched.proofRequirement && errors.proofRequirement ? "is-invalid" : ""}`}
                                                                value={values.proofRequirement || ''}
                                                                onChange={val => { 
                                                                    const selectedIndex = val.target.options.selectedIndex; 
                                                                    let code = val.target.options[selectedIndex].getAttribute('name');
                                                                    code = code.replace("proof_", "");
                                                                    let label = val.target.options[selectedIndex].getAttribute('label');
                                                                    console.log(label, "fdsfsd")
                                                                    setFieldValue('proofRequirement', code);
                                                                    // setFieldValue('proofIdentifier', label);
                                                                }}
                                                            >
                                                                {proofList.map((value, index) => {
                                                                    return <option value={value._id} name={'proof_' + value._id} key={'proof_' + index} label={value.name} />
                                                                })}
                                                            </select>
                                                            <ErrorMessage component="div" name="proofRequirement" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <small className="file"
                                                            name={fieldValue.name}
                                                            value={fieldValue._id}
                                                            onClick={event => {
                                                            let _id = event.target.getAttribute('value');
                                                            let name = event.target.getAttribute('name');
                                                            viewFile(_id, name);
                                                            }}>
                                                                {fieldValue.name}
                                                            </small>
                                                        </div>
                                                        <label>Reason: <small className="red">{value.remarks}</small></label>
                                                        <div className="form-group">
                                                            <p><strong>NOTE:</strong> Please upload a scanned copy of your <strong>selected proof of registration or government accreditation</strong>. Maximum upload file size: 12 MB</p>
                                                            <ImageUploaderField accept="image/*,application/pdf" name="proof" type="file" value={undefined}
                                                                className={`form-control-file ${touched.proof && errors.proof ? "is-invalid" : ""}`}
                                                            />
                                                            <ErrorMessage component="div" name="proof" className="invalid-feedback" />
                                                        </div>
                                                    </div>
                                                }
                                            })
                                        }
                                        {/* {
                                            flaggedItems.map((value, index) => {
                                                if(value.fieldName === 'file_name'){
                                                    return  <div className="form-group" key={`field_2_${index}`}>
                                                        <label>{value.transform_field_name}</label>
                                                        <label><strong>
                                                            {requirements.filter(item => item.uuid === value.upload_file_uuid).map(item => { return item.identifier_no})}
                                                        </strong></label>
                                                        <div className="clearfix"></div>
                                                        <label>
                                                            <strong>Current File: <span className="file badge bg-info"
                                                                onClick={(event) => {
                                                                    let uuid = value.upload_file_uuid;
                                                                    let name = event.target.outerText;
                                                                    viewFile(uuid, name);
                                                                }}>{value.field_value}</span>
                                                            </strong>
                                                        </label>
                                                        <p><strong>NOTE:</strong> Maximum upload file size: 12 MB.</p>
                                                        <ImageUploaderField accept="image/*,application/pdf" name="proof" type="file" value={undefined}
                                                            className={`form-control-file ${touched.proof && errors.proof ? "is-invalid" : ""}`}
                                                            onChange={value => {
                                                                setFieldValue("proof", value.currentTarget.files[0]);
                                                            }}
                                                        />
                                                        <ErrorMessage component="div" name="proof" className="invalid-feedback" />
                                                        <label>Reason: <small className="red">{value.remarks}</small></label>
                                                    </div>
                                                }else{
                                                    return <div className="form-group" key={`field_3_${index}`}>
                                                        <label htmlFor={value.field_name}>{value.transform_field_name}</label>
                                                        <Field type="text" validate={value.field_name == "business_name" ? validateBusinessName : validateRegistrationNo} value={values[value.field_name]} name={value.field_name} className={`form-control ${touched[value.field_name] && errors[value.field_name] ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name={value.field_name} className="invalid-feedback" />
                                                        <label>Reason: <small className="red">{value.remarks}</small></label>
                                                    </div>
                                                }
                                            })
                                        } */}
                                        <div className="form-button text-center mt-5">
                                            <button type="button" className="startup-btn-main mx-3" onClick={back}>Back</button>
                                            {/* <button type="button" className="startup-btn-main" onClick={formSubmit}>Submit</button> */}
                                            <button type="submit" className="startup-btn-main">Submit</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                backdrop="static"
                keyboard={false}
                show={showConfirmationModal}
                onHide={closeConfirmationModal}
            >
                <Modal.Header closeButton>
                <Modal.Title>You are about to submit new information. Please review and confirm that all inputs are correct.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        Object.keys(rectifyValue).map((key,index)=>{
                            return (
                                <div key={index}>
                                    <h6 className="transformText"> <strong>-  {transformFieldName(key)}: </strong></h6>
                                    <label className="m-l-11">{key === 'proofOfRegistration' ? rectifyValue[key].name : rectifyValue[key]}</label>
                                </div>
                            )
                        })
                    }
                </Modal.Body>
                <div className="modal-footer text-center">
                    <button type="button" className="startup-btn-main mx-3" onClick={() => closeConfirmationModal(!showConfirmationModal)}>Back</button>
                    <button type="button" className="startup-btn-main" onClick={confirmationSubmit}>Confirm</button>
                </div>
            </Modal>
            <Modal
                backdrop="static"
                keyboard={false}
                show={showSuccessRectifyModal}
            >
                <Modal.Header closeButton>
                <Modal.Title>Rectification has been submitted.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>Thank you for rectifying your account.</p>
                <p>The DTI will assess your application.</p>

                <div className="clearfix">&nbsp;</div>

                <p>You may see the status of your registration by going to your Dashboard &gt;<button type="button" onClick={myCompanyClick} className="btn btn-link link-my-company">My Company.</button></p>
                <div className="text-center">
                    <button type="button" className="startup-btn" onClick={handleCloseAccountCreated}>OK</button>     
                </div>        
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};
export default Rectify;
