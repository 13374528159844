import React, { useState,useEffect } from "react";
import Header from "../../components/Header";
import { Modal } from "react-bootstrap";
import "../../dist/css/registration.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import LoadingService from "../../imports/LoadingService";
import '../../dist/scss/custom.scss';
import Lottie from "react-lottie";
import CloseRegistration from '../../dist/images/registration-close.json';

const signupSchema = Yup.object().shape({
  username: Yup.string().required("This is Required!"),
  password: Yup.string().required("This is Required!").matches(
    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{8,})/,
    "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character."
  ),
  confirmPassword: Yup.string().required("This is Required!").oneOf([Yup.ref('password'), null], 'Password and Confirm Password does not match.'),
  email: Yup.string().email("Invalid Email!").required("This is Required!"),
});

const activateSchema = Yup.object().shape({
  first: Yup.string().required("This is Required!"),
  second: Yup.string().required("This is Required!"),
  third: Yup.string().required("This is Required!"),
  fourth: Yup.string().required("This is Required!"),
  fifth: Yup.string().required("This is Required!"),
  sixth: Yup.string().required("This is Required!"),
});

const Registration = (props) => {
	document.title = 'User Sign Up';
  const history = useHistory();

  const [showLottie, setShowLottie] = useState(false);

	const [loader, showLoader, hideLoader] = LoadingService();

  const [showOTPModal, setShowOTPModal] = useState(false);
  const [uuid, setUuid] = useState("");
	const [isOTPModalOpen, setOTPModal] = useState(false);

  const [showSuccessOTP, setShowSuccessOTP] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const showPasswordHandler  = () =>{
    setShowPassword(!showPassword);
  }

  const complete = "Sending...";

  const OTPModalHandler = () => {
    setShowOTPModal(!showOTPModal);
  };

  const showSuccessOTPHandler = () => {
    setShowSuccessOTP(!showSuccessOTP);
  };

  const setUuidHandler = (userId) => {
    setUuid(userId);
  };

  const activateSubmit = async (values) => {
    showLoader();
    let otp = Object.values(values).join("");
    otp = Number(otp);
    let data = {
      _id: uuid,
      otp: otp,
    };
    const api = url.hostPort() + "/api/validate-otp";
    axios.post(api, data).then(
      (res) => {
        hideLoader();
        OTPModalHandler();
        showSuccessOTPHandler();
        // let result = res.data;
        // let message = result.message;
        // toast.info(message, {
        //   position: "bottom-right",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: false,
        //   pauseOnHover: false,
        //   draggable: false,
        //   progress: undefined,
        // });
      },
      (error) => {
        hideLoader();
        let errorMessage = "OTP code is invalid.";
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    );
  };

  const redirectHome = () => {
    showSuccessOTPHandler();
    history.push("/home");
  };

  const signUpSubmit = async (values) => {
    showLoader();
    if(!isOTPModalOpen){
      let params = {
        email: values.email,
        username: values.username,
        password: values.password,
        confirmPassword: values.confirmPassword,
      };
      const api = url.hostPort() + "/api/signup";
      axios.post(api, params).then(
        (res) => {
          hideLoader();
          let result = res.data;
          setUuidHandler(result._id);
          setOTPModal(true);
          toast.info(result.message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          OTPModalHandler();
        },
        (error) => {
          try{
            hideLoader();
            const response = error.response;
            const data = response.data;
            let message = "";
            if(data.message == "Entry matches an existing record!"){
              let sysmessage = data.sysmessage.error;
              let keyValue = sysmessage.keyValue;
              let property = Object.getOwnPropertyNames(sysmessage.keyPattern);
              message = `${keyValue[property]} is already taken.`
            }else{
              message = data.message;
            }
            toast.error(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            });
          }catch(ex){
            toast.error(ex, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            });
          }
        }
      );
    }else{
      hideLoader();
      OTPModalHandler();
    }
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      resendingHandler(false);
      return complete;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  const [times, setTimes] = useState([Date.now() + 180000]);
  const [currentTimeIndex, setCurrentTimeIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState(Date.now);
  const [showResending, setShowResending] = useState(true);

  const setCurrentTimeIndexHandler = (value) => {
    setCurrentTimeIndex(value);
  };

  const setCurrentTimeHandler = (value) => {
    setCurrentTime(value);
  };

  const resendingHandler = (value) => {
    setShowResending(value);
  };

  const completeTime = () => {
    const api = url.hostPort() + "/api/resend-otp";
    let data = {
      _id: uuid,
    };
    axios.post(api, data).then(
      (res) => {
        let result = res.data;
        if (times.length - 1 <= times.indexOf(currentTime)) {
          return;
        } else {
          // move to next time
          setTimeout(function () {
            setCurrentTimeIndexHandler(currentTimeIndex + 1);
            setCurrentTimeHandler(new Date(times[currentTimeIndex + 1]));
            resendingHandler(true);
          }, 2500);
        }
        return result;
      },
      (error) => {
        return console.log("Error in Resend OTP", error);
      }
    );
  };
  
  const closeOTPModal = () => {
    OTPModalHandler();
    setShowOTPModal(false);
  };

  const ResendingLabel = () => {
    return `Resending in `;
  };

  const onKeyUp = (event, index)=> {
    let value = event.target.value;
    let valueLength = value.length;
    let maxLength = event.target.maxLength;
    if(valueLength == maxLength){
      if(index === "first"){
        document.getElementById("second").focus();
      }else if(index === "second"){
        document.getElementById("third").focus();
      }else if(index === "third"){
        document.getElementById("fourth").focus();
      }else if(index === "fourth"){
        document.getElementById("fifth").focus();
      }else if(index === "fifth"){
        document.getElementById("sixth").focus();
      }else if(index === "sixth"){
        document.getElementById("submitButton").focus();
      }
    }
  };

  useEffect(() => {
    props.PageHandler('notLoggedIn');
    const registration = url.registration();
    if (registration) {
      setShowLottie(true);
    } else {
      setShowLottie(false);
    }
}, [])

  return (
    <React.Fragment>
      {showLottie ? (
        <div className="lottie-animation">
          <Lottie
            options={{
              animationData: CloseRegistration,
              loop: true,
              autoplay: true,
            }}
            width={400}
            height={400}
          />
          <div className="registration-message">
            <h2>Sorry, registration is closed.</h2>
            <p className="m-b-0">For more information, please contact</p>
            <p className="m-b-0 underline-text"><a href="mailto:helpdesk@startup.gov.ph">helpdesk@startup.gov.ph</a></p>
          </div>
      </div>
      ) : (
        <React.Fragment>
          {loader}
          <Modal
            backdrop="static"
            keyboard={false}
            show={showOTPModal}
            onHide={closeOTPModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>OTP Sent</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Please check your email inbox or spam for the one-time-passcode</p>
              <Formik
                validationSchema={activateSchema}
                onSubmit={activateSubmit}
                initialValues={{
                  first: "",
                  second: "",
                  third: "",
                  fourth: "",
                  fifth: "",
                  sixth: "",
                }}
              >
                {({ handleSubmit, errors, touched }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Field
                      type="text"
                      name="first"
                      id="first"
                      className={`otp-input ${
                        touched.first && errors.first ? "is-invalid" : ""
                      }`}
                      maxLength="1"
                      autoFocus={true}
                      autoComplete="off"
                      onKeyUp={val => {
                        onKeyUp(val, "first")
                      }}
                    />
                    {/* <ErrorMessage component="div" name="first" className="invalid-feedback" /> */}
    
                    <Field
                      type="text"
                      name="second"
                      id="second"
                      className={`otp-input ${
                        touched.second && errors.second ? "is-invalid" : ""
                      }`}
                      maxLength="1"
                      autoComplete="off"
                      onKeyUp={val => {
                        onKeyUp(val, "second")
                      }}
                    />
                    {/* <ErrorMessage component="div" name="second" className="invalid-feedback" /> */}
    
                    <Field
                      type="text"
                      name="third"
                      id="third"
                      className={`otp-input ${
                        touched.third && errors.third ? "is-invalid" : ""
                      }`}
                      maxLength="1"
                      autoComplete="off"
                      onKeyUp={val => {
                        onKeyUp(val, "third")
                      }}
                    />
                    {/* <ErrorMessage component="div" name="third" className="invalid-feedback" /> */}
    
                    <Field
                      type="text"
                      name="fourth"
                      id="fourth"
                      className={`otp-input ${
                        touched.fourth && errors.fourth ? "is-invalid" : ""
                      }`}
                      maxLength="1"
                      autoComplete="off"
                      onKeyUp={val => {
                        onKeyUp(val, "fourth")
                      }}
                    />
                    {/* <ErrorMessage component="div" name="fourth" className="invalid-feedback" /> */}
    
                    <Field
                      type="text"
                      name="fifth"
                      id="fifth"
                      className={`otp-input ${
                        touched.fifth && errors.fifth ? "is-invalid" : ""
                      }`}
                      maxLength="1"
                      autoComplete="off"
                      onKeyUp={val => {
                        onKeyUp(val, "fifth")
                      }}
                    />
                    {/* <ErrorMessage component="div" name="fifth" className="invalid-feedback" /> */}
    
                    <Field
                      type="text"
                      name="sixth"
                      id="sixth"
                      className={`otp-input ${
                        touched.sixth && errors.sixth ? "is-invalid" : ""
                      }`}
                      maxLength="1"
                      autoComplete="off"
                      onKeyUp={val => {
                        onKeyUp(val, "sixth")
                      }}
                    />
                    {/* <ErrorMessage component="div" name="sixth" className="invalid-feedback" /> */}
    
                    <div>
                      <button
                        className="startup-btn continue text-center"
                        type="submit"
                        id="submitButton"
                      >
                        Continue
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
            <Modal.Footer className="modal-footer text-center">
              <p>
                {/* Did not receive OTP? Resending in {" "} */}
                Did not receive OTP? {showResending ? (
                  <ResendingLabel />
                ) : (
                  complete
                )}{" "}
                {showResending ? (
                  <Countdown
                    key={currentTimeIndex}
                    date={Date.now() + 180000}
                    onComplete={completeTime}
                    renderer={renderer}
                  />
                ) : null}
              </p>
            </Modal.Footer>
          </Modal>
          <Header  activePage = {props.activePage} />
          <div className="registration-container text-left mb-5">
            <div className="container border rounded mt-4">
              <div className="row bg-white">
                <div className="col-md-8 col-sm-12 form-align mb-4">
                  <div className="sign-up-instruction mt-4">
                    <h4 className="p-t-25">Sign up instructions</h4>
                    <div className="clearfix">&nbsp;</div>
                    <p>
                      For full access to all the features, create an account on the
                      Portal. <br />
                      <br />
                      The creation of a website account should be accomplished by
                      the applicant startup or enabler themselves, using their own
                      details.
                    </p>
                  </div>
                  <div className="clearfix">&nbsp;</div>
                  <div className="signup-form-container">
                    <Formik
                      validationSchema={signupSchema}
                      onSubmit={signUpSubmit}
                      initialValues={{
                        username: "",
                        password: "",
                        confirmPassword: "",
                        email: "",
                      }}
                    >
                      {({ handleSubmit, errors, touched, values }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <div className="form-group">
                            <strong><label htmlFor="email">Email Address*</label></strong>
                            <Field
                              type="email"
                              name="email"
                              autoComplete="off"
                              className={`form-control ${
                                touched.email && errors.email ? "is-invalid" : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="email"
                              className="invalid-feedback"
                            />
                            <small id="emailHelp" className="form-text text-muted">
                              This address will be used for receiving updates in the
                              status of your registration.
                            </small>
                          </div>
    
                          <div className="form-group">
                            <strong><label htmlFor="username">Username*</label></strong>
                            <Field
                              type="text"
                              name="username"
                              autoComplete="off"
                              className={`form-control ${
                                touched.username && errors.username
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="username"
                              className="invalid-feedback"
                            />
                          </div>
    
                          <div className="form-group">
                            <strong><label htmlFor="password">Password*</label></strong>
                            <Field
                              type={showPassword ? 'text' : 'password'}
                              name="password"
                              autoComplete="off"
                              className={`form-control ${
                                touched.password && errors.password
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="password"
                              className="invalid-feedback"
                            />
                          </div>
    
                          <div className="form-group">
                            <strong><label htmlFor="confirmPassword">
                              Confirm Password*
                            </label></strong>
                            <Field
                              type="password"
                              name="confirmPassword"
                              autoComplete="off"
                              className={`form-control ${
                                touched.confirmPassword && errors.confirmPassword
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="confirmPassword"
                              className="invalid-feedback"
                            />
                          </div>
    
                          <div className="text-center mt-5">
                            <button className="startup-btn" type="submit">
                              <b>Create Account</b>
                            </button>
                          </div>
                          <div className="clearfix">&nbsp;</div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={showSuccessOTP}>
            <Modal.Header>
              <Modal.Title>Account activation successful</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>You may now login to your account and create your profile.</p>
            </Modal.Body>
            {/* <Modal.Footer> */}
            <div className="modal-footer text-center">
            <button type="button" className="startup-btn" onClick={redirectHome}>Okay</button>
            </div>
            {/* </Modal.Footer> */}
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default Registration;
