import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
// import "../../dist/scss/custom.scss"; 
import "../../dist/css/account.css";
import { useHistory } from "react-router-dom";
import store from "store";
import * as moment from "moment";
import axios from "axios";
import url from "../../environment";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import ImageUploaderField from '../../imports/Upload';
import { Modal } from "react-bootstrap";
import LoadingService from "../../imports/LoadingService";

const requiredLabel = 'This is required.';

const registrationSchema = Yup.object().shape({
    govId: Yup.string().when(['citizenship', 'individualType'],
	(citizenship, individualType, schema) => {
		if(citizenship == 1){
			return schema;
		}else{
            if(individualType == 1){
                return schema;
            }else{
                return schema.required(requiredLabel);
            }
		}
	}),
    govIdFile: Yup.mixed().when(['citizenship', 'individualType'],
	(citizenship, individualType, schema) => {
		if(citizenship == 1){
			return schema;
		}else{
            if(individualType == 1){
                return schema;
            }else{
                return schema.required(requiredLabel);
            }
		}
	}).test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
        }
    }),
    govIdIdentifier: Yup.string(),
    foreignFile: Yup.mixed().when(['citizenship', 'individualType'],
	(citizenship, individualType, schema) => {
		if(citizenship == 0){
			return schema;
		}else{
            if(individualType == 1){
                return schema;
            }else{
                return schema.required(requiredLabel);
            }
		}
	}).test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        } 

    }),
    schoolIdFile: Yup.mixed().when('individualType',
	(individualType, schema) => {
		if(individualType == 0){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}).test('FILE_SIZE', "Uploaded file is too big.", (value) => {
        try {
            if(value == undefined) {
                return(value === undefined ? true : true)
            } else if(value.length != 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            } else if (value.length == 0) {
                return(value !== undefined ? value[0].size > 12000000 ? false : true : true)
            }
        }
        catch(e) {
            return(value === undefined ? true : true)
           
        }
        
    }),
    schoolName: Yup.string().when('individualType',
	(individualType, schema) => {
		if(individualType == 0){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
    individualType: Yup.string(),
    region: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 1){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
	regionName: Yup.string(),
	province: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 1){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
	provinceName: Yup.string(),
	city: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 1){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
	cityName: Yup.string(),
	barangay: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 1){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
	barangayName: Yup.string(),
	streetNo: Yup.string(),
	lotBlockUnit: Yup.string(),
	zipCode: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 1){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
    foreignAddress: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema;
		}else{
			return schema.required(requiredLabel);
		}
	}),
	classification: Yup.array().min(1, requiredLabel),
    citizenship: Yup.string()
});

const AspiringIndividual = (props) => {
    document.title = 'Aspiring Individual';
    const history = useHistory();

	const formikRef = useRef();

	const [loader, showLoader, hideLoader] = LoadingService();

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
    const user = props.user !== null ? props.user : {};
    const personalInfo = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? props.personalInfo : "";

    const host = url.hostPort();
    const headers = {
        'Authorization': `Bearer ${token()}`,
        
    };

    const [regionList, setRegionList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [barangayList, setBarangayList] = useState([]);

    const [sectorList, setSectorList] = useState([]);
    const [requirementList, setRequirementList] = useState([]);
    const [transformedList, setTransformedList] = useState([]);
    const [schoolId, setSchoolId] = useState({});
    const [passportId, setPassportId] = useState({});

    const [isForeign, setIsForeign] = useState(false);
    const [aspirantType, setAspirantType] = useState(0);

    const [registrationNo, setRegistrationNo] = useState('');

    const [parameters, setParameters] = useState({
    });

	const [formValue, setFormValue] = useState({
        'govId': '',
        'govIdFile': undefined,
        'govIdIdentifier': '',
        'foreignFile': undefined,
        'schoolIdFile': undefined,
        'schoolName': '',
        'individualType': 0,
        'region': '',
        'regionName': '',
        'province': '',
        'provinceName': '',
        'city': '',
        'cityName': '',
        'barangay': '',
        'barangayName': '',
        'streetNo': '',
        'lotBlockUnit': '',
        'zipCode': '',
        'foreignAddress': '',
		'classification': '',
        'citizenship': 0
	});

    const numberPerColumn = Math.ceil(sectorList.length / 3);

    const getRegion = () => {
        const params = {
            sort: {
                region_code: 1
            }
        }
		const endpoint = `/api/region/search`;
		const api = host + endpoint;
		if(regionList.length == 0){
			axios.post(api, params, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.data;
                data = data.filter(function(item){ return item.region_psgc !== "180000000"});
				setRegionList(data);
			}, error => {
			});
		}
	};

    const getProvince = (value) =>{
        const params = {
            region_psgc: value,
            sort: {
                province_name: 1
            }
        }
		if(value != ""){
			const endpoint = `/api/province/search`;
			const api = host + endpoint;
			axios.post(api, params, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.data;
				setProvinceList(data);
			}, error => {
			})
		}
	};

    const getCity = (value) => {
        const params = {
            province_psgc: value,
            sort: {
                city_municipality_name: 1
            }
        }
		if(value != ""){
			const endpoint = `/api/city-municipality/search`;
			const api = host + endpoint;
			axios.post(api, params, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.data;
				setCityList(data);
			}, error => {
			})
		}
	};

    const getBarangay = (value) => {
        const params = {
            city_municipality_psgc: value,
            sort: {
                barangay_name: 1
            }
        }
		if(value != ""){
			const endpoint = `/api/barangay/search`;
			const api = host + endpoint;
			axios.post(api, params, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.data;
				setBarangayList(data);
			}, error => {
			})
		}
	};
    
    const [disableSubmitApplication, setDisableSubmitApplication] = useState(true);
    const [haveRead, setHaveRead] = useState(false);
    const [declareTruth, setDeclareTruth] = useState(false);

    //modal 
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    //end of modal

    //modal of account creation
    const [showCreatedModal, setShowCreatedModal] = useState(false);
    const handleAccountCreated = () => {
        setShowCreatedModal(true);
        setShow(false);
    }
    
    const handleCloseAccountCreated = () => {
        setShowCreatedModal(false);
        history.push('/dashboard');
    }
    
    //end of modal of account creation
    const setHaveReadHandler = () => {
		setHaveRead(!haveRead);
	};

    const setDeclareTruthHandler = () => {
		setDeclareTruth(!declareTruth);
	};

    const submit = (values) => {
        showLoader();
        if(values.classification.length === 0){
            hideLoader();
            const errorMessage = "Industry sector/s is required.";
			toast.error(errorMessage, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
			});

        }else{
            let applicantType = 0;
            let address = "";
            if(values.citizenship === 0){
                let comma = values.lotBlockUnit !== "" || values.streetNo !== "" ? "," : "";
                address = `${values.lotBlockUnit} ${values.streetNo}${comma} ${values.barangayName}, ${values.cityName}, ${values.provinceName}, ${values.regionName} ${values.zipCode}`
            }else{
                address = values.foreignAddress;
            }
            let params = {
                sectors: values.classification,
                address: address
            };
            if(values.individualType === 0){
                if(isForeign){//foreign
                    params.applicantType = 3;
                    params.passportDatasheetRequirement = passportId._id;
                    params.passportDatasheet = values.foreignFile;
                }else{//individual
                    params.applicantType = 1;
                    params.supportDocumentRequirement = values.govId;
                    params.supportDocument = values.govIdFile;
                }
            }else{//student
                params.applicantType = 2;
                params.schoolName = values.schoolName;
                params.supportDocumentRequirement = schoolId._id;
                params.supportDocument = values.schoolIdFile;
            }
            let formData = new FormData();
            formData.append('applicantType', params.applicantType);
            formData.append('address', params.address);
            if(params.applicantType === 1){
                formData.append('supportDocumentRequirement', params.supportDocumentRequirement);
                formData.append('supportDocument', params.supportDocument[0]);
            }
            if(params.applicantType === 2){
                formData.append('supportDocumentRequirement', params.supportDocumentRequirement);
                formData.append('supportDocument', params.supportDocument[0]);
                formData.append('schoolName', params.schoolName);
            }
            if(params.applicantType === 3){
                formData.append('passportDatasheetRequirement', params.passportDatasheetRequirement);
                formData.append('passportDatasheet', params.passportDatasheet[0]);

            }
            for(let x = 0; x < params.sectors.length; x++){
                formData.append('sectors', params.sectors[x]);
            }

            let api = host + `/api/startup/aspiring/register?_id=${user._id}`;
            axios.post(api, formData, { headers: headers }).then((res) => {
                let result = res.data;
                let data = result.data;
                setRegistrationNo(data.startupId);
                hideLoader();
                handleAccountCreated();
            },
            (error) => {
                try{
                  hideLoader();
                  const response = error.response;
                  const data = response.data;
                  let message = "";
                  if(data.message == "Entry matches an existing record!"){
                    let sysmessage = data.sysmessage.error;
                    let keyValue = sysmessage.keyValue;
                    let property = Object.getOwnPropertyNames(sysmessage.keyPattern);
                    message = `${keyValue[property]} is already taken.`
                  }else{
                    message = data.message;
                  }
                  toast.error(message, {
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  });
                }catch(ex){
                  toast.error(ex, {
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  });
                }
            });
        }
    };

    const getSectorList = () => {
        let params = {
            "isActive": true,
            "sort":  {
                "name": 1
            }
        }
		const endpoint = `/api/sector/search`;
		const api = host + endpoint;
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            for(let x = 0; x < data.length; x++){
                data[x].checked = false;
            }
            setSectorList(data);
        }, error => {
        });
    };

    const getRequirementList = () => {
        let params = {
            "isActive": true,
            "sort":  {
                "name": 1
            }
        }
		const endpoint = `/api/requirement/search`;
		const api = host + endpoint;
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            let schoolId = data.filter(function(item){ return item.type === 3});
            let passport = data.filter(function(item){ return item.name === "Passport Datasheet"});
            setPassportId(passport[0]);
            setSchoolId(schoolId[0]);
            setRequirementList(data);
            checkNationality(data);
        }, error => {
        });
    };

    const checkNationality = (data) => {
        const citizenship = personalInfo.citizenship;
        if(citizenship === "Filipino"){
            setIsForeign(false);
            formikRef.current.setFieldValue('citizenship', 0);
            let filter = data.filter(function(item){ return item.type === 1});
            setTransformedList(filter);
        }else{
            setIsForeign(true);
            formikRef.current.setFieldValue('citizenship', 1);
            let filter = data.filter(function(item){ return item.type === 2});
            setTransformedList(filter);
        }
    };

    const checkRegistration = () => {
        showLoader();
        let api = host + `/api/startup/registration/check?_id=${user._id}`;
        axios.get(api, {headers: headers}).then(res => {
            hideLoader();
            let result = res.data;
            let exist = result.exist;
            if(exist){
                history.push('/dashboard');
                let error = 'Registration is already created.';
                toast.error(error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }else{
                getRequirementList();
                getSectorList();
                getRegion();
            }
        });
    }

    useEffect( () => {
        checkRegistration();
    }, [props]);
    
    useEffect(() => {
		if(haveRead && declareTruth){
			setDisableSubmitApplication(false);
		}else{
			setDisableSubmitApplication(true);
		}
	}, [haveRead, declareTruth]);

    return (
        <React.Fragment>
            {loader}
            <Header isLoggedIn={isLoggedIn} />
            <div className="account-container mt-4">
                <div className="container mb-5">
                <div className="row bg-white border">
                    <div className="col-md-8 col-sm-12 form-align">
                        <h4 className="p-t-25">Aspiring Individual</h4>
                        <hr></hr>
                        <p>The<strong> Startup Portal </strong> registration is open to anyone who wishes to know more about and explore the products
                        and services that the <strong> Philippine Startup Ecosystem </strong> offers.</p>
                        <p>However, some of the <strong> Programs and Services </strong> in the portal are only open to duly registered entities.</p>
                        
                        <Formik
                            validationSchema={registrationSchema}
                            innerRef={formikRef}
                            initialValues={Object.assign(formValue)}
                            onSubmit={submit}
                        >
                            {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values, field }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <label><strong>Tell us who you are*</strong></label>
                                    <div className="form-check">
                                        <Field
                                            className="form-check-input"
                                            id="aspiringIndividual"
                                            type="radio"
                                            name="aspiringIndividual"
                                            value="0"
                                            onChange={() => setFieldValue("individualType", 0)}
                                            checked={aspirantType === 0}
                                            onClick={(e) => setAspirantType(0)}
                                        />
                                        <label className="form-check-label" htmlFor="aspiringIndividual">
                                            Aspiring Startup / Startup Enabler
                                        </label>
                                    </div>
                                    <div id="collapse1" className={aspirantType === 0 ? "collapseOne panel-collapse p-1 show" : "collapse"}>
                                        <div className="panel-body border-left p-2 bg-light">
                                            <div className="form-group" hidden={isForeign}>
                                                <label htmlFor="govId"><b>Government-Issued ID*</b></label>
                                                <select name="govId"
                                                    className={`form-control ${touched.govId && errors.govId ? "is-invalid" : ""}`}
                                                    value={values.govId || ''}
                                                    onChange={val => { 
                                                        const selectedIndex = val.target.options.selectedIndex; 
                                                        let code = val.target.options[selectedIndex].getAttribute('name');
                                                        code = code.replace("govId_", "");
                                                        let label = val.target.options[selectedIndex].getAttribute('label');
                                                        setFieldValue('govId', code);
                                                        setFieldValue('govIdIdentifier', label);
                                                    }}
                                                >
                                                    <option name="govId_" key="3" value="" label="" />
                                                    {transformedList.map((value, index) => {
                                                        return <option value={value._id} name={'govId_' + value._id} key={'govId_' + index} label={value.name} />
                                                    })}
                                                </select>
                                                <ErrorMessage component="div" name="govId" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group"  hidden={isForeign}>
                                            <p><strong>NOTE:</strong> Please upload a scanned copy of your <strong>selected government-issued ID</strong>. Maximum upload file size: 12 MB.</p>
                                                {/* <p><strong>NOTE:</strong> Maximum upload file size: 12 MB.</p> */}
                                                <ImageUploaderField accept="image/*,application/pdf" name="govIdFile" type="file" value={undefined}
                                                    className={`form-control-file ${touched.govIdFile && errors.govIdFile ? "is-invalid" : ""}`}
                                                    onChange={value => {
                                                        setFieldValue("govIdFile", value.currentTarget.files[0]);
                                                    }}
                                                />
                                                <ErrorMessage component="div" name="govIdFile" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group" hidden={!isForeign}>
                                                {/* <p><strong>NOTE:</strong> Maximum upload file size: 12 MB.</p> */}
                                                <p><strong>NOTE:</strong> Please upload a scanned copy of your <strong>passport datasheet</strong>. Maximum upload file size: 12 MB.</p>
                                                <label htmlFor="foreignFile"><b>Passport Datasheet</b></label>
                                                <ImageUploaderField accept="image/*,application/pdf" name="foreignFile" type="file" value={undefined}
                                                    className={`form-control-file ${touched.foreignFile && errors.foreignFile ? "is-invalid" : ""}`}
                                                    onChange={value => {
                                                        setFieldValue("foreignFile", value.currentTarget.files[0]);
                                                    }}
                                                />
                                                <ErrorMessage component="div" name="foreignFile" className="invalid-feedback" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-check mb-2">
                                        <Field
                                            className="form-check-input"
                                            id="student"
                                            type="radio"
                                            name="student"
                                            value="1"
                                            onChange={() => setFieldValue("individualType", 1)}
                                            checked={aspirantType === 1}
                                            onClick={(e) => setAspirantType(1)}
                                        />
                                        <label className="form-check-label" htmlFor="student">
                                            Student (for minors)
                                        </label>
                                    </div>
                                    
                                    <div id="collapse2"  className={aspirantType === 1 ? "collapseOne panel-collapse collapse p-1 show" : "collapseOne panel-collapse collapse p-1"}>
                                        <div className="panel-body border-left p-2 bg-light">
                                            <div className="form-group">
                                                <p><strong>NOTE:</strong> Please upload a scanned copy of your <strong>school ID</strong>. Maximum upload file size: 12 MB.</p>
                                                {/* <p><strong>NOTE:</strong> Maximum upload file size: 12 MB.</p> */}
                                                <label htmlFor="schoolIdFile"><b>School ID*</b></label>
                                                <ImageUploaderField accept="image/*,application/pdf" name="schoolIdFile" type="file" value={undefined}
                                                    className={`form-control-file ${touched.schoolIdFile && errors.schoolIdFile ? "is-invalid" : ""}`}
                                                    onChange={value => {
                                                        setFieldValue("schoolIdFile", value.currentTarget.files[0]);
                                                    }}
                                                />
                                                <ErrorMessage component="div" name="schoolIdFile" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="schoolName"><b>School Name</b></label>
                                                <Field type="text" value={values.schoolName || ''} name="schoolName" autoComplete="off" className={`form-control ${touched.schoolName && errors.schoolName ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="schoolName" className="invalid-feedback" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group" hidden={isForeign}>
                                        <label className="mt-4"><strong>Home Address</strong><small> (for persons living in the Philippines)</small></label>
                                        <div className="border-left pl-3">
                                            <div className="form-group">
                                                <label htmlFor="region">Region*</label>
                                                <select name="region"
                                                    value={values.region || ''}
                                                    className={`form-control ${touched.region && errors.region ? "is-invalid" : ""}`}
                                                    onChange={val => { 
                                                        const selectedIndex = val.target.options.selectedIndex;
                                                        let code = val.target.options[selectedIndex].getAttribute('value');
                                                        let region = val.target.options[selectedIndex].getAttribute('label');
                                                        setFieldValue('region', code);
                                                        setFieldValue('regionName', region);

                                                        setProvinceList([]);
                                                        setCityList([]);
                                                        setBarangayList([]);
                                                        getProvince(code);
                                                    }}
                                                >
                                                    <option name="region_" key="0" value="" label="" />
                                                    {regionList.map((value, index) => {
                                                        return <option value={value.region_psgc} name={'region_' + value.region_psgc} key={'region_' + index} label={value.region_code + ' - ' +value.region_name} />
                                                    })}
                                                </select>
                                                <ErrorMessage component="div" name="region" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="province">Province*</label>
                                                <select name="province"
                                                    className={`form-control ${touched.province && errors.province ? "is-invalid" : ""}`}
                                                    value={values.province || ''}
                                                    onChange={val => { 
                                                        const selectedIndex = val.target.options.selectedIndex; 
                                                        let code = val.target.options[selectedIndex].getAttribute('name');
                                                        code = code.replace("province_", "");
                                                        let province = val.target.options[selectedIndex].getAttribute('label');
                                                        setFieldValue('province', code);
                                                        setFieldValue('provinceName', province);

                                                        setCityList([]);
                                                        setBarangayList([]);
                                                        getCity(code);
                                                    }}
                                                >
                                                    <option name="province_" key="1" value="" label="" />
                                                    {provinceList.map((value, index) => {
                                                        return <option value={value.province_psgc} name={'province_' + value.province_psgc} key={'province_' + value.province_psgc} label={value.province_name} />
                                                    })}
                                                </select>
                                                <ErrorMessage component="div" name="province" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="city">City/Municipality*</label>
                                                <select name="city"
                                                    className={`form-control ${touched.city && errors.city ? "is-invalid" : ""}`}
                                                    value={values.city || ''}
                                                    onChange={val => { 
                                                        const selectedIndex = val.target.options.selectedIndex; 
                                                        let code = val.target.options[selectedIndex].getAttribute('name');
                                                        code = code.replace("city_", "");
                                                        let city = val.target.options[selectedIndex].getAttribute('label');
                                                        setFieldValue('city', code);
                                                        setFieldValue('cityName', city);

                                                        setBarangayList([]);
                                                        getBarangay(code);
                                                    }}
                                                >
                                                    <option name="city_" key="2" value="" label="" />
                                                    {cityList.map((value, index) => {
                                                        return <option value={value.city_municipality_psgc} name={'city_' + value.city_municipality_psgc} key={'city_' + value.city_municipality_psgc} label={value.city_municipality_name} />
                                                    })}
                                                </select>
                                                <ErrorMessage component="div" name="city" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="barangay">Barangay*</label>
                                                <select name="barangay"
                                                    className={`form-control ${touched.barangay && errors.barangay ? "is-invalid" : ""}`}
                                                    value={values.barangay || ''}
                                                    onChange={val => { 
                                                        const selectedIndex = val.target.options.selectedIndex; 
                                                        let code = val.target.options[selectedIndex].getAttribute('name');
                                                        code = code.replace("barangay_", "");
                                                        let barangay = val.target.options[selectedIndex].getAttribute('label');
                                                        setFieldValue('barangay', code);
                                                        setFieldValue('barangayName', barangay);
                                                    }}
                                                >
                                                    <option name="barangay_" key="3" value="" label="" />
                                                    {barangayList.map((value, index) => {
                                                        return <option value={value.barangay_psgc} name={'barangay_' + value.barangay_psgc} key={'barangay_' + value.barangay_psgc} label={value.barangay_name} />
                                                    })}
                                                </select>
                                                <ErrorMessage component="div" name="barangay" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="streetNo">Street No., Village, Subdivision etc.</label>
                                                <Field type="text" name="streetNo" autoComplete="off" className={`form-control ${touched.streetNo && errors.streetNo ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="streetNo" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="lotBlockUnit">Lot, Block, Unit, Floor No., Etc.</label>
                                                <Field type="text" name="lotBlockUnit" autoComplete="off" className={`form-control ${touched.lotBlockUnit && errors.lotBlockUnit ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="lotBlockUnit" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="zipCode">Zip Code*</label>
                                                <Field type="text" name="zipCode" autoComplete="off" className={`form-control ${touched.zipCode && errors.zipCode ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="zipCode" className="invalid-feedback" />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group" hidden={!isForeign}>
                                        <label className="mt-4"><strong>Home Address*</strong><small> (for persons living outside the Philippines)</small></label>
                                        <Field type="text" as="textarea" rows="5" value={values.foreignAddress || ''} name="foreignAddress" autoComplete="off" className={`form-control ${touched.foreignAddress && errors.foreignAddress ? "is-invalid" : ""}`} />
                                        <ErrorMessage component="div" name="foreignAddress" className="invalid-feedback" />
                                    </div>
                                    
                                    <div className="form-group">
                                        <label className="mt-4"><strong>Which industry sector/s are you interested in?*</strong></label>
                                                    
                                        <FieldArray
                                            name="classification"
                                            className={`form-control ${touched.classification && errors.classification ? "is-invalid" : ""}`}
                                            render={arrayHelpers => (
                                                <div className="row classrow">
                                                    <div className="col-md-4 pr-0">
                                                        {sectorList.filter((v, i) => i < numberPerColumn).map((value, index) => {
                                                            return (
                                                                <div key={`classification_${value._id}`} className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={`classification_${index}`}
                                                                    value={value._id}
                                                                    checked={value.checked}
                                                                    onChange={event => {
                                                                        
                                                                    }}
                                                                    onClick={event => {
                                                                        let checked = event.target.checked;
                                                                        let find = values.classification.indexOf(value._id);
                                                                        value.checked = checked;
                                                                        if(checked){
                                                                            arrayHelpers.insert('', value._id);
                                                                        }else{
                                                                            arrayHelpers.remove(find);
                                                                        }
                                                                    }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`classification_${index}`}>{value.name}</label>
                                                            </div> 
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-md-4 pr-0">
                                                        {sectorList.filter((v, i) => i >= numberPerColumn && i < numberPerColumn*2 ).map((value, index) => {
                                                            return (
                                                                <div key={`classification_${value._id}`} className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={`classification_${index + numberPerColumn}`}
                                                                    value={value._id}
                                                                    checked={value.checked}
                                                                    onChange={event => {
                                                                        
                                                                    }}
                                                                    onClick={event => {
                                                                        let checked = event.target.checked;
                                                                        let find = values.classification.indexOf(value._id);
                                                                        value.checked = checked;
                                                                        if(checked){
                                                                            arrayHelpers.insert('', value._id);
                                                                        }else{
                                                                            arrayHelpers.remove(find);
                                                                        }
                                                                    }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`classification_${index + numberPerColumn}`}>{value.name}</label>
                                                            </div> 
                                                            )
                                                        })}
                                                    </div>  
                                                    <div className="col-md-4 pr-0">
                                                        {sectorList.filter((v, i) => i >= numberPerColumn*2 && i < sectorList.length).map((value, index) => {
                                                            return (
                                                                <div key={`classification_${value._id}`} className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={`classification_${index + numberPerColumn*2}`}
                                                                    value={value.id}
                                                                    checked={value.checked}
                                                                    onChange={event => {
                                                                        
                                                                    }}
                                                                    onClick={event => {
                                                                        let checked = event.target.checked;
                                                                        let find = values.classification.indexOf(value._id);
                                                                        value.checked = checked;
                                                                        if(checked){
                                                                            arrayHelpers.insert('', value._id);
                                                                        }else{
                                                                            arrayHelpers.remove(find);
                                                                        }
                                                                    }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`classification_${index + numberPerColumn*2}`}>{value.name}</label>
                                                            </div> 
                                                            )
                                                        })}
                                                    </div> 
                                                </div>   
                                            )}
                                        />
                                    </div>
    
                                    <div className="py-4 text-center mt-3">
                                        <button type="submit" className="startup-btn-main">Save</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        {/* <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Aspiring Startup / Startup Enabler
                            </label>
                            <div className="form-group">
                                <label htmlFor="issuedID"><b>Government-Issued ID</b></label>
                                <select name="issuedID"
                                    
                                >
                                    <option name="issuedID" key="3" value="" label="" />
                                    {proofList.map((value, index) => {
                                        return <option value={value.requirement_id} name={'proof_' + value.requirement_id} key={'proof_' + index} label={value.requirement_name} />
                                    })}
                                </select>
                            
                            </div>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                Student (for minors)
                            </label>
                        </div> */}
                    </div>
                
                </div>
                
                </div>
            </div>
            <Modal show={showCreatedModal} modalOptions={{ dismissible: false }}>
                <Modal.Header>
                    <Modal.Title>Company Account Created</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Thank you for creating your portal company account. </p>
                    <p> Your Startup ID is:</p>
                    <div className="text-center">
                        <h4><strong>{registrationNo}</strong> </h4>
                    </div>
                    <div className="clearfix">&nbsp;</div>
                    <p>Most of the <strong>Programs and Services </strong> in the portal are for duly registered entities.</p>
                    <p>However, please feel free to look for programs designed for students or aspiring startups and startup enablers.</p>
                    <div className="text-center">
                        <button type="button" className="startup-btn" onClick={handleCloseAccountCreated}>OK</button>     
                    </div>           
                </Modal.Body>
               
            </Modal>
        </React.Fragment>
    );
};

export default AspiringIndividual;
