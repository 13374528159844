import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import { Formik, Form, Field, FieldArray, ErrorMessage, setIn } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../dist/css/editaccount.css";
import * as moment from "moment";
import store from "store";
import token from "../../store/token";
import xAuthToken from "../../store/xAuthToken";
import LoadingService from "../../imports/LoadingService";
import ls from '../../config/ls';

const accountSchema = Yup.object().shape({
  lastName: Yup.string().required("This is Required!"),
  firstName: Yup.string().required("This is Required!"),
  suffix: Yup.string(),
  middleName: Yup.string(),
  sex: Yup.string(),
  birthDate: Yup.string().required("This is Required!"),
  placeOfBirth: Yup.string().required("This is Required!"),
  citizenship: Yup.string(),
  others: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema;
		}else{
			return schema.required('This is Required!');
		}
	}),
  contact_no: Yup.string().required("This is Required!"),
  socialClassification: Yup.array()
});

const EditAccount = (props) => {
	document.title = 'Edit User Details';
  const history = useHistory();

	const [loader, showLoader, hideLoader] = LoadingService();

  const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
  const user =
    props.user && Object.keys(props.user).length !== 0 ? props.user : {};
  const personalInfo =
    props.personalInfo && Object.keys(props.personalInfo).length !== 0
      ? props.personalInfo
      : "";
  const lastUrl = props.lastUrl ? props.lastUrl : "/myaccount";
  const buttonLabel =
    props.personalInfo && Object.keys(props.personalInfo).length !== 0
      ? "Update"
      : "Create";

  const [citizenshipStatus, setStatus] = useState(0);
  const [sexStatus, setSex] = useState(0);

  const host = url.hostPort();
  const headers = {
		'Authorization': `Bearer ${token()}`,
    
	};

  const socialClassificationList = [
    {
      "id": 1,
      "name": "Person with disability",
      "checked": false
    },
    {
      "id": 2,
      "name": "Indigenous Person",
      "checked": false
    }
  ];
  
  const [userInfo, setUserInfo] = useState("");
  const [personalInformation, setPersonalInformation] = useState("");
  const [social, setSocial] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  const [classificationList, setClassificationList] = useState([]);
  const [sexList, setSexList] = useState([]);

  const [personalInfoValues, setPersonalInfoValues] = useState({
    lastName: "",
    firstName: "",
    suffix: "",
    middleName: "",
    birthDate: "",
    placeOfBirth: "",
    others: "",
    sex: "",
    citizenship: "",
    contact_no: "",
    socialClassification: [],
  });

  const citizenshipHandler = (value) => {
    setStatus(value);
  };

  const sexHandler = (value) => {
    setSex(value);
  };

  const getSocialClassification = (social) => {
    let params = {
      "isActive": true,
      "sort":  {
        "name": -1 
      }
    }
    let endpoint = "/api/socialClassification/search";
    const api = host + endpoint;
    axios.post(api, params, {headers: headers}).then((res) => {
        let data = res.data.data;
        if(data.length !== 0){
          for(let x = 0; x < data.length; x++){
            if(social.indexOf(data[x]._id) !== -1){
              data[x].checked = true;
            }else{
              data[x].checked = false;
            }
          }
        }
        setClassificationList(data);
      }, (error) => {
      }
    )
  };

  const getSex = () => {
    let params = {
      "isActive": true,
      "sort":  {
        "name": -1 
      }
    }
    let endpoint = "/api/sex/search";
    const api = host + endpoint;
    axios.post(api, params, {headers: headers}).then((res) => {
      let data = res.data.data;
      setSexList(data);
      },
      (error) => {
      }
    )
  };

  const getRegistrationDetails = () => {
    showLoader();
    let api = host + `/api/startup/personal-information/view?_id=${personalInfo._id}`;
    axios.get(api, {headers: headers}).then(res => {
      hideLoader();
      let data = res.data.data;
      setUserInfo(data);
      setPersonalInformation(data.personalInformation);
      setSocial(data.personalInformation.socialClassification);
      setInitialValue(data.personalInformation);
    });
	};

  const setInitialValue = (data) => {
    let social_id = data.socialClassification.map(item => {
      return item._id;
    });
    let citizenship = data.citizenship !== "Filipino" ? 1 : 0;
    let value = {
      lastName: data.lastName,
      firstName: data.firstName,
      suffix: data.suffix,
      middleName: data.middleName,
      birthDate: moment(data.birthDate).format("YYYY-MM-DD"),
      placeOfBirth: data.birthPlace,
      others: data.citizenship !== "Filipino" ? data.citizenship : "",
      sex: data.sex._id,
      citizenship: data.citizenship !== "Filipino" ? 1 : 0,
      contact_no: data.contactNo === null ? '' : data.contactNo,
      socialClassification: social_id,
    };
    getSocialClassification(social_id);
    setStatus(citizenship);
    sexHandler(data.sex._id);
    setPersonalInfoValues(value);
    setIsLoaded(true);
  };

  const submitAccount = async (values) => {
    showLoader();
    let params = {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      suffix: values.suffix,
      sex: values.sex,
      birthDate: moment(values.birthDate).format("MM/DD/YYYY"),
      birthPlace: values.placeOfBirth,
      citizenship: values.citizenship === 0 ? "Filipino" : values.others,
      contactNo: values.contact_no,
      socialClassification: values.socialClassification == undefined ? [] : values.socialClassification
    };
    let endpoint = `/api/startup/personal-information/update?_id=${personalInformation._id}`;
    const api = host + endpoint;
    axios.put(api, params, {headers: headers}).then(
      (res) => {
        hideLoader();
        let result = res.data;
        let data = result.data;
        ls.set("HYLQcSsSWe", data);
        toast.info(result.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        history.push('/myaccount');
      },
      (error) => {
        hideLoader();
        const response = error.response;
        const data = response.data;
        let message = "";
        if(data.message == "Entry matches an existing record!"){
          let sysmessage = data.sysmessage.error;
          let keyValue = sysmessage.keyValue;
          let property = Object.getOwnPropertyNames(sysmessage.keyPattern);
          message = `${keyValue[property]} is already taken.`
        }else{
          message = data.message;
        }
        toast.error(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        });
      }
    );
  };

  useEffect(() => {
    getRegistrationDetails();
    getSex();
  }, [props]);

  const showOthers = (touched, errors) => {
    return (
      <div className="form-group">
        <Field
          type="text"
          name="others"
          autoComplete="off"
          className={`form-control form-control-sm ${
            touched.others && errors.others ? "is-invalid" : ""
          }`}
        />
        <ErrorMessage
          component="div"
          name="others"
          className="invalid-feedback"
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      {loader}
      <Header isLoggedIn={isLoggedIn} />
      <div className="information-container text-left mt-4">
        <div className="container">
          <div className="row justify-content-md-center border rounded bg-white">
            <div className="col-md-8 justify-content-md-center mb-5">
              <h4 className="mt-5">To continue with your account creation</h4>
              <div className="clearfix">&nbsp;</div>
              <p>
                Review and edit the information you initially gave when creating
                an account in the Portal.
              </p>
              <p>
                The information given here will be re-used as necessary in the
                Portal.
              </p>
              <hr></hr>
              {isLoaded ?
                <Formik
                  validationSchema={accountSchema}
                  initialValues={Object.assign(personalInfoValues)}
                  onSubmit={submitAccount}
                >
                  {({ handleSubmit, errors, touched, values, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="lastName"><strong>Last Name</strong></label>
                        <Field
                          type="text"
                          name="lastName"
                          autoComplete="off"
                          className={`form-control ${
                            touched.lastName && errors.lastName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="lastName"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="firstName"><strong>First Name</strong></label>
                        <Field
                          type="text"
                          name="firstName"
                          autoComplete="off"
                          className={`form-control ${
                            touched.firstName && errors.firstName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="firstName"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="suffix"><strong>Suffix</strong></label>
                        <Field
                          type="text"
                          name="suffix"
                          autoComplete="off"
                          className={`form-control ${
                            touched.suffix && errors.suffix ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="suffix"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="suffix"><strong>Middle Name</strong></label>
                        <Field
                          type="text"
                          name="middleName"
                          autoComplete="off"
                          className={`form-control ${
                            touched.middleName && errors.middleName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="middleName"
                          className="invalid-feedback"
                        />
                      </div>

                      <label htmlFor="sex"><strong>Sex</strong> (Assigned at birth)</label>
                      <FieldArray
                        name="sex"
                        className={`form-control ${touched.sex && errors.sex ? "is-invalid" : ""}`}
                        render={arrayHelpers => (
                          <div>
                            {sexList.map((value, index) => {
                              return (
                                <div key={`sex_${value._id}`} className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id={`sex_${index}`}
                                  value={value._id}
                                  checked={sexStatus === value._id}
                                  onChange={event => {
                                  }}
                                  onClick={(e) => {
                                    sexHandler(value._id);
                                    setFieldValue("sex", value._id);
                                  }}
                                  />
                                  <label className="form-check-label" htmlFor={`sex_${index}`}>{value.name}</label>
                              </div> 
                              )
                            })}
                          </div>
                        )}
                      />

                      <div className="form-group">
                        <label htmlFor="birthDate"><strong>Birth Date</strong></label>
                        <Field
                          type="date"
                          name="birthDate"
                          autoComplete="off"
                          className={`form-control ${
                            touched.birthDate && errors.birthDate
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="birthDate"
                          className="invalid-feedback display-block"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="placeOfBirth"><strong>Place of Birth</strong></label>
                        <Field
                          type="text"
                          name="placeOfBirth"
                          autoComplete="off"
                          className={`form-control ${
                            touched.placeOfBirth && errors.placeOfBirth
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="placeOfBirth"
                          className="invalid-feedback"
                        />
                      </div>

                      <label htmlFor="citizenship"><strong>Citizenship</strong></label>
                      <div className="form-check">
                        <Field
                          className="form-check-input"
                          id="filipino"
                          type="radio"
                          name="filipino"
                          value="Filipino"
                          onChange={() => setFieldValue("citizenship", 0)}
                          checked={citizenshipStatus === 0}
                          onClick={(e) => citizenshipHandler(0)}
                        />
                        <label className="form-check-label" htmlFor="filipino">
                          Filipino{" "}
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <Field
                          className="form-check-input"
                          id="others"
                          type="radio"
                          name="others"
                          value="Others"
                          onChange={() => setFieldValue("citizenship", 1)}
                          checked={citizenshipStatus === 1}
                          onClick={(e) => citizenshipHandler(1)}
                        />
                        <label className="form-check-label" htmlFor="others">
                          Others{" "}
                        </label>
                        {citizenshipStatus === 1
                          ? showOthers(touched, errors)
                          : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="contact_no"><strong>Contact No.</strong></label>
                        <Field
                          type="text"
                          name="contact_no"
                          autoComplete="off"
                          className={`form-control ${
                            touched.contact_no && errors.contact_no
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="contact_no"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                          <label>Social Classification</label>
                          <FieldArray
                            name="socialClassification"
                            className={`form-control ${touched.socialClassification && errors.socialClassification ? "is-invalid" : ""}`}
                            render={arrayHelpers => (
                              <div>
                                {classificationList.map((value, index) => {
                                  return (
                                    <div key={`socialClassification_${value._id}`} className="form-check">
                                      <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`socialClassification_${index}`}
                                      value={value._id}
                                      checked={value.checked}
                                      onChange={event => {
                                      }}
                                      onClick={event => {
                                        let checked = event.target.checked;
                                        let find = values.socialClassification.indexOf(value._id);
                                        value.checked = checked;
                                        if(checked){
                                          arrayHelpers.insert('', value._id);
                                        }else{
                                          arrayHelpers.remove(find);
                                        }
                                      }}
                                      />
                                      <label className="form-check-label" htmlFor={`socialClassification_${index}`}>{value.name}</label>
                                    </div> 
                                  )
                                })}
                              </div>
                            )}
                          />
                      </div>
                      <div className="text-center mt-4">
                        <button className="startup-btn mt-4" type="submit">
                          <b>{buttonLabel} Account</b>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              : 
                null
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditAccount;
