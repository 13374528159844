import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import isLoggedIn from "../store/isLoggedIn";

const PublicRoute = ({component: Component, ...rest}) => {
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            !isLoggedIn() ?
                <Component {...props} />
            : <Redirect to="/home" />
        )} />
    );
};

export default PublicRoute;