import React, { useEffect, useState } from "react";
import GOVPHLogo from "../dist/images/logo.png";
import "../dist/css/main.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import store from 'store';
import moment from 'moment';
import Moment from "react-moment";
import psdpLogo from "../dist/images/psdp-logo.png";
import govPhLogo from "../dist/images/govph-logo.png";
import personalInfo from "../store/personalInfo";
import token from "../store/token";
import xAuthToken from "../store/xAuthToken";
import axios from "axios";
import url from "../environment";
import currentActions from '../store/currentActions';

const Header = (props) => {
  const history = useHistory();

	const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
    
	};

  const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
  const user = props.user !== null ? props.user : {};
  const isAdmin = props.isAdmin !== undefined ? props.isAdmin : false;

  const [hideDashboard, setHideDashboard] = useState(true);

  const [portalAdmin, setPortalAdmin] = useState(false);
  const [dtiAdmin, setDTIAdmin] = useState(false);

  const logout = async (event) => {
    const api = host + 'api/logout';
    axios.post(api, {}, {headers: headers}).then(res => {
      let message = "Logout Successful.";
      toast.info(message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      store.remove("zgETDnOXBY");
      store.remove("LjfADyiZFB");
      store.remove("OnvBIFUcVC");
      store.remove("HYLQcSsSWe");
      store.remove("oqzAsiGkQs");
      store.remove("iSWgCQnurz");
      store.remove("MaeIHzsYGC");
      store.remove("startupRegistrationFirst");
      store.remove("startupRegistrationFirstFile");
      store.remove("startupRegistrationSecond");
      window.location.href = '/home';
      // history.push("/home");
      setTimeout(function () {
        window.location.reload();
      }, 200);
    }, error => {
      let message = "Logout Successful.";
      toast.info(message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      store.remove("zgETDnOXBY");
      store.remove("LjfADyiZFB");
      store.remove("OnvBIFUcVC");
      store.remove("HYLQcSsSWe");
      store.remove("oqzAsiGkQs");
      store.remove("iSWgCQnurz");
      store.remove("MaeIHzsYGC");
      store.remove("startupRegistrationFirst");
      store.remove("startupRegistrationFirstFile");
      store.remove("startupRegistrationSecond");
      window.location.href = '/home';
      // history.push("/home");
      setTimeout(function () {
        window.location.reload();
      }, 200);
    })
  };

  const toDashboard = () => {
    store.remove("startupRegistrationFirst");
    store.remove("startupRegistrationFirstFile");
    store.remove("startupRegistrationSecond");
    history.push("/dashboard");
  };

  const toHome = () => {
    store.remove("startupRegistrationFirst");
    store.remove("startupRegistrationFirstFile");
    store.remove("startupRegistrationSecond");
    history.push("/home");
  };

  const toStartupEvaluations = () => {
    window.location.href = '/startup/evaluation/index';
  };

  const redirectToService = () => {
    window.open('http://13.213.129.242:2002/home?_services=123456', '_blank', 'noopener,noreferrer');
  };

  const checkAdminType = () => {
    try{
      const actions = currentActions();
      if(actions.indexOf('/api/approval/startup/evaluate') !== -1){
        setDTIAdmin(true);
      }
      if(actions.indexOf('/api/user/create') !== -1){
        setPortalAdmin(true);
      }
    }catch(ex){

    }
  };

  useEffect(() => {
    let storedPersonalInfo = personalInfo();
    if (storedPersonalInfo === '') {
      setHideDashboard(true);
    } else {
      if (user !== undefined && user.resetPassword === 1) {
        setHideDashboard(true);
        console.log(user);
      } else {
        setHideDashboard(false);
      }
    }
    checkAdminType();
  }, [props]);

  return (
    <React.Fragment>
      <div className="main-header">
        <div className="top-header">
          <div className="container">
            <div className="row">
              <div className="col text-left">
                <a
                  className="navbar-brand d-inline-block"
                  href="#"
                  onClick={() => toHome()}
                >
                  <img src={govPhLogo} alt="govph-logo" />
                </a>
              </div>
              <div className="col text-right my-auto py-1">
                <p className="text-right">
                  Philippine Standard Time - {moment().format('dddd')}, <Moment interval={1000} format="MMMM D, YYYY, h:mm:ss a"></Moment>
                </p>
                {/* <p className="text-right">Accessibility</p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="nav-header">
          <nav className="navbar navbar-expand-lg navbar-light bg-white">
            <div className="container">
              <a
                href="https://startup.gov.ph/"
                className="custom-logo-link"
                rel="home"
                aria-current="page"
              >
                <img
                  width="114"
                  height="50"
                  src={psdpLogo}
                  className="custom-logo"
                  alt="Startup Innovations Portal"
                />
              </a>
              {/* <a className="m-l-10 pointer" onClick={() => redirectToService()}>Service</a> */}
              <button
                className={props.activePage === 'notLoggedIn' ? "navbar-toggler hide" : "navbar-toggler"}
                type="button"
                data-toggle="collapse"
                data-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarText">
                {/* <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Programs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Services
                    </a>
                  </li>
                </ul> */}
                <span className="navbar-text ml-auto">
                  {isLoggedIn ? (
                    <ul className="navbar-nav ml-auto">
                      <li className={props.activePage === 'initialLogin' ? "nav-item hide" : "nav-item"} hidden={hideDashboard}>
                        <a className="nav-link" href="#" onClick={() => toDashboard()}>
                          Dashboard
                        </a>
                      </li>
                      <li className="nav-item dropdown" hidden={!portalAdmin}>
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                          Settings
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                          <a class="dropdown-item" href="/user-management/user/index">User Management</a>
                          <a class="dropdown-item disabled" href="#" >Content Management</a>
                          <a class="dropdown-item" href="/libraries/sector/index">Libraries</a>
                          <a class="dropdown-item disabled" href="#">Audit Logs</a>
                        </div>
                      </li>
                      <li className="nav-item" hidden={!dtiAdmin}>
                        <a className="nav-link" href="#" onClick={() => toStartupEvaluations()}>
                          Startup Registrations
                        </a>
                      </li>
                      {/* <li className="nav-item" hidden={isAdmin}>
                        <a className="nav-link" href="#" onClick={() => toDashboard()}>
                          Startup Enabler Registrations
                        </a>
                      </li> */}
                      <li className={props.activePage === 'initialLogin' ? "nav-item hide" : "nav-item"} hidden={isAdmin}>
                        <a className="nav-link" href="/mycompany">
                          My Company
                        </a>
                      </li>
                      <li className={props.activePage === 'initialLogin' ? "nav-item hide" : "nav-item"} hidden={isAdmin}>
                        <a className="nav-link" href="/myaccount">
                          My Account
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#"
                          onClick={(e) => logout(e)}
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                  ) : (
                    <ul className="navbar-nav ml-auto" hidden={true}>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Signup
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          {/* onClick={(e) => setLogin(true)} */}
                          Login
                        </a>
                      </li>
                    </ul>
                  )}
                </span>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
