import React, { useEffect, useState, useRef } from "react";
import Header from "../../../components/Header";
import DataTable, { defaultThemes } from 'react-data-table-component';
import url from "../../../environment";
import { useHistory } from "react-router-dom";
import token from "../../../store/token";
import xAuthToken from "../../../store/xAuthToken";
import axios from "axios";
import * as moment from "moment";
import "../../../dist/scss/custom.scss";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import orderBy from 'lodash/orderBy';
import { toast } from "react-toastify";
import SidebarLibraries from "../../../components/SidebarLibraries";
import { useForm } from "react-hook-form";

const customStyles = {
}

const SectorIndex = (props) => {

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;

    document.title = 'Sectors List';
    const [checked, setChecked] = useState(false);
    const startupApplicationColumns = [
        {
            name: 'SECTOR NAME',
            selector: row => row['name'],
            style: { cursor: 'pointer' },
            sortable: true,
            grow: 3,
        },
        {
            name: 'DESCRIPTION',
            selector: row => row['description'],
            style: { cursor: 'pointer' },
            sortable: true,
            grow: 3,
        },
        {
            name: 'STATUS',
            cell:(value) => { 
                const activateDeactivateSocialClassification = (id, isActive) => {
                  const status = {
                    id: id,
                    isActive: !isActive
                  }
      
                  value.isActive = status.isActive;
                  setChecked(!checked)
      
                  const params = {
                    _id: id,
                    isActive: value.isActive
                  }
      
                  let api = url.hostPort() + '/api/sector/setIsActive';
                  axios.post(api, params).then(res => {
                      let result = res.data;
                      toast.info(result.message, {
                          position: "bottom-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: false,
                          draggable: false,
                          progress: undefined,
                      });
                  }, error => {
                      let responseError = error.response.data;
                      let errorMessage = responseError.message;
                      toast.error(errorMessage, {
                          position: "bottom-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: false,
                          draggable: false,
                          progress: undefined,
                      });
                  });
                }
                return <label class="switch"><input type="checkbox" id={`status${value._id}`}  
                        checked={value.isActive} onChange={() => activateDeactivateSocialClassification(value._id, value.isActive)}
                        />
                      <span class="slider round"></span>
                      </label> 
            },
            style: { cursor: 'pointer' },
            sortable: false,
            grow: 1
        },
        {
            name: 'DATE CREATED',
            selector: row => moment(row['dateCreatedFormatted']).format("MM-DD-YYYY"),
            style: { cursor: 'pointer' },
            sortable: true,
            grow: 3
        },
    ];
    const [startupSectorList, setStartupSectorList] = useState();
    const [sectorList, setSectorList] = useState([]);
    const [startupApplicationListotalRows, setStartupApplicationListTotalRows] = React.useState(0);
    const [startupApplicationListLoading, setStartupApplicationListLoading] = React.useState(true);
    const rowPerPage = [10, 25, 50, 100];
    const { register, handleSubmit, reset, formState } = useForm();
    const [sector, setSector] = useState();
    const [updateSector, setUpdateSector] = useState({
        page: 1,
        limit: 10,
        name: "",
        description: "",
        status: "",
        date: ""
    })
    
    const onSubmit = (data) => {
        updateSector.name = data.name;
        updateSector.description = data.description;
        updateSector.date = data.date;
        updateSector.dateCreated = data.date;
        updateSector.status = JSON.parse(data.status);
        getStartupSectorListwithFilter();
    }

    /* Get Sector List */
    const getStartupUserSectorList = async () => {
        const host = url.hostPort();
        const headers = {
            'Authorization': `Bearer ${token()}`,
            
        };

        let params = {
            "sort":  {
              "name": 1,
            }
        }

        let api = host + `/api/sector/search`;
        axios.post(api, params, { headers: headers }).then((res) => {
            setSectorList(res.data.data);
        },
            (error) => {

                let responseError = error.message;
                toast.error(responseError, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            });
    };

    /* Get Sector List with Filter */
    const getStartupSectorListwithFilter = async () => {
        setStartupApplicationListLoading(true);
        const host = url.hostPort();
        const headers = {
            'Authorization': `Bearer ${token()}`,
            
        };

        let params = {
            page: (sector != null ? sector.page : 1),
            limit: (sector != null ? sector.limit : 10),
            name: (sector != null ? updateSector.name : ""),
            description: (sector != null ? updateSector.description : ""),
            isActive: sector == null ? "" : updateSector.status,
            dateCreatedFormatted: updateSector.dateCreated === undefined ? "" : moment(updateSector.dateCreated).format('MM-DD-YYYY')
        };

        if(updateSector.dateCreated === ""){
            delete params.dateCreatedFormatted
        }

        let api = host + `/api/sector/search`;
        axios.post(api, params, { headers: headers }).then((res) => {
            setStartupSectorList(res.data.data)
            setStartupApplicationListTotalRows(res.data.total);
            setStartupApplicationListLoading(false);
        },
            (error) => {

                let responseError = error.message;
                setStartupApplicationListLoading(false);
                toast.error(responseError, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            });
    };

    /* Fetch Sector using rows per page dropdown */
    const startupHandlePerRowsChange = async (newPerPage, page) => {
        sector.limit = newPerPage;
        sector.page = page;
        getStartupSectorListwithFilter();
    };

    /* Fetch Sector using page arrow */
    const startupHandlePageChange = page => {
        sector.page = page;
        getStartupSectorListwithFilter();
    };

    const customSort = (rows, field, direction) => {
        field = field.toString();
        let transformField = field.match(/'([^']+)'/)[1];
        const handleField = row => {
          if (row[transformField]) {
            return row[transformField].toLowerCase();
          }
          return row[transformField];
        };
      
        return orderBy(rows, handleField, direction);
    };
    
    useEffect( () => {
        setSector({ 
            page: 1,
            limit: 10,
            name: "",
            description: "",
            status: true,
            date: ""
        })
    }, [])

    useEffect(() => {
        reset(sector);
    }, [sector]);

    useEffect(() => {
        getStartupUserSectorList();
        getStartupSectorListwithFilter();
    }, []);

    useEffect(() => {
        props.PageHandler('sector');
    }, [])

    return (
        <React.Fragment>
            <Header isLoggedIn={isLoggedIn} isAdmin={true} />
            <div className="container py-4">
                <div className="row">
                    <div className="col-3">
                        <SidebarLibraries activePage={props.activePage} />
                    </div>
                    <div className="col-9">
                        <div className="container startup-table-container">
                            <div className="mb-4"><a className="startup-secondary-btn-fill" href="/libraries/sector/create"><i className="bi bi-plus"></i>CREATE SECTOR</a></div>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <div className="advance-search-btn w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <span className="text-left">Advanced Search</span>
                                            <span className="accicon float-right rotate-icon"><i className="bi bi-chevron-down"></i></span>
                                        </div>
                                    </div>

                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="name" className="form-label">Name</label>
                                                        <select name="name"
                                                            className="form-control"
                                                            {...register('name')}
                                                        >
                                                            <option name="name" key="0" value="" label="" />
                                                            {sectorList.map((value, index) => {
                                                                return <option value={value.name} name="role" key={value._id} label={value.name} />
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="date" className="form-label">Date Created</label>
                                                        <input type="date" className="form-control" name="date" {...register('date')} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="status" className="form-label">Status</label>
                                                        {/* <input type="text" className="form-control" name="status" value={status} onChange={changeHandler}/> */}
                                                        <select className="custom-select"
                                                            name="status"
                                                            {...register('status')}
                                                        >
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>

                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="p-t-25"></div>

                                                <div className="btn-div d-flex justify-content-end">
                                                    <div className="btn-cancel pr-3">
                                                        <button className="startup-btn" type="button" onClick={() => reset()}>
                                                        <b>Clear</b>
                                                        </button>
                                                    </div>
                                                    <div className="btn-submit">
                                                        <button className="startup-btn" >
                                                        <b>Search</b>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-t-25"></div>
                            {/* <hr></hr> */}

                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <DataTable
                                        columns={startupApplicationColumns}
                                        data={startupSectorList}
                                        progressPending={startupApplicationListLoading}
                                        customStyles={customStyles}
                                        highlightOnHover
                                        striped
                                        responsive
                                        persistTableHead
                                        pagination
                                        paginationRowsPerPageOptions={rowPerPage}
                                        paginationServer
                                        paginationTotalRows={startupApplicationListotalRows}
                                        onChangeRowsPerPage={startupHandlePerRowsChange}
                                        onChangePage={startupHandlePageChange}
                                        //   onRowClicked={onRowClick}
                                          sortFunction={customSort}
                                        noDataComponent="There are no records to display."
                                    />
                                    {/* <div className="text-right">
                                    <a href="#">
                                    <strong>See Full List of Startups Applications</strong>
                                    </a>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </React.Fragment>
    );
}

export default SectorIndex;