import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import isLoggedIn from "../store/isLoggedIn";

const UserRoute = ({component: Component, isAdmin, ...rest}) => {
    return (
        // isAdmin = false meaning normal user
        // isAdmin = true meaning admin
        <Route {...rest} render={props => (
            isLoggedIn() && !isAdmin ?
                <Component {...props} />
            : <Redirect to="/dashboard" />
        )} />
    );
};

export default UserRoute;