import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import Header from "../../../components/Header";
import axios from "axios";
import url from "../../../environment";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import LoadingService from "../../../imports/LoadingService";
import { useHistory } from "react-router-dom";
import Sidebar from "../../../components/Sidebar"
import token from "../../../store/token";
import xAuthToken from "../../../store/xAuthToken";

const userCreateSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email!").required("This is Required!"),
    lastname: Yup.string().required("This is Required!"),
    firstname: Yup.string().required("This is Required!"),
    middlename: Yup.string(),
    suffix: Yup.string(),
    username: Yup.string().required("This is Required!"),
    roles: Yup.string().required("This is Required!"),
});

const UserCreate = (props) => {

    const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;

    document.title = 'User Create';
    
	const headers = {
		'Authorization': `Bearer ${token()}`,
        
	};

    const [loader, showLoader, hideLoader] = LoadingService();
    // const [roleName, setRoleName] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const formikRef = useRef();
    const [roleList, setRoleList] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [formValue, setFormValue] = useState({
        email: '',
        lastname: '',
        firstname: '',
        middlename: '',
        suffix: '',
        username: '',
        roles: '',
    });
    const history = useHistory();

    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const cancelBack = () => {
        history.push('/user-management/user/index');
    };

    const getRole = () => {
        let params = {
            isActive: true
        };
        const endpoint = `/api/role/search`;
        const api = url.hostPort() + endpoint;
        const headers = {
            'Authorization': `Bearer ${token()}`,
            
        };
        axios.post(api, params, {headers: headers}).then(res => {
            let result = res.data;
            let data = result.data;
            setRoleList(data);
            // setIsLoaded(true);
            // console.log(res);
        }, error => {
        });
    };

    const createUser = async (values) => {
        setShowConfirmationModal(true);
        setFormValue({
            email: values.email,
            lastName: values.lastname,
            firstName: values.firstname,
            middleName: values.middlename,
            suffix: values.suffix,
            username: values.username,
            roles: values.roles,
        })
    };

    const confirmSubmit = async () => {
        showLoader();
        let api = url.hostPort() + '/api/user/create';
        const headers = {
            'Authorization': `Bearer ${token()}`,
            
        };
        axios.post(api, formValue, {headers: headers}).then(res => {
            let result = res.data;
            toast.info(result.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            setShowConfirmationModal(false);
            history.push('/user-management/user/index');
        }, error => {
            try{
                hideLoader();
                const response = error.response;
                const data = response.data;
                const sys = data.sysmessage;
                let message = "";
                if(sys.message == "Entry matches an existing record!"){
                    let sysmessage = sys.error;
                    let keyValue = sysmessage.keyValue;
                    let property = Object.getOwnPropertyNames(sysmessage.keyPattern);
                    message = `${keyValue[property]} is already taken.`
                }else{
                    message = data.message;
                }
                toast.error(message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }catch(ex){
                console.log("ASDsadas", ex);
                toast.error(ex, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                });
            }
        });
    };

    const checkKeyDown = (e) => {
        if (e.code === 'Enter') e.preventDefault();
    };

    useEffect(() => {
        getRole();
    }, []);

    useEffect(() => {
        props.PageHandler('user');
    }, [])

    return (
        <React.Fragment>
            {loader}
            <Modal
                backdrop="static"
                keyboard="false"
                show={showConfirmationModal}
                onHide={closeConfirmationModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Confirm User Creation</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Assign {formValue.firstName} {formValue.lastName} ({formValue.email}) as {selectedRole}?</p>
                    <small>This action will send them an email containing their account credentials, prompting them to log in.</small>
                    <div className="btn-div d-flex justify-content-end">
                        <div>
                            <button
                                className="startup-btn cancel mr-2"
                                onClick={closeConfirmationModal}
                            >
                                <b>Cancel</b>
                            </button>
                        </div>
                        <div>
                            <button
                                className="startup-btn confirm"
                                type="button"
                                onClick={confirmSubmit}
                            >
                                <b>Confirm</b>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Header isLoggedIn={isLoggedIn} isAdmin={true} />
            <div className="container py-4">
                <div className="row">
                    <div className="col-3">
                        <Sidebar activePage = {props.activePage}/>
                    </div>
                    <div className="col-9">
                        <div className="container">
                            <div className="row justify-content-md-center">
                                <div className="col-8">
                                    <div className="title-div">
                                        <h4>Create Users</h4>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <Formik
                                                validationSchema={userCreateSchema}
                                                onSubmit={createUser}
                                                innerRef={formikRef}
                                                initialValues={Object.assign(formValue)}
                                            >
                                                {({ handleSubmit, setFieldValue, errors, touched, values }) => (
                                                    <Form noValidate onSubmit={handleSubmit} onKeyDown={checkKeyDown}>
                                                        <div className="form-group">
                                                            <label htmlFor="email">Email address*</label>
                                                            <Field
                                                                type="email"
                                                                name="email"
                                                                autoComplete="off"
                                                                className={`form-control ${touched.email && errors.email
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="email"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="lastname">Last Name*</label>
                                                            <Field
                                                                type="text"
                                                                name="lastname"
                                                                autoComplete="off"
                                                                className={`form-control ${touched.lastname && errors.lastname
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="lastname"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="firstname">First Name*</label>
                                                            <Field
                                                                type="text"
                                                                name="firstname"
                                                                autoComplete="off"
                                                                className={`form-control ${touched.firstname && errors.firstname
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="firstname"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="middlename">Middle Name</label>
                                                            <Field
                                                                type="text"
                                                                name="middlename"
                                                                autoComplete="off"
                                                                className={`form-control ${touched.middlename && errors.middlename
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="middlename"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="suffix">Suffix</label>
                                                            <Field
                                                                type="text"
                                                                name="suffix"
                                                                autoComplete="off"
                                                                className={`form-control ${touched.suffix && errors.suffix
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="suffix"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="username">Username*</label>
                                                            <Field
                                                                type="text"
                                                                name="username"
                                                                autoComplete="off"
                                                                className={`form-control ${touched.username && errors.username
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="username"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="roles">Role*</label>
                                                            <select
                                                                name="roles"
                                                                className={`form-control ${touched.roles && errors.roles
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                                value={values.roles || ''}
                                                                onChange={val => {
                                                                    const selectedIndex = val.target.options.selectedIndex;
                                                                    let code = val.target.options[selectedIndex].getAttribute('value');
                                                                    let roles = val.target.options[selectedIndex].getAttribute('label');
                                                                    setFieldValue('roles', code);
                                                                    setSelectedRole(roles);
                                                                }}
                                                            >
                                                                <option name="roles_" key="0" value="" label="" />
                                                                {roleList.filter(value => value.name != 'Startup User').map((value, index) => {
                                                                    return <option value={value._id} name={value.name} key={'roles_' + index} label={value.name} />
                                                                })}
                                                            </select>
                                                            <ErrorMessage
                                                                component="div"
                                                                name="roles"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="btn-div d-flex justify-content-end">
                                                            <div className="btn-cancel pr-3">
                                                                <button className="startup-btn-flat" onClick={cancelBack}>
                                                                    <b>Cancel</b>
                                                                </button>
                                                            </div>
                                                            <div className="btn-submit">
                                                                <button className="startup-secondary-btn-fill" type="submit">
                                                                    <b>Submit</b>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default UserCreate;