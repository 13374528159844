// import { globalEval } from "jquery";
import React from "react";
import "../dist/css/footer.css";
import GovPhFooter from "../dist/images/logo-footer.png"

const Footer = () => {
    return (
      <React.Fragment>
        <div className="footer">
            <div className="footer-container">
                <div className="container">
                    <div className="row">
                        <div className="logo-col col-md-3">
                            <img src={GovPhFooter} alt="govImage" />
                        </div>
                        <div className="col-md-3 text-left">
                            <h5>Republic of the Philippines</h5>
                            <p>All content is in the public domain unless otherwise stated.</p>
                        </div>
                        <div className="col-md-3 text-left">
                            <h5>About GOVPH</h5>
                            <p>Learn more about the Philippine government, its structure, how government works and the people behind it.</p>
                            <a href="https://www.gov.ph/" target="_blank">GOV.PH</a><br/>
                            <a href="https://data.gov.ph/" target="_blank">Open Data Portal</a><br/>
                            <a href="https://mirror.officialgazette.gov.ph/" target="_blank">Official Gazette</a><br/>
                            <a href="https://www.foi.gov.ph/" target="_blank">Freedom of Information</a><br/>
                        </div>
                        <div className="gov-links col-md-3 text-left">
                            <h5>Government Links</h5>
                            <a href="https://op-proper.gov.ph/">Office of the President</a><br/>
                            <a href="https://ovp.gov.ph/">Office of the Vice President</a><br/>
                            <a href="http://legacy.senate.gov.ph/">Senate of the Philippines</a><br/>
                            <a href="https://www.congress.gov.ph/">House of Representatives</a><br/>
                            <a href="https://sc.judiciary.gov.ph/">Supreme Court</a><br/>
                            <a href="https://ca2.judiciary.gov.ph/caws-war/">Court of Appeals</a><br/>
                            <a href="https://sb.judiciary.gov.ph/">Sandiganbayan</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </React.Fragment>
    );
  };
  
  export default Footer;