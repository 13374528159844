import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../dist/css/home.css";
import Header from "../components/Header";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import url from "../environment";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingService from "../imports/LoadingService";
import ls from '../config/ls';
import ParticleBackground from "../ParticleBackground";
import RedirectModal from "../components/RedirectModal";

const loginSchema = Yup.object().shape({
	username: Yup.string().required('This is Required!'),
	password: Yup.string().required('This is Required!'),
});

const Login = (props) => {
	document.title = 'Startup Portal Login';
	const history = useHistory();
	const location = useLocation()

	const [loader, showLoader, hideLoader] = LoadingService();
	const [show, setShow] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
	const host = url.hostPort();

	const [queryParams, setQueryParams] = useState([]);

	const loginSubmit = async (values) => {
		showLoader();
		let params = {
			'username': values.username,
			'password': values.password,
		}
		const api = host + "/api/login";
		axios.post(api, params).then(res => {
			let result = res.data.data;
			let currentActions = result.defaultActions;
			let hasPersonalInfo = result.hasPersonalInfo;
			ls.set('zgETDnOXBY', true); 
			ls.set('LjfADyiZFB', result.token);
			ls.set('MaeIHzsYGC', result.xAuthToken);
			ls.set('OnvBIFUcVC', result);
			ls.set('iSWgCQnurz', currentActions);
			if(result.resetPassword){
				history.push('/user/resetpassword');
			}else{
				if(hasPersonalInfo){
					ls.set('HYLQcSsSWe', result.PersonalInformation);
					window.scrollTo(0, 0);
					window.location.href = '/dashboard';
					hideLoader();
				}else{
					ls.set('HYLQcSsSWe', '');
					history.push('/user/signup/details');
					hideLoader();
				}
			}
		}, error => {
			if (error.response.data.message == "Error! Account has been deactivated!") {
				let errorMessage = "Error! Account has been deactivated!";
				setShow(true);
			} else {
				let errorMessage = "Incorrect username or password.";
				toast.error(errorMessage, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
			}
			hideLoader();
			
		});
	};

	const Redirect = () => {
		let value = 'startup.gov.ph';
		return <a href={`//${value}`} target="_blank" rel="noopener noreferrer">startup.gov.ph</a>;
	};

	const cancel = () => {
		setShow(!true);
	};

	useEffect( () => {
		setIsLoggedIn(props.isLoggedIn);
        if(props.isLoggedIn){
            history.push('/dashboard');
        }
    }, [props]);

	useEffect(() => {
		props.PageHandler('notLoggedIn');
	}, []);

	return (
		<React.Fragment>
		
		<div className="homepage-container">
			{loader}
			
			<Header isLoggedIn={isLoggedIn} isAdmin={true}  activePage = {props.activePage} />
			{/* <CarouselComponent items={CarouselItems} /> */}
			
			{/** Welcome */}
			<div className="welcome">
				<div className="container">
					<div className="row">
						<div className="col-md-12 d-flex justify-content-center align-items-center">
                            {!isLoggedIn ? (
                                <div className="login-container col-md-6">
                                    <h2 className="title">Log in</h2>
                                    <div className="login-form rounded">
                                        <Formik
                                            validationSchema={loginSchema}
                                            onSubmit={loginSubmit}
                                            initialValues={{
                                                'username': '',
                                                'password': '',
                                            }}
                                        >
                                    {({ handleSubmit, errors, touched }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="username">Username</label>
                                                <Field type="text" name="username" autoComplete="off" className={`form-control ${touched.username && errors.username ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="username" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <Field type="password" name="password" autoComplete="off" className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="password" className="invalid-feedback" />
                                            </div>
                                            <button type="submit" className="btn btn-primary login-button">Log in</button>
                                        </Form>
                                    )}
                        
                                        </Formik>
                                    </div>
                                </div>
                            ): null}
                        </div>
				    </div>
                </div>
			</div>
		</div>
		<Modal show={show}>
			<Modal.Header>
				<Modal.Title>Account Deactivated</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Sorry, your account seems to have been deactivated. Please contact helpdesk@startup.gov.ph for assistance.</p>
			</Modal.Body>
			{/* <Modal.Footer> */}
			<div className="modal-footer text-right">
			<button type="button" className="startup-btn" onClick={cancel}>Close</button>
			</div>
			{/* </Modal.Footer> */}
		</Modal>
		<ParticleBackground />
		</React.Fragment>
	);
};

export default Login;
