import React from "react";
import "../dist/css/sidebar-libraries.css";

const SidebarLibraries = (props) => {
    return (
        <React.Fragment>
            <div className="container">
                <h4 className="mb-3">Libraries</h4>
                <div>
                    <a className={props.activePage === 'sector' ? "sidenav-link active" : "sidenav-link"} href="/libraries/sector/index">
                        Sectors
                    </a>
                    <a className={props.activePage === 'startupStage' ? "sidenav-link active" : "sidenav-link"} href="/libraries/startup-stage/index">
                        Startup Development Phase
                    </a>
                    <a className={props.activePage === 'businessClass' ? "sidenav-link active" : "sidenav-link"} href="/libraries/business-classification/index">
                        Business Classification
                    </a>
                    <a className={props.activePage === 'requirements' ? "sidenav-link active" : "sidenav-link"} href="/libraries/requirements/index">
                        Requirements
                    </a>
                    <a className={props.activePage === 'socialClass' ? "sidenav-link active" : "sidenav-link"} href="/libraries/social-classification/index">
                        Social Classification
                    </a>
                    <a className={props.activePage === 'oicDesignation' ? "sidenav-link active" : "sidenav-link"} href="/libraries/oic-designation/index">
                        OIC Designation
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SidebarLibraries;