import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import isLoggedIn from "../store/isLoggedIn";

const PortalAdminRoute = ({component: Component, isPortalAdmin, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            isLoggedIn() && isPortalAdmin ?
                <Component {...props} />
            : <Redirect to="/dashboard" />
        )} />
    );
};

export default PortalAdminRoute;