import React, { useState, useEffect, useRef, } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import url from "../../environment";
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import token from '../../store/token';
import xAuthToken from '../../store/xAuthToken';
import store, { set } from "store";
import ImageUploaderField from '../../imports/Upload';
import LoadingService from '../../imports/LoadingService';

const individualSchema = Yup.object().shape({
	startupName: Yup.string().nullable(),
	designation: Yup.string().nullable(),
	lastName: Yup.string().nullable(),
	firstName: Yup.string().nullable(),
	suffix: Yup.string().nullable(),
	middleName: Yup.string().nullable(),
	citizenship: Yup.string().nullable(),
	others: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema;
		}else{
			return schema;
		}
	}).nullable(),
	requirement: Yup.string().nullable(),
	requirementFile: Yup.mixed().nullable(),
	requirementIdentifier: Yup.string() 
});

const businessSchema = Yup.object().shape({
	businessName: Yup.string().required('This is Required!').nullable(),
	businessClassification: Yup.string().required('This is Required!').nullable(),
	registrationNo: Yup.string().required('This is Required!').nullable(),
	yearFounding: Yup.string().nullable(),
	region: Yup.string().required('This is Required!').nullable(),
	province: Yup.string().required('This is Required!').nullable(),
	city: Yup.string().required('This is Required!').nullable(),
	barangay: Yup.string().required('This is Required!').nullable(),
	streetNo: Yup.string().nullable(),
	lotBlockUnit: Yup.string().nullable(),
	zipCode: Yup.string().required('This is Required!').nullable(),
	// isOIC: Yup.boolean(),
	designation: Yup.string().required('This is Required!').nullable(),
	lastName: Yup.string().required('This is Required!').nullable(),
	firstName: Yup.string().required('This is Required!').nullable(),
	suffix: Yup.string().nullable(),
	middleName: Yup.string().required('This is Required!').nullable(),
	citizenship: Yup.string().required('This is Required!').nullable(),
	others: Yup.string().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema.nullable();
		}else{
			return schema.required('This is Required!').nullable();
		}
	}).nullable(),
	contact_no: Yup.string().required('This is Required!').nullable(),
	registration: Yup.string().required('This is Required!').nullable(),
	registrationFile: Yup.mixed().required('This is Required!').nullable(),
	registrationIdentifier: Yup.string().nullable(),
	requirement: Yup.string().required('This is Required!').nullable(),
	requirementFile: Yup.mixed().required("This is Required!").nullable(),
	requirementIdentifier: Yup.string().nullable(),
    oicFile: Yup.mixed().nullable(),
    proofFile: Yup.mixed().nullable(),
    oicForeignOtherRequirement: Yup.string(),
    oicForeignOtherRequirementIdentifier: Yup.string(),
    oicForeignOtherRequirementFile: Yup.mixed().when('citizenship',
	(citizenship, schema) => {
		if(citizenship == 0){
			return schema.nullable();
		}else{
			return schema.required('This is Required!').nullable();
		}
	}).nullable(),

    description: Yup.string().required('This is Required!').nullable(),
	problemSolved: Yup.string().required('This is Required!').nullable(),
	idea: Yup.string().required('This is Required!').nullable(),
	usp: Yup.string().required('This is Required!').nullable(),
	startupStage: Yup.string().required('This is Required!').nullable(),
	classification: Yup.array().nullable(),

    companyEmail: Yup.string().email('Invalid Email Format!').required('This is Required!').nullable(),
    companyContactNumber: Yup.string().required('This is Required!').nullable(),
    website: Yup.string().nullable(),
    
    linkedin: Yup.string().nullable(),
    facebook: Yup.string().nullable(),
    twitter: Yup.string().nullable(),
    instagram: Yup.string().nullable(),
    youtube: Yup.string().nullable(),
});

const EditStartup = (props) => {
	document.title = 'Update PSDP Verification';
	const history = useHistory();
	const formikRef = useRef();

    const host = url.hostPort();
	const headers = {
		'Authorization': `Bearer ${token()}`,
        
	};
    
    const startupVersion = 2;

    const [loader, showLoading, hideLoading] = LoadingService();
  
    const user = props.user !== null ? props.user : {};
	const isLoggedIn = props.isLoggedIn ? props.isLoggedIn : false;
	const personalInfo = props.personalInfo && Object.keys(props.personalInfo).length !== 0 ? props.personalInfo : '';
    
    const [registrationId, setRegistrationId] = useState('');
    const [startupType, setStartupType] = useState('');

	const [registrationList, setRegistrationList] = useState([]);
    const [proofList, setProofList] = useState([]);
	const [requirementList, setRequirementList] = useState([]);
	const [transformRequirementList, setTransformList] = useState([]);

	const [regionList, setRegionList] = useState([]);
	const [provinceList, setProvinceList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [barangayList, setBarangayList] = useState([]);

	const [selectedRegion, setSelectedRegion] = useState('');
	const [selectedProvince, setSelectedProvince] = useState('');
	const [selectedCity, setSelectedCity] = useState('');
	const [selectedBarangay, setSelectedBarangay] = useState('');

    const [isLoaded, setIsLoaded] = useState(false);

	const [registrationLabel, setRegistrationLabel] = useState('DTI BN');
	const setRegistrationLabelHandler = (id) => {
		if(id === 1){
			setRegistrationLabel('DTI (For Sole Proprietorship)');
		}else if(id === 2 || id === 3 || id === 4 || id === 6){
			setRegistrationLabel('SEC Certificate of Registration (For Partnership, One Person Corporation, Joint Venture, and Association)');
		}else if(id === 5){
			setRegistrationLabel('CDA Certificate of Registration (For Cooperative)');
		}else if(id === 7){
			setRegistrationLabel("Mayor's Permit (For NECs)");
        }
	};

	const [citizenshipStatus, setStatus] = useState(0);
	const citizenshipHandler = (value) =>{
		setStatus(value);
	};

	const [isOfficerInCharge, setOfficerInCharge] = useState(false);
	const setOfficerInChargeHandler = () => {
		setOfficerInCharge(!isOfficerInCharge);
	};

	const [requirementLabel, setRequirmentLabel] = useState('Government-Issued ID (for Filipino OIC)');
	const setRequirmentLabelHandler = (value) => {
		setRequirmentLabel(value);
	};

	const [requirementIdentifier, setRequirementIdentifier] = useState('');
	const setRequirementIdentifierHandler = (value) => {
		setRequirementIdentifier(value);
	};

    const [businessClassificationList, setBusinessClassificationList] = useState([]);

	const designationOptions = ['CEO', 'Founder', 'President', 'Owner'];

	const startupDevelopmentOptions = [
		{
			"name": "Formation",
			"id": 1
		},
		{
			"name": "Validation",
			"id": 2
		},
		{
			"name": "Growth",
			"id": 3
		},
	];

	const [classificationList, setClassificationList] = useState([]);

    const [showDescriptionLabel, setShowDescriptionLabel] = useState([]);

    const [individualValue, setIndividualValue] = useState({
		'startupName': '',
		'designation': '',
		'lastName': '',
		'firstName': '',
		'middleName': '',
		'citizenship': 0,
		'others': '',
		'requirement': '',
		'requirementFile': {},
		'requirementIdentifier': '',
        'oicFile': {}
    });

    const [individualApproval, setIndividualApproval] = useState({
        'firstname': {
            'hidden': true,
            'remarks': ''
        },
        'middlename': {
            'hidden': true,
            'remarks': ''
        },
        'lastname': {
            'hidden': true,
            'remarks': ''
        },
        'extname': {
            'hidden': true,
            'remarks': ''
        },
        'designation': {
            'hidden': true,
            'remarks': ''
        },
        'citizenship': {
            'hidden': true,
            'remarks': ''
        },
        'startup_name': {
            'hidden': true,
            'remarks': ''
        },
        'startup_description': {
            'hidden': true,
            'remarks': ''
        },
        'problems_solved': {
            'hidden': true,
            'remarks': ''
        },
        'products_offered': {
            'hidden': true,
            'remarks': ''
        },
        'usp': {
            'hidden': true,
            'remarks': ''
        },
        'startup_stage': {
            'hidden': true,
            'remarks': ''
        },
        'industry_classification': {
            'hidden': true,
            'remarks': ''
        },
        'startup_website': {
            'hidden': true,
            'remarks': ''
        },
        'address_line_1': {
            'hidden': true,
            'remarks': ''
        },
        'oicFile': {
            'hidden': true,
            'remarks': ''
        },
    });

	const [businessValue, setBusinessValue] = useState({
		'businessName': '',
		'businessClassification': 1,
		'registrationNo': '',
		'yearFounding': '',
		'region': '',
		'province': '',
		'city': '',
		'barangay': '',
		'streetNo': '',
		'lotBlockUnit': '',
		'zipCode': '',
		// 'isOIC': false,
		'designation': '',
		'lastName': '',
		'firstName': '',
		'middleName': '',
        'suffix': '',
		'citizenship': 0,
		'others': '',
        'contact_no': '',
		'registration': '',
		'registrationFile': '',
		'registrationIdentifier': '',
		'requirement': '',
		'requirementFile': '',
		'requirementIdentifier': '',
        'oicFile': {},
        'proofFile': {},

        'oicForeignOtherRequirement': '',
        'oicForeignOtherRequirementIdentifier': '',
        'oicForeignOtherRequirementFile': {},

        'description': '',
		'problemSolved': '',
		'idea': '',
		'usp': '',
		'startupStage': 1,
		'classification': [],
		'website': ''
	});

    const [businessApproval, setBusinessApproval] = useState({
        'firstname': {
            'hidden': true,
            'remarks': ''
        },
        'middlename': {
            'hidden': true,
            'remarks': ''
        },
        'lastname': {
            'hidden': true,
            'remarks': ''
        },
        'extname': {
            'hidden': true,
            'remarks': ''
        },
        'designation': {
            'hidden': true,
            'remarks': ''
        },
        'citizenship': {
            'hidden': true,
            'remarks': ''
        },
        'contact_no': {
            'hidden': true,
            'remarks': ''
        },
        'business_name': {
            'hidden': true,
            'remarks': ''
        },
        'business_class_id': {
            'hidden': true,
            'remarks': ''
        },
        'business_regulatory_no': {
            'hidden': true,
            'remarks': ''
        },
        'founding_year': {
            'hidden': true,
            'remarks': ''
        },
        'startup_description': {
            'hidden': true,
            'remarks': ''
        },
        'problems_solved': {
            'hidden': true,
            'remarks': ''
        },
        'products_offered': {
            'hidden': true,
            'remarks': ''
        },
        'usp': {
            'hidden': true,
            'remarks': ''
        },
        'startup_stage': {
            'hidden': true,
            'remarks': ''
        },
        'industry_classification': {
            'hidden': true,
            'remarks': ''
        },
        'startup_website': {
            'hidden': true,
            'remarks': ''
        },
        'address_line_1': {
            'hidden': true,
            'remarks': ''
        },
        'proofFile': {
            'hidden': true,
            'remarks': ''
        },
        'oicFile': {
            'hidden': true,
            'remarks': ''
        },
        'oicForeignOtherRequirementFile': {
            'hidden': true,
            'remarks': ''
        },
    });

    const [showOICLabel, setShowOICLabel] = useState(true);
    const [showBusinessDesc, setShowBusinessDesc] = useState(true);

    const getDetails = async (userId, startupType) => {
        showLoading(); /// loading starts
        let api = host + `/api/v1/registration/approvals?user_id=${userId}&view=all&`;
        let requirementApi = host + `/api/v1/registration/approvals?user_id=${userId}&view=all&`;
        if(startupType == 1){//individual
            if(startupVersion === 1){
                api = api + `group_by=officer_in_charge,reg_startup`;
            }else{
                api = api + `group_by=reg_startup`;
            }
            requirementApi = requirementApi + `reg_requirements&requirement_for=startup_info_individual`;
        }else{//business
            api = api + `group_by=officer_in_charge,office_address,reg_startup`;
            requirementApi = requirementApi + `reg_requirements&requirement_for=startup_info_business`;
        }
        try{
            const response = await axios.get(
                api, {headers: headers},
            );
            const result = response.data;
            const registration = result.items;
            let data;

            if(startupVersion === 1){
                data = registration;
            }else{
                if(startupType === 1){
                    let stage = registration.filter(item => { return item.field_name === 'startup_stage' });
                    let classification = registration.filter(item => { return item.field_name === 'industry_classification' });
                    data = [...stage, ...classification];
                }else{
                    data = registration;
                }
            }
        
            const requirementResponse = await axios.get(
                requirementApi, {headers: headers},
            );
            const requirementResult = requirementResponse.data;
            const requirementData = requirementResult.items;

            getRegistration(data, requirementData);
        }catch(ex){
          let errorMessage = "Registration not found.";
          toast.error(errorMessage, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          history.push(`/mycompany?_id=${user.user_id}`);
        }
    };

    const getRegistration = (fields, requirementData) => {
        let params = 'expand=requirements.requirement.requirement_category,startupInfo,startupInfo.industry_classification,startupInfo.startup_stage';
		let api = host + `/api/v1/registrations?user_id=${user.user_id}&${params}`;
		axios.get(api, {headers: headers}).then(res => {
            hideLoading(); /// loading stops
			let result = res.data;
			let data = result.items;
			if(data.length != 0){
                let registration = data[0];
                let startup = registration.startupInfo[0];
                const oic = startup.officerInCharge[0];
                let citizenship = oic.citizenship === "" ? 0 : oic.citizenship !== "Filipino" ? 1 : 0;
                setStartupType(startup.startup_type);
                setIsLoaded(true);
                setDetails(registration);
                checkApproval(fields, requirementData, startup.startup_type, citizenship);
			}else{

			}
		}, error => {
            hideLoading(); /// loading stops
			console.log("error in get registration", error);
		});
    };

    const setDetails = (data) => {
        const startup = data.startupInfo[0];
        const startup_type = startup.startup_type;
        const requirements = data.requirements;
        const oic = startup.officerInCharge[0];
        let citizenship = oic.citizenship === "" ? 0 : oic.citizenship !== "Filipino" ? 1 : 0;
        if(startup_type === 1){//individual
            const info = {
                'startupName': startup.startup_name,
                'designation': oic.designation,
                'lastName': oic.lastname,
                'firstName': oic.firstname,
                'middleName': oic.middlename,
                'suffix': oic.extname,
                'citizenship': citizenship,
                'others': oic.citizenship !== "Filipino" ? oic.citizenship : "",
                'contact_no': oic.contact_no,
                'requirement': '',
                'requirementFile': {},
                'requirementIdentifier': '',
                'oicFile': '',

                'description': startup.startup_description,
                'problemSolved': startup.problems_solved,
                'idea': startup.products_offered,
                'usp': startup.usp,
                'startupStage': startup.startup_stage,
                'classification': startup.industry_classification,
                'website': startup.startup_website
            }
            setRequirements(requirements, startup_type, info, citizenship);
            getClassification(startup.industry_classification);
        }else{//business
            const officeAddress = startup.officeAddress[0];
            const info = {
                'businessName': startup.business_name,
                'startupName': startup.startup_name,
                'businessClassification': startup.businessClass.business_id,
                'registrationNo': startup.business_regulatory_no,
                'yearFounding': startup.founding_year,
                'region': officeAddress.region_psgc,
                'province': officeAddress.province_psgc,
                'city': officeAddress.city_municipality_psgc,
                'barangay': officeAddress.barangay_psgc,
                'lotBlockUnit': officeAddress.address_opt_1,
                'streetNo': officeAddress.address_opt_2,
                'zipCode': officeAddress.zip_code,
                // 'isOIC': false,
                'designation': oic.designation,
                'lastName': oic.lastname,
                'firstName': oic.firstname,
                'middleName': oic.middlename,
                'suffix': oic.extname,
                'citizenship': citizenship,
                'others': oic.citizenship !== "Filipino" ? oic.citizenship : "",
                'contact_no': oic.contact_no,
                'registration': '',
                'registrationFile': '',
                'registrationIdentifier': '',
                'requirement': '',
                'requirementFile': '',
                'requirementIdentifier': '',
                'oicFile': '',
                'proofFile': '',

                'description': startup.startup_description,
                'problemSolved': startup.problems_solved,
                'idea': startup.products_offered,
                'usp': startup.usp,
                'startupStage': startup.startup_stage,
                'classification': startup.industry_classification,

                'companyEmail': startup.company_email,
                'companyContactNumber': startup.company_contact_no,
                'website': startup.company_website,

                'linkedin': startup.linkedin_account,
                'facebook': startup.facebook_account,
                'twitter': startup.twitter_account,
                'instagram': startup.instagram_account,
                'youtube': startup.youtube_account,
            }
            setRequirements(requirements, startup_type, info, citizenship);
            getClassification(startup.industry_classification);
        }
    };

    const setRequirements = (requirement, startup_type, info, citizenship) => {
        if(startup_type === 1){//individual
            if(startupVersion === 1){
                let files = requirement.filter(item => item.requirement.requirement_category === "STARTUP_INFO_INDIVIDUAL")[0];
                info.requirement = files.requirement.requirement_id;
                info.requirementIdentifier = files.identifier_no;
                info.oicFile = files;
            }
            setFormikRef(info, startup_type);
            setIndividualValue(info);
        }else{//business
            let files = requirement.filter(item => item.requirement.requirement_category === "STARTUP_INFO_BUSINESS");
            if(startupVersion === 1){
                let proof = files.filter(item => item.identifier_no.includes('For'))[0];
                let oic = files.filter(item => !item.identifier_no.includes('For'))[0];
                info.registration = proof.requirement.requirement_id;
                info.requirement = oic.requirement.requirement_id;
                info.registrationIdentifier = proof.identifier_no;
                info.requirementIdentifier = oic.identifier_no;
                info.proofFile = proof;
                info.oicFile = oic;
            }else{
                let proof = files.filter(item => item.identifier_no.includes('For'))[0];
                info.registration = proof.requirement.requirement_id;
                info.registrationIdentifier = proof.identifier_no;
                info.proofFile = proof;

                let oic = files.filter(item => !item.identifier_no.includes('For'));
                oic = oic.filter(item => item.identifier_no !== 'Business Logo');
                let mainOICFile = oic.filter(item => item.identifier_no !== "Passport Datasheet")[0];
                info.requirement = mainOICFile.requirement.requirement_id;
                info.requirementIdentifier = mainOICFile.identifier_no;
                info.oicFile = mainOICFile;

                if(citizenship === 1){
                    let otherOICFile = oic.filter(item => item.identifier_no === "Passport Datasheet")[0];
                    info.oicForeignOtherRequirement = otherOICFile.requirement.requirement_id;
                    info.oicForeignOtherRequirementIdentifier = otherOICFile.identifier_no;
                    info.oicForeignOtherRequirementFile = otherOICFile;
                }else{
                    info.oicForeignOtherRequirement = '';
                    info.oicForeignOtherRequirementIdentifier = '';
                    info.oicForeignOtherRequirementFile = {};
                }
            }
            setFormikRef(info, startup_type);
            setBusinessValue(info);
        }
    };

    const setFormikRef = (info, startup_type) => {
        if(startup_type === 1){//individual
            setIndividualValue(info);
            formikRef.current.setFieldValue('startupName', info.startupName);
            formikRef.current.setFieldValue('designation', info.designation);
            formikRef.current.setFieldValue('lastName', info.lastName);
            formikRef.current.setFieldValue('firstName', info.firstName);
            formikRef.current.setFieldValue('middleName', info.middleName);
            formikRef.current.setFieldValue('suffix', info.suffix);
            formikRef.current.setFieldValue('citizenship', info.citizenship);
            formikRef.current.setFieldValue('others', info.others);
            formikRef.current.setFieldValue('requirement', info.requirement);
            formikRef.current.setFieldValue('requirementFile', info.oicFile);
            formikRef.current.setFieldValue('requirementIdentifier', info.requirementIdentifier);
            formikRef.current.setFieldValue('oicFile', info.oicFile);

            formikRef.current.setFieldValue('description', info.description);
			formikRef.current.setFieldValue('problemSolved', info.problemSolved);
			formikRef.current.setFieldValue('idea', info.idea);
			formikRef.current.setFieldValue('usp', info.usp);
			formikRef.current.setFieldValue('startupStage', info.startupStage);
			formikRef.current.setFieldValue('classification', info.classification);
			formikRef.current.setFieldValue('website', info.website);
        }else{//business
            setBusinessValue(info);
            getProvince(info.region);
            getCity(info.province);
            getBarangay(info.city);
            formikRef.current.setFieldValue('businessName', info.businessName);
            formikRef.current.setFieldValue('startupName', info.startupName);
            formikRef.current.setFieldValue('businessClassification', info.businessClassification);
            formikRef.current.setFieldValue('registrationNo', info.registrationNo);
            formikRef.current.setFieldValue('yearFounding', info.yearFounding);
            formikRef.current.setFieldValue('region', info.region);
            formikRef.current.setFieldValue('province', info.province);
            formikRef.current.setFieldValue('city', info.city);
            formikRef.current.setFieldValue('barangay', info.barangay);
            formikRef.current.setFieldValue('lotBlockUnit', info.lotBlockUnit === null ? "" : info.lotBlockUnit);
            formikRef.current.setFieldValue('streetNo', info.streetNo === null ? "" : info.streetNo);
            formikRef.current.setFieldValue('zipCode', info.zipCode);
            formikRef.current.setFieldValue('designation', info.designation);
            formikRef.current.setFieldValue('lastName', info.lastName);
            formikRef.current.setFieldValue('firstName', info.firstName);
            formikRef.current.setFieldValue('middleName', info.middleName);
            formikRef.current.setFieldValue('citizenship', info.citizenship);
            formikRef.current.setFieldValue('others', info.others);
            formikRef.current.setFieldValue('contact_no', info.contact_no);
            formikRef.current.setFieldValue('registration', info.registration);
            formikRef.current.setFieldValue('registrationFile', info.proofFile);
            formikRef.current.setFieldValue('registrationIdentifier', info.registrationIdentifier);
            formikRef.current.setFieldValue('requirement', info.requirement);
            formikRef.current.setFieldValue('requirementFile', info.oicFile);
            formikRef.current.setFieldValue('requirementIdentifier', info.requirementIdentifier);
            formikRef.current.setFieldValue('oicFile', info.oicFile);
            formikRef.current.setFieldValue('proofFile', info.proofFile);
            
            formikRef.current.setFieldValue('oicForeignOtherRequirement', info.oicForeignOtherRequirement);
            formikRef.current.setFieldValue('oicForeignOtherRequirementIdentifier', info.oicForeignOtherRequirementIdentifier);
            formikRef.current.setFieldValue('oicForeignOtherRequirementFile', info.oicForeignOtherRequirementFile);

            formikRef.current.setFieldValue('description', info.description);
			formikRef.current.setFieldValue('problemSolved', info.problemSolved);
			formikRef.current.setFieldValue('idea', info.idea);
			formikRef.current.setFieldValue('usp', info.usp);
			formikRef.current.setFieldValue('startupStage', info.startupStage);
			formikRef.current.setFieldValue('classification', info.classification);

			formikRef.current.setFieldValue('companyEmail', info.companyEmail);
			formikRef.current.setFieldValue('companyContactNumber', info.companyContactNumber);
			formikRef.current.setFieldValue('website', info.website);

			formikRef.current.setFieldValue('linkedin', info.linkedin);
			formikRef.current.setFieldValue('facebook', info.facebook);
			formikRef.current.setFieldValue('twitter', info.twitter);
			formikRef.current.setFieldValue('instagram', info.instagram);
			formikRef.current.setFieldValue('youtube', info.youtube);

            info.citizenship === 0 ? citizenshipHandler(0) : citizenshipHandler(1);
            info.citizenship === 0 ? setRequirmentLabel('Government-Issued ID (for Filipino OIC)') : setRequirmentLabel('Other Issued ID (for Non-Filipino OIC)');
        }
    };
    
    const checkApproval = (fields, requirements, startup_type, citizenship) => {
        if(startup_type === 1){ //individual
            for(let x = 0; x < fields.length; x++){
                if(fields[x].remarks !== null && fields[x].field_status === 2){
                    let name = fields[x].field_name;
                    const oldList = individualApproval;
                    oldList[name] = {...oldList[name], hidden: false, remarks: fields[x].remarks};
                    setIndividualApproval(oldList);
                }
            }
            if(startupVersion === 1){
                let files = requirements[0];
                if(files.remarks !== null && files.field_status === 2){
                    const oldList = individualApproval;
                    oldList.oicFile = {...oldList.oicFile, hidden: false, remarks: files.remarks};
                    setBusinessApproval(oldList);
                    formikRef.current.setFieldValue('requirementFile', '');
                }
            }else{
                formikRef.current.setFieldValue('requirementFile', {});
            }
        }else{ //business
            for(let x = 0; x < fields.length; x++){
                if(fields[x].remarks !== null && fields[x].field_status === 2){
                    let name = fields[x].field_name;
                    const oldList = businessApproval;
                    oldList[name] = {...oldList[name], hidden: false, remarks: fields[x].remarks};
                    setBusinessApproval(oldList);
                }
            }
            for(let x = 0; x < fields.length; x++){
                if(fields[x].field_group === 'officer_in_charge'){
                    if(fields[x].field_status === 2){
                        setShowOICLabel(false);
                        break;
                    }
                }
            }
            for(let x = 0; x < fields.length; x++){
                if(fields[x].field_name === 'startup_description' || fields[x].field_name === 'problems_solved' ||
                fields[x].field_name === 'products_offered' || fields[x].field_name === 'usp' ||
                fields[x].field_name === 'startup_stage' || fields[x].field_name === 'industry_classification' ||
                fields[x].field_name === 'startup_website'){
                    if(fields[x].field_status === 2){
                        setShowBusinessDesc(false);
                        break;
                    }
                }
            }
            let proof = requirements.filter(item => item.field_value.includes('For'))[0];
            if(proof.remarks !== null && proof.field_status === 2){
                const oldList = businessApproval;
                oldList.proofFile = {...oldList.proofFile, hidden: false, remarks: proof.remarks};
                setBusinessApproval(oldList);
                formikRef.current.setFieldValue('registrationFile', '');
            }

            let oic = requirements.filter(item => !item.field_value.includes('For'));
            oic = oic.filter(item => item.identifier_no !== 'Business Logo');
            let mainOICFile = oic.filter(item => item.field_value !== "Passport Datasheet")[0];
            if(mainOICFile.remarks !== null && mainOICFile.field_status === 2){
                const oldList = businessApproval;
                oldList.oicFile = {...oldList.oicFile, hidden: false, remarks: mainOICFile.remarks};
                setBusinessApproval(oldList);
                formikRef.current.setFieldValue('requirementFile', '');
            }

            if(citizenship === 1){
                let otherOICFile = oic.filter(item => item.field_value === "Passport Datasheet")[0];
                if(otherOICFile.remarks !== null && otherOICFile.field_status === 2){
                    const oldList = businessApproval;
                    oldList.oicForeignOtherRequirementFile = {...oldList.oicForeignOtherRequirementFile, hidden: false, remarks: otherOICFile.remarks};
                    setBusinessApproval(oldList);
                    formikRef.current.setFieldValue('oicForeignOtherRequirementFile', '');
                }
            }
        }
        setIsLoaded(true);
    };

	const onClickOfficerInCharge = (value, setFieldValue, touched, errors) => {
		if(value){
			setFieldValue('lastName', personalInfo.lastname);
			setFieldValue('firstName', personalInfo.firstname);
			setFieldValue('suffix', personalInfo.extname);
			setFieldValue('middleName', personalInfo.middlename);
			setFieldValue('citizenship', personalInfo.citizenship.toLowerCase() == 'filipino' ? 0 : 1);
			setFieldValue('others', personalInfo.citizenship.toLowerCase() == 'filipino' ? '' : personalInfo.citizenship);
			citizenshipHandler(personalInfo.citizenship.toLowerCase() == 'filipino' ? 0 : 1);
			setRequirmentLabelHandler(personalInfo.citizenship.toLowerCase() == 'filipino' ? 'Government-Issued ID (for Filipino OIC)' : 'Other Issued ID (for Non-Filipino OIC)');
		
			touched.lastName = false;
			touched.firstName = false;
			touched.suffix = false;
			touched.middleName = false;
		}else{
			setFieldValue('lastName', '');
			setFieldValue('firstName','');
			setFieldValue('suffix', '');
			setFieldValue('middleName', '');
			setFieldValue('citizenship', 0);
			setFieldValue('others', '');
			citizenshipHandler(0);
		}
		setFieldValue('isOIC', value);
	};

	const submitIndividual = (values) => {
        showLoading();
        let transformClassification = values.classification.map((value) => {
            if(typeof value === 'object' && value !== null){
                return value.id;
            }else{
                return value;
            }
        });
        let params = {
            registration_id: registrationId,
            startup_name: values.startupName,
            startup_description: values.description,
            problems_solved: values.problemSolved,
            products_offered: values.idea,
            usp: values.usp,
            startup_stage: values.startupStage,
            industry_classification: transformClassification,
            startup_website: values.website == undefined ? "" : values.website,
            firstname: values.firstName,
            middlename: values.middleName,
            lastname: values.lastName,
            extname: values.suffix == undefined ? "" : values.suffix,
            designation: values.designation,
            citizenship: values.citizenship == 1 ? values.others : "Filipino",
        };
        let fileArray = [];
        if(!individualApproval.oicFile.hidden){
            let oic = {
                "_id": values.requirement,
                "name": values.requirementIdentifier,
                "file": values.requirementFile[0]
            }
            fileArray.push(oic);
        }
        // console.log("awit", fileArray);
        let endpoint = "/api/v1/register/startup-individual";
        let api = host + endpoint;
        axios.post(api, params, { headers: headers }).then(
            (res) => {
                let result = res.data;
                let data = result.data;
                if(fileArray.length !== 0){
                    uploadFileSubmit(registrationId, fileArray);
                }else{
                    hideLoading();
                    let message = "Successfully updated!";
                    toast.info(message, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });
                    history.push(`/mycompany?_id=${user.user_id}`);
                }
            },
            (error) => {
                hideLoading();
                let responseError = error.response.data;
                let errorMessage = responseError.message;
                let message = errorMessage[Object.keys(errorMessage)[0]];
                let firstError = message[0];
                toast.error(firstError, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }
        )
    };

    const submitBusiness = (values) => {
        showLoading();
        let transformClassification = values.classification.map((value) => {
            if(typeof value === 'object' && value !== null){
                return value.id;
            }else{
                return value;
            }
        });
		let region = regionList.find(item => item.region_psgc === values.region);
		let province = provinceList.find(item => item.province_psgc === values.province);
		let city = cityList.find(item => item.city_municipality_psgc === values.city);
		let barangay = barangayList.find(item => item.barangay_psgc === values.barangay);
        let params = {
            registration_id: registrationId,
            business_name: values.businessName,
            startup_name: values.startupName,
            business_class_id: values.businessClassification,
            business_regulatory_no: values.registrationNo,
            founding_year: values.yearFounding,
            startup_description: values.description,
            problems_solved: values.problemSolved,
            products_offered: values.idea,
            usp: values.usp,
            startup_stage: values.startupStage,
            industry_classification: transformClassification,

            company_email: values.companyEmail,
            company_contact_no: values.companyContactNumber,
            company_website: values.website == undefined ? "" : values.website,

            linkedin_account: values.linkedin,
            facebook_account: values.facebook,
            twitter_account: values.twitter,
            instagram_account: values.instagram,
            youtube_account: values.youtube,

            firstname: values.firstName,
            middlename: values.middleName,
            lastname: values.lastName,
            extname: values.suffix == undefined ? "" : values.suffix,
            designation: values.designation,
            citizenship: values.citizenship == 1 ? values.others : "Filipino",
            contact_no: values.contact_no,
			address_line_1: `${values.lotBlockUnit} ${values.streetNo}, ${barangay.barangay_name}, ${city.city_municipality_name} ${province.province_name} ${region.region_name} ${values.zipCode}`,
            address_line_2: "",
            region_psgc: values.region,
            province_psgc: values.province,
            city_municipality_psgc: values.city,
            barangay_psgc: values.barangay,
            zip_code: values.zipCode,
			address_opt_1: values.lotBlockUnit,
			address_opt_2: values.streetNo
        };

        let fileArray = [];
        if(!businessApproval.proofFile.hidden){
            let proofFile = {
				"_id": values.registration,
				"name": values.registrationIdentifier,
				"file": values.registrationFile[0]
			}
            fileArray.push(proofFile);
        }
        if(!businessApproval.oicFile.hidden){
            let oicFile = {
				"_id": values.requirement,
				"name": values.requirementIdentifier,
				"file": values.requirementFile[0]
			}
            fileArray.push(oicFile);
        }
        if(startupVersion === 2){
            if(!businessApproval.oicForeignOtherRequirementFile.hidden){
                let otherForeignFile = {
                    "_id": values.oicForeignOtherRequirement,
                    "name": values.oicForeignOtherRequirementIdentifier,
                    "file": values.oicForeignOtherRequirementFile[0]
                }
                fileArray.push(otherForeignFile);
            }
        }
        // console.log("wewew", fileArray);
        let endpoint = "/api/v1/register/startup-business";
        let api = host + endpoint;
        axios.post(api, params, { headers: headers }).then(
            (res) => {
                let result = res.data;
                let data = result.data;
                if(fileArray.length !== 0){
                    uploadFileSubmit(registrationId, fileArray);
                }else{
                    hideLoading();
                    let message = "Successfully updated!";
                    toast.info(message, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });
                    history.push(`/mycompany?_id=${user.user_id}`);
                }
            },
            (error) => {
                hideLoading();
                let responseError = error.response.data;
                let errorMessage = responseError.message;
                let message = errorMessage[Object.keys(errorMessage)[0]];
                let firstError = message[0];
                toast.error(firstError, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                });
            }
        );
    };

    const uploadFileSubmit = async (registration_id, fileArray) => {
        let axiosArray = [];
    
        for (let x = 0; x < fileArray.length; x++) {
            let formData = new FormData();
            formData.append("registration_id", registration_id);
            formData.append("requirement_id", fileArray[x]._id);
            formData.append("identifier_no", fileArray[x].name);
            formData.append('upload_file', fileArray[x].file, `${registration_id}-${fileArray[x].file.name}`);
    
            let api = host + "/api/v1/requirement/upload";
            let newPromise = axios({
                method: "post",
                url: api,
                data: formData,
                headers: headers,
            });
            axiosArray.push(newPromise);
        }
    
        axios.all(axiosArray).then(
            axios.spread((...responses) => {
            responses.forEach((res) => {});
                hideLoading();
                let message = "Successfully updated!";
                toast.info(message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
                history.push(`/mycompany?_id=${user.user_id}`);
            })
        ).catch((error) => {
            hideLoading();
            console.log("error in upload", error);
            let errorMessage = "Error!";
            toast.error(errorMessage, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            });
        });
    };

    const getClassification = (classificationArray) => {
		const endpoint = `/api/v1/industry-classifications?view=all&sort=name`;
		const api = host + endpoint;
        axios.get(api, {headers: headers}).then(res => {
			let result = res.data;
			let data = result.items;
			let transform = data.map(value => {
                value.checked = false;
                return value;
            });
            transform.map(value => {
                let find = classificationArray.indexOf(value.id);
                if(find !== -1){
                    value.checked = true;
                }
                return value;
            });
            setClassificationList(transform);
		}, error => {
			console.log("error in get classification", error);
		});
    };
    
    const getBusinessClassification = () => {
        let api = `/api/v1/business-class`;
        api = host + api;
        axios.get(api, { headers: headers }).then(
            (res) => {
                let result = res.data;
                let data = result.items;
                setBusinessClassificationList(data);
            },(error) => {
                console.log("error", error);
            }
        )
    };

	const getRegion = () => {
		const endpoint = `/api/v1/location/regions?sort=region_code&view=all`;
		const api = host + endpoint;
		if(regionList.length == 0){
			axios.get(api, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.items;
                data = data.filter(function(item){ return item.region_psgc !== "180000000"});
				setRegionList(data);
			}, error => {
			});
		}
	};

	const getProvince = (value) =>{
		if(value != ""){
			const endpoint = `/api/v1/location/provinces?sort=province_name&region_psgc=${value}&view=all`;
			const api = host + endpoint;
			axios.get(api, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.items;
				setProvinceList(data);
			}, error => {
			})
		}
	};

	const getCity = (value) => {
		if(value != ""){
			const endpoint = `/api/v1/location/cities-municipalities?sort=city_municipality_name&province_psgc=${value}&view=all`;
			const api = host + endpoint;
			axios.get(api, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.items;
				setCityList(data);
			}, error => {
			})
		}
	};

	const getBarangay = (value) => {
		if(value != ""){
			const endpoint = `/api/v1/location/barangays?sort=barangay_name&view=all&city_municipality_psgc=${value}`;
			const api = host + endpoint;
			axios.get(api, {headers: headers}).then(res => {
				let result = res.data;
				let data = result.items;
				setBarangayList(data);
			}, error => {
			})
		}
	};

	async function getRequirementList(startup_type) {
        let endpoint = '';
        if(startup_type == 1){
            endpoint = `/api/v1/requirements?requirement_category=STARTUP_INFO_INDIVIDUAL&expand=requirement_type,requirement_category&view=all`;
        }else{
            endpoint = `/api/v1/requirements?requirement_category=STARTUP_INFO_BUSINESS&expand=requirement_type,requirement_category&view=all`;
        }
		const api = host + endpoint;
		let response = await axios.get(api, {headers: headers});
		let result = response.data;
		let data = result.items;
		setRequirementList(data);
		mapRequirementList(data);
		mapRegistrationList(data);
	};

	const mapRequirementList = (data) => {
		let newList = [];
		if(citizenshipStatus == 0){ //filipino
			newList = data.filter(function(item){ return item.requirement_type === 1 });
		}else{
			let newData = data.filter(function(item){ return item.requirement_type === 2 });
            newData.map(function(item){
                if(item.requirement_name === "Special Investor's Resident Visa"){
                    newList.push(item);
                }else if(item.requirement_name === "Certificate of Authority to Engage in Business in the Philippines"){
                    newList.push(item);
                }else if(item.requirement_name === "Others"){
                    newList.push(item);
                }
            });
		}
		setTransformList(newList);
	};

	const mapRegistrationList = (data) => {
		let newList = [];
        let proof = [];
		newList = data.filter(function(item){ return item.requirement_type === 2 });
		newList.map(function(item){
			if(item.requirement_name === "DTI Registration"){
				item.requirement_name = "DTI (For Sole Proprietorship)";
                proof.push(item);
			}else if(item.requirement_name === "SEC Certificate of Registration"){
				item.requirement_name = "SEC Certificate of Registration (For Partnership, One Person Corporation, Joint Venture, and Association)";
                proof.push(item);
			}else if(item.requirement_name === "CDA Certificate of Registration"){
				item.requirement_name = "CDA Certificate of Registration (For Cooperative)";
                proof.push(item);
			}else if(item.requirement_name === "Mayor's Permit"){
				item.requirement_name = "Mayor's Permit (For NECs)";
                proof.push(item);
			}
		});
        setProofList(proof);
	};

	const showOthers = (touched, errors, values) => {
		return <div className="form-group">
			<Field value={values.others || ''} type="text" name="others" className={`form-control form-control-sm ${touched.others && errors.others ? "is-invalid" : ""}`} />
			<ErrorMessage component="div" name="others" className="invalid-feedback" />
		</div>
	};

	useEffect( () => {
		let newList = [];
		if(citizenshipStatus == 0){ //filipino
			newList = requirementList.filter(function(item){ return item.requirement_type === 1 });
		}else{
			let newData = requirementList.filter(function(item){ return item.requirement_type === 2 });
            newData.map(function(item){
                if(item.requirement_name === "Special Investor's Resident Visa"){
                    newList.push(item);
                }else if(item.requirement_name === "Certificate of Authority to Engage in Business in the Philippines"){
                    newList.push(item);
                }else if(item.requirement_name === "Others"){
                    newList.push(item);
                }
            });
		}
		setTransformList(newList);
	}, [citizenshipStatus]);

    const viewFile = (uuid, name) => {
        let endpoint = `/api/v1/requirement/download?uuid=${uuid}`;
        let api = host + endpoint;
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = name;
        axios.get(api, {responseType: "blob", headers: headers}).then((res) => {
          let responseHeaders = res.headers;
          let content = Object.values(responseHeaders);
          content = content[0];
          //for download
          // if(content === "application/pdf"){
          //   // link.href = URL.createObjectURL(
          //   //   new Blob([res.data], { type: "application/pdf" })
          //   // );
          //   // link.click();
          // }else{
          //   // link.href = URL.createObjectURL(
          //   //   new Blob([res.data], { type: "image/jpg" })
          //   // );
          //   // link.click();
          // }
          
          //new tab
          let fileURL = window.URL.createObjectURL(res.data);
          let tab = window.open();
          tab.location.href = fileURL;
        }, error => {
          console.log("error", error);
        });
    };

    const back = () => {
        history.push(`/mycompany?_id=${user.user_id}`);
    }

    useEffect( () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let _id = params.get('reg_id');
        let startup_type = params.get('startup_type');
    
        setRegistrationId(_id);
        getRegion();
        getDetails(user.user_id, startup_type);
		getRequirementList(startup_type);
        getBusinessClassification();
    }, []);

	return (
		<React.Fragment>
            {loader}
			<Header isLoggedIn={isLoggedIn} />
			<div className="startup-business-container pt-5">
				<div className="container text-left">
                    <h4 className="mb-4">Update Startup {startupType === 1 ? "Individual" : "Business"} Information</h4>
                    {!isLoaded ? null : 
                        (startupType === 1 ? 
                            <div className="col-md-6">
                                <Formik
                                    innerRef={formikRef}
                                    validationSchema={individualSchema}
                                    initialValues={Object.assign(individualValue)}
                                    onSubmit={submitIndividual}
                                    key="individualForm"
                                >
                                    {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values, field }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <div className="form-group" hidden={individualApproval.startup_name.hidden}>
                                                <label htmlFor="startupName"><strong>Startup Name</strong></label>
                                                <Field type="text" value={values.startupName || ''} name="startupName" className={`form-control ${touched.startupName && errors.startupName ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="startupName" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={individualApproval.startup_name.hidden}>
                                                <label>Remarks: <small className="red">{individualApproval.startup_name.remarks}</small></label>
                                            </div>
                                            
                                            <div className="startup-individual-container page-1">
                                                <label><strong>Officer-in-Charge Information</strong></label>
                                                <div className="border-left pl-3">
                                                    <div className="form-group" hidden={individualApproval.designation.hidden}>
                                                        <label htmlFor="designation"><strong>Designation</strong></label>
                                                        <select name="designation"
                                                            className={`form-control ${touched.designation && errors.designation ? "is-invalid" : ""}`}
                                                            value={values.designation || ''}
                                                            onChange={val => { 
                                                                const selectedIndex = val.target.options.selectedIndex; 
                                                                let designation = val.target.options[selectedIndex].getAttribute('label');
                                                                setFieldValue('designation', designation);
                                                            }}
                                                        >
                                                            <option name="designation_" key="3" value="" label="" />
                                                            {designationOptions.map((value, index) => {
                                                                return <option value={value} name={'designation_' + index} key={'designation_' + value} label={value} />
                                                            })}
                                                        </select>
                                                        <ErrorMessage component="div" name="designation" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={individualApproval.designation.hidden}>
                                                        <label>Remarks: <small className="red">{individualApproval.designation.remarks}</small></label>
                                                    </div>

                                                    <div className="form-group" hidden={individualApproval.lastname.hidden}>
                                                        <label htmlFor="lastName"><b>Last Name</b></label>
                                                        <Field type="text" value={values.lastName || ''} name="lastName" className={`form-control ${touched.lastName && errors.lastName ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="lastName" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={individualApproval.lastname.hidden}>
                                                        <label>Remarks: <small className="red">{individualApproval.lastname.remarks}</small></label>
                                                    </div>

                                                    <div className="form-group" hidden={individualApproval.firstname.hidden}>
                                                        <label htmlFor="firstName"><b>First Name</b></label>
                                                        <Field type="text" value={values.firstName || ''} name="firstName" className={`form-control ${touched.firstName && errors.firstName ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="firstName" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={individualApproval.firstname.hidden}>
                                                        <label>Remarks: <small className="red">{individualApproval.firstname.remarks}</small></label>
                                                    </div>

                                                    <div className="form-group" hidden={individualApproval.extname.hidden}>
                                                        <label htmlFor="suffix"><b>Suffix</b></label>
                                                        <Field type="text" value={values.suffix || ''} name="suffix" className={`form-control ${touched.suffix && errors.suffix ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="suffix" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={individualApproval.extname.hidden}>
                                                        <label>Remarks: <small className="red">{individualApproval.extname.remarks}</small></label>
                                                    </div>
                                                    <div className="form-group" hidden={individualApproval.middlename.hidden}>
                                                        <label htmlFor="middleName"><b>Middle Name</b></label>
                                                        <Field type="text" value={values.middleName || ''} name="middleName" className={`form-control ${touched.middleName && errors.middleName ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="middleName" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={individualApproval.middlename.hidden}>
                                                        <label>Remarks: <small className="red">{individualApproval.middlename.remarks}</small></label>
                                                    </div>

                                                    <div className="form-group" hidden={individualApproval.citizenship.hidden}>
                                                        <label htmlFor="citizenship"><b>Citizenship</b></label>
                                                        <div className="form-check">
                                                            <Field id="filipino" className="form-check-input" type="radio" name="filipino" value="0"
                                                                checked={citizenshipStatus === 0} 
                                                                onClick={event => {
                                                                    citizenshipHandler(0);
                                                                    let value = event.target.value;
                                                                    // if(value != citizenshipStatus){
                                                                    //     setFieldValue('requirement', '');
                                                                    // }
                                                                    setFieldValue("citizenship", 0);
                                                                }}
                                                                onChange={event => {
                                                                    setRequirmentLabelHandler('Government-Issued ID (for Filipino OIC)');
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="filipino">Filipino </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <Field id="others" className="form-check-input" type="radio" name="others" value="1" 
                                                                checked={citizenshipStatus === 1}
                                                                onClick={event => {
                                                                    citizenshipHandler(1);
                                                                    let value = event.target.value;
                                                                    // if(value != citizenshipStatus){
                                                                    //     setFieldValue('requirement', '');
                                                                    // }
                                                                    setFieldValue("citizenship", 1);
                                                                }}
                                                                onChange={event => {
                                                                    setRequirmentLabelHandler('Other Issued ID (for Non-Filipino OIC)');
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="others">Others </label>
                                                            { citizenshipStatus === 1 ? showOthers(touched, errors, values) : null }
                                                        </div>
                                                    </div>
                                                    <div className="form-group" hidden={individualApproval.citizenship.hidden}>
                                                        <label>Remarks: <small className="red">{individualApproval.citizenship.remarks}</small></label>
                                                    </div>

                                                    <div className="form-group" hidden={individualApproval.oicFile.hidden}>
                                                        <label htmlFor="requirement"><b>{requirementLabel}</b></label>
                                                        <select name="requirement"
                                                            disabled={true}
                                                            className={`form-control ${touched.requirement && errors.requirement ? "is-invalid" : ""}`}
                                                            value={values.requirement || ''}
                                                            onChange={val => { 
                                                                const selectedIndex = val.target.options.selectedIndex; 
                                                                let code = val.target.options[selectedIndex].getAttribute('name');
                                                                code = code.replace("requirement_", "");
                                                                let label = val.target.options[selectedIndex].getAttribute('label');
                                                                setFieldValue('requirement', code);
                                                                setFieldValue('requirementIdentifier', label);
                                                                setRequirementIdentifierHandler(label);
                                                            }}
                                                        >
                                                            <option name="requirement_" key="3" value="" label="" />
                                                            {transformRequirementList.map((value, index) => {
                                                                return <option value={value.requirement_id} name={'requirement_' + value.requirement_id} key={'requirement_' + index} label={value.requirement_name} />
                                                            })}
                                                        </select>
                                                        <ErrorMessage component="div" name="requirement" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={individualApproval.oicFile.hidden}>
                                                        <small className="file" onClick={event => {
                                                            let uuid = individualValue.oicFile.uuid;
                                                            let name = individualValue.oicFile.file_name;
                                                            viewFile(uuid, name);
                                                        }}>
                                                        {individualValue.oicFile.file_name}
                                                        </small>
                                                    </div>
                                                    <div className="form-group" hidden={individualApproval.oicFile.hidden}>
                                                        <label htmlFor="requirementFile">Upload</label>
                                                        <ImageUploaderField accept="image/*,application/pdf" name="requirementFile" type="file" value={undefined}
                                                            className={`form-control-file ${touched.requirementFile && errors.requirementFile ? "is-invalid" : ""}`}
                                                            onChange={value => {
                                                                setFieldValue("requirementFile", value.currentTarget.files[0]);
                                                            }}
                                                        />
                                                        <ErrorMessage component="div" name="requirementFile" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={individualApproval.oicFile.hidden}>
                                                        <label>Remarks: <small className="red">{individualApproval.oicFile.remarks}</small></label>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            <h4>Description of your Startup Business</h4>

                                            <div className="form-group" hidden={individualApproval.startup_description.hidden}>
                                                <label htmlFor="description"><strong>Brief description of your Startup Business</strong></label>
                                                <Field type="text" as="textarea" rows="5" value={values.description || ''} name="description" className={`form-control ${touched.description && errors.description ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="description" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={individualApproval.startup_description.hidden}>
                                                <label>Remarks: <small className="red">{individualApproval.startup_description.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={individualApproval.problems_solved.hidden}>
                                                <label htmlFor="problemSolved"><strong>Problems being solved </strong>(seperate by comma)</label>
                                                <Field type="text" rows="5" value={values.problemSolved || ''} name="problemSolved" className={`form-control ${touched.problemSolved && errors.problemSolved ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="problemSolved" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={individualApproval.problems_solved.hidden}>
                                                <label>Remarks: <small className="red">{individualApproval.problems_solved.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={individualApproval.products_offered.hidden}>
                                                <label htmlFor="idea"><strong>Idea or Product being offered</strong></label>
                                                <Field type="text" as="textarea" rows="5" value={values.idea || ''} name="idea" className={`form-control ${touched.idea && errors.idea ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="idea" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={individualApproval.products_offered.hidden}>
                                                <label>Remarks: <small className="red">{individualApproval.products_offered.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={individualApproval.usp.hidden}>
                                                <label htmlFor="usp"><strong>Unique Selling Proposition (USP) of your solution</strong></label>
                                                <Field type="text" as="textarea" rows="5" value={values.usp || ''} name="usp" className={`form-control ${touched.usp && errors.usp ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="usp" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={individualApproval.usp.hidden}>
                                                <label>Remarks: <small className="red">{individualApproval.usp.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={individualApproval.startup_stage.hidden}>
                                                <label><strong>Startup Development Stage</strong></label>
                                                {startupDevelopmentOptions.map((value, index) => {
                                                    return 	<div className="form-check" key={`startup_stage_${value.id}`}>
                                                        <Field id={`startup_stage_radioButton_${value.id}`} className="form-check-input" type="radio" name={`startupStage_${value.name}`} value={value.id}
                                                            checked={ values.startupStage ? values.startupStage == value.id : false }
                                                            onClick={event => {
                                                                let value = event.target.value;
                                                                setFieldValue("startupStage", value);
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor={`startup_stage_radioButton_${value.id}`}>{value.name}</label>
                                                    </div>
                                                })}
                                            </div>
                                            <div className="form-group" hidden={individualApproval.startup_stage.hidden}>
                                                <label>Remarks: <small className="red">{individualApproval.startup_stage.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={individualApproval.industry_classification.hidden}>
                                                <label><strong>Industry Classification</strong></label>
                                                <FieldArray
                                                    name="classification"
                                                    className={`form-control ${touched.classification && errors.classification ? "is-invalid" : ""}`}
                                                    render={arrayHelpers => (
                                                        isLoaded ? 
                                                            classificationList.map((value, index) => {
                                                                return 	<div key={`classification_${value.id}`} className="form-check">
                                                                    <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={`classification_${index}`}
                                                                    value={value.id}
                                                                    checked={value.checked}
                                                                    onChange={event => {
                                                                        
                                                                    }}
                                                                    onClick={event => {
                                                                        let checked = event.target.checked;
                                                                        let find = values.classification.indexOf(value);
                                                                        value.checked = checked;
                                                                        if(checked){
                                                                            arrayHelpers.insert('', value);
                                                                        }else{
                                                                            if(find !== -1){
                                                                                arrayHelpers.remove(find);
                                                                            }else{
                                                                                const classification = values.classification.find(item => item == value.id);
                                                                                const otherIndex = values.classification.indexOf(classification);
                                                                                arrayHelpers.remove(otherIndex);
                                                                            }
                                                                        }
                                                                    }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`classification_${index}`}>{value.name}</label>
                                                                </div>
                                                            })
                                                        :
                                                        null
                                                    )}
                                                />
                                                <ErrorMessage component="div" name="classification" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={individualApproval.industry_classification.hidden}>
                                                <label>Remarks: <small className="red">{individualApproval.industry_classification.remarks}</small></label>
                                            </div>
                                            
                                            <div className="form-group" hidden={individualApproval.startup_website.hidden}>
                                                <label htmlFor="website"><strong>Startup website or social media url</strong></label>
                                                <Field type="text" value={values.website || ''} name="website" className={`form-control ${touched.website && errors.website ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="website" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={individualApproval.startup_website.hidden}>
                                                <label>Remarks: <small className="red">{individualApproval.startup_website.remarks}</small></label>
                                            </div>

                                            <div className="py-4 text-center mt-3">
                                                <button className="startup-btn-main mx-2" type="button" onClick={back}>Back</button>
                                                <button className="startup-btn-main" type="submit">Update</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        : 
                            <div className="col-md-6">
                                <Formik
                                    innerRef={formikRef}
                                    validationSchema={businessSchema}
                                    initialValues={Object.assign(businessValue)}
                                    onSubmit={submitBusiness}
                                >
                                    {({ handleSubmit, handleBlur, handleChange, setFieldValue, errors, touched, values, field }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <div className="form-group" hidden={businessApproval.business_name.hidden}>
                                                <label htmlFor="businessName"><strong>Business Name</strong></label>
                                                <Field type="text" value={values.businessName || ''} name="businessName" className={`form-control ${touched.businessName && errors.businessName ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="businessName" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={businessApproval.business_name.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.business_name.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={businessApproval.business_class_id.hidden}>
                                                <label><strong>Business Classification</strong></label>
                                                {businessClassificationList.map((value, index) => {
                                                    return 	<div className="form-check" key={`stage_${value.business_id}`}>
                                                        <Field id={`radioButton_${value.business_id}`} className="form-check-input" type="radio" name={`businessClassification_${value.name}`} value={value.id}
                                                            checked={ values.businessClassification ? values.businessClassification == value.business_id : false }
                                                            onClick={event => {
                                                                setFieldValue("businessClassification", value.business_id);
                                                                setRegistrationLabelHandler(value.business_id);
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor={`radioButton_${value.business_id}`}>{value.name}</label>
                                                        <ErrorMessage component="div" name="businessClassification" className="invalid-feedback" />
                                                    </div>
                                                })}
                                            </div>
                                            <div className="form-group" hidden={businessApproval.business_class_id.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.business_class_id.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={businessApproval.business_regulatory_no.hidden}>
                                                <label htmlFor="registrationNo"><strong>{registrationLabel} Registration No.</strong></label>
                                                <Field type="text" value={values.registrationNo || ''} name="registrationNo" className={`form-control ${touched.registrationNo && errors.registrationNo ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="registrationNo" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={businessApproval.business_regulatory_no.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.business_regulatory_no.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={businessApproval.proofFile.hidden}>
                                                <label htmlFor="registration"><b>Proof of registration or government accreditation</b></label>
                                                <select name="registration"
                                                    disabled={true}
                                                    className={`form-control ${touched.registration && errors.registration ? "is-invalid" : ""}`}
                                                    value={values.registration || ''}
                                                    onChange={val => { 
                                                        const selectedIndex = val.target.options.selectedIndex; 
                                                        let code = val.target.options[selectedIndex].getAttribute('name');
                                                        code = code.replace("registration_", "");
                                                        let label = val.target.options[selectedIndex].getAttribute('label');
                                                        setFieldValue('registration', code);
                                                        setFieldValue('registrationIdentifier', label);
                                                    }}
                                                >
                                                    <option name="registration_" key="3" value="" label="" />
                                                    {proofList.map((value, index) => {
                                                        return <option value={value.requirement_id} name={'proof_' + value.requirement_id} key={'proof_' + index} label={value.requirement_name} />
                                                    })}
                                                </select>
                                                <ErrorMessage component="div" name="registration" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={businessApproval.proofFile.hidden}>
                                                <small className="file" onClick={event => {
                                                    let uuid = businessValue.proofFile.uuid;
                                                    let name = businessValue.proofFile.file_name;
                                                    viewFile(uuid, name);
                                                }}>
                                                {businessValue.proofFile.file_name}
                                                </small>
                                            </div>
                                            <div className="form-group" hidden={businessApproval.proofFile.hidden}>
                                                <label htmlFor="registrationFile">Upload</label>
                                                <ImageUploaderField accept="image/*,application/pdf" name="registrationFile" type="file" value={undefined}
                                                    className={`form-control-file ${touched.registrationFile && errors.registrationFile ? "is-invalid" : ""}`}
                                                    onChange={value => {
                                                        setFieldValue("registrationFile", value.currentTarget.files[0]);
                                                    }}
                                                />
                                                <ErrorMessage component="div" name="registrationFile" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={businessApproval.proofFile.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.proofFile.remarks}</small></label>
                                            </div>
                                                
                                            <div className="form-group" hidden={businessApproval.founding_year.hidden}>
                                                <label htmlFor="yearFounding"><strong>Year of Founding/Registration</strong></label>
                                                <Field type="text" value={values.yearFounding || ''} name="yearFounding" className={`form-control w-25 ${touched.yearFounding && errors.yearFounding ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="yearFounding" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={businessApproval.founding_year.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.founding_year.remarks}</small></label>
                                            </div>
                                            
                                            <div className="form-group" hidden={businessApproval.address_line_1.hidden}>
                                                <label><strong>Office Address</strong></label>
                                                <div className="form-group">
                                                    <label htmlFor="region">Region</label>
                                                    <select name="region"
                                                        value={values.region || ''}
                                                        className={`form-control ${touched.region && errors.region ? "is-invalid" : ""}`}
                                                        onChange={val => { 
                                                            const selectedIndex = val.target.options.selectedIndex;
                                                            let code = val.target.options[selectedIndex].getAttribute('value');
                                                            let region = val.target.options[selectedIndex].getAttribute('label');
                                                            setFieldValue('region', code);
                                                            setFieldValue('regionName', region);
                                                            setSelectedRegion(region);

                                                            setProvinceList([]);
                                                            setCityList([]);
                                                            setBarangayList([]);
                                                            getProvince(code);
                                                        }}
                                                    >
                                                        <option name="region_" key="0" value="" label="" />
                                                        {regionList.map((value, index) => {
                                                            return <option value={value.region_psgc} name={'region_' + value.region_psgc} key={'region_' + index} label={value.region_code + ' - ' +value.region_name} />
                                                        })}
                                                    </select>
                                                    <ErrorMessage component="div" name="region" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="province">Province</label>
                                                    <select name="province"
                                                        className={`form-control ${touched.province && errors.province ? "is-invalid" : ""}`}
                                                        value={values.province || ''}
                                                        onChange={val => { 
                                                            const selectedIndex = val.target.options.selectedIndex; 
                                                            let code = val.target.options[selectedIndex].getAttribute('name');
                                                            code = code.replace("province_", "");
                                                            let province = val.target.options[selectedIndex].getAttribute('label');
                                                            setFieldValue('province', code);
                                                            setFieldValue('provinceName', province);
                                                            setSelectedProvince(province);

                                                            setCityList([]);
                                                            setBarangayList([]);
                                                            getCity(code);
                                                        }}
                                                    >
                                                        <option name="province_" key="1" value="" label="" />
                                                        {provinceList.map((value, index) => {
                                                            return <option value={value.province_psgc} name={'province_' + value.province_psgc} key={'province_' + value.province_psgc} label={value.province_name} />
                                                        })}
                                                    </select>
                                                    <ErrorMessage component="div" name="province" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="city">City/Municipality</label>
                                                    <select name="city"
                                                        className={`form-control ${touched.city && errors.city ? "is-invalid" : ""}`}
                                                        value={values.city || ''}
                                                        onChange={val => { 
                                                            const selectedIndex = val.target.options.selectedIndex; 
                                                            let code = val.target.options[selectedIndex].getAttribute('name');
                                                            code = code.replace("city_", "");
                                                            let city = val.target.options[selectedIndex].getAttribute('label');
                                                            setFieldValue('city', code);
                                                            setFieldValue('cityName', city);
                                                            setSelectedCity(city);

                                                            setBarangayList([]);
                                                            getBarangay(code);
                                                        }}
                                                    >
                                                        <option name="city_" key="2" value="" label="" />
                                                        {cityList.map((value, index) => {
                                                            return <option value={value.city_municipality_psgc} name={'city_' + value.city_municipality_psgc} key={'city_' + value.city_municipality_psgc} label={value.city_municipality_name} />
                                                        })}
                                                    </select>
                                                    <ErrorMessage component="div" name="city" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="barangay">Barangay</label>
                                                    <select name="barangay"
                                                        className={`form-control ${touched.barangay && errors.barangay ? "is-invalid" : ""}`}
                                                        value={values.barangay || ''}
                                                        onChange={val => { 
                                                            const selectedIndex = val.target.options.selectedIndex; 
                                                            let code = val.target.options[selectedIndex].getAttribute('name');
                                                            code = code.replace("barangay_", "");
                                                            let barangay = val.target.options[selectedIndex].getAttribute('label');
                                                            setFieldValue('barangay', code);
                                                            setFieldValue('barangayName', barangay);
                                                            setSelectedBarangay(barangay);
                                                        }}
                                                    >
                                                        <option name="barangay_" key="3" value="" label="" />
                                                        {barangayList.map((value, index) => {
                                                            return <option value={value.barangay_psgc} name={'barangay_' + value.barangay_psgc} key={'barangay_' + value.barangay_psgc} label={value.barangay_name} />
                                                        })}
                                                    </select>
                                                    <ErrorMessage component="div" name="barangay" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="streetNo">Street No., Village, Subdivision etc.</label>
                                                    <Field type="text" name="streetNo" className={`form-control ${touched.streetNo && errors.streetNo ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="streetNo" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="lotBlockUnit">Lot, Block, Unit, Floor No., Etc.</label>
                                                    <Field type="text" name="lotBlockUnit" className={`form-control ${touched.lotBlockUnit && errors.lotBlockUnit ? "is-invalid" : ""}`} />
                                                    <ErrorMessage component="div" name="lotBlockUnit" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                <label htmlFor="zipCode">Zip Code</label>
                                                <Field type="text" name="zipCode" className={`form-control ${touched.zipCode && errors.zipCode ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="zipCode" className="invalid-feedback" />
                                            </div>
                                                <div className="form-group" hidden={businessApproval.address_line_1.hidden}>
                                                    <label>Remarks: <small className="red">{businessApproval.address_line_1.remarks}</small></label>
                                                </div>
                                            </div>
                                            <div className="startup-individual-container page-1">
                                                <label hidden={showOICLabel}><strong>Officer-in-Charge Information</strong></label>
                                                <div className="border-left pl-3">
                                                    <div className="form-group" hidden={businessApproval.designation.hidden}>
                                                        <label htmlFor="designation"><strong>Designation</strong></label>
                                                        <select name="designation"
                                                            className={`form-control ${touched.designation && errors.designation ? "is-invalid" : ""}`}
                                                            value={values.designation || ''}
                                                            onChange={val => { 
                                                                const selectedIndex = val.target.options.selectedIndex; 
                                                                let designation = val.target.options[selectedIndex].getAttribute('label');
                                                                setFieldValue('designation', designation);
                                                            }}
                                                        >
                                                            <option name="designation_" key="3" value="" label="" />
                                                            {designationOptions.map((value, index) => {
                                                                return <option value={value} name={'designation_' + index} key={'designation_' + value} label={value} />
                                                            })}
                                                        </select>
                                                        <ErrorMessage component="div" name="designation" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={businessApproval.designation.hidden}>
                                                        <label>Remarks: <small className="red">{businessApproval.designation.remarks}</small></label>
                                                    </div>
                                                    
                                                    <div className="form-group" hidden={businessApproval.lastname.hidden}>
                                                        <label htmlFor="lastName"><b>Last Name</b></label>
                                                        <Field type="text" value={values.lastName || ''} name="lastName" className={`form-control ${touched.lastName && errors.lastName ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="lastName" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={businessApproval.lastname.hidden}>
                                                        <label>Remarks: <small className="red">{businessApproval.lastname.remarks}</small></label>
                                                    </div>

                                                    <div className="form-group" hidden={businessApproval.firstname.hidden}>
                                                        <label htmlFor="firstName"><b>First Name</b></label>
                                                        <Field type="text" value={values.firstName || ''} name="firstName" className={`form-control ${touched.firstName && errors.firstName ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="firstName" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={businessApproval.firstname.hidden}>
                                                        <label>Remarks: <small className="red">{businessApproval.firstname.remarks}</small></label>
                                                    </div>

                                                    <div className="form-group" hidden={businessApproval.extname.hidden}>
                                                        <label htmlFor="suffix"><b>Suffix</b></label>
                                                        <Field type="text" value={values.suffix || ''} name="suffix" className={`form-control ${touched.suffix && errors.suffix ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="suffix" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={businessApproval.extname.hidden}>
                                                        <label>Remarks: <small className="red">{businessApproval.extname.remarks}</small></label>
                                                    </div>

                                                    <div className="form-group" hidden={businessApproval.middlename.hidden}>
                                                        <label htmlFor="middleName"><b>Middle Name</b></label>
                                                        <Field type="text" value={values.middleName || ''} name="middleName" className={`form-control ${touched.middleName && errors.middleName ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="middleName" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={businessApproval.middlename.hidden}>
                                                        <label>Remarks: <small className="red">{businessApproval.middlename.remarks}</small></label>
                                                    </div>
                                                    
                                                    <div className="form-group" hidden={businessApproval.citizenship.hidden}>
                                                        <label htmlFor="citizenship"><b>Citizenship</b></label>
                                                        <div className="form-check">
                                                            <Field id="filipino" className="form-check-input" type="radio" name="filipino" value="0"
                                                                checked={citizenshipStatus === 0} 
                                                                onClick={event => {
                                                                    citizenshipHandler(0);
                                                                    let value = event.target.value;
                                                                    // if(value != citizenshipStatus){
                                                                    //     setFieldValue('requirement', '');
                                                                    // }
                                                                    setFieldValue("citizenship", 0);
                                                                }}
                                                                onChange={event => {
                                                                    setRequirmentLabelHandler('Government-Issued ID (for Filipino OIC)');
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="filipino">Filipino </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <Field id="others" className="form-check-input" type="radio" name="others" value="1" 
                                                                checked={citizenshipStatus === 1}
                                                                onClick={event => {
                                                                    citizenshipHandler(1);
                                                                    let value = event.target.value;
                                                                    // if(value != citizenshipStatus){
                                                                    //     setFieldValue('requirement', '');
                                                                    // }
                                                                    setFieldValue("citizenship", 1);
                                                                }}
                                                                onChange={event => {
                                                                    setRequirmentLabelHandler('Other Issued ID (for Non-Filipino OIC)');
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="others">Others </label>
                                                            { citizenshipStatus === 1 ? showOthers(touched, errors, values) : null }
                                                        </div>
                                                    </div>
                                                    <div className="form-group" hidden={businessApproval.citizenship.hidden}>
                                                        <label>Remarks: <small className="red">{businessApproval.citizenship.remarks}</small></label>
                                                    </div>

                                                    <div className="form-group" hidden={businessApproval.contact_no.hidden}>
                                                        <label htmlFor="contact_no"><b>Contact No.</b></label>
                                                        <Field type="text" value={values.contact_no || ''} name="contact_no" className={`form-control ${touched.contact_no && errors.contact_no ? "is-invalid" : ""}`} />
                                                        <ErrorMessage component="div" name="contact_no" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={businessApproval.contact_no.hidden}>
                                                        <label>Remarks: <small className="red">{businessApproval.contact_no.remarks}</small></label>
                                                    </div>

                                                    <div className="form-group" hidden={businessApproval.oicFile.hidden}>
                                                        <label htmlFor="requirement"><b>{requirementLabel}</b></label>
                                                        <select name="requirement"
                                                            disabled={true}
                                                            className={`form-control ${touched.requirement && errors.requirement ? "is-invalid" : ""}`}
                                                            value={values.requirement || ''}
                                                            onChange={val => { 
                                                                const selectedIndex = val.target.options.selectedIndex; 
                                                                let code = val.target.options[selectedIndex].getAttribute('name');
                                                                code = code.replace("requirement_", "");
                                                                let label = val.target.options[selectedIndex].getAttribute('label');
                                                                setFieldValue('requirement', code);
                                                                setFieldValue('requirementIdentifier', label);
                                                                setRequirementIdentifierHandler(label);
                                                            }}
                                                        >
                                                            <option name="requirement_" key="3" value="" label="" />
                                                            {transformRequirementList.map((value, index) => {
                                                                return <option value={value.requirement_id} name={'requirement_' + value.requirement_id} key={'requirement_' + index} label={value.requirement_name} />
                                                            })}
                                                        </select>
                                                        <ErrorMessage component="div" name="requirement" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={businessApproval.oicFile.hidden}>
                                                        <small className="file" onClick={event => {
                                                            let uuid = businessValue.oicFile.uuid;
                                                            let name = businessValue.oicFile.file_name;
                                                            viewFile(uuid, name);
                                                        }}>
                                                        {businessValue.oicFile.file_name}
                                                        </small>
                                                    </div>
                                                    <div className="form-group" hidden={businessApproval.oicFile.hidden}>
                                                        <label htmlFor="requirementFile">Upload</label>
                                                        <ImageUploaderField accept="image/*,application/pdf" name="requirementFile" type="file" value={undefined}
                                                            className={`form-control-file ${touched.requirementFile && errors.requirementFile ? "is-invalid" : ""}`}
                                                            onChange={value => {
                                                                setFieldValue("requirementFile", value.currentTarget.files[0]);
                                                            }}
                                                        />
                                                        <ErrorMessage component="div" name="requirementFile" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group" hidden={businessApproval.oicFile.hidden}>
                                                        <label>Remarks: <small className="red">{businessApproval.oicFile.remarks}</small></label>
                                                    </div>
                                                    <div hidden={startupVersion === 1 ? true : false}>
                                                        <div hidden={citizenshipStatus === 0 ? true : false}>
                                                            <div className="form-group" hidden={businessApproval.oicForeignOtherRequirementFile.hidden}>
                                                                <label><b>DTI Certificate of Authority to Engage in Business in the Philippines (for Non-Filipino OIC)</b></label>
                                                                <small className="file" onClick={event => {
                                                                    let uuid = businessValue.oicForeignOtherRequirementFile.uuid;
                                                                    let name = businessValue.oicForeignOtherRequirementFile.file_name;
                                                                    viewFile(uuid, name);
                                                                }}>
                                                                {businessValue.oicForeignOtherRequirementFile.file_name}
                                                                </small>
                                                            </div>
                                                            <div className="form-group" hidden={businessApproval.oicForeignOtherRequirementFile.hidden}>
                                                                <ImageUploaderField accept="image/*,application/pdf" name="oicForeignOtherRequirementFile" type="file" value={undefined}
                                                                    className={`form-control-file ${touched.oicForeignOtherRequirementFile && errors.oicForeignOtherRequirementFile ? "is-invalid" : ""}`}
                                                                    onChange={value => {
                                                                        setFieldValue("oicForeignOtherRequirementFile", value.currentTarget.files[0]);
                                                                    }}
                                                                />
                                                                <ErrorMessage component="div" name="oicForeignOtherRequirementFile" className="invalid-feedback" />
                                                            </div>
                                                            <div className="form-group" hidden={businessApproval.oicForeignOtherRequirementFile.hidden}>
                                                                <label>Remarks: <small className="red">{businessApproval.oicForeignOtherRequirementFile.remarks}</small></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-check">
                                                        {/* <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="officerInCharge"
                                                        onChange={event => {
                                                            setOfficerInChargeHandler();
                                                        }}
                                                        onClick={event => {
                                                            let checked = event.target.checked;
                                                            onClickOfficerInCharge(checked, setFieldValue, touched, errors);
                                                        }}
                                                        checked={isOfficerInCharge === true}
                                                        />
                                                        <label className="form-check-label" htmlFor="officerInCharge">
                                                            I am the Officer-in-Charge. Use my personal information to
                                                            fill out this section.
                                                        </label> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <h4 hidden={showBusinessDesc}>Description of your Startup Business</h4>

                                            <div className="form-group" hidden={businessApproval.startup_description.hidden}>
                                                <label htmlFor="description"><strong>Brief description of your Startup Business</strong></label>
                                                <Field type="text" as="textarea" rows="5" value={values.description || ''} name="description" className={`form-control ${touched.description && errors.description ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="description" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={businessApproval.startup_description.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.startup_description.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={businessApproval.problems_solved.hidden}>
                                                <label htmlFor="problemSolved"><strong>Problems being solved </strong>(seperate by comma)</label>
                                                <Field type="text" rows="5" value={values.problemSolved || ''} name="problemSolved" className={`form-control ${touched.problemSolved && errors.problemSolved ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="problemSolved" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={businessApproval.problems_solved.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.problems_solved.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={businessApproval.products_offered.hidden}>
                                                <label htmlFor="idea"><strong>Idea or Product being offered</strong></label>
                                                <Field type="text" as="textarea" rows="5" value={values.idea || ''} name="idea" className={`form-control ${touched.idea && errors.idea ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="idea" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={businessApproval.products_offered.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.products_offered.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={businessApproval.usp.hidden}>
                                                <label htmlFor="usp"><strong>Unique Selling Proposition (USP) of your solution</strong></label>
                                                <Field type="text" as="textarea" rows="5" value={values.usp || ''} name="usp" className={`form-control ${touched.usp && errors.usp ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="usp" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={businessApproval.usp.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.usp.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={businessApproval.startup_stage.hidden}>
                                                <label><strong>Startup Development Stage</strong></label>
                                                {startupDevelopmentOptions.map((value, index) => {
                                                    return 	<div className="form-check" key={`startup_stage_${value.id}`}>
                                                        <Field id={`startup_stage_radioButton_${value.id}`} className="form-check-input" type="radio" name={`startupStage_${value.name}`} value={value.id}
                                                            checked={ values.startupStage ? values.startupStage == value.id : false }
                                                            onClick={event => {
                                                                let value = event.target.value;
                                                                setFieldValue("startupStage", value);
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor={`startup_stage_radioButton_${value.id}`}>{value.name}</label>
                                                    </div>
                                                })}
                                            </div>
                                            <div className="form-group" hidden={businessApproval.startup_stage.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.startup_stage.remarks}</small></label>
                                            </div>

                                            <div className="form-group" hidden={businessApproval.industry_classification.hidden}>
                                                <label><strong>Industry Classification</strong></label>
                                                <FieldArray
                                                    name="classification"
                                                    className={`form-control ${touched.classification && errors.classification ? "is-invalid" : ""}`}
                                                    render={arrayHelpers => (
                                                        isLoaded ? 
                                                            classificationList.map((value, index) => {
                                                                return 	<div key={`classification_${value.id}`} className="form-check">
                                                                    <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={`classification_${index}`}
                                                                    value={value.id}
                                                                    checked={value.checked}
                                                                    onChange={event => {
                                                                        
                                                                    }}
                                                                    onClick={event => {
                                                                        let checked = event.target.checked;
                                                                        let find = values.classification.indexOf(value);
                                                                        value.checked = checked;
                                                                        if(checked){
                                                                            arrayHelpers.insert('', value);
                                                                        }else{
                                                                            if(find !== -1){
                                                                                arrayHelpers.remove(find);
                                                                            }else{
                                                                                const classification = values.classification.find(item => item == value.id);
                                                                                const otherIndex = values.classification.indexOf(classification);
                                                                                arrayHelpers.remove(otherIndex);
                                                                            }
                                                                        }
                                                                    }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`classification_${index}`}>{value.name}</label>
                                                                </div>
                                                            })
                                                        :
                                                        null
                                                    )}
                                                />
                                                <ErrorMessage component="div" name="classification" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={businessApproval.industry_classification.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.industry_classification.remarks}</small></label>
                                            </div>
                                            
                                            <div className="form-group" hidden={businessApproval.startup_website.hidden}>
                                                <label htmlFor="website"><strong>Startup website or social media url</strong></label>
                                                <Field type="text" value={values.website || ''} name="website" className={`form-control ${touched.website && errors.website ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="website" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group" hidden={businessApproval.startup_website.hidden}>
                                                <label>Remarks: <small className="red">{businessApproval.startup_website.remarks}</small></label>
                                            </div>

                                            <div className="py-4 text-center mt-3">
                                                <button type="button" className="startup-btn-main mx-2" onClick={back}>Back</button>
                                                <button type="submit" className="startup-btn-main">Update</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        )
                    }
                </div>
            </div>
		</React.Fragment>
	);
};
export default EditStartup;